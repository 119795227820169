import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import ExitToAppIconOutlined from "@material-ui/icons/ExitToAppOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import { Zoom } from "react-awesome-reveal";

import { useUiContext } from "../../Contexts/UiContext";
import { resetPassword } from "../../store/actions/userActions";
import { UI_API_BASE } from "../../store/env";
import verifySchema from "./../../util/yup/verifySchema";
import { passwordResetSchema } from "./../../util/yup/yupSchemas";

const PasswordResetForm = ({ firestoreObbCode, isModeVerify }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();

  const { register, handleSubmit } = useForm();
  const { errors, setErrors, clearErrors } = useUiContext();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [verifyEmailError, setVerifyEmailError] = useState(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ValidationType = ({ type }) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <Typography style={{ color: "red" }}>{ErrorMessage}</Typography>
      </Zoom>
    );
  };

  const onSubmit = async (data) => {
    data.code = firestoreObbCode;

    const valid = await verifySchema(
      passwordResetSchema,
      data,
      clearErrors,
      setErrors
    );

    if (valid) {
      dispatch(resetPassword(data, history));
    }
  };

  const verifyUserEmail = async () => {
    let firestoreObbCode1 = new URLSearchParams(location.search).get("oobCode");
    let firestoreKey = new URLSearchParams(location.search).get("apiKey");

    let body = {
      oobCode: firestoreObbCode1,
    };

    try {
      setLoading(true);
      const response = await fetch(
        `https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=${firestoreKey}`,
        {
          headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            "content-type": "application/json",
            "sec-fetch-dest": "empty",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "cross-site",
            "sec-gpc": "1",
            "x-client-version": "Chrome/JsCore/3.7.5/FirebaseCore-web",
          },
          referrer: "https://payeda-e2b59.firebaseapp.com/",
          referrerPolicy: "strict-origin-when-cross-origin",
          body: JSON.stringify(body),
          method: "POST",
          mode: "cors",
          credentials: "omit",
        }
      );

      const json = await response.json();
      return json;
    } catch (error) {
      return error;
    }
  };
  // eslint-disable-next-line
  useEffect(async () => {
    if (isModeVerify) {
      // eslint-disable-next-line
      let result = await verifyUserEmail();

      if (result.error) {
        setVerifyEmailError(true);
        setLoading(false);
      } else {
        setVerifyEmailError(false);
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [isModeVerify, firestoreObbCode]);

  return (
    <div style={{ padding: 100 }}>
      <Grid
        container
        spacing={3}
        direction={"column"}
        justify={"center"}
        alignItems={"center"}
      >
        {!isModeVerify && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />{" "}
            </Grid>
            <Grid item xs={12}>
              <ValidationType type="password" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="confirmPassword"
                name="confirmPassword"
                label="Repeat Password"
                type={showPassword ? "text" : "password"}
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <ValidationType type="confirmPassword" />
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                fullWidth
              >
                Reset Password
              </Button>
            </Grid>{" "}
          </>
        )}

        {!isLoading && isModeVerify && (
          <>
            {!verifyEmailError && (
              <>
                <Grid item xs={12}>
                  <h2>Your email has been verified</h2>
                  <p>You can now sign in with your account</p>
                </Grid>
              </>
            )}

            {verifyEmailError && (
              <>
                <Grid item xs={12}>
                  <h2>Try verifying your email again</h2>
                  <p>
                    Your request to verify your email has expired or the link
                    has already been used
                  </p>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid item xs={2}>
          <Button
            variant="contained"
            startIcon={<ExitToAppIconOutlined />}
            onClick={() => (window.location.href = `${UI_API_BASE}/signin`)}
            fullWidth
          >
            Signin
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PasswordResetForm;
