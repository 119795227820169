const initialState = {
  isLoading: false,
  members: [],
  errorMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "PACKAGE_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case "PACKAGE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        allPackages: action.payload,
      };
    case "SINGLE_PACKAGE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        member: action.payload,
      };
    default:
      return state;
  }
}
