import React from "react";
//Vendor
import styled from "@react-pdf/styled-components";
import { Document, Font } from "@react-pdf/renderer";
import Noto from "../NotoSans-Regular.ttf";

import currency from "currency.js";
Font.register({
  family: "Noto Sans",
  format: "truetype",
  src: Noto,
});

const BillPage = styled.Page`
  padding: 80px 40px;
  font-family: "Noto Sans";
`;

const BillDetails = styled.View`
  display: table;
  width: auto;
  margin: 0 auto;
  flex-direction: row;
`;

const BillColumnLeft = styled.View`
  width: 50%;
  padding-right: 50px;
  padding-left: 0px;

  text-align: left;
`;
const BillColumnRight = styled(BillColumnLeft)`
  padding-left: 50px;
  padding-right: 0px;
  text-align: right;
`;

const InvoiceHeading = styled.Text`
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  width: 100%;
`;
const InvoiceNumber = styled.Text`
  color: #444;
  font-size: 12px;
`;

const Details = styled.Text`
  font-size: 12;
  padding: 5px 0;
  line-height: 1.2;
`;

const BillTable = styled.View`
  display: table;
  width: 100%;
`;
const BillRow = styled.View`
  margin: 0 auto;
  flex-direction: row;
  padding: 8px 0;
`;
const BillRowHead = styled(BillRow)`
  width: 100%;
  background-color: #333;
  font-size: 15px;
  border-radius: 2px;
  color: white;
`;

const BillDataText = styled.Text`
  width: 50%;
  padding: 0 5px;
  font-size: 12px;
`;
const BillDataNum = styled.Text`
  width: 45%;
  text-align: right;
  padding: 0 5px;
  font-size: 12px;
`;
const BillDataSerial = styled(BillDataNum)`
  width: 5%;
`;

function InvoicePDF(props) {
  let data = props.invoiceData;
  let invoices = data.invoices;

  let invoiceType = data.payeda_package_id;
  const itemList = invoices.map(({ invoice_number, invoice_amount, id }, i) => (
    <BillRow key={id}>
      <BillDataSerial>{i + 1}</BillDataSerial>
      <BillDataText>{invoice_number}</BillDataText>
      <BillDataNum>{invoice_amount}</BillDataNum>
    </BillRow>
  ));

  return (
    <Document>
      <BillPage>
        <BillDetails>
          <BillColumnLeft>
            <Details style={{ marginTop: "60px" }}>Paid To: </Details>
            <Details style={{ fontSize: "10px" }}>{data?.supplier_data?.name}</Details>
            <Details style={{ fontSize: "10px" }}>
              {data.supplier_data?.address.country ?? ""} {data?.supplier_data.address?.city ?? ""}{" "}
            </Details>
            <Details style={{ fontSize: "10px" }}>
              {data.supplier_data?.address?.address_line_1 ?? ""} {data.supplier_data?.address?.address_line_1 ?? ""}{" "}
            </Details>

            <InvoiceNumber style={{ fontSize: "10px" }}>Receipt Id: {data.id}</InvoiceNumber>
          </BillColumnLeft>
          <BillColumnRight>
            <InvoiceHeading>{invoiceType ? "Payment note" : "RECEIPT"}</InvoiceHeading>
            <Details>Date: {data.paid_at}</Details>

            <Details style={{ marginTop: "20px" }}>Paid By: </Details>
            <Details style={{ fontSize: "10px" }}>{data?.customer_data?.remitter_name ?? ""}</Details>
            <Details style={{ fontSize: "10px" }}>
              Ref:
              {data?.remitter_reference ?? ""}
            </Details>
          </BillColumnRight>
        </BillDetails>
        {!invoiceType && (
          <>
            <BillTable style={{ marginTop: "5px" }}>
              <BillRowHead>
                <BillDataSerial>#</BillDataSerial>
                <BillDataText>Invoice Number </BillDataText>
                <BillDataNum>Total Amount</BillDataNum>
              </BillRowHead>
            </BillTable>
            {itemList}
          </>
        )}

        {invoiceType && (
          <>
            <BillTable>
              <BillRowHead>
                <BillDataText>Payment Details</BillDataText>
              </BillRowHead>
            </BillTable>
            <Details>Payeda Package Payment - {data.detail === "Membership" ? "Membership / 90 Days" : data.detail || ""}</Details>
          </>
        )}
        <Details style={{ float: "right", textAlign: "right", marginTop: "20px" }}>Total: GBP {currency(data.amount).format({ symbol: "" })}</Details>

        {/* <BillDetails style={{ padding: "0 5px", marginTop: "50px" }}>
          <BillColumnLeft>
            <Details>Beneficiary Account Number: </Details>
            <Details>Remitter Account Number: </Details>
          </BillColumnLeft>
          <BillColumnRight>
            <Details>
              {data.beneficiary_account_number}- {data.beneficiary_sort_code}
            </Details>
            <Details>
              {data.remitter_account_number}- {data.remitter_sort_code}
            </Details>
          </BillColumnRight>
        </BillDetails> */}
      </BillPage>
    </Document>
  );
}

export default InvoicePDF;
