import { Grid, Link, Typography, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { removeAssignedPackagesFromBusiness } from "../../../store/actions/adminActions";
const PackageDetailsDialog = ({
  open,
  setOpen,
  title,
  description,
  business,
}) => {
  const dispatch = useDispatch();

  const handleRemovePackage = (id) => {
    let businessId = business.id;
    let dataToSend = {
      business_id: businessId,
      package_id: id,
    };

    dispatch(removeAssignedPackagesFromBusiness(dataToSend));
    setOpen(false);
  };

  const renderPackageDetails = (data) => {
    return (
      <>
        <Grid container>
          <Grid
            item
            xs={10}
            style={{ marginLeft: "25px", marginBottom: "15px" }}
          >
            <Typography
              color="textSecondary"
              variant="subtitle1"
              style={{ fontSize: "12px" }}
            ></Typography>{" "}
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ fontSize: "15px" }}
            >
              {data.package_name} ({data.package_type})
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              style={{ fontSize: "20px", color: "black" }}
            >
              {" "}
              GBP {data.package_fee}
            </Typography>
            {data.commission_rate && (
              <Typography
                color="textSecondary"
                variant="subtitle1"
                style={{ fontSize: "20px", color: "black" }}
              >
                {" "}
                Commission Rate: {data.commission_rate}
              </Typography>
            )}
            <Typography
              color="textSecondary"
              variant="subtitle1"
              style={{ fontSize: "18px", color: "red" }}
            >
              {" "}
              Features:
            </Typography>
            {data &&
              data?.package_features?.map((ft) => {
                return (
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    style={{ fontSize: "15px" }}
                  >
                    *{ft}
                  </Typography>
                );
              })}
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Remove from user's packages">
              <IconButton
                onClick={() => handleRemovePackage(data.id)}
                style={{ color: "red" }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      withActions={false}
    >
      <Grid container spacing={3}>
        {business &&
          business?.package_data.map((pk) => {
            return (
              <Grid xs={12} style={{ marginTop: "25px", marginRight: "25px" }}>
                {renderPackageDetails(pk)}
              </Grid>
            );
          })}
      </Grid>
    </DialogComponent>
  );
};

export default PackageDetailsDialog;
