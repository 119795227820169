import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import { importCustomersFromErp } from "../../store/actions/customerActions";

const ImportCustomerFromErpDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState(false);

  const customerLoading = useSelector(
    (state) => state.customer.customerImportLoading
  );

  const handleImport = () => {
    setClicked(true);
    dispatch(importCustomersFromErp());
  };

  useEffect(() => {
    if (customerLoading === false && clicked) {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [customerLoading]);

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={"Import your customers from Erp"}
      description={""}
      withActions={false}
    >
      <Grid container justify="center">
        <Grid xs={6} md={6}>
          {customerLoading && "Getting your customer..."}
          <Button disable={customerLoading} onClick={() => handleImport()}>
            Get All Customers
          </Button>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default ImportCustomerFromErpDialog;
