import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyHouseInformation, getHMRCInformation } from "../../../store/actions/businessActions";

//TODO needs translations
function VerifySoleTraderWithVat({
  errors,
  business,
  isPathUpdate,
  hmrcResult,
  setShouldRenderCompanyInformation,
  setShouldAskForCompanyDetails,
  registrationOption,
  setRegistrationOption,
  utrNo,
  setUtrNo,
  setErrors,
  textFieldName,
  textFieldLabel,
  setTextFieldValue,
  textFieldValue,
}) {
  const dispatch = useDispatch();

  const loadingButton = useSelector((state) => state.ui.loadingButton);

  const [searchTimeout, setSearchTimeout] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleRadioChange = (e) => {
    if (business?.company_details?.passport_id) {
      setRegistrationOption("passportId")
    } else if (business?.company_details?.utr_number) {
      setRegistrationOption("utrNo");
    } else if (business?.company_details?.vat_number) {
      setRegistrationOption("vatNo");
    } else if (business?.company_details?.national_insurance_number) {
      setRegistrationOption("national_insurance_number");
    } else {
      let val = e.target.value;
      setRegistrationOption(val);
    }

  };

  useEffect(() => {
    if (business?.company_details?.passport_id) {
      setRegistrationOption("passportId")
      setTextFieldValue(business?.company_details?.passport_id)
      setIsDisabled(true)
    } else if (business?.company_details?.utr_number) {
      setRegistrationOption("utrNo");
      setTextFieldValue(business?.company_details?.utr_number)
      setIsDisabled(true)

    } else if (business?.company_details?.vat_number) {
      setRegistrationOption("vatNo");
      setTextFieldValue(business?.company_details?.vat_number)
      setIsDisabled(true)

    } else if (business?.company_details?.national_insurance_number) {
      setTextFieldValue(business?.company_details?.national_insurance_number)
      setRegistrationOption("national_insurance_number");
      setIsDisabled(true)

    }
  }, [business]);


  // //todo: Fix here
  // useEffect(() => {
  //   if (isPathUpdate) {
  //     let vatRegNumber = textFieldValue;
  //     if (searchTimeout) {
  //       clearTimeout(searchTimeout);
  //     }
  //     setSearchTimeout(
  //       setTimeout(() => {
  //         if (vatRegNumber !== "" || vatRegNumber !== undefined || vatRegNumber !== null) {
  //           dispatch(getHMRCInformation(vatRegNumber, true, textFieldName, textFieldValue, setShouldRenderCompanyInformation, setShouldAskForCompanyDetails));
  //           // setValue("vatRegNumber", vatRegNumber);
  //         }
  //       }, 1000)
  //     );
  //   } else {
  //   }
  //   // eslint-disable-next-line
  // }, [textFieldValue]);
  console.log("textFieldValue", textFieldName, textFieldValue, textFieldLabel);
  const checkForTaxId = () => {
    if (!textFieldValue) {
      setErrors({ general: `${textFieldLabel}` });
      return false;
    } else if (textFieldValue.length > 20) {
      setErrors({ general: "Maximum length is 20" });
      return false;
    }
    dispatch({
      type: "SET_HMRC_INFORMATION",
      payload: {},
    });
    setTextFieldValue(textFieldValue);
    dispatch(getHMRCInformation(textFieldValue, false, textFieldName, textFieldValue, setShouldRenderCompanyInformation, setShouldAskForCompanyDetails));

    // setShouldRenderCompanyInformation(true);
    // setShouldAskForCompanyDetails(true);
  };

  const displayHmrcResult = () => {
    return (
      <>
        <Typography>
          Company Name:
          <span style={{ color: "green" }}>{hmrcResult.target.name}</span>
        </Typography>

        <Typography>
          Address:{" "}
          <span style={{ color: "green" }}>
            {hmrcResult?.target?.address?.line1} {hmrcResult?.target?.address?.line2}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "green" }}></span>
        </Typography>
        <Typography>
          Postal Code: <span style={{ color: "green" }}>{hmrcResult?.target?.address?.postcode} </span>
        </Typography>
      </>
    );
  };

  const renderChooseVatOrUtrSelection = () => {
    return (
      <form>
        <div style={{ marginTop: "30px" }}>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}>Crate account with: </strong>
          </Typography>
          <div>
            <input id="vatNo" name="businessType" value="vatNo" checked={registrationOption === "vatNo"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="vatNo">Vat registration number</label>
          </div>

          <div>
            <input id="passportId" name="businessType" value="passportId" checked={registrationOption === "passportId"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="passportId">Passport Number</label>
          </div>
          <div>
            <input
              id="national_insurance_number"
              name="businessType"
              value="national_insurance_number"
              checked={registrationOption === "national_insurance_number"}
              type="radio"
              onChange={(e) => handleRadioChange(e)}
            />
            <label for="national_insurance_number">National Insurance Number</label>
          </div>

          <div>
            <input id="utrNo" name="businessType" value="utrNo" checked={registrationOption === "utrNo"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="utrNo">Unique Taxpayer Reference</label>
          </div>
        </div>
      </form>
    );
  };
  return (
    <form>
      <div style={{ marginBottom: "50px" }}>{renderChooseVatOrUtrSelection()}</div>
      <Grid container spacing={2}>
        <>
          <Grid item xs={4} lg={4} md={4}>
            {registrationOption && (
              <TextField
                required
                variant="outlined"
                id={textFieldName}
                name={textFieldName}
                label={textFieldLabel}
                // placeholder="GB123456789"
                error={errors.textFieldName ? true : false}
                helperText={errors.textFieldName ?? ""}
                type="text"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={textFieldValue}
                onChange={(e) => setTextFieldValue(e.target.value)}
                inputProps={{ maxLength: 20 }}
                disabled={isDisabled}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={6} md={6}>
            {registrationOption && (
              <Button
                variant="outlined"
                color="primary"
                style={{ height: "55px" }}
                // onClick={checkForTaxId}
                onClick={() => checkForTaxId()}
                disabled={loadingButton}
              >
                {loadingButton && <CircularProgress />}
                {!loadingButton && (registrationOption === "vatNo" ? "Verify VAT registration number" : "Continue")}
              </Button>
            )}
          </Grid>
        </>

        <Grid item xs={12} lg={4} md={4}>
          {hmrcResult?.target && displayHmrcResult()}
        </Grid>
      </Grid>
    </form>
  );
}

export default VerifySoleTraderWithVat;
