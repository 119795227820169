import { Alert } from "@material-ui/lab";
import { Button, Typography, Grid } from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BuyPackageDialog from "../../../Components/Dialogs/BuyPackageDialog";
import DropdownComponent from "../../../Components/DropdownComponent";
import { usePermissionsContext } from "../../../Contexts/PermissionsContext";
import { useUiContext } from "../../../Contexts/UiContext";
import { getCustomer, getCustomers } from "../../../store/actions/customerActions";
import {
  getInvoicesFromCloud,
  getOutgoingInvoices,
  handleRequestPayment,
  handleSendInvoiceReminder,
  handleSendInvoicePaymentLink,
  removeOutgoingInvoices,
  requestFinancingonInvoice,
  getPastDueInvoices,
} from "../../../store/actions/invoiceActions";
import { UI_API_BASE } from "../../../store/env";
import InviteDialog from "../../Customer/InviteDialog";
import BusinessFinancialInformationsDialog from "../../Settings/BusinessFinancialInformationsDialog";
import InvoicesListComponent from "../InvoicesListComponent";
import ImportOutgoingFromCloudDialog from "./ImportOutgoingFromCloudDialog";
import OutgoingInvoicesFooter from "./OutgoingInvoicesFooter";
import OutgoingInvoicesToolbar from "./OutgoingInvoicesToolbar";
import PaymentQRDialog from "./PaymentQRDialog";
import BasePage from "../../../Components/BasePage";
import { getUnreadNotifications } from "../../../store/actions/notificationActions";

const statuses = [
  {
    value: "Awaiting Payment",
    label: "Awaiting Payment",
  },
  {
    value: "Pending Approval",
    label: "Pending Invoice Approval",
  },
  {
    value: "Approved By Customer",
    label: "Approved By Customer",
  },
  {
    value: "Factoring Requested",
    label: "Factoring Requested",
  },
  {
    value: "Factoring Accepted",
    label: "Factoring Accepted",
  },
  { value: "Paid", label: "Payeda Payments" },
];

const OutgoingInvoices = ({ business, products, businessId, history }) => {
  const { setErrors, errors } = useUiContext();
  const { register, control, handleSubmit } = useForm();

  const { permissions } = usePermissionsContext();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [selectedStatus, setSelectedStatus] = useState("Awaiting Payment");
  const [selectedCustomer, setSelectedCustomer] = useState("all_customers");
  const [msgToCustomer, setMsgToCustomer] = useState("Join to Payeda and see your invoices I made for you ");

  const [selected, setSelected] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [openFinancialInformationsDialog, setOpenFinancialInformationsDialog] = useState(false);

  const [downloadPDF, setDownloadPDF] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [openImportFromErp, setOpenImportFromErp] = useState(false);
  const [paymentLinkDialogOpen, setPaymentLinkDialogOpen] = useState(false);

  const [buyPackageDialogOpen, setBuyPackageDialogOpen] = useState(false);
  const [openPaymentQRCode, setOpenPaymentQRCode] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");

  const [dataForTable, setDataForTable] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);

  const [afterRender, setAfterRender] = useState(false);

  const [shouldPaginateRedis, setShouldPaginateRedis] = useState(false);
  const [sendLinkOption, setSendLinkOption] = useState("");
  const [selectedDateFilterOption, setSelectedDateFilterOption] = useState("");
  const [applyDateFilterOption, setApplyDateFilterOption] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [shouldGetData, setShouldGetData] = useState(true);
  let [concatData, setConcatData] = useState([]);

  const loading = useSelector((state) => state.invoice.outgoingInvoiceLoading);
  const [prevInterval, setPrevInternal] = useState(null);
  const customer = useSelector((state) => state.customer.customer);
  const customers = useSelector((state) => state.customer.customers);

  const cloudInvoices = useSelector((state) => state.invoice.cloudInvoices);
  const outgoingInvoices = useSelector((state) => state.invoice.outgoingInvoices);

  const paidOutgoingInvoices = useSelector((state) => state.invoice.paidOutgoingInvoices);
  const pendingOutgoingInvoices = useSelector((state) => state.invoice.pendingOutgoingInvoices);
  const approvedOutgoingInvoices = useSelector((state) => state.invoice.approvedOutgoingInvoices);
  const factoringOutgoingInvoices = useSelector((state) => state.invoice.factoringOutgoingInvoices);
  const factoringAcceptedInvoices = useSelector((state) => state.invoice.factoringAcceptedInvoices);

  const isLastPage = useSelector((state) => state.business.lastPage);
  useEffect(() => {
    if (business && business.account_verified && business.account_type === "Business") {
      if (!business.payeda_package) {
        setBuyPackageDialogOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [business]);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pendingOutgoingInvoices) {
      concatData = concatData.filter((data) => data.statusDetails.status !== "Awaiting Payment");
      setConcatData([...concatData, ...pendingOutgoingInvoices]);
    }
  }, [pendingOutgoingInvoices]);

  useEffect(() => {
    if (factoringOutgoingInvoices) {
      concatData = concatData.filter((data) => data.statusDetails.status !== "Factoring Requested");
      setConcatData([...concatData, ...factoringOutgoingInvoices]);
    }
  }, [factoringOutgoingInvoices]);

  useEffect(() => {
    if (factoringAcceptedInvoices) {
      concatData = concatData.filter((data) => data.statusDetails.status !== "Factoring Accepted");
      setConcatData([...concatData, ...factoringAcceptedInvoices]);
    }
  }, [factoringAcceptedInvoices]);

  useEffect(() => {
    if (approvedOutgoingInvoices) {
      concatData = concatData.filter((data) => data.statusDetails.status !== "Approved By Customer");
      setConcatData([...concatData, ...approvedOutgoingInvoices]);
    }
  }, [approvedOutgoingInvoices]);

  useEffect(() => {
    if (paidOutgoingInvoices) {
      concatData = concatData.filter((data) => data.statusDetails.status !== "Payeda Payments");
      setConcatData([...concatData, ...paidOutgoingInvoices]);
    }

    // handleSetData(paidOutgoingInvoices, "Payeda Payments");
  }, [paidOutgoingInvoices]);

  useEffect(() => {
    if (selected && selected.length === 1) {
      let id = selected[0].id;
      setPaymentLink(`${UI_API_BASE}/checkout?id=${id}`);
    }
    // eslint-disable-next-line
  }, [selected]);

  // run at first render
  useEffect(() => {
    if (afterRender) {
      getInvoices();
    }
    // eslint-disable-next-line
  }, [afterRender]);

  // this function gets invoices for Awaiting Payment and Paid.
  const getInvoices = () => {
    if (user?.permissions?.outgoingInvoice?.view) {
      dispatch(getOutgoingInvoices([{ statusField: "Awaiting Payment" }]));
      dispatch(getOutgoingInvoices([{ statusField: "Paid" }]));
      dispatch(getOutgoingInvoices([{ statusField: "Approved By Customer" }]));
      dispatch(getOutgoingInvoices([{ statusField: "Factoring Requested" }]));
      dispatch(getOutgoingInvoices([{ statusField: "Factoring Accepted" }]));
      dispatch(getCustomers({}));
    }
  };

  // the above function retrieves the all invoices so this function will decide what data to render
  const prepareDataToDisplay = () => {
    let filteredInvoices = [];

    if (shouldGetData && concatData && concatData.length > 0) {
      if (selectedCustomer === "all_customers") {
        filteredInvoices = concatData.filter((invoice) => invoice.status === selectedStatus);
      } else {
        filteredInvoices = concatData.filter((invoice) => invoice.status === selectedStatus && invoice.customer === selectedCustomer);
      }
    }
    var uniq = {};

    var arrFiltered = filteredInvoices.filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true));

    setDataForTable(arrFiltered);
  };

  useEffect(() => {
    prepareDataToDisplay();
  }, [selectedCustomer, selectedStatus, shouldGetData, concatData]);

  // useEffect(() => {
  //   let intervalRef;
  //   if (afterRender && user?.permissions?.outgoingInvoice?.view) {
  //     if (!loading) {
  //       intervalRef = setInterval(() => {
  //         getInvoices();
  //       }, 60000);

  //       setPrevInternal(intervalRef);
  //       return () => clearInterval(intervalRef);
  //     }
  //   }
  // }, [afterRender]);

  useEffect(() => {
    // clear all intervals except the last one created so it keeps getting the data with the last status selected
    if (prevInterval !== null) {
      for (let i = 0; i < prevInterval; i++) {
        clearInterval(i);
      }
    }
  }, [prevInterval]);

  useEffect(() => {
    if (selected.length === 1) {
      let selectedCustomer = selected[0].customer;
      dispatch(getCustomer(selectedCustomer));
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (customer) {
      setCustomerDetails([customer]);
    }
    // eslint-disable-next-line
  }, [customer]);

  const getCustomerLists = () => {
    let lists = [];
    lists.push({ value: "all_customers", label: "All Customers" });
    if (customers && customers.length > 0) {
      customers &&
        customers.forEach((cust) => {
          lists.push({ value: cust.id, label: cust.name });
        });
    }
    return lists;
  };

  const renderToolbar = () => {
    return (
      <OutgoingInvoicesToolbar
        numSelected={selected ? selected.length : 0}
        header="Outgoing Invoices"
        setDeleteDialogOpen={setDeleteDialogOpen}
        setInviteDialogOpen={setInviteDialogOpen}
        selectedInvoice={selected[0]}
        permissions={permissions}
        setSelectedInvoices={setSelected}
        getCustomer={getCustomer}
        dispatch={dispatch}
        setErrors={setErrors}
        business={business}
        renderBankAccountInformations={renderBankAccountInformations()}
        renderCustomerSelection={renderCustomerSelectionDropBox()}
        renderStatusSelection={renderStatusSelectionDropbox()}
        setOpenImportFromErp={setOpenImportFromErp}
        onInvite={onInvite}
        handleSubmit={handleSubmit}
        register={register}
        setOpenPaymentQRCode={setOpenPaymentQRCode}
        handleRequestPayment={handleRequestPayment}
        selected={selected}
        setCurrentPage={setCurrentPage}
        setSendLinkOption={setSendLinkOption}
        setPaymentLinkDialogOpen={setPaymentLinkDialogOpen}
        paymentLinkDialogOpen={paymentLinkDialogOpen}
        handleSendReminder={handleSendReminder}
      />
    );
  };

  const renderBankAccountInformations = () => {
    return (
      <>
        {business?.company_details?.banking[0] && !business?.receive_payment_details && (
          <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
            Receive Payment Account Details (Not verified yet):
            <br />
            <strong>Account number:</strong> {business && business.company_details.banking[0].account_number} <strong>Sort code:</strong>{" "}
            {business && business.company_details.banking[0].sort_code}
          </Alert>
        )}
      </>
    );
  };

  console.log("selectedStats", selectedStatus);
  const renderFooter = () => {
    return (
      <OutgoingInvoicesFooter
        selected={selected}
        setSelected={setSelected}
        business={business}
        permissions={permissions}
        setErrors={setErrors}
        updateInvoiceStatus={requestFinancingonInvoice}
        history={history}
        dispatch={dispatch}
        setDownloadPDF={setDownloadPDF}
        downloadPDF={downloadPDF}
        selectedCustomer={selectedCustomer}
        setSelectedStatus={setSelectedStatus}
        setOpenFinancialInformationsDialog={setOpenFinancialInformationsDialog}
      />
    );
  };
  //TODO: it shows error when phone or email missing, it should show error when both are missing
  // this function send payment link
  const onInvite = (data) => {
    if (!sendLinkOption) return;

    // let customerEmail = customerDetails[0].email;
    if (sendLinkOption === "withWhatsApp" && selected.length === 1) {
      let link = `${UI_API_BASE}/view-invoice?id=${selected[0].id}`;
      let customerPhone = customerDetails[0].phone;

      if (!customerPhone) {
        setErrors({ general: "Please add a phone number for your customer" });
        return false;
      }
      window.open("https://api.whatsapp.com/send?phone=" + customerPhone + "&text=Click%20here%20to%20pay%20your%20invoice:%20" + link);
      dispatch(handleRequestPayment(selected[0]));
      setPaymentLinkDialogOpen(false);
    } else if (sendLinkOption === "withEmail") {
      // TODO: handle sending multiple links here
      let dataToSend = {};
      dataToSend.supplier_name = business.company_details.name;
      dataToSend.invoices = selected;
      dispatch(handleSendInvoicePaymentLink(dataToSend));
      dispatch(getUnreadNotifications());
      // let customerEmail = customerDetails[0].email;
      // if (!customerEmail) {
      //   setErrors({ general: "Please add an email for your customer" });
      //   return false;
      // }
      // let dataToSend = {
      //   email: customerEmail,
      //   supplier_name: business.company_details.name,
      //   customer_name: customerDetails[0].name,
      //   invoice_amount: selected[0].amount,
      //   link: link,
      //   imageLink: originalInvoiceImageLink,
      // };
      // dispatch(handleRequestPayment(selected[0]));
      // dispatch(handleSendInvoicePaymentLink(dataToSend));
      // // if (!customerEmail) return false;
      setSelected([]);
      setPaymentLinkDialogOpen(false);
    }
  };

  const handleSendReminder = () => {
    if (selected.length < 1) {
      setErrors({ general: "Please select at least one invoice" });
      return false;
    }

    const isFound = selected.some((inv) => {
      if (inv.statusDetails.status === "Paid") {
        setErrors({
          general: "Please select only unpaid invoices",
        });

        return true;
      }
    });

    if (isFound) {
      return false;
    }

    let dataToSend = {};

    dataToSend.supplier_name = business.company_details.name;
    dataToSend.invoices = selected;

    dispatch(handleSendInvoiceReminder(dataToSend));
    setSelected([]);
  };
  const renderCustomerSelectionDropBox = () => {
    return (
      <>
        <DropdownComponent
          required
          fullWidth
          id="selectedInformation"
          name="selectedInformation"
          label="Select a customer"
          items={getCustomerLists()}
          onChange={(e) => {
            setSelectedCustomer(e.target.value);
          }}
          error={errors.selectedInformation ? true : false}
          helperText={errors.selectedInformation ?? ""}
          control={control}
          defaultValue="all_customers"
        />
      </>
    );
  };

  const renderStatusSelectionDropbox = () => {
    return (
      <>
        <DropdownComponent
          required
          fullWidth
          id="selectedStatus"
          name="selectedStatus"
          label="Select a status"
          items={statuses}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
          error={errors.selectedStatus ? true : false}
          helperText={errors.selectedStatus ?? ""}
          control={control}
          defaultValue={selectedStatus || "Awaiting Payment"}
        />
      </>
    );
  };

  const dateOptions = [
    { label: "Past Due", value: "past_due" },
    { label: "Due Today", value: "due_today" },
  ];

  const handleDateOptionChange = (e) => {
    setSelectedDateFilterOption(e.target.value);
  };

  const renderInvoiceFilterOptions = () => {
    return (
      <>
        <FormControl variant="standard" sx={{ m: 5, minWidth: 500 }} fullWidth>
          <InputLabel id="demo-simple-select-standard-label" style={{ marginLeft: "10px" }}>
            By Date:
          </InputLabel>

          <Select
            value={selectedDateFilterOption}
            onChange={handleDateOptionChange}
            error={errors.selectedDateFilterOption ? true : false}
            helperText={errors.selectedDateFilterOption ?? ""}
            variant="outlined"
          >
            {dateOptions.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const renderList = () => {
    return (
      <>
        <InvoicesListComponent
          invoices={dataForTable}
          toolbar={renderToolbar()}
          footer={renderFooter()}
          header="Outgoing Invoices"
          businessId={businessId}
          getAllData={getOutgoingInvoices}
          removeInvoices={removeOutgoingInvoices}
          selected={selected}
          setSelected={setSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          products={products}
          isLoading={false}
          islastPage={isLastPage}
          dispatch={dispatch}
          setErrors={setErrors}
          shouldView={permissions?.outgoingInvoice?.view}
          shouldGetData={shouldGetData}
          setShouldGetData={setShouldGetData}
          shouldPaginate={true}
          shouldSearch={true}
          showFilters={true}
          selectedFieldId={selectedCustomer}
          paginateRedis={shouldPaginateRedis}
          selectedStatus={selectedStatus}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          selectedDateOption={selectedDateFilterOption}
          applyDateFilterOption={applyDateFilterOption}
        />
      </>
    );
  };
  return (
    <BasePage navigation="Dashboard/Outgoing Invoices" business={business} canView={user?.permissions?.outgoingInvoice?.view}>
      {!business.isSoleTrader && business.canReceivePayment && (
        <div style={{ float: "right" }}>
          <Button variant="outlined" onClick={() => history.push("/dashboard/financing-invoices")}>
            Financing offers
          </Button>
        </div>
      )}

      {renderBankAccountInformations()}

      {renderList()}

      <PaymentQRDialog open={openPaymentQRCode} setOpen={setOpenPaymentQRCode} link={paymentLink} />
      <ImportOutgoingFromCloudDialog
        open={openImportFromErp}
        setOpen={setOpenImportFromErp}
        title="Import from your cloud service"
        description="You must select customer and date"
        selectedCustomer={selectedCustomer}
        getInvoicesFromCloud={getInvoicesFromCloud}
        renderCustomersLists={renderCustomerSelectionDropBox()}
        cloudInvoices={cloudInvoices}
        history={history}
      />

      <InviteDialog
        title="Invite the customer"
        description="Invite the customer to receive payment"
        selected={customerDetails}
        setSelected={setCustomerDetails}
        onSubmit={onInvite}
        open={inviteDialogOpen}
        setOpen={setInviteDialogOpen}
        msgToCustomer={msgToCustomer}
        setMsgToCustomer={setMsgToCustomer}
      />

      <BusinessFinancialInformationsDialog
        dispatch={dispatch}
        setOpen={setOpenFinancialInformationsDialog}
        open={openFinancialInformationsDialog}
        errors={errors}
        business={business}
      />

      <BuyPackageDialog open={buyPackageDialogOpen} setOpen={setBuyPackageDialogOpen} />
    </BasePage>
  );
};

export default OutgoingInvoices;
