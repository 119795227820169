import axios from "axios";
import { setSuccess, setErrors, clearErrors } from "./uiActions";
import { API_BASE } from "../env";

export const addProduct = (details) => async (dispatch) => {
  try {
    let products = await axios.post(`${API_BASE}/product`, details);
    dispatch({ type: "PRODUCT_DATA_FETCH_SUCCESS", payload: products.data.allProducts });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added new product."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllProducts = (...params) => async (dispatch) => {
  try {
    dispatch({ type: "PRODUCT_DATA_FETCH_STARTED" });

    let product = await axios.get(`${API_BASE}/products`, {
      params: {
        lastItemId: params[0]?.lastItem ? params[0]?.lastItem : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
      },
    });

    dispatch({ type: "PRODUCT_DATA_FETCH_SUCCESS", payload: product.data.allProducts });
    dispatch({ type: "SET_LAST_PAGE", payload: product.data.lastPage.isLastPage });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editProduct = (details) => async (dispatch) => {
  try {
    let products = await axios.put(`${API_BASE}/product`, details);
    dispatch({ type: "PRODUCT_DATA_FETCH_SUCCESS", payload: products.data.allProducts });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully edited the product."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeProducts = (products) => async (dispatch) => {
  try {
    let business = await axios.post(`${API_BASE}/remove-products`, products);
    dispatch({ type: "SET_BUSINESS", payload: business.data });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed product(s)."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
