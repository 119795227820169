import { Grid, Paper, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React from "react";
import { RiNumber1 } from "react-icons/ri";
import { useTimeLineStyles } from "./HowToUseStyles";

// const personalStarter = [
//   { header: "GET START PERSONAL" },
//   { step1: "Create Your Payeda Account" },
//   { step2: "Wait for approval" },
//   { step3: "Buy transfer money membership" },
//   { step4: "Connect your bank accounts" },
//   { step5: "Start transfering money" },
// ];
//TODO use translations
const TimelineComponent = ({ loading, authenticated }) => {
  const classes = useTimeLineStyles();

  return (
    <Grid container component="main" className={classes.root} spacing={3}>
      {/* <Grid item xs={false} sm={4} md={6} className={classes.image} /> */}

      {/* {personalStarter.map((data)=>{
          return(
              
          )
      })} */}
      <Box component={Grid} item lg={12}>
        <div className={classes.getStartedStyle}>
          <Typography
            style={{
              fontSize: "22px",
              color: "#EC684B",

              fontWeight: "bold",
            }}
          >
            <strong>START RECEIVING PAYMENTS IN 3 STEPS</strong>
          </Typography>

          <div>
            <Timeline align="alternate">
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot style={{ backgroundColor: "white" }}>
                    <RiNumber1 size="40px" color="#EC684B" />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.timePaper}>
                    <Typography
                      variant="h6"
                      component="h1"
                      style={{ fontSize: "17px", color: "#7F8F9E" }}
                    >
                      CREATE YOUR PAYEDA ACCOUNT
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </Box>

      {/* <Grid item xs={false} sm={false} lg={7} className={classes.image} /> */}
    </Grid>
  );
};

export default TimelineComponent;
