const initialState = {
  isLoading: false,
  financingInvoices: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FINANCING_INVOICES_DATA_FETCH_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FINANCING_INVOICES_DATA_FETCHED":
      return {
        ...state,
        isLoading: false,
        financingInvoices: action.payload,
      };
    case "FINANCING_OFFERS":
      return {
        ...state,
        isLoading: false,
        financingOffers: action.payload,
      };
    default:
      return state;
  }
}
