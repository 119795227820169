import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import LoadingIcon from "./Components/LoadingIcon";
import "./i18n";
import "./index.css";
import { REACT_ENV } from "./store/env";
//Redux
import rootReducer from "./store/reducers/rootReducer";

const composeEnhancers =
  (typeof window === "object" &&
    REACT_ENV !== "live" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})) ||
  compose;
const enhancer = composeEnhancers(applyMiddleware(...[thunk]));
const store = createStore(rootReducer, {}, enhancer);

//set lastPage variable in localStorage to the pathname so can reload the same page afterwards
window.onbeforeunload = (e) => {
  const page = window.location.pathname;
  localStorage.setItem("lastPage", page);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Suspense fallback={<LoadingIcon />}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <App />
          </SnackbarProvider>
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
