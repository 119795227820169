import { Divider, Grid, Link, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import Box from "@material-ui/core/Box";
import DropdownComponent from "../../../Components/DropdownComponent";
import { useForm } from "react-hook-form";
import { addInvoiceToBasket, getFinanceBasketData } from "../../../store/actions/financeActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ChooseBasketDialog = ({ open, setOpen, title, selectedInvoice, dropdownData }) => {
  const dispatch = useDispatch();

  const [selectedBasket, setSelectedBasket] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const { control, handleSubmit } = useForm();

  const handleAddToBasket = () => {
    let body = {
      shouldCreateNewBasket: selectedOption === "createNewBasket" ? true : false,
      invoiceId: selectedInvoice.id,
      financing_request_id: selectedInvoice.financing_request_id,
      selectedBasketId: selectedBasket,
    };

    dispatch(addInvoiceToBasket(body));
    //body'e financeId backend tarafından eklenecek!
  };

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
  };

  const renderBasketSelection = () => {
    return (
      <form>
        <div style={{ marginTop: "30px" }}>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}>Choose how you want to create basket</strong>
          </Typography>
          <div>
            <input id="fromBaskets" name="basketAddingOption" value="fromBaskets" checked={selectedOption === "fromBaskets"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="fromBaskets">From Previously Added Basket</label>
          </div>
          <div>
            <input
              id="createNewBasket"
              name="basketAddingOption"
              value="createNewBasket"
              checked={selectedOption === "createNewBasket"}
              type="radio"
              onChange={(e) => handleRadioChange(e)}
            />
            <label for="createNewBasket">Create new Basket</label>
          </div>
        </div>
      </form>
    );
  };
  const renderBaskets = () => {
    return (
      <DropdownComponent
        required
        fullWidth
        id="sup"
        name="sup"
        label="Select a basket"
        items={dropdownData || []}
        onChange={(e) => {
          setSelectedBasket(e.target.value);
        }}
        control={control}
      />
    );
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description="" withActions={true} onSubmit={handleSubmit(handleAddToBasket)}>
      <div style={{ width: "450px" }}>
        {renderBasketSelection()}
        {selectedOption === "fromBaskets" && renderBaskets()}
        {selectedOption === "createNewBasket" && <div style={{ marginTop: "15px", marginBottom: "20px", fontSize: "20px" }}>New Basket will be created for selected invoice</div>}
      </div>
    </DialogComponent>
  );
};

export default ChooseBasketDialog;
