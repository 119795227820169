import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Paper, Typography, Button, Divider, FormControl, TextField, Link } from "@material-ui/core";

import UpdateDetailsDialog from "./UpdateDetailsDialog";

function UpdateBankingInfo() {
  const business = useSelector((state) => state.business);

  const [openUpdateDetailsDialog, setOpenUpdateDetailsDialog] = useState(false);

  return (
    <Paper>
      <Typography align="center" style={{ color: "black", padding: "20px" }} variant="h6">
        Receive Payment Account Details
      </Typography>
      <Divider />
      <Paper>
        <div style={{ padding: "50px", paddingBottom: "50px" }}>
          {business?.company_details?.bank_statement ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business?.company_details?.bank_statement);
              }}
            >
              Download the bank statement
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
          <Typography>Account Number: {business?.company_details?.banking[0].account_number}</Typography>
          <Typography>Sort Code: {business?.company_details?.banking[0].sort_code}</Typography>

          <Button variant="outlined" color="primary" style={{ marginTop: "30px" }} onClick={() => setOpenUpdateDetailsDialog(true)}>
            Edit information
          </Button>
          {openUpdateDetailsDialog && <UpdateDetailsDialog open={openUpdateDetailsDialog} setOpen={setOpenUpdateDetailsDialog} />}
        </div>
      </Paper>
    </Paper>
  );
}

export default UpdateBankingInfo;
