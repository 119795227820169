import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Grid, Button } from "@material-ui/core";

import FormLabel from "@material-ui/core/FormLabel";
import BasePage from "../../Components/BasePage";
import { useUiContext } from "./../../Contexts/UiContext";
import { getAuthUrl, exchangeCodeForCloudToken, getTenantsFromCloudApp, revokeCloudErpToken } from "../../store/actions/invoiceActions";

import checkIfHasActiveMembership from "./../../util/permissions/checkIfHasActiveMembership";

import ConnectedErpApplicationDetailsDialog from "./ConnectedErpApplicationDetailsDialog";
import CloudErpTenantsDialog from "./CloudErpTenantsDialog";
import ConfirmDeleteDialog from "../../Components/Dialogs/ConfirmDeleteDialog";
import ConfirmInformationDialog from "../../Components/Dialogs/ConfirmInformationDialog";

const ConnectCloudErp = ({ business }) => {
  const { errors, setErrors } = useUiContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("");
  const [isUserConnectedToErp, setIsUserConnectedToErp] = useState(false);
  const [connectedErpAppDetails, setOpenConnectedErpDetails] = useState(false);
  const [connectedCloudApp, setConnectedCloudApp] = useState("");
  const [afterRender, setAfterRender] = useState(false); // internal state
  const [userHasMembership, setUserHasMembership] = useState(null);
  const [openCloudErpTenantsDialogOpen, setOpenTenantsDialogs] = useState(false);
  const [queryString, setQueryString] = useState(null);
  const [state, setSatate] = useState(null);
  const [userTenant, setUserTenant] = useState(null);
  const [selectedTenant, setTenantName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [informationDialogOpen, setInformationDialogOpen] = useState(false);

  let tenants = useSelector((state) => state.invoice.tenants);
  let accessTokenLoading = useSelector((state) => state.invoice.accessTokenLoading);
  let isConnectedToErp = useSelector((state) => state.invoice.connectedToErp);
  let cloudBankAccounts = useSelector((state) => state.invoice.cloudBankAccounts);
  const user = useSelector((state) => state.user);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleDisconnectApp = () => {
    dispatch(revokeCloudErpToken());
    setOpenConnectedErpDetails(false);
    setDeleteDialogOpen(false);
  };
  useEffect(() => {
    setAfterRender(true); // (1) will be called after DOM rendered
  }, []); //

  useEffect(() => {
    if (business.account_settings) {
      if (business.account_settings.invoice_settings.cloud_app.isSynced) {
        setIsUserConnectedToErp(true);
        setConnectedCloudApp(business.account_settings.invoice_settings.cloud_app.application);
      } else if (business?.account_settings?.invoice_settings?.cloud_app?.application) {
        setIsUserConnectedToErp(false);
        setConnectedCloudApp(business.account_settings.invoice_settings.cloud_app.application);
      }
    }
  }, [business, business.account_settings]);

  useEffect(() => {
    async function checkMembership() {
      let hasMembership = await checkIfHasActiveMembership(business);
      setUserHasMembership(hasMembership);
    }
    checkMembership();
    // eslint-disable-next-line
  }, [business]);

  const getQueryString = () => {
    if (afterRender) {
      let dataToSend;

      var code = new URLSearchParams(window.location.search).get("code");
      var state = new URLSearchParams(window.location.search).get("state");

      if (code) setQueryString(code);
      if (state) setSatate(state);

      if (state === "quickBooks" || state === "xero") {
        if (state === "quickBooks") {
          dataToSend = {
            quickBooksUrl: window.location.href,
            application: state,
          };
        } else {
          dataToSend = { application: state, xeroCode: code };
        }
        setOpenTenantsDialogs(true);
        dispatch(exchangeCodeForCloudToken(dataToSend));
      }
    }
  };

  useEffect(() => {
    if (afterRender) {
      getQueryString();
    }
    // eslint-disable-next-line
  }, [afterRender]);

  // xero diye belirtmeden getirelim, backende nere baglandıgını kontrol edip ona getiririz
  useEffect(() => {
    dispatch(getTenantsFromCloudApp("xero"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((tenants && tenants.length > 0) || (cloudBankAccounts && cloudBankAccounts.length > 0)) setOpenTenantsDialogs(true);
  }, [tenants, cloudBankAccounts]);

  const onConnectButtonClicked = () => {
    if (!userHasMembership) {
      // TODO: setError here
      setErrors({ general: "Please buy membership package" });
      return false;
    }
    if (!isUserConnectedToErp) {
      let path = window.location.pathname.split("/")[2];

      let dataToSend = {
        application: value,
        path: path,
      };

      dispatch(getAuthUrl(dataToSend));
    } else {
      setOpenConnectedErpDetails(true);
    }
  };

  const RenderConnectStatus = () => {
    return (
      <>
        <div style={{ marginTop: "50px" }}>
          {isUserConnectedToErp && !business.account_settings.invoice_settings.cloud_app.canReConnect && (
            <Button onClick={() => setOpenConnectedErpDetails(true)} style={{ color: "green" }}>
              Connected
            </Button>
          )}
          {!isUserConnectedToErp && business.account_settings.invoice_settings.cloud_app.canReConnect && (
            <Button onClick={() => setOpenConnectedErpDetails(true)} style={{ color: "blue" }}>
              Re-connect
            </Button>
          )}
        </div>
      </>
    );
  };
  const renderErpApps = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Choose your favorite erp application</FormLabel>
        <RadioGroup aria-label="erp" name="gender1" value={value} onChange={handleChange} style={{ marginTop: "15px" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={3}>
              <div style={{ marginTop: "50px" }}>
                <FormControlLabel value="xero" control={<Radio />} label="Xero" />
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <img src="/img/xero-logo-png.png" alt="Xero" weight="150" height="150" />
            </Grid>
            <Grid item xs={12} lg={5}>
              {connectedCloudApp === "xero" && <RenderConnectStatus />}{" "}
            </Grid>
            <Grid item xs={12} lg={3}>
              <div style={{ marginTop: "50px" }}>
                <FormControlLabel value="quickbooks" control={<Radio />} label="Quickbooks" disabled />
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <img src="/img/quickbooks-logo-png.png" alt="Quickbooks" weight="150" height="150" />
            </Grid>
            <Grid item xs={12} lg={5}>
              {connectedCloudApp === "quickBooks" && (
                <div style={{ marginTop: "50px" }}>
                  <Button onClick={() => setOpenConnectedErpDetails(true)} style={{ color: "green" }}>
                    Connected
                  </Button>
                </div>
              )}
            </Grid>

            <Grid item xs={12} lg={3}>
              <div style={{ marginTop: "50px" }}>
                <FormControlLabel value="sage" control={<Radio />} label="Sage" disabled />
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <img src="/img/sage-logo.png" alt="sage" weight="150" height="150" />
            </Grid>
            <Grid item xs={12} lg={5}>
              {connectedCloudApp === "sage" && (
                <div style={{ marginTop: "50px" }}>
                  <Button onClick={() => setOpenConnectedErpDetails(true)} style={{ color: "green" }}>
                    Connected
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    );
  };
  const DeleteTextHtml = () => {
    return (
      <>
        <strong>Information:</strong>
        <br />

        <p>If you revoke Xero access, and then re-link to the same account. Xero will automatically re-connect. </p>
        <p>
          If you want Payeda to forget linked Xero account and to re-sync all information over with new/different Xero account, you will need to contact Payeda support to remove
          your Xero data from Payeda.
        </p>
      </>
    );
  };

  const InformationTextHtml = () => {
    return (
      <>
        <strong>Important!</strong>
        <br />

        <p>*You can only connect to the account you disconnected before</p>
        <p>
          If you want Payeda to forget disconnected Xero account and to connect new/different Xero account, you will need to contact Payeda support to remove your Xero data from
          Payeda.
        </p>
      </>
    );
  };

  const ReSyncOrDisconnectButton = () => {
    return (
      <>
        {!isUserConnectedToErp && (
          <Button style={{ float: "left", backgroundColor: "green", color: "white" }} onClick={() => onConnectButtonClicked()}>
            Connect
          </Button>
        )}
        {business.account_settings.invoice_settings.cloud_app.canReConnect && (
          <Button style={{ float: "right", backgroundColor: "blue", color: "white" }} onClick={() => setInformationDialogOpen(true)}>
            Re-Connect
          </Button>
        )}
        {business.account_settings.invoice_settings.cloud_app.isSynced && !business.account_settings.invoice_settings.cloud_app.canReConnect && (
          <Button style={{ float: "right", backgroundColor: "red", color: "white" }} onClick={() => setDeleteDialogOpen(true)}>
            Disconnect
          </Button>
        )}
      </>
    );
  };
  return (
    <BasePage navigation="" business={business} canView={user?.permissions?.connectCloudErp?.create}>
      <div>{renderErpApps()}</div>
      {value && <ReSyncOrDisconnectButton />}
      <ConnectedErpApplicationDetailsDialog
        open={connectedErpAppDetails}
        setOpen={setOpenConnectedErpDetails}
        business={business}
        setDeleteDialogOpen={setDeleteDialogOpen}
        onConnectButtonClicked={onConnectButtonClicked}
        ReSyncOrDisconnectButton={ReSyncOrDisconnectButton}
      />
      {(queryString || (tenants && tenants.length > 0) || (cloudBankAccounts && cloudBankAccounts.length > 0)) && !isUserConnectedToErp && (
        <CloudErpTenantsDialog
          open={openCloudErpTenantsDialogOpen}
          setOpen={setOpenTenantsDialogs}
          business={business}
          userTenant={userTenant}
          setUserTenant={setUserTenant}
          selectedTenant={selectedTenant}
          setTenantName={setTenantName}
          setErrors={setErrors}
        />
      )}

      {/* <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} /> */}
      <ConfirmDeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onSubmit={handleDisconnectApp}
        deleteHead="Are you sure to disconnect your app?"
        DeleteTextHtml={DeleteTextHtml}
        shouldRenderHtml={true}
      />
      <ConfirmInformationDialog
        open={informationDialogOpen}
        setOpen={setInformationDialogOpen}
        onSubmit={onConnectButtonClicked}
        deleteHead="Re-Connect Xero Account"
        DeleteTextHtml={InformationTextHtml}
        shouldRenderHtml={true}
      />
    </BasePage>
  );
};

export default ConnectCloudErp;
