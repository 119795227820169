import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Texfield from "@material-ui/core/TextField";
import clsx from "clsx";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PhoneInputComponent from "../../../Components/PhoneInputComponent";
import { useUiContext } from "../../../Contexts/UiContext";
import { addFinanceAccount } from "../../../store/actions/adminActions";
import verifySchema from "../../../util/yup/verifySchema";
import { addFinanceAccountSchema } from "../../../util/yup/yupSchemas";
import { useAdminStyles } from "../AdminStyles";

const AddFinance = () => {
  const { register, control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { errors, sidebarOpen, clearErrors, setErrors } = useUiContext();

  const classes = useAdminStyles();

  const onSubmit = async (data) => {
    const valid = await verifySchema(
      addFinanceAccountSchema,
      data,
      clearErrors,
      setErrors
    );
    if (valid) {
      dispatch(addFinanceAccount(data));
    }
  };

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: sidebarOpen,
      })}
    >
      <div className={classes.root}>
        <FormControl style={{ display: "flex" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Texfield
                required
                id="bankName"
                name="bankName"
                label="Bank Name"
                inputRef={register}
                error={errors.bankName ? true : false}
                helperText={errors.bankName ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Texfield
                required
                id="repFullName"
                name="repFullName"
                label="Representative Full Name"
                inputRef={register}
                error={errors.repFullName ? true : false}
                helperText={errors.repFullName ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Texfield
                required
                id="email"
                name="email"
                label="Email"
                inputRef={register}
                error={errors.email ? true : false}
                helperText={errors.email ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInputComponent
                errors={errors}
                register={register}
                control={control}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Texfield
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                inputRef={register}
                error={errors.password ? true : false}
                helperText={errors.password ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Texfield
                required
                id="confirmPassword"
                name="confirmPassword"
                label="Repeat Password"
                type="password"
                inputRef={register}
                error={errors.confirmPassword ? true : false}
                helperText={errors.confirmPassword ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                style={{ marginBottom: "15px" }}
                variant="contained"
                color="secondary"
                onClick={handleSubmit((data) => onSubmit({ ...data }))}
              >
                Add Finance
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </div>
    </main>
  );
};

export default AddFinance;
