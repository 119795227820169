import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NotificationsTable from "./NotificationsTable";
import { useNotificationsStyle } from "./NotificationStyles";
import { getNotifications, markNotificationAsRead } from "../../store/actions/notificationActions";
import NotificationsDetailsDialog from "./NotificationsDetailsDialog";

const filters = [
  { label: "Status: Read", value: "read" },
  { label: "Status: Unread", value: "unread" },
];

const Notifications = () => {
  const classes = useNotificationsStyle();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.notifications);

  const [selected, setSelected] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("unread");
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

  useEffect(() => {
    dispatch(getNotifications(selectedFilter));
  }, [selectedFilter]);

  const markAsRead = async () => {
    let notificationsTobeMarked = selected.map((notification) => notification.id);
    let details = { notifications: notificationsTobeMarked };
    await dispatch(markNotificationAsRead(details));
    setSelected([]);
  };
  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };
  const renderFilterOptions = () => {
    return (
      <>
        <FormControl variant="standard" sx={{ m: 5, minWidth: 500 }} fullWidth>
          <InputLabel id="demo-simple-select-standard-label" style={{ marginLeft: "10px" }}>
            Filter
          </InputLabel>

          <Select value={selectedFilter} onChange={handleFilterChange} variant="outlined">
            {filters.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  return (
    <>
      <NotificationsTable
        notifications={notifications}
        selected={selected}
        setSelected={setSelected}
        numSelected={selected ? selected.length : 0}
        filters={renderFilterOptions()}
        markNotification={markAsRead}
        openDetailsDialog={openDetailsDialog}
        setOpenDetailsDialog={setOpenDetailsDialog}
      />

      <NotificationsDetailsDialog open={openDetailsDialog} setOpen={setOpenDetailsDialog} selected={selected[0]} />
    </>
  );
};

export default Notifications;
