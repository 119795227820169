import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMergedAccounts } from "../../../../store/actions/businessActions";
import canPerformAction from "../../../../util/permissions/canPerformAction";
import BasePage from "./../../../../Components/BasePage";
import AddButton from "./../../../../Components/shared/AddButton";
import TableComponent from "./../../../../Components/Table/TableComponent";

const searchOptions = [{ default: true, id: "invitation_code_lower", label: "Invitation Code" }];
const headCells = [
  { id: "inviter_name", label: "Inviter" },
  { id: "inviter_email", label: "Email" },
  { id: "inviter_phone", label: "Phone" },
  { id: "inviter_role", label: "Role" },
  { id: "invitee_invite_information", label: "Invitation Code" },

  // { id: "is_joined", label: "Joined"}
];
const MergedAccountTable = ({ business, user, history }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  let permissions = user.permissions;
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getMergedAccounts({}));
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const onMergeNewClick = () => {
    history.push("/dashboard/add-supplier");
  };

  const renderToolbar = () => {
    return (
      <>
        {canPerformAction("suppliers", permissions, "create") && (
          <div>
            <AddButton text="Add New Supplier" onClick={onMergeNewClick} />
          </div>
        )}
      </>
    );
  };

  const mergedAccts = useSelector((state) => state.business.mergedAccts);
  const isLoading = useSelector((state) => state.business.mergedLoading);
  const isLastPage = useSelector((state) => state.lastPage);

  return (
    <BasePage navigation="Dashboard/MergeAccounts" business={business} canView={user?.permissions?.supplier?.view}>
      <TableComponent
        toolbar={renderToolbar()}
        data={mergedAccts}
        selected={selected}
        headCells={headCells}
        setSelected={setSelected}
        searchOptions={searchOptions}
        isLoading={isLoading}
        isLastPage={isLastPage}
        getAllData={getMergedAccounts}
        searchBarText="Search customers"
        shouldGetData={false}
        shouldPaginate={true}
        shouldView={true}
        shouldSearch={true}
      />
    </BasePage>
  );
};

export default MergedAccountTable;
