import React from "react";
import { useDispatch } from "react-redux";

import { TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useUiContext } from "../../Contexts/UiContext";

import DialogComponent from "./../../Components/Dialogs/DialogComponent";
import verifySchema from "../../util/yup/verifySchema";
import { bankAccountSchema } from "../../util/yup/yupSchemas";
import { updateReceivePaymentAccount } from "../../store/actions/businessActions";

const UpdateDetailsDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { errors, clearErrors, setErrors } = useUiContext();

  const onSubmit = async (data) => {
    // alert("hey", data);
    let bankAcctValid = await verifySchema(bankAccountSchema, data, clearErrors, setErrors);
    if (bankAcctValid) {
      let fd = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key === "bank_statement" && value.item(0) !== null) {
          data[key] = value.item(0);
        }
      }
      for (var key in data) {
        fd.append(key, data[key]);
      }
      dispatch(updateReceivePaymentAccount(fd));
      setOpen(false);
    }
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} title="Update Receive Payment Details" onSubmit={handleSubmit(onSubmit)} withActions={true}>
      <TextField
        margin="dense"
        variant="outlined"
        required
        autoFocus
        id="account_number"
        name="account_number"
        label="Account Number"
        error={errors.account_number ? true : false}
        helperText={errors.account_number ?? ""}
        fullWidth
        inputRef={register}
      />
      <TextField
        margin="dense"
        variant="outlined"
        required
        autoFocus
        id="sort_code"
        name="sort_code"
        label="Sort Code"
        error={errors.sort_code ? true : false}
        helperText={errors.sort_code ?? ""}
        fullWidth
        inputRef={register}
      />
      <Typography>Bak Statement</Typography>
      <input name="bank_statement" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" ref={register} /> <br />
      <br />
    </DialogComponent>
  );
};

export default UpdateDetailsDialog;
