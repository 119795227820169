import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TableComponent from "../../Components/Table/TableComponent";
import { getBasketInformation, getFinanceBaskets, removeFromBasket } from "../../store/actions/financeActions";
import { useUiContext } from "./../../Contexts/UiContext";
import FinanceBasketTableToolbar from "./FinanceBasketTableToolbar";
import FinanceOfferDialog from "./FinanceOfferDialog";

const headCells = [
  { id: "invoice_number", label: "Invoice Number" },
  { id: "customer_name", label: "Customer Name" },
  { id: "issued_date", label: "Issue Date" },
  { id: "due_date", label: "Due Date" },

  { id: "amount_to_be_paid", label: "Amount to be Paid" },
  { id: "bid_closes_at", label: "Bid Expires at" },
  { id: "bid_status", label: "Status" },

  // { id: "is_joined", label: "Joined"}
];

const SingleFinanceBasketTable = () => {
  let dispatch = useDispatch();
  const { errors, loading, clearErrors, setErrors } = useUiContext();
  const history = useHistory();
  let isLoading = useSelector((state) => state.finance.isLoading);

  const [selected, setSelected] = useState([]);
  const [openOfferDialog, setOpenOfferDialog] = useState(false);

  const [financeBasketId, setFinanceBasketId] = useState(null);
  const [dataForTable, setDataforTable] = useState([]);

  let financeBasketDetails = useSelector((state) => state.finance?.financeBasket?.baskets);

  const handleRemoveFromBasket = async () => {
    let selectedDetailId = await Promise.all(
      selected.map((sel) => {
        return sel.basket_detail_id;
      })
    );
    dispatch(removeFromBasket({ basket_details_ids: selectedDetailId }));
    setSelected([]);
  };

  useEffect(() => {
    let basketId = window.location.href.split("/")[5];
    if (parseInt(basketId) && financeBasketDetails) {
      let basket = financeBasketDetails.find((basket) => basket.id === parseInt(basketId));
      if (basket?.invoices) setDataforTable(basket.invoices);
      else setDataforTable([]);
    }
  }, [financeBasketDetails]);

  const financeBasketTableToolbar = () => {
    return (
      <FinanceBasketTableToolbar
        header="Basket Details"
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenOfferDialog}
        handleRemoveFromBasket={handleRemoveFromBasket}
      />
    );
  };

  const calcualteTotalAmount = () => {
    let total = 0;

    if (openOfferDialog) {
      for (let basket of dataForTable) {
        total = parseFloat(basket.amount_to_be_paid) + parseFloat(total);
      }

      return currency(total).format({ symbol: "" });
    }
  };

  return (
    <>
      <TableComponent
        toolbar={financeBasketTableToolbar()}
        data={dataForTable || []}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isLoading={isLoading}
        getAllData={getFinanceBaskets}
        searchBarText="Search customers"
        shouldGetData={false}
        shouldPaginate={false}
        shouldView={true}
      />

      <FinanceOfferDialog
        open={openOfferDialog}
        setOpen={setOpenOfferDialog}
        errors={errors}
        setErrors={setErrors}
        clearErrors={clearErrors}
        total={calcualteTotalAmount()}
        dispatch={dispatch}
        basketInformations={dataForTable}
        history={history}
      />
    </>
  );
};
export default SingleFinanceBasketTable;
