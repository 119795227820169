import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

const BusinessDetails = ({ business, user }) => {
  return (
    <Grid container style={{ margin: "10px" }}>
      <Grid item xs={12}>
        {user.accountType === "Business" && (
          <>
            <TextField
              fullWidth
              id="standard-read-only-input"
              label="Company Name"
              defaultValue={business.name}
              InputProps={{
                readOnly: true,
              }}
              style={{ marginTop: "25px" }}
            />
            <TextField
              fullWidth
              id="standard-read-only-input"
              label="Address"
              defaultValue={business.city + "/" + business.country}
              InputProps={{
                readOnly: true,
              }}
              style={{ marginTop: "25px" }}
            />
            <TextField
              fullWidth
              id="standard-read-only-input"
              label="Phone"
              defaultValue={user.phone}
              InputProps={{
                readOnly: true,
              }}
              style={{ marginTop: "25px" }}
            />
            <TextField
              fullWidth
              id="standard-read-only-input"
              label="Tax Id Number"
              defaultValue={business.taxIdNo}
              InputProps={{
                readOnly: true,
              }}
              style={{ marginTop: "25px" }}
            />
            <TextField
              fullWidth
              id="standard-read-only-input"
              label="Tax Administration"
              defaultValue={business.taxAdmin}
              InputProps={{
                readOnly: true,
              }}
              style={{ marginTop: "25px" }}
            />
            <TextField
              fullWidth
              id="standard-read-only-input"
              label="Business Model"
              defaultValue={business.details.business_model}
              InputProps={{
                readOnly: true,
              }}
              style={{ marginTop: "25px" }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default BusinessDetails;
