import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect } from "react";
import DialogComponent from "../../Components/Dialogs/DialogComponent";

const BuyPackageDialog = ({ open, setOpen, onSubmit, conditionsAccepted, setConditionsAccepted }) => {
  useEffect(() => {
    if (open === false) {
      setConditionsAccepted(false);
    }
    // eslint-disable-next-line
  }, [open]);

  const renderTermsAndConditions = () => {
    return (
      <div style={{ width: "300px" }}>
        <FormControlLabel
          control={
            <Checkbox
              id="termsAndConditionAccepted"
              name="termsAndConditionAccepted"
              checked={conditionsAccepted}
              onChange={() => setConditionsAccepted(!conditionsAccepted)}
              color="primary"
            />
          }
          label={
            <div>
              <span style={{ fontSize: "12px" }}>accept the </span>
              <a style={{ fontSize: "15px", fontWeight: "bold" }} href="/PAYEDA_Membership_PricingAgreement_16112021_4.pdf" download="pricing_agreement.pdf" target="_blank">
                pricing agreement
              </a>
            </div>
          }
        />
      </div>
    );
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={"Information"} onSubmit={onSubmit} withActions={false}>
      {renderTermsAndConditions()}
    </DialogComponent>
  );
};

export default BuyPackageDialog;
