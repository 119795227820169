import React from "react";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";

import TableComponent from "../../Components/Table/TableComponent";
import SuppliersTableToolbar from "./SuppliersTableToolbar";

const headCells = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "erp_no", label: "Erp No" },
  { id: "invite_information", label: "Invite" },
];

const searchOptions = [
  { default: true, id: "company_name_lower", label: "Company Name" },
  { id: "full_name_lower", label: "Full Name" },
  { id: "erp_no_lower", label: "Erp Number" },
];

const SuppliersTable = ({
  suppliers,
  selected,
  setSelected,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  setBankAccountDialogOpen,
  getAllData,
  isLoading,
  history,
}) => {
  const { permissions } = usePermissionsContext();

  const renderToolbar = () => {
    return (
      <SuppliersTableToolbar
        numSelected={selected ? selected.length : 0}
        header="Suppliers"
        setCreateDialogOpen={setCreateDialogOpen}
        setEditDialogOpen={setEditDialogOpen}
        setInviteDialogOpen={setInviteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setBankAccountDialogOpen={setBankAccountDialogOpen}
        history={history}
      />
    );
  };

  return (
    <>
      <TableComponent
        toolbar={renderToolbar()}
        data={suppliers}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        getAllData={getAllData}
        searchOptions={searchOptions}
        isLoading={isLoading}
        searchbarText="Search suppliers"
        shouldGetData={false}
        shouldPaginate={true}
        shouldView={permissions?.supplier?.view}
        shouldSearch={true}
      />
    </>
  );
};
export default SuppliersTable;
