import React, { useState } from 'react'
import clsx from "clsx";
import {Button } from '@material-ui/core';

import ImportSupplierComponent from './ImportSupplierComponent';
import { useSupplierStyles } from './SupplierStyles';
import { useUiContext } from '../../Contexts/UiContext';
import SimpleTable from '../../Components/Table/SimpleTable'

const headCells=[
    { id: "taxIdNo", label: "Tax Id No" },
    { id: "erp_no", label: "ERP NO" },
    { id: "name", label: "Company Name" },
    { id: "repName", label: "Representative Name" },
    { id: "repSurname", label: "Surname" },
    { id: "email", label: "Email" },
    { id: "phone", label: "phone" },
    { id: "country", label: "Country" },
    { id: "city", label: "City" },
]

const ImportSuppliers = ({ addSuppliers, history, business }) => {
    const [suppliers, setSuppliers] = useState([]);

    const classes = useSupplierStyles();
    const { sidebarOpen, loading, errors } = useUiContext();

    const handleSubmitCustomers = () => {
        let data = []
        suppliers.forEach(supplier => {
            supplier.country = business.country;
            supplier.city = "";
            data.push(supplier);
        })

        addSuppliers({ data },history)
    }

    const renderImportedSuppliers = () => {

        const getCreatedSuppliersData=()=>{
            let createdSuppliers=[];

            for (let x = 0; x < suppliers.length; x++) {      
    
                const current = suppliers[x]; 
                createdSuppliers.push({
                    taxIdNo: current.taxIdNo,
                    erp_no: current.erp_no,
                    name: current.name,
                    repName: current.repName,
                    repSurname: current.repSurname,
                    email: current.email,
                    phone: current.phone,
                    country: current.country,
                    city: current.city,
                })
    
            }           
           return createdSuppliers;

        }

        return (
            <div>
                <SimpleTable
                    data={getCreatedSuppliersData()}
                    headCells={headCells}
                />
            </div>
        ) 
    }
        

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: sidebarOpen,
            })}
        >
            <ImportSupplierComponent
                suppliers={suppliers}
                setSuppliers={setSuppliers}
                title="Suppliers"
                loading={loading}
                errors={errors}
            />
            { suppliers.length>0 && ( renderImportedSuppliers() )}
    
            <Button onClick={handleSubmitCustomers} color="primary" variant="contained">
                Submit
            </Button>
        </main>
    )
}

export default ImportSuppliers
