import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { getPendingApprovalBusinesses, verifyBusiness } from "../../../store/actions/adminActions";
import BusinessesToolbar from "./BusinessesToolbar";
import BusinessFilesDialog from "./BusinessFilesDialog";
import PendingApprovalBusinessesTable from "./PendingApprovalBusinessesTable";

const PendingApprovalBusinesses = ({ business, history }) => {
  const pendingApprovalBusinesses = useSelector((state) => state.admin.pendingApprovalBusinesses);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [businessFilesDialogOpen, setBusinessFilesDialogOpen] = useState(false);
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getPendingApprovalBusinesses());
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const handleVerifyBusiness = (e) => {
    var businessId;
    selected.map((business, index) => (businessId = business.id));
    var details = { businessId };
    dispatch(verifyBusiness(details, history));
    setSelected([]);
  };

  const renderToolbar = () => {
    return (
      <BusinessesToolbar
        numSelected={selected ? selected.length : 0}
        header="Pending Approval Businesses"
        setBusinessFilesDialogOpen={setBusinessFilesDialogOpen}
        selected={selected[0]}
        handleVerifyBusiness={handleVerifyBusiness}
      />
    );
  };

  return (
    <BasePage navigation="Dashboard/Pending-Approval-Businesses" business={business} canView={true}>
      <div>
        <PendingApprovalBusinessesTable
          toolbar={renderToolbar()}
          businessesToApprove={pendingApprovalBusinesses}
          selected={selected}
          setSelected={setSelected}
          getAllData={getPendingApprovalBusinesses}
        />

        {selected.length > 0 && (
          <BusinessFilesDialog open={businessFilesDialogOpen} selected={selected} setSelected={setSelected} setOpen={setBusinessFilesDialogOpen} business={selected[0]} />
        )}
      </div>
    </BasePage>
  );
};

export default PendingApprovalBusinesses;
