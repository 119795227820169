import { Button, Tooltip } from "@material-ui/core";
import React from "react";
import TableToolbarComponent from "../../../Components/Table/TableToolbarComponent";

const PendingApprovalBusinessesTableToolbar = ({
  numSelected,
  header,
  setBusinessFilesDialogOpen,
  handleVerifyBusiness,
  selected,
}) => {
  //TODO: permission ekle
  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {numSelected > 0 ? (
          <>
            {numSelected === 1 && (
              <>
                <Tooltip title="Sanction check for business">
                  <Button
                    style={{
                      backgroundColor: "#FFC600",
                      color: "white",
                      width: "175px",
                      marginRight: "5px",
                    }}
                    variant="contained"
                    onClick={() =>
                      window.open(
                        `http://35.246.50.248/?query=${selected.name}`
                      )
                    }
                  >
                    Sanction Check
                  </Button>
                </Tooltip>

                <Tooltip title="Show Files">
                  <Button
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      width: "175px",
                      marginRight: "5px",
                    }}
                    variant="contained"
                    onClick={() => setBusinessFilesDialogOpen(true)}
                  >
                    Show Files
                  </Button>
                </Tooltip>

                {header === "Pending Approval Businesses" ? (
                  <>
                    <Tooltip title="Verify ">
                      <Button
                        variant="contained"
                        onClick={handleVerifyBusiness}
                        style={{
                          backgroundColor: "green",
                          color: "white",
                        }}
                      >
                        Verify
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {/* <Tooltip title="Deny ">
                          <Button
                            variant="contained"
                            onClick={handleDenyBusiness}
                            style={{
                              backgroundColor: "red",
                              color: "white",
                            }}
                          >
                            Deny
                          </Button>
                        </Tooltip> */}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </>
    </TableToolbarComponent>
  );
};

export default PendingApprovalBusinessesTableToolbar;
