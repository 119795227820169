import { Button, Divider, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import UpgradeBusinessDialog from "../../Components/Dialogs/UpgradeBusinessDialog";
import { useUiContext } from "../../Contexts/UiContext";
import { getUserRemainingBalance } from "../../store/actions/bankingActions";
import { getAllPackages } from "../../store/actions/businessActions";
import { API_BASE } from "../../store/env";
import PaymentResult from "../../util/banking/PaymentResult";
import MakePaymentDialog from "../Invoice/MakePaymentDialog";
import AgreementDialog from "./AgreementDialog";
import "./plans.css";

const PlansCardContainer = ({ business }) => {
  let location = useLocation();
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { setErrors, errors, clearErrors } = useUiContext();

  const allPackages = useSelector((state) => state.business.packages);

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [makePaymentDialogOpen, setMakePaymentDialogOpen] = useState(false);
  const [openUpgradeBusiness, setOpenUpgradeBusinessDialog] = useState(false);
  const [upgradeLink, setUpgradeLink] = useState("");

  const [agreementDialogOpen, setAgreementDialogOpen] = useState(false);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);

  const [shouldRenderPaymentResult, setShouldRenderPaymentResult] = useState(false);
  const [hasPaymentId, setHasPaymentId] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // const [dashboardType, setDashboardType] = useState(null);
  let dashboardType = localStorage.getItem("dashboardType");

  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }, []);

  useEffect(() => {
    if (user?.permissions?.plan?.view) {
      dispatch(getAllPackages(dashboardType));
    }
    // eslint-disable-next-line
  }, [dashboardType]);

  useEffect(() => {
    let payment_id = new URLSearchParams(location.search).get("payment_id");
    if (payment_id) {
      setHasPaymentId(true);
      setShouldRenderPaymentResult(true);
    }
    // eslint-disable-next-line
  }, []);

  useState(() => {
    if (hasPaymentId) {
      setShouldRenderPaymentResult(true);
    }
  }, [hasPaymentId]);

  const [packId, setPackId] = useState("");
  const [packType, setPackType] = useState("");

  const handleBuyPackage = async (id, pkType) => {
    if (pkType === "business_membership" || pkType === "transfer_money_membership") {
      setAgreementDialogOpen(true);
      setPackId(id);
      setPackType(pkType);
    } else {
      makePayment(id, pkType);
    }
  };

  useEffect(() => {
    if (conditionsAccepted) {
      setAgreementDialogOpen(false);
      makePayment(packId, packType);
    }
    // eslint-disable-next-line
  }, [conditionsAccepted]);

  useEffect(() => {
    dispatch(getUserRemainingBalance());
    // eslint-disable-next-line
  }, []);

  const makePayment = async (id, pkType) => {
    let accountType = business.account_type;

    let isPaymentReceivedVerified = business.canReceivePayment;
    let isTransferMoneyVerified = business.canTransferMoney;

    let isAwaitingReceivePayment = business.awaitingReceivePaymentApproval;
    let isAwaitingTransferMoney = business.awaitingTransferMoneyApproval;

    if (pkType === "business_membership") {
      if (accountType === "Business" && !isPaymentReceivedVerified && !isAwaitingReceivePayment) {
        setOpenUpgradeBusinessDialog(true);
        setUpgradeLink("/dashboard/update-business?type=receive_payment");
        return false;
      }
    }

    if (pkType === "transfer_money_membership") {
      if (accountType === "Personal" && !isTransferMoneyVerified && !isAwaitingTransferMoney) {
        setOpenUpgradeBusinessDialog(true);
        setUpgradeLink("/dashboard/update-business?type=transfer_money");
        return false;
      }
    }

    if (conditionsAccepted || pkType === "payment_token" || pkType === "transfer_money_token") {
      try {
        setIsLoading(true);
        let response = await axios({
          method: "post",
          url: `${API_BASE}/v1/packages/payment`,
          data: { package_ids: id },
        });
        if (response.data.invoiceIds) {
          setIsLoading(false);
          setSelectedInvoices([...response.data.invoiceIds]);
          setMakePaymentDialogOpen(true);
          setPackId(id);
        }
      } catch (error) {
        setIsLoading(false);

        setErrors({ general: error?.response?.data?.error?.general });
      }
    }
  };
  // useEffect(async () => {
  //   let payment_id = new URLSearchParams(location.search).get("payment_id");

  //   if (payment_id && !paymentLoading) {
  //     dispatch({ type: "PAYMENT_RESULT_LOADING" });

  //     let response = await axios({
  //       method: "post",
  //       url: `${API_BASE}/v1/packages/payment-result`,
  //       data: { paymentID: payment_id },
  //     });
  //     dispatch({
  //       type: "PAYMENT_RESPONSE_FETCH_SUCCESS",
  //       payload: response.data,
  //     });
  //     setShouldRenderPaymentResult(true);
  //     dispatch(getBusinessDetails());
  //     history.replace("/dashboard/buy-plans");
  //   }
  // }, [afterRender]);

  // 14 UNAVAILABLE: No connection established
  const renderPrice = (pk, c) => {
    return (
      <>
        <Typography style={{ color: c ? c : "black", fontWeight: "bold" }}>GBP {pk.package_fee}</Typography>
      </>
    );
  };

  const sho1 = {
    well: {
      boxShadow: `10px 3px 10px grey`,
      backgroundColor: "#FFCDD2",
      borderRadius: "20px",
      flexGrow: 2,
      maxWidth: "400px",
    },
  };

  const renderMembership = (pk, i) => {
    return (
      <div>
        {i === 0 && (
          <Grid item xs={12} lg={3} md={3} style={{ padding: "40px" }} class="pricingGrid">
            <ul class="pricing p-green">
              <li>
                <big>{pk.package_name}</big>
              </li>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return (
                      <>
                        <li>{p}</li>
                      </>
                    );
                  })}
                </>
              )}
              <li>
                <strong>UNLIMITED</strong> Invoices upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Customer upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Suppliers upload
              </li>

              <li>
                <h3>GBP {pk.package_fee}</h3>
                <span>per 90 days</span>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleBuyPackage(pk.id, pk.package_type);
                  }}
                >
                  Get Now
                </button>
              </li>
            </ul>
          </Grid>
        )}
        {i === 1 && (
          <Grid item xs={12} lg={3} md={3} style={{ padding: "40px" }} class="pricingGrid">
            <ul class="pricing p-yel">
              <li>
                <big>{pk.package_name}</big>
              </li>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return (
                      <>
                        <li>{p}</li>
                      </>
                    );
                  })}
                </>
              )}
              <li>
                <strong>UNLIMITED</strong> Invoices upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Customer upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Suppliers upload
              </li>

              <li>
                <h3>GBP {pk.package_fee}</h3>
                <span>per 90 days</span>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleBuyPackage(pk.id, pk.package_type);
                  }}
                >
                  Get Now
                </button>
              </li>
            </ul>
          </Grid>
        )}
        {i === 2 && (
          <Grid item xs={12} lg={3} md={3} style={{ padding: "40px" }} class="pricingGrid">
            <ul class="pricing p-red">
              <li>
                <big>{pk.package_name}</big>
              </li>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return (
                      <>
                        <li>{p}</li>
                      </>
                    );
                  })}
                </>
              )}
              <li>
                <strong>UNLIMITED</strong> Invoices upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Customer upload
              </li>
              <li>
                <strong>UNLIMITED</strong> Suppliers upload
              </li>

              <li>
                <h3>GBP {pk.package_fee}</h3>
                <span>per 90 days</span>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleBuyPackage(pk.id, pk.package_type);
                  }}
                >
                  Get Now
                </button>
              </li>
            </ul>
          </Grid>
        )}
      </div>
    );
  };

  const sho2 = {
    well: {
      textAlign: "center",
      marginTop: "25px",
      boxShadow: `1px 3px 10px grey`,
    },
  };

  const renderOtherPackages = (pk) => {
    return (
      <div style={sho2.well}>
        <div
          style={{
            width: "200px",
            height: "200px",
            backgroundColor: pk?.package_type === "transfer_money_token" ? "#898989" : "#898989",
            float: "left",
            margin: "10px",
            borderRadius: "50%",
            marginLeft: "60px",
            boxShadow: `10px 0px 10px grey`,
          }}
        >
          <div
            style={{
              color: "white",
            }}
          >
            <p style={{ marginTop: "30px", fontSize: "18px" }}>{pk.package_name}</p>

            {renderPrice(pk, "white")}

            <div style={{ marginTop: "10px", fontSize: "13px" }}>
              {pk.package_features && (
                <>
                  {pk.package_features.map((p) => {
                    return <>{p}</>;
                  })}
                </>
              )}
            </div>

            <Button
              variant="outlined"
              color="white"
              style={{ fontWeight: "bold", marginTop: "13px" }}
              onClick={() => {
                handleBuyPackage(pk.id, pk.package_type);
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const renderPackages = () => {
    let cards = [];
    let i = 0;

    if (allPackages && allPackages.length > 0) {
      for (let pk of allPackages) {
        let item;
        if (pk !== null) {
          if (pk?.package_type === "business_membership" || pk?.package_type === "transfer_money_membership") {
            item = renderMembership(pk, i);
            i++;
          } else {
            item = renderOtherPackages(pk);
            // eslint-disable-next-line
            i++;
          }
        }

        cards.push(item);
      }
    }
    return cards;
  };
  return (
    <div>
      {shouldRenderPaymentResult && <PaymentResult />}
      {isLoading ? (
        "Loading"
      ) : (
        <>
          {allPackages && allPackages.length === 0 && (
            <>
              <Typography>No suitable package found</Typography>
            </>
          )}
        </>
      )}
      <Grid container spacing={3} style={{ justifyContent: "center" }}>
        {allPackages && allPackages.length > 0 && (
          <>
            {allPackages.map((pk, i) => {
              return renderMembership(pk, i);
            })}
          </>
        )}
      </Grid>
      <AgreementDialog open={agreementDialogOpen} setOpen={setAgreementDialogOpen} conditionsAccepted={conditionsAccepted} setConditionsAccepted={setConditionsAccepted} />
      {selectedInvoices.length > 0 && makePaymentDialogOpen && (
        <MakePaymentDialog
          open={makePaymentDialogOpen}
          setOpen={setMakePaymentDialogOpen}
          selectedInvoices={selectedInvoices}
          errors={errors}
          setErrors={setErrors}
          clearErrors={clearErrors}
          redirectURI="payment-result/plans"
          payeda_package_id={packId}
        />
      )}

      <UpgradeBusinessDialog open={openUpgradeBusiness} setOpen={setOpenUpgradeBusinessDialog} upgradeLink={upgradeLink} />
    </div>
  );
};

export default PlansCardContainer;
