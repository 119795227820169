import { Button, Grid, Link } from "@material-ui/core";
import React, { useState } from "react";
import TableToolbarComponent from "../../Components/Table/TableToolbarComponent";
import InvoiceDetailsDialog from "./InvoiceDetailsDialog";

const FinancingInvoicesToolbar = ({ numSelected, selected, data, getExell, acceptOffer }) => {
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  return (
    <TableToolbarComponent header={"Financing Invoices"} numSelected={numSelected}>
      <>
        {numSelected && numSelected === 1 ? (
          <>
            <Button onClick={() => setOpenDetailsDialog(true)} variant="contained" style={{ float: "right", marginRight: "5px", backgroundColor: "#9867C5", color: "white" }}>
              Invoice Details
            </Button>
            <>
              <Button
                onClick={() => {
                  window.open(selected[0].offer_document);
                }}
                variant="contained"
                style={{ float: "right", marginRight: "5px", backgroundColor: "green", color: "white" }}
              >
                Download Offer PDF{" "}
              </Button>
            </>
            <>
              <Button onClick={() => getExell("one")} color="primary" variant="contained" style={{ float: "right", marginRight: "5px" }}>
                Download Offer Excell
              </Button>
            </>
            <>
              <Button onClick={() => acceptOffer()} color="secondary" variant="contained" style={{ float: "right" }}>
                Accept Offer
              </Button>
            </>
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4} md={4}>
                {data && data.length > 0 && (
                  <>
                    <Button onClick={() => getExell("all")} color="primary" variant="outlined">
                      Download All Offers
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <InvoiceDetailsDialog open={openDetailsDialog} setOpen={setOpenDetailsDialog} selected={selected} rows={selected[0]?.invoice_details || []} />
      </>
    </TableToolbarComponent>
  );
};

export default FinancingInvoicesToolbar;
