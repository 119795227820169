import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from './../../util/constants/general';

export const useCreateInvoiceComponentStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 30,
    },
    paper: {
        width: "100%",

        color: theme.palette.text.secondary,
    },
    content: {
        flexGrow: 0,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        paddingLeft: drawerWidth + 40,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    gridPaper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));


export const useOutgoingInvoicesStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },

    content2: {
        display: "flex",
        flex: "1 1 auto",
        overlflow: "hidden",
        paddingTop : 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
          }

    },
    contentContainer: {
        display : "flex",
        flex : "1 1 auto",
        overflow : "hidden"
    },

    contentIn: {
        flex : "1 1 auto",
        height : "100%",
        overflow : "auto"
    },
}));

export const useIncomingInvoicesStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    content: {
        flexGrow: 0,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -250,
        paddingLeft: 250 + 40,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    gridPaper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },


}));

export const useImportInvoiceStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 30,
    },
    paper: {
        width: "100%",
        color: theme.palette.text.secondary,
        borderRadius:30,
    },


    content2: {
        display: "flex",
        flex: "1 1 auto",
        overlflow: "hidden",
        paddingTop : 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
          }

    },
    contentContainer: {
        display : "flex",
        flex : "1 1 auto",
        overflow : "hidden"
    },

    contentIn: {
        flex : "1 1 auto",
        height : "100%",
        overflow : "auto"
    },
    
    box: {
        padding: 10,
        margin: 10,
        display: "inline-block",
        borderStyle: "dashed",
        width: "min-content"
    },
    grid: {
        flexGrow: 1,
        margin: "10px",
        width: "80%",
    },
}));

export const useInvoiceAmountStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "28px",
        marginLeft: "800px",
        fontWeight: 400,
    },
    line: {
        fontWeight: "700",
        color: "#363636",
    }
}));

export const useCheckoutStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        background: '#828181',
        height: '100vh',
    },
    paper: {
        margin: "40px",
        padding: "20px",
    },
 
}));



