import React from 'react'

import SimpleTable from '../../Components/Table/SimpleTable'

const headCells = [
    { id: "order", label: "Order" },
    { id: "product", label: "Product" },
    { id: "product_quantity", label: "Quantity" },
    { id: "unit_price", label: "Unit Price" },
    { id: "line_vat_amont", label: "VAT AMOUNT" },
    { id: "line_discount_amount", label: "Discount" },
    { id: "subtotal_amount", label: "Sub Total" },
    { id: "line_total_amount", label: "Total" },

];

const InvoiceComponent = ({ invoice }) => {
    const getInvoiceLines = (invoiceLines) => {
        let fixedInvoiceLines = []

        for (let x = 0; x < invoiceLines.length; x++) {
            const current = invoiceLines[x];

            fixedInvoiceLines.push({
                order: x + 1,
                product: current.product ?? "",
                product_quantity: current.product_quantity ?? "",
                unit_price: current.unit_price ?? "",
                line_discount_amount: current.line_discount_amount ?? 0,
                line_vat_amont: current.line_vat_amont ?? 0,
                line_total_amount: current.line_total_amount ?? 0,
                subtotal_amount: current.subtotal_amount ?? 0,
            })
        }

        return fixedInvoiceLines;
    }

    return (
        <div>
            <SimpleTable
                data={getInvoiceLines(invoice.invoice_lines)}
                headCells={headCells}
                canDelete={false}
            />
        </div>
    )
}

export default InvoiceComponent
