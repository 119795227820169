import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "../../../../Components/DropdownComponent";
import { getAllBusinesses, getAllVerifiedSuppliers, getSuppliersCustomers, getSuppliersInvoices } from "../../../../store/actions/adminActions";
import { useForm } from "react-hook-form";
import Customers from "./Customers";
import Invoices from "./Invoices";

const MainPage = () => {
    const dispatch = useDispatch();
    const { register, control, watch, handleSubmit, setValue } = useForm();

    const [selectedChoice, setSelectedChoice] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const pageChoices = [{ value: "customer", label: "Customers" }, { value: "invoice", label: "Invoices" }, { value: "receipt", label: "Receipts" }];

    let allSuppliers = useSelector((state) => state.admin.allSuppliers);
    let isSuppliersLoading = useSelector((state) => state.admin.loadingSuppliers);

    const allCustomers = useSelector((state) => state.admin.allCustomerOfSuppliers);
    const allInvoices = useSelector((state) => state.admin.allInvoiceOfSuppliers);

    let loadingSuppliersCustomer = useSelector((state) => state.admin.loadingSuppliersCustomer);
    let loadingSuppliersInvoices = useSelector((state) => state.admin.loadingSuppliersInvoices);

    const allReceipts = [];

    useEffect(() => {
        if (selectedChoice === "customer") {
            dispatch(getSuppliersCustomers(selectedSupplier))
        } else if (selectedChoice === "invoice") {
            dispatch(getSuppliersInvoices(selectedSupplier))
        }
    }, [selectedChoice, selectedSupplier])

    useEffect(() => {
        dispatch(getAllVerifiedSuppliers());
    }, []);


    console.log("selectedChoice", selectedChoice)
    const suppliersList = () => {
        if (isSuppliersLoading) {
            return [{ value: "null", label: "Loading..." }];
        }
        if (isSuppliersLoading === false && allSuppliers && allSuppliers.length > 0) {
            console.log("asllS", allSuppliers)
            let list = allSuppliers.map((supplier) => {
                return { value: supplier.id, label: supplier.name };
            });
            return list;
        } else {
            return [{ value: "null", label: "No supplier" }];
        }
    };

    const renderSuppliersList = () => {
        return (
            <DropdownComponent
                required
                id="page"
                name="page"
                label="Select a supplier"
                items={suppliersList() || []}

                onChange={(e) => {
                    setSelectedSupplier(e.target.value);
                }}
                control={control}
            />)
    }
    const renderPageChoicesDropDown = () => {
        return (
            <DropdownComponent
                required
                id="page"
                name="page"
                label="Select a field"
                items={pageChoices}
                onChange={(e) => {
                    setSelectedChoice(e.target.value);
                }}
                control={control}
            />

        )
    }

    const renderCustomersTable = () => {
        return (
            <>
                {loadingSuppliersCustomer !== false ? "Customers Loading" : allCustomers && allCustomers.length > 0 ? <Customers customers={allCustomers} isLoading={loadingSuppliersCustomer} /> : "No customers found"
                }
            </>
        )
    }

    const rendderInvoicesTable = () => {
        return (
            <>{loadingSuppliersInvoices !== false ? "Invoices Loading" : allInvoices && allInvoices.length > 0 ? <Invoices invoices={allInvoices} isLoading={loadingSuppliersInvoices} /> : "No invoices found"}</>
        )
    }

    return (
        <div>
            {isSuppliersLoading !== false ? "Suppliers Loading" : renderSuppliersList()}
            {selectedSupplier && renderPageChoicesDropDown()}
            {selectedChoice && selectedSupplier && (
                (selectedChoice === "customer" && (renderCustomersTable()) ||
                    selectedChoice === "invoice" && rendderInvoicesTable())
            )}
        </div>
    )
}

export default MainPage