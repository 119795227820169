import { Grid, Typography } from "@material-ui/core";
import React from "react";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import SimpleTable from "../../Components/Table/SimpleTable";
import InvoiceComponent from "../Invoice/InvoiceComponent";

const outgoingInvoicesTableHeadCells = [
  { id: "invoice_number", label: "Invoice Number" },
  { id: "customer_name", label: "Customer Name" },
  { id: "issued_date", label: "Issue Date" },
  { id: "due_date", label: "Due Date" },
  { id: "status", label: "Status" },
  { id: "amount", label: "Amount to be Paid" },
];

const InvoiceDetailsDialog = ({ open, setOpen, title, invoice }) => {
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description=""
      withActions={false}
    >
      <>
        <Grid container spacing={3}>
          <Grid>
            <SimpleTable
              data={[{ ...invoice }]}
              headCells={outgoingInvoicesTableHeadCells}
              canDelete={false}
            />
          </Grid>
          <Grid item xs={12} spacing={1}>
            <Typography>Invoice Lines </Typography>
            <p></p>
            <InvoiceComponent invoice={invoice} />
          </Grid>
        </Grid>
      </>
    </DialogComponent>
  );
};

export default InvoiceDetailsDialog;
