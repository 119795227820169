import { Grid, TextField, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import PhoneInputComponent from "../../../Components/PhoneInputComponent";
import { BUSINESS_MODELS, BUSINESS_TYPES } from "../../../util/constants/companyInformation";

//TODO needs translations
function CompanyInformationPage({
  errors,
  business,
  usageType,
  accountType,
  setCompanyCert,
  setBnkSt,
  setLogo,
  shouldAskForCompanyDetails,
  selectedOption,
  setIdFront,
  setIdRear,
}) {
  // const methods = useFormContext({shouldUnregister: true});
  const { register, setValue, control } = useFormContext();
  const [businessTypes, setBusinessTypes] = useState([]);

  useEffect(() => {
    let types = [];
    if (selectedOption === "soleTrader") {
      types = BUSINESS_TYPES.filter((type) => type.value === "soleTrader");
    } else {
      types = BUSINESS_TYPES.filter((type) => type.value !== "soleTrader");
    }
    setBusinessTypes(types);
  }, [selectedOption]);

  console.log("types", businessTypes);
  useEffect(() => {
    if (business && business.company_details) {
      setValue("business_email", business.company_details.email);
      setValue("business_phone", business.company_details.phone);
      setValue("city", business?.company_details?.address?.city);
      setValue("business_type", business?.company_details?.business_type, true);
      setValue("business_model", business?.company_details?.business_model, true);

      setValue("address_line_1", business?.company_details?.address?.address_line_1);
      setValue("address_line_2", business?.company_details?.address?.address_line_2);
    }
    // eslint-disable-next-line
  }, [business]);

  const renderGeneralInfor = () => {
    return (
      <Grid container spacing={3} style={{ marginTop: "5px" }}>
        {shouldAskForCompanyDetails && (
          <>
            <Grid item xs={12} lg={6} md={6}>
              <TextField
                required
                variant="outlined"
                id="business_name"
                name="business_name"
                label="Company Name"
                error={errors.business_name ? true : false}
                helperText={errors.business_name ?? ""}
                type="text"
                fullWidth={true}
                InputProps={{ inputProps: { min: 2 } }}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} lg={3} md={3}>
              <TextField
                required
                variant="outlined"
                id="address_line_1"
                name="address_line_1"
                label="Address Line 1"
                error={errors.address_line_1 ? true : false}
                helperText={errors.address_line_1 ?? ""}
                type="text"
                fullWidth={true}
                InputProps={{ inputProps: { min: 2 } }}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} lg={3} md={3}>
              <TextField
                required
                variant="outlined"
                id="address_line_2"
                name="address_line_2"
                label="Address Line 2"
                error={errors.address_line_2 ? true : false}
                helperText={errors.address_line_2 ?? ""}
                type="text"
                fullWidth={true}
                InputProps={{ inputProps: { min: 2 } }}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} lg={6} md={6}>
          <PhoneInputComponent name="business_phone" errors={errors} register={register} control={control} required />
          <br />
          <TextField
            required
            variant="outlined"
            id="business_email"
            name="business_email"
            label="Company Email"
            error={errors.business_email ? true : false}
            helperText={errors.business_email ?? ""}
            type="text"
            fullWidth={true}
            InputProps={{ inputProps: { min: 2 } }}
            InputLabelProps={{ shrink: true }}
            inputRef={register}
          />
        </Grid>

        <Grid item xs={12} lg={6} md={6}>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Choose business type"
            defaultValue={selectedOption === "soleTrader" ? "soleTrader" : business?.company_details?.business_type}
            error={errors.business_type}
            helperText={errors.business_type?.message}
            inputRef={register}
            onChange={(e) => setValue("business_type", e.target.value, true)}
          >
            {businessTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />

          <TextField
            select
            fullWidth
            variant="outlined"
            label="Choose business model"
            defaultValue={business?.company_details?.business_model}
            inputRef={register}
            error={errors.business_model}
            helperText={errors.business_model}
            onChange={(e) => setValue("business_model", e.target.value, true)}
          >
            {BUSINESS_MODELS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  const renderIsErpOrIsManuel = () => {
    return (
      <form>
        <div style={{ fontSize: "18px" }}>
          <div>
            <input
              id="isErp"
              value="isErp"
              name="isErpOrIsManuel"
              type="radio"
              // onChange={(e) => handleRadioChange(e)}
              ref={register}
            />
            <label for="isErpOrIsManuel">Cloud Invoicing Platfrom</label>
          </div>
          <input
            id="isManuel"
            value="isManuel"
            name="isErpOrIsManuel"
            type="radio"
            // onChange={(e) => handleRadioChange(e)}
            ref={register}
          />
          <label for="isErpOrIsManuel">Manual invoicing platform</label>
        </div>
      </form>
    );
  };

  const renderFilesForBusiness = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} md={3}>
          {accountType === "Business" && (
            <>
              <small>Logo:</small> <br />
              <input name="logo" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setLogo(e.target.files)} /> <br />
              <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
            </>
          )}
        </Grid>
        {accountType === "Business" && usageType === "receive_payment" && (
          <>
            {selectedOption !== "soleTrader" && (
              <Grid item xs={12} lg={4} md={4}>
                <small>Company Certificate:</small> <br />
                <input
                  name="company_certificate"
                  type="file"
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  onChange={(e) => setCompanyCert(e.target.files)}
                />{" "}
                <br />
                <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
              </Grid>
            )}

            <Grid item xs={12} lg={3} md={3}>
              <small>Bank statement:</small> <br />
              <input name="bank_statement" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setBnkSt(e.target.files)} />
              <br />
              <small style={{ fontSize: "13px" }}>Document of the account you want to receive payments into</small>
              <br />
              <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
            </Grid>

            {selectedOption === "soleTrader" && (
              <>
                {" "}
                <Grid item xs={12} lg={3} md={3}>
                  <small>Front page of your ID:</small> <br />
                  <input name="id_front_page" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setIdFront(e.target.files)} /> <br />
                  <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <small>Rear page of your ID:</small> <br />
                  <input name="id_rear_page" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setIdRear(e.target.files)} /> <br />
                  <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={12} md={12}>
              <small>Currency: </small>
              <select name="currency" ref={register}>
                <option value="GBP" selected>
                  GBP
                </option>
              </select>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                id="account_number"
                name="account_number"
                label="Account Number"
                error={errors.account_number ? true : false}
                helperText={errors.account_number ?? "Account Number of the account specified in the bank statement document"}
                type="text"
                fullWidth={true}
                InputProps={{ inputProps: { min: 2 } }}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
            </Grid>

            <Grid item sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                id="sort_code"
                name="sort_code"
                label="Sort code"
                error={errors.sort_code ? true : false}
                helperText={errors.sort_code ?? "Sort Code of the account specified in the bank statement document"}
                type="text"
                fullWidth={true}
                InputProps={{ inputProps: { min: 2 } }}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <form>
      <Grid container spacing={2}>
        <>
          {accountType === "Business" && (
            <>
              {" "}
              {renderGeneralInfor()}
              <div style={{ marginTop: "30px" }}>{renderFilesForBusiness()}</div>
              <Grid item xs={12} lg={12} md={12}>
                {usageType === "receive_payment" && (
                  <>
                    {" "}
                    <Typography style={{ marginTop: "10px" }}>Choose your invoicing platform usage type:</Typography>
                    {renderIsErpOrIsManuel()}
                  </>
                )}
              </Grid>
            </>
          )}
        </>

        <br />
      </Grid>
    </form>
  );
}

export default CompanyInformationPage;
