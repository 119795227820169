import Button from "@material-ui/core/Button";
import React from "react";
import AddButton from "./../../../../Components/shared/AddButton";
import TableToolbarComponent from "./../../../../Components/Table/TableToolbarComponent";
import { usePermissionsContext } from "./../../../../Contexts/PermissionsContext";
import canPerformAction from "./../../../../util/permissions/canPerformAction";

const BankAccountsToolbar = ({ numSelected, header, setAddBankAccountDialogOpen, history, business }) => {
  const { permissions } = usePermissionsContext();

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      {business && business?.payeda_package?.bank_token && Number(business?.payeda_package?.bank_token.Balance) > 0 ? (
        <>
          <div style={{ marginRight: "20px" }}>
            <Button variant="outlined" onClick={() => history.push("/dashboard/transactions")}>
              transactions
            </Button>
          </div>
          <div>
            {canPerformAction("bankAccounts", permissions, "create") && (
              <>
                <AddButton onClick={() => setAddBankAccountDialogOpen(true)} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Button color="primary" variant="outlined" onClick={() => history.push("/dashboard/buy-plans")}>
            Buy bank token
          </Button>
        </>
      )}
    </TableToolbarComponent>
  );
};

export default BankAccountsToolbar;
