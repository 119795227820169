import React, { createContext, useContext } from 'react'
import { connect } from "react-redux";

const PermissionsContext = createContext();

const PermissionsProvider = (props) => {
    const { children, permissions } = props;
    const providerValue = { permissions }

    return (
        <PermissionsContext.Provider value={providerValue}>
            {children}
        </PermissionsContext.Provider>
    )
}

export function usePermissionsContext() {
    return useContext(PermissionsContext);
}

const mapStateToProps = state => {
    return {
        permissions: state.user.permissions
    }
}

export default connect(mapStateToProps)(PermissionsProvider)
