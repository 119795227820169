import React from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

import PhoneInputComponent from './../../../../Components/PhoneInputComponent';
import { useUiContext } from './../../../../Contexts/UiContext';

//TODO use translations
const EditBusinessDetails = ({ updateUserInformation, user }) => {
  const { register, control, handleSubmit, reset } = useForm();
  const { errors } = useUiContext();

  React.useEffect(() => {
    reset(user)
  }, [user, reset])

  const onSubmit = (data) => {
    updateUserInformation(data)
  };

  const renderFormBody = () => {
    return (
      <>
        <TextField
          id="name"
          name="name"
          label="Name"
          error={errors.name ? true : false}
          helperText={errors.name ?? ""}
          inputRef={register}
          fullWidth
        />
        <TextField
          id="surname"
          name="surname"
          label="Surname"
          error={errors.surname ? true : false}
          helperText={errors.surname ?? ""}
          inputRef={register}
          fullWidth
        />

        <PhoneInputComponent
          errors={errors}
          register={register}
          control={control}
        />
{/* 
        <TextField
          id="currentPassword"
          name="currentPassword"
          label="Current Password"
          type="password"
          error={errors.currentPassword ? true : false}
          inputRef={register}
          helperText={errors.currentPassword ?? ""}
        />
        <TextField
          id="newPassword"
          name="newPassword"
          label="Password"
          type="password"
          error={errors.newPassword ? true : false}
          inputRef={register}
          helperText={errors.newPassword ?? ""}
        />
        <TextField
          id="confirmPassword"
          name="confirmPassword"
          label="Repeat Password"
          type="password"
          error={errors.confirmPassword ? true : false}
          inputRef={register}
          helperText={errors.confirmPassword ?? ""}
        /> */}
        <br />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit(onSubmit)()}
        >
          Update
        </Button>
      </>
    );
  };

  return (
    <FormControl style={{ width: "100%" }}>{renderFormBody()}</FormControl>
  );
};
export default withRouter(EditBusinessDetails);
