// eslint-disable-next-line

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Assessment from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import GroupIcon from "@material-ui/icons/Group";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import StarIcon from "@material-ui/icons/Star";
import CloudIcon from "@material-ui/icons/Cloud";
//TODO needs translation
export const BUSINESS_ITEMS = [
  { text: "DashBoard", icon: <Assessment />, link: "/dashboard" },
  {
    text: "Incoming Invoices",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/incoming-invoices",
  },
  {
    text: "Outgoing Invoices",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/outgoing-invoices",
  },
  {
    text: "Customers",
    icon: <GroupIcon />,
    link: "/dashboard/customers",
  },
  {
    text: "Suppliers",
    icon: <GroupIcon />,
    link: "/dashboard/suppliers",
  },
  {
    text: "Products",
    icon: <ShoppingCartOutlinedIcon />,
    link: "/dashboard/products",
    permissionType: "product",
    permission: "view",
  },
  {
    text: "Account",
    icon: <AccountBoxIcon />,
    subMenus: [
      {
        text: "Documents",
        icon: <AssignmentIcon />,
        link: "/dashboard/my-documents",
      },

      {
        text: "Bank Account",
        icon: <CreditCardIcon />,
        link: "/dashboard/bank-accounts",
        permissionType: "bankAccount",
        permission: "view",
      },
    ],
  },

  {
    text: "Buy Plan",
    icon: <StarIcon />,
    link: "/dashboard/buy-plans",
  },
];
export const CONNECT_ERP_CLOUD = [
  {
    text: "Connect Erp Cloud",
    icon: <CloudIcon />,
    link: "/dashboard/connect-erp-cloud-app",
  },
];
const ADMIN_ITEMS = [
  {
    text: "All Users",
    icon: <GroupIcon />,
    link: "/dashboard/user-accounts",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Pending Approval",
    icon: <GroupIcon />,
    link: "/dashboard/pending-business-table",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Verified Businesses",
    icon: <GroupIcon />,
    link: "/dashboard/verified-business-table",
    // permissionType: "customer",
    // permission: "view",
  },

  {
    text: "Businesses Packages",
    icon: <GroupIcon />,
    link: "/dashboard/businesses-packages",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Add Finance Account",
    icon: <GroupIcon />,
    link: "/dashboard/add-finance-account",
    // permissionType: "customer",
    // permission: "view",
  },

  {
    text: "Transactions",
    icon: <GroupIcon />,
    link: "/dashboard/all-transactions",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Incomplete Payments",
    icon: <GroupIcon />,
    link: "/dashboard/incomplete-payments",
    // permissionType: "customer",
    // permission: "view",
  },
  // {
  //   text: "Transactions By User",
  //   icon: <GroupIcon />,
  //   link: "/dashboard/all-transactions-by-user",
  //   // permissionType: "customer",
  //   // permission: "view",
  // },
  {
    text: "Packages",
    icon: <GroupIcon />,
    link: "/dashboard/packages",
    // permissionType: "customer",
    // permission: "view",
  },
  {
    text: "Bank Account Details",
    icon: <GroupIcon />,
    link: "/dashboard/business-bank-accounts",
    // permissionType: "customer",
    // permission: "view",
  },
];

const FINANCE_ITEMS = [
  {
    text: "Factoring Requests",
    icon: <CreditCardIcon />,
    link: "/dashboard/factoring-requests",
  },
  {
    text: "Request Baskets",
    icon: <CreditCardIcon />,
    link: "/dashboard/request-baskets",
  },
  {
    text: "All Offers",
    icon: <CreditCardIcon />,
    link: "/dashboard/finance-offers",
  },
];

const DASHBOARD_ITEM = [
  {
    text: "DashBoard",
    icon: <Assessment />,
    link: "/dashboard",
  },
];

const BANK_ACCOUNT_ITEM = [
  {
    text: "Bank Account",
    icon: <CreditCardIcon />,
    link: "/dashboard/bank-accounts",
    permissionType: "bankAccount",
    permission: "view",
  },
];

const USER_ACCOUNT_ITEM = [
  {
    text: "Account",
    icon: <AccountBoxIcon />,
    subMenus: [
      {
        text: "Members",
        icon: <GroupIcon />,
        link: "/dashboard/members",
      },
      {
        text: "Contacts",
        icon: <GroupIcon />,
        link: "/dashboard/contacts",
      },
      {
        text: "Documents",
        icon: <AssignmentIcon />,
        link: "/dashboard/my-documents",
      },
      {
        text: "Token Spendings",
        icon: <AssignmentIcon />,
        link: "/dashboard/token-logs",
      },

      {
        text: "Banking Logs",
        icon: <AssignmentIcon />,
        link: "/dashboard/banking-logs",
      },
    ],
  },
];

const MAKE_PAYMENT_ITEMS = [
  {
    text: "Incoming Invoices",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/incoming-invoices",
  },
  {
    text: "Payment Done",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/incoming-receipts",
  },
  {
    text: "Suppliers",
    icon: <GroupIcon />,
    link: "/dashboard/suppliers",
  },
];

const RECEIVE_PAYMENT_ITEMS = [
  {
    text: "Outgoing Invoices",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/outgoing-invoices",
  },
  {
    text: "Payment Received",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/outgoing-receipts",
  },
  {
    text: "Customers",
    icon: <GroupIcon />,
    link: "/dashboard/customers",
  },

  {
    text: "Products",
    icon: <ShoppingCartOutlinedIcon />,
    link: "/dashboard/products",
    permissionType: "product",
    permission: "view",
  },
];

const TRANSFER_MONEY_ITEMS = [
  {
    text: "Transfer Money",
    icon: <ReceiptOutlinedIcon />,
    link: "/dashboard/transfer-money",
  },
];

const BUY_PLAN_ITEM = [
  {
    text: "Buy Plan",
    icon: <StarIcon />,
    link: "/dashboard/buy-plans",
  },
];

const AWAITING_FOR_APPROVAL_ITEMS = [
  {
    text: "Waiting for approval",
    icon: <HourglassEmptyIcon />,
    link: "/dashboard?state=awaiting_approval",
    id: "awaiting",
  },
];

const sidebarItems = (user, business) => {
  let dashboardType = localStorage.getItem("dashboardType");
  let items = [];
  // items.push(...DASHBOARD_ITEM);
  if (business.account_type === "Admin") {
    items = user.sidebarItems;
  }

  if (business.accountType === "Finance" || user.accountType === "Finance") {
    items = user.sidebarItems;
  }

  if (business.account_type === "Business" || business.account_type === "Personal") {
    if (dashboardType === "payedaBank") {
      items = user.sidebarItems[0].bankDashboard;
    } else if (dashboardType === "payedaInvoice") {
      items = user.sidebarItems[0].invoiceDasboard;
    }
  }

  if (business.account_type === "Business" && dashboardType === "payedaFinancing") {
    items.push(...items, ...(user.sidebarItems[0]?.financingDashboard || []));
  }

  items.map((item) => {
    if (item.icon === "group") item.icon = <GroupIcon />;
    if (item.icon === "receipt") item.icon = <ReceiptOutlinedIcon />;
    if (item.icon === "star") item.icon = <StarIcon />;
    if (item.icon === "shop") item.icon = <ShoppingCartOutlinedIcon />;
    if (item.icon === "assignment") item.icon = <AssignmentIcon />;
    if (item.icon === "credit") item.icon = <CreditCardIcon />;
    if (item.icon === "dashboard") item.icon = <Assessment />;
    if (item.icon === "cloud") item.icon = <CloudIcon />;
    if (item.icon === "account") item.icon = <AccountBoxIcon />;
  });

  // items.push(...USER_ACCOUNT_ITEM);
  // items.push(...BUY_PLAN_ITEM);

  // if (business.awaitingTransferMoneyApproval || business.awaitingReceivePaymentApproval) {
  //   items.push(...AWAITING_FOR_APPROVAL_ITEMS);
  // }

  // console.log("items", items);

  return items;
};

export default sidebarItems;
