const initialState = {
  isLoading: false,
  invoices: [],
  errorMessage: null,
  tenants: [],
  cloudInvoices: [],
  outgoingInvoiceLoading: false,
  paidOutgoingInvoices: [],
  pendingOutgoingInvoices: [],
  approvedOutgoingInvoices: [],
  factoringOutgoingInvoices: [],
  factoringAcceptedInvoices: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FINANCING_INVOICES":
      return {
        ...state,
        isLoading: true,
        financingInvoices: action.payload,
      };
    case "INVOICE_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case "OUTGOING_INVOICE_DATA_FETCH_STARTED":
      return {
        ...state,
        outgoingInvoiceLoading: true,
        errorMessage: null,
      };
    case "INVOICE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case "INCOMING_INVOICE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        incomingInvoices: action.payload,
      };
    case "INCOMING_INVOICE_ERP_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        incomingInvoicesFromErp: action.payload,
      };
    case "OUTGOING_INVOICE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        outgoingInvoiceLoading: false,
        outgoingInvoices: action.payload,
      };
    case "OUTGOING_INVOICE_PAID_DATA_FETCH_SUCCESS":
      return {
        ...state,
        outgoingInvoiceLoading: false,
        paidOutgoingInvoices: action.payload,
      };
    case "OUTGOING_INVOICE_PENDING_DATA_FETCH_SUCCESS":
      return {
        ...state,
        outgoingInvoiceLoading: false,
        pendingOutgoingInvoices: action.payload,
      };
    case "OUTGOING_INVOICE_APPROVED_DATA_FETCH_SUCCESS":
      return {
        ...state,
        outgoingInvoiceLoading: false,
        approvedOutgoingInvoices: action.payload,
      };
    case "OUTGOING_INVOICE_FACTORING_DATA_FETCH_SUCCESS":
      return {
        ...state,
        outgoingInvoiceLoading: false,
        factoringOutgoingInvoices: action.payload,
      };
    case "OUTGOING_INVOICE_FACTORING_ACCEPTED_DATA_FETCH_SUCCESS":
      return {
        ...state,
        outgoingInvoiceLoading: false,
        factoringAcceptedInvoices: action.payload,
      };
    case "INCOMING_INVOICE_PAID_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        paidIncomingInvoices: action.payload,
      };
    case "INCOMING_INVOICE_PENDING_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        pendingIncomingInvoices: action.payload,
      };

    case "PAST_DUE_OUTGOING_INVOICE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        pastDueOutgoingInvoices: action.payload,
      };
    case "OUTGOING_INVOICE_SINGLE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        outgoingInvoice: action.payload,
      };
    case "SHOULD_PAGINATE_REDIS":
      return {
        ...state,
        isLoading: false,
        paginateRedis: action.payload,
      };
    case "INVOICE_SINGLE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        singleInvoice: action.payload,
      };
    case "INCOMING_RECEIPTS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        incomingReceipts: action.payload,
      };
    case "OUTGOING_RECEIPTS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        outgoingReceipts: action.payload,
      };
    case "CLOUD_TENANTS_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_CLOUD_TENANTS":
      return {
        ...state,
        isLoading: false,
        tenants: action.payload,
      };
    case "SET_CLOUD_INVOICES":
      return {
        ...state,
        isLoading: false,
        cloudInvoices: action.payload,
      };
    case "SET_SINGLE_CLOUD_INVOICE":
      return {
        ...state,
        isLoading: false,
        cloudInvoice: action.payload,
      };

    case "FETCH_SINGLE_CLOUD_INVOICE":
      return {
        ...state,
        isLoading: true,
      };
    case "ACCESS_TOKEN_FETCH_STARTED":
      return {
        ...state,
        accessTokenLoading: true,
      };
    case "ACCESS_TOKEN_FETCH_SUCCESS":
      return {
        ...state,
        accessTokenLoading: false,
        connectedToErp: true,
      };
    case "FETCH_CLOUD_BANK_ACCOUNTS":
      return {
        isLoading: true,
      };

    case "SET_CLOUD_BANK_ACCOUNTS":
      return {
        isLoading: false,
        cloudBankAccounts: action.payload,
      };
    case "FINANCING_INVOICE_DATA_FETCH_SUCCESS":
      return {
        isLoading: false,
        financingInvoices: action.payload,
      };
    case "FINANCING_INVOICE_OFFERS":
      return {
        isLoading: false,
        financingOffers: action.payload,
      };
    case "FINANCING_EXCELL_FILE":
      return {
        isLoading: false,
        financingExcell: action.payload,
      };
    case "IMPORT_SUCESS":
      return {
        isLoading: false,
        importSuccess: "success",
      };

    case "REQUESTED_INVOICE":
      return {
        isLoading: false,
        requestedInvoice: action.payload,
      };

    case "RECEIPT_DATA_FETCH_STARTED":
      return {
        isLoading: true,
      };
    case "RECEIPT_DATA_FETCH_SUCCESS":
      return {
        isLoading: false,
        receipts: action.payload,
      };
    default:
      return state;
  }
}
