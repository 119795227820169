const checkIfHasActiveMembership = (business) => {
  if (!business?.payeda_package?.business_membership) {
    return false;
  }

  let packageExpireDate = business.payeda_package.business_membership.expireDate;;

  let todaysDateAsSeconds = Date.now();
  return !(todaysDateAsSeconds >= packageExpireDate);


  // let packageExpireDate = membership.expireDate;

  // let date = new Date();

  // let todaysDateAsSeconds = date.getTime() / 1000;

  // console.log("expire",todaysDateAsSeconds >= packageExpireDate)
  // return !(todaysDateAsSeconds >= packageExpireDate);
};

module.exports = checkIfHasActiveMembership;
