import { useState } from "react";
import { Snackbar, Button } from "@material-ui/core";

const CopyToClipboardButton = ({ text }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  };

  return (
    <>
      <input type="text" value={text} id="myInput" style={{ display: "none" }} />

      {/* <TextField value={text} id="myInput" /> */}
      <button onClick={handleClick}>Copy Link</button>

      <Snackbar message="Copied to clibboard" anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={2000} onClose={() => setOpen(false)} open={open} />
    </>
  );
};

export default CopyToClipboardButton;
