import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from './../../../../util/constants/general';

export const useBusinessProfileStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  content: {
    flexGrow: 0,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    paddingLeft: drawerWidth + 40,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  companyLogo: {
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  userProfileNameSurname: {
    textAlign: "center",
    color: "#5a7391",
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "7px",
  },
  userProfileRole: {
    textAlign: "center",
  },
  userProfileContact: {
    color: "#5b9bd1",
    backgroundColor: "#f6f9fb",
    borderLeft: "2px solid #5b9bd1",
    marginLeft: "-2px"
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },

}));
