import React, { useEffect, useState } from "react";
import { useBasePageStyles } from "./ComponentStyles";
import BuyPackageDialog from "./Dialogs/BuyPackageDialog";

const BasePage = (props) => {
  const classes = useBasePageStyles();
  const { title, navigation, business } = props;

  const [buyPackageDialogOpen, setBuyPackageDialogOpen] = useState(false);

  useEffect(() => {
    if (business) {
      if (business.account_type === "Business" && business.receivePaymentVerified) {
        if (!business.payeda_package) {
          setBuyPackageDialogOpen(true);
        }
      }
    }
  }, [business]);

  return (
    <div>
      {!business ? (
        "Loading"
      ) : (
        <>
          {!props.canView ? (
            "You have no permission to view this page"
          ) : (
            <>
              <span className={classes.navigation}>{navigation}</span>

              <h3 className={classes.title}>{title}</h3>

              {props.children}
              <div className={classes.clear} />

              <BuyPackageDialog open={buyPackageDialogOpen} setOpen={setBuyPackageDialogOpen} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BasePage;
