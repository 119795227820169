import React, { useState } from 'react'
import clsx from "clsx";
import {Button } from '@material-ui/core';

import ImportCustomerComponent from './ImportCustomerComponent';
import { useCustomerStyles } from './CustomerStyles';
import { useUiContext } from '../../Contexts/UiContext';
import SimpleTable from '../../Components/Table/SimpleTable'

const headCells=[
    { id: "taxIdNo", label: "Tax Id No" },
    { id: "erp_no", label: "ERP NO" },
    { id: "name", label: "Company Name" },
    { id: "repName", label: "Representative Name" },
    { id: "repSurname", label: "Surname" },
    { id: "email", label: "Email" },
    { id: "phone", label: "phone" },
    { id: "country", label: "Country" },
    { id: "city", label: "City" },
]

const ImportCustomers = ({ addCustomers, history, business }) => {
    const [customers, setCustomers] = useState([]);

    const classes = useCustomerStyles();
    const { sidebarOpen, loading, errors } = useUiContext();

    const handleSubmitCustomers = () => {
        let data = []
        customers.forEach(customer => {
            customer.country = business.country;
            customer.city = "";
            data.push(customer);
        })
        addCustomers({ data }, history)
    }

    const renderImportedCustomers = () => {

        const getCreatedCustomersData=()=>{
            let createdCustomers=[];

            for (let x = 0; x < customers.length; x++) {      
    
                const current = customers[x]; 
                createdCustomers.push({
                    taxIdNo: current.taxIdNo,
                    erp_no: current.erp_no,
                    name: current.name,
                    repName: current.repName,
                    repSurname: current.repSurname,
                    email: current.email,
                    phone: current.phone,
                    country: current.country,
                    city: current.city,
                })
    
            }           
           return createdCustomers;

        }

        return (
            <div>
                <SimpleTable
                    data={getCreatedCustomersData()}
                    headCells={headCells}
                />
            </div>
        ) 
    }
        

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: sidebarOpen,
            })}
        >
            <ImportCustomerComponent
                customers={customers}
                setCustomers={setCustomers}
                title="Customers"
                loading={loading}
                errors={errors}
            />
            { customers.length>0 && ( renderImportedCustomers() )}
    
            <Button onClick={handleSubmitCustomers} color="primary" variant="contained">
                Submit
            </Button>
        </main>
    )
}

export default ImportCustomers
