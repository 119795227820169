import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const Cards = ({ title, number, avatar, footer }) => (
  <Card style={{ width: "600px", borderRadius: "15px", boxShadow: "15px 15px 55px rgba(141, 138, 156, 0.5)" }}>
    <CardContent>
      <Grid container spacing={6} sx={{ justifyContent: "space-between" }} xs={12} lg={12}>
        <Grid item>
          <Typography color="textPrimary" gutterBottom variant="h6">
            {title}
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {number}
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: "0", marginLeft: "auto" }}>
          {avatar}
        </Grid>
      </Grid>

      <Typography color="textSecondary" variant="caption">
        {footer}
      </Typography>
    </CardContent>
  </Card>
);

export default Cards;
