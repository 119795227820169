import React, { createContext, useContext } from "react";
import { connect } from "react-redux";
import {
  clearErrors,
  clearSuccess,
  hideSidebar,
  setErrors,
  setSuccess,
  showSidebar,
  startLoading,
  stopLoading,
} from "../store/actions/uiActions";

const UiContext = createContext();

const UiProvider = (props) => {
  const {
    errors,
    error,
    loading,
    sidebarOpen,
    setErrors,
    clearErrors,
    setSuccess,
    clearSuccess,
    showSidebar,
    hideSidebar,
    startLoading,
    stopLoading,
    children,
  } = props;
  const providerValue = {
    errors,
    error,
    loading,
    sidebarOpen,
    setErrors,
    clearErrors,
    setSuccess,
    clearSuccess,
    showSidebar,
    hideSidebar,
    startLoading,
    stopLoading,
  };

  return (
    <UiContext.Provider value={providerValue}>{children}</UiContext.Provider>
  );
};

export function useUiContext() {
  return useContext(UiContext);
}

const mapStateToProps = (state) => {
  return {
    errors: state.ui.errors,
    error: state.ui.error,
    loading: state.ui.loading,
    sidebarOpen: state.ui.sidebarOpen,
  };
};

const mapDispatchToProps = {
  setErrors,
  clearErrors,
  setSuccess,
  clearSuccess,
  showSidebar,
  hideSidebar,
  startLoading,
  stopLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(UiProvider);
