import React, {useState} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import DialogComponent from '../../Components/Dialogs/DialogComponent';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import LoadingIcon from "../../Components/LoadingIcon";
import { useUiContext } from './../../Contexts/UiContext';

const RedirectCheckoutPISPConsent =(props)=>{
    let {submitPispConsent, paymentResult, authenticated} = props;

    let location = useLocation();
    let history = useHistory();
    
    const [successDialog, setSuccessDialogOpen] = useState(false);
    const { errors } = useUiContext();


    React.useEffect(() => {
        if (Object.keys(errors).length > 0 || paymentResult) {
            console.log("errorrs",errors)
            return;
        }
        // location.search
        let consent = new URLSearchParams(location.search).get('consent')
        let error = new URLSearchParams(location.search).get('error')
        let bankingId = new URLSearchParams(location.search).get('user-uuid')

        if (consent && bankingId) {
            submitPispConsent(consent, bankingId);
        }
        else {
            console.log("Error occurred", error)
        }
        // eslint-disable-next-line
    }, [location])

    
    React.useEffect(() => {
        if (paymentResult) {
            setSuccessDialogOpen(true)
        }
    }, [paymentResult]);

    const handleClose = () =>{
        setSuccessDialogOpen(false);
        if(authenticated){
            history.push("/");
        }else{
            history.push("/signup");
        }
    }
    
    return (
        <DialogComponent
            open={successDialog}
            setOpen={setSuccessDialogOpen}
            withActions={false}
        >
            {!paymentResult ? <LoadingIcon /> : (
                <div style={{textAlign: "center"}}>
                    <DoneIcon color="primary" style={{fontSize: 100}}/>
                    <h2>Transaction has been completed succesfully</h2>
                    <h3>If something goes wrong please contact us at <span style={{color:"blue"}}>support@payeda.com</span></h3>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>Okay</Button> 
                </div>
            )}   

            
        </DialogComponent>
    )
}


export default RedirectCheckoutPISPConsent;