import { Box, Button, FormControlLabel, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useUiContext } from "../Contexts/UiContext";
import { useUploadComponentStyle } from "./ComponentStyles";

const UploadComponent = ({ name, label, register, accept, error }) => {
  const classes = useUploadComponentStyle();
  const [fileName, setFileName] = useState("");
  // const [error, setError] = useState();
  const { setErrors, errors } = useUiContext();

  const handleFileSelect = (e) => {
    // 2MB
    const maxAllowedSize = 10 * 1024 * 1024;
    if (e.target.files[0] && e.target.files[0].name) {
      if (e.target.files[0].size <= maxAllowedSize) {
        setFileName(e.target.files[0].name);
        setErrors({});
      } else {
        setFileName("");
        let errorName = e.target.name;
        setErrors({ [errorName]: "File too big" });
        return false;
      }
    } else {
      setFileName("");
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Box border={1} borderRadius={16} className={classes.box}>
            <Typography variant="body1" color={error ? "error" : "textPrimary"}>
              {fileName}
            </Typography>
            <Button variant="contained" component="label">
              Upload
              <input
                name={name}
                type="file"
                onChange={(e) => handleFileSelect(e)}
                ref={register}
                style={{ display: "none" }}
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              />
            </Button>
          </Box>
        }
        style={{ width: "fit-content" }}
        label={
          <Typography color={error ? "error" : "textPrimary"}>
            {errors[name] === name ? errors[name] : label}
          </Typography>
        }
      />
    </>
  );
};

export default UploadComponent;
