import { Grid, TextField, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import NumberFormatCustom from "../../Components/NumberFormatCustom";
import UploadComponent from "../../Components/UploadComponent";
import { getSelfBankAccounts } from "../../store/actions/bankingActions";
import { updateFinancialInformations } from "../../store/actions/businessActions";
import BankStatementUploadPreferenceDialog from "./BankStatementUploadPreferenceDialog";
import { useUiContext } from "./../../Contexts/UiContext";
import { financingInformationSchema } from "../../util/yup/yupSchemas";
import verifySchema from "./../../util/yup/verifySchema";

const BusinessFinancialInformationsDialog = ({
  open,
  setOpen,
  dispatch,
  business,
}) => {
  const { register, handleSubmit } = useForm();
  const { errors, setErrors, clearErrors } = useUiContext();

  const [
    openUploadPreferenceSelection,
    setOpenUploadPreferenceSelection,
  ] = useState(false);

  const [bankStatementPDF, setBankStatementPDF] = useState(null);
  const [fromConnectedBankAccounts, setFromConnectedBankAccounts] = useState(
    false
  );
  useEffect(() => {
    dispatch(getSelfBankAccounts());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBankStatementPDF(null);
    setFromConnectedBankAccounts(false);
  }, [open]);
  const handleAddFinancialInfo = async (data) => {
    let dataToSend = { ...data };

    dataToSend.bank_statement = bankStatementPDF;

    if (!dataToSend.bank_statement) {
      setErrors({ general: "Please upload a bank statement" });
      return false;
    }

    const valid = await verifySchema(
      financingInformationSchema,
      dataToSend,
      clearErrors,
      setErrors
    );

    if (!valid) return false;

    let fd = new FormData();

    for (const [key, value] of Object.entries(dataToSend)) {
      if (!fromConnectedBankAccounts) {
        if (key === "bank_statement" && value.item(0) !== null) {
          dataToSend[key] = value.item(0);
        }
      } else {
        if (key === "bank_statement" && value !== null) {
          dataToSend[key] = value;
        }
      }
    }

    for (var key in dataToSend) {
      fd.append(key, dataToSend[key]);
    }

    dispatch(updateFinancialInformations(fd));
    setOpen(false);
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title="Financial Informations"
      onSubmit={handleSubmit((data) => handleAddFinancialInfo(data))}
      description=""
      withActions={true}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            required
            label="Turnover Amount"
            variant="outlined"
            name="turnover_amount"
            id="turnover_amount"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            required
            label="Currency"
            variant="outlined"
            name="currency"
            id="currency"
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            required
            label="Year"
            variant="outlined"
            name="year"
            id="year"
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            required
            label="Total number of employee"
            variant="outlined"
            name="number_of_employee"
            id="number_of_employee"
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <UploadComponent
            id="bank_statement"
            name="bank_statement"
            label="Bank statement"
            register={register}
            fullWidth
          /> */}

          <Button
            onClick={() => setOpenUploadPreferenceSelection(true)}
            color="primary"
          >
            {bankStatementPDF === null
              ? "Upload Bank Statement"
              : "Change Bank Statement"}
          </Button>

          {fromConnectedBankAccounts && "BankStatement.pdf"}

          {!fromConnectedBankAccounts &&
            bankStatementPDF &&
            bankStatementPDF[0] &&
            bankStatementPDF[0].name}
          <BankStatementUploadPreferenceDialog
            open={openUploadPreferenceSelection}
            setOpen={setOpenUploadPreferenceSelection}
            business={business}
            bankStatementPDF={bankStatementPDF}
            setBankStatementPDF={setBankStatementPDF}
            setFromConnectedBankAccounts={setFromConnectedBankAccounts}
            setErrors={setErrors}
          />
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default BusinessFinancialInformationsDialog;
