import React from "react";
import { usePermissionsContext } from "../../Contexts/PermissionsContext";

import TableComponent from "../../Components/Table/TableComponent";
import ContactsTableToolbar from "./ContactsTableToolbar";

const headCells = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "group_name", label: "Group" },

  { id: "message_sent_at", label: "Message Sent" },
  { id: "is_member", label: "Payeda Member" },

  // { id: "is_joined", label: "Joined"}
];

const searchOptions = [
  { default: true, id: "company_name_lower", label: "Company Name" },
  { id: "full_name_lower", label: "Representative Name" },
  { id: "erp_no_lower", label: "Erp Number" },
];

const ContactsTable = ({
  contacts,
  setErrors,
  getAllData,
  shouldGetData,
  getSearchData,
  selected,
  setSelected,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  isLoading,
  isLastPage,
  business,
  dispatch,
  userHasMembership,
  renderCustomerGroups,
  setAssignToGroupDialogOpen,
}) => {
  const { permissions } = usePermissionsContext();

  const renderToolbar = () => {
    return (
      <ContactsTableToolbar
        numSelected={selected ? selected.length : 0}
        selected={selected}
        business={business}
        setCreateDialogOpen={setCreateDialogOpen}
        setEditDialogOpen={setEditDialogOpen}
        setInviteDialogOpen={setInviteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setErrors={setErrors}
        dispatch={dispatch}
        userHasMembership={userHasMembership}
        renderCustomerGroups={renderCustomerGroups}
        setAssignToGroupDialogOpen={setAssignToGroupDialogOpen}
      />
    );
  };

  return (
    <TableComponent
      toolbar={renderToolbar()}
      data={contacts}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      searchOptions={searchOptions}
      isLoading={isLoading}
      isLastPage={isLastPage}
      getAllData={getAllData}
      searchBarText="Search customers"
      shouldGetData={shouldGetData}
      shouldPaginate={false}
      shouldSearch={false}
      shouldView={true}
    />
  );
};
export default ContactsTable;
