import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import shortid from "shortid";
import BasePage from "../../Components/BasePage";
import ConfirmDeleteDialog from "../../Components/Dialogs/ConfirmDeleteDialog";
import DropdownComponent from "../../Components/DropdownComponent";

import { invitePeople, mailToContact } from "../../store/actions/businessActions";
import { addCustomer, editCustomer, getCustomerGroups, getCustomers, removeCustomers } from "../../store/actions/customerActions";
import { customerSchema } from "../../util/yup/yupSchemas";
import { useUiContext } from "./../../Contexts/UiContext";
import verifySchema from "./../../util/yup/verifySchema";
import CustomerDialog from "./CustomerDialog";
import CustomersTable from "./CustomersTable";
import InviteDialog from "./InviteDialog";
import checkIfHasActiveMembership from "../../util/permissions/checkIfHasActiveMembership";
import { createNotification } from "../../store/actions/notificationActions";

const Customers = ({ business, businessId }) => {
  const dispatch = useDispatch();
  const { register, control, handleSubmit } = useForm();

  const customers = useSelector((state) => state.customer);
  const isLastPage = useSelector((state) => state.business.lastPage);
  const user = useSelector((state) => state.user);

  let customerGroups = useSelector((state) => state.customer.customerGroups);

  const { errors, setErrors, clearErrors } = useUiContext();

  const [selected, setSelected] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [msgToCustomer, setMsgToCustomer] = useState("Join to Payeda and see your invoices I made for you ");
  const [afterRender, setAfterRender] = useState(false);
  const [customersWithoutEmail, setCustomersWithoutEmail] = useState(null);
  const [selectedCustomerGroupId, setSelectedCustomerGroupId] = useState(null);
  const [userHasMembership, setUserHasMembership] = useState(null);
  const [isMessage, setIsMessage] = useState(false);

  useEffect(() => {
    async function checkMembership() {
      let hasMembership = await checkIfHasActiveMembership(business);
      setUserHasMembership(hasMembership);
    }
    checkMembership();
    // eslint-disable-next-line
  }, [business]);

  useEffect(() => {
    if (selectedCustomerGroupId === "all_customers") {
      dispatch(getCustomers({}));
    } else {
      dispatch(getCustomerGroups(selectedCustomerGroupId));
    }
  }, [selectedCustomerGroupId]);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getCustomers({}));
    }
    // eslint-disable-next-line
  }, [afterRender]);

  // TODO: select customer group olarak değiştirildi
  useEffect(() => {
    if (customerGroups && customerGroups.length > 0 && customerGroups[customerGroups.length - 1].value !== "all_customers") {
      customerGroups.push({ value: "all_customers", label: "All Customers" });
    }
  }, [customerGroups]);

  const onCreateNew = async (data) => {
    !data.country ?? (data.country = business.country);
    const valid = await verifySchema(customerSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateDialogOpen(false);
      dispatch(addCustomer(data));
    }
  };

  const onEdit = async (data) => {
    const valid = await verifySchema(customerSchema, data, clearErrors, setErrors);
    if (valid) {
      const dataToSend = { id: selected[0].id, ...data };
      setEditDialogOpen(false);
      dispatch(editCustomer(dataToSend));
      setSelected([]);
    }
  };

  const onDelete = async () => {
    let customersToDelete = selected.map((customer) => customer.id);
    let details = { customers: customersToDelete };
    dispatch(removeCustomers(details));
    setSelected([]);
    setDeleteDialogOpen(false);
  };

  const onInvite = async () => {
    if (isMessage) {
      if (!msgToCustomer) {
        setErrors({ general: "Please enter your message" });
        return false;
      }
      let dataToSend = {};
      let invitees = [];
      for (let sel of selected) {
        invitees.push({ id: sel.id, email: sel.email });
      }
      dataToSend.invitees = invitees;
      dataToSend.message = msgToCustomer;
      dispatch(mailToContact(dataToSend));
      setInviteDialogOpen(false);
      setSelected([]);
      return true;
    }
    if (selected.length === 1 && selected[0].whatsappChecked) {
      //TODO: check if user has already invited
      var inviteeInfo = selected[0];
      var inviteePhone = inviteeInfo.phone;

      var inviteCode;
      if (selected[0].invite_information.invited) {
        inviteCode = selected[0].invite_information.invitation_code;
      } else {
        inviteCode = shortid.generate();
      }
      window.open("https://api.whatsapp.com/send?phone=" + inviteePhone + "&text=" + msgToCustomer + " https://payeda-dev-8fa74.web.app/invite?code=" + inviteCode);

      selected[0].invite_information.invitation_code = inviteCode;
      dispatch(invitePeople(selected, false));
    } else {
      // eger toplu gönderim olacaksa (email) kod backend'de üretilecek ve mail atılacak
      // mail address'i olmayanlar array'den çıkarılacak

      selected[0].businessName = business.company_details.name;
      selected[0].msgToCustomer = msgToCustomer;
      selected[0].emailChecked = true;
      let customersWithEmail = selected.filter((s) => s.email);
      let customersWithoutEmail = selected.filter((s) => !s.email);

      dispatch(invitePeople(customersWithEmail, false));
      // handle adding notification for user when people could not send because they do not have mail address

      let notificationDetail = [];

      if (customersWithoutEmail.length > 0) {
        customersWithoutEmail.map((person) => {
          return notificationDetail.push(`${person.name} doesn't have email address`);
        });

        let notificationData = {
          message: `${customersWithoutEmail.length} customers doesn't have email address, select to see the details`,
          recipient: user.business,
          title: "Invitations could not send to some customers",
          read: false,
          logId: "",
          detail: notificationDetail,
        };

        // TODO: dispatch nofitication data
        dispatch(createNotification(notificationData));
      }
    }
    setInviteDialogOpen(false);
    setSelected([]);
  };

  // const renderCustomerGroups = () => {
  //   return (
  //     <DropdownComponent
  //       required
  //       fullWidth
  //       id="selectedCustomerGroupId"
  //       name="selectedCustomerGroupId"
  //       label="Select a contact group"
  //       items={customerGroups || []}
  //       onChange={(e) => {
  //         setSelectedCustomerGroupId(e.target.value);
  //       }}
  //       error={errors.selectedCustomerGroupId ? true : false}
  //       helperText={errors.selectedCustomerGroupId ?? ""}
  //       control={control}
  //     />
  //   );
  // };

  const handleGroupOptionChange = (e) => {
    setSelectedCustomerGroupId(e.target.value);
  };
  const renderCustomerGroupSelection = () => {
    return (
      <>
        <FormControl variant="standard" sx={{ m: 5, minWidth: 500 }} fullWidth>
          <InputLabel id="demo-simple-select-standard-label" style={{ marginLeft: "10px" }}>
            Select a contact group{" "}
          </InputLabel>

          <Select
            value={selectedCustomerGroupId}
            onChange={handleGroupOptionChange}
            error={errors.selectedCustomerGroupId ? true : false}
            helperText={errors.selectedCustomerGroupId ?? ""}
            variant="outlined"
          >
            {customerGroups &&
              customerGroups?.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </>
    );
  };
  return (
    <BasePage navigation="Dashboard/Customers" business={business} canView={user?.permissions?.customer?.view}>
      <Grid container style={{ marginBottom: "5px" }}>
        <Grid item xs={8}>
          {business?.company_details?.banking[0] && !business?.receive_payment_details && (
            <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
              Receive Payment Account Details (Not verified yet):
              <br />
              <strong>Account number:</strong> {business && business.company_details.banking[0].account_number} <strong>Sort code:</strong>{" "}
              {business && business.company_details.banking[0].sort_code}
            </Alert>
          )}
          {business?.receive_payment_details && business?.receive_payment_details?.receive_payment_account_id && (
            <Alert severity="success" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
              <AlertTitle>Success</AlertTitle>
              Account ownership verified to receive payment for invoices.
              <br />
              <strong>Provider: </strong>
              {business?.receive_payment_details.trueLayerDetails?.provider.display_name}
              <strong> Account Name: </strong>
              {business?.receive_payment_details.trueLayerDetails?.display_name}
              <strong> Account Number:</strong> {business?.receive_payment_details.trueLayerDetails?.account_number.number}
              <strong> Sort Code :</strong> {business?.receive_payment_details.trueLayerDetails?.account_number.sort_code}
            </Alert>
          )}
        </Grid>
      </Grid>

      <div>
        <CustomersTable
          customers={customers.customers}
          setCreateDialogOpen={setCreateDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          setInviteDialogOpen={setInviteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          selected={selected}
          setSelected={setSelected}
          setErrors={setErrors}
          isLoading={customers.isLoading}
          getAllData={getCustomers}
          islastPage={isLastPage}
          business={business}
          dispatch={dispatch}
          userHasMembership={userHasMembership}
          renderCustomerGroups={renderCustomerGroupSelection()}
          setIsMessage={setIsMessage}
        />
        {business.company_details && (
          <>
            <CustomerDialog
              open={createDialogOpen}
              setOpen={setCreateDialogOpen}
              title="Create new customer"
              description="Create new customer to start receiving payments."
              onSubmit={onCreateNew}
              errors={errors}
              business={business}
              isEdit={false}
            />

            <CustomerDialog
              open={editDialogOpen}
              setOpen={setEditDialogOpen}
              title="Edit customer"
              description="Edit details of the customer."
              onSubmit={onEdit}
              errors={errors}
              defaultValues={selected[0]}
              business={business}
              isEdit={true}
            />

            <InviteDialog
              title={!isMessage ? "Invite the customer" : "Type your message"}
              description={!isMessage ? "Invite the customer to receive payment" : ""}
              selected={selected}
              setSelected={setSelected}
              onSubmit={onInvite}
              open={inviteDialogOpen}
              setOpen={setInviteDialogOpen}
              msgToCustomer={msgToCustomer}
              setMsgToCustomer={setMsgToCustomer}
            />

            <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} />
          </>
        )}
      </div>
    </BasePage>
  );
};

export default Customers;
