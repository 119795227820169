import React from "react";
import { useDispatch } from "react-redux";

import { Typography, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

import DialogComponent from "../../Components/Dialogs/DialogComponent";
import {
  sendPasswordResetLink,
  sendVerifyEmailLink,
} from "../../store/actions/userActions";

const PasswordResetVerifyEmailDialog = ({
  open,
  setOpen,
  errors,
  isPasswordReset,
}) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const handleSubmitEmail = (data) => {
    if (isPasswordReset) {
      dispatch(sendPasswordResetLink(data, setOpen));
    } else {
      dispatch(sendVerifyEmailLink(data, setOpen));
    }
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      onSubmit={handleSubmit((data) => handleSubmitEmail(data))}
      withActions={true}
    >
      <div style={{ width: "400px" }}>
        <Typography>
          {!isPasswordReset &&
            "If you have not received an email yet, please check your junk folder or resend the email now."}
          {isPasswordReset && "Forgot Password"}
        </Typography>

        <TextField
          required
          id="email"
          name="email"
          label="Email"
          fullWidth
          error={errors.email ? true : false}
          helperText={errors.email ?? false}
          inputRef={register}
        />
        <br />
        <br />
      </div>
    </DialogComponent>
  );
};

export default PasswordResetVerifyEmailDialog;
