import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useForm } from 'react-hook-form';

import DialogComponent from '../../Components/Dialogs/DialogComponent';
import { useNotificationSettings } from './SettingStyles';

const NotificationSettings = ({ open, setOpen, updatePreferences, external}) => {

    const classes = useNotificationSettings();
    const { register, handleSubmit } = useForm();
    const [data, setData] = React.useState(external);

    React.useEffect(() => {
        setData(external);
    }, [external])

    const [state, setState] = React.useState({
        emailOnImport: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const onSubmit = async (data) => {
        const dataToSend = {notificationSettings: { ...data}}
        await updatePreferences(dataToSend)
        setOpen(false);
    }

    return (
        <DialogComponent
            open={open}
            setOpen={setOpen}
            withActions={false}
        >
        <form >
            <List className={classes.root}>
                <Typography>When imported document</Typography>
                <ListItem dense button>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={state.checkedA}
                            onChange={handleChange} name="emailOnImport"
                            tabIndex={-1}
                            disableRipple
                            inputRef={register}
                            defaultChecked={data.accountSettings?.notificationSettings?.emailOnImport}
                        />
                    </ListItemIcon>
                    <ListItemText primary={"Email"} />
                </ListItem>
                <Divider />
            </List>
                <Button 
                    variant="contained" 
                    color="primary" fullWidth 
                    onClick={handleSubmit((data) => onSubmit(data))}>
                Update
                </Button>
            </form>

        </DialogComponent>

    )
}

export default NotificationSettings