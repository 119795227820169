import React, { useState } from "react";

import { Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, TextField, Typography, Link, Avatar } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Zoom } from "react-awesome-reveal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useUiContext } from "../../Contexts/UiContext";
import { signupUser } from "../../store/actions/userActions";
import verifySchema from "./../../util/yup/verifySchema";
import { signUpSchema } from "./../../util/yup/yupSchemas";
import { useSignupFormStyles } from "./LoginStyles";

//TODO use translations
const SignupForm = ({ history }) => {
  const classes = useSignupFormStyles();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { errors, setErrors, clearErrors } = useUiContext();

  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [value, setValue] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ValidationType = ({ type }) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <Typography className={classes.errorMessage} style={{ color: "red" }}>
          {ErrorMessage}
        </Typography>
      </Zoom>
    );
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = async (data) => {
    data.termsAndConditionAccepted = conditionsAccepted;
    if (value === "transferMoney") {
      data.accountType = "Personal";
    } else if (value === "invoicing") {
      data.accountType = "Business";
    }

    data.usageType = value;

    const valid = await verifySchema(signUpSchema, data, clearErrors, setErrors);

    if (valid) {
      dispatch(signupUser(data, history));
    }
  };

  const renderUsageTypeForClassicSignup = () => {
    return (
      <>
        <div style={{ marginTop: "20PX" }}>
          <FormControl sx={{ m: 3 }} component="fieldset" error={errors.usageType ? true : false} variant="standard">
            <FormLabel component="legend" style={{ fontSize: "20px", fontWeight: "bol" }}>
              Usage type
            </FormLabel>
            <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange} row>
              <FormControlLabel value="invoicing" control={<Radio />} label={<Typography style={{ fontSize: "13px" }}>Invoicing + Transfer Money (Business)</Typography>} />
              <FormControlLabel value="transferMoney" control={<Radio />} label={<Typography style={{ fontSize: "13px" }}>Transfer money (Personal)</Typography>} />
            </RadioGroup>
          </FormControl>
        </div>
      </>
    );
  };

  const renderTermsAndConditions = () => {
    return (
      <div>
        {errors.termsAndConditionAccepted !== undefined && (
          <div>
            <span id="access-code-error" style={{ color: "red" }}>
              {errors.termsAndConditionAccepted}
            </span>
          </div>
        )}
        <FormControlLabel
          control={
            <Checkbox
              id="termsAndConditionAccepted"
              name="termsAndConditionAccepted"
              checked={conditionsAccepted}
              onChange={() => setConditionsAccepted(!conditionsAccepted)}
              color="primary"
            />
          }
          label={
            <div>
              <span style={{ fontSize: "12px" }}>accept the </span>
              <a style={{ fontSize: "15px", fontWeight: "bold" }} href="assets/Payeda_End_User_Terms_of_Service.pdf" target="_blank">
                terms of use
              </a>
              <span style={{ fontSize: "12px" }}> and </span>
              <a style={{ fontSize: "15px", fontWeight: "bold" }} href="assets/Payeda_Privacy Policy.pdf" target="_blank">
                privacy policy
              </a>
            </div>
          }
        />
      </div>
    );
  };

  const renderFormBody = () => {
    return (
      <div style={{ marginTop: "15px" }}>
        <TextField variant="outlined" required id="name" name="name" label="Name" error={errors.name ? true : false} helperText={errors.name ?? ""} inputRef={register} fullWidth />
        <TextField
          variant="outlined"
          required
          id="surname"
          name="surname"
          label="Surname"
          error={errors.surname ? true : false}
          helperText={errors.surname ?? ""}
          inputRef={register}
          fullWidth
          style={{ marginTop: "10px" }}
        />

        <TextField
          variant="outlined"
          required
          id="email"
          name="email"
          label="Email"
          error={errors.email ? true : false}
          helperText={errors.email ?? ""}
          inputRef={register}
          fullWidth
          style={{ marginTop: "10px" }}
        />
        {/* {renderAccountTypesRadio()} */}

        <TextField
          required
          fullWidth
          variant="outlined"
          id="password"
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          error={errors.password ? true : false}
          inputRef={register}
          style={{ marginTop: "10px" }}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ValidationType type="password" />
        <TextField
          required
          fullWidth
          variant="outlined"
          id="confirmPassword"
          name="confirmPassword"
          label="Repeat Password"
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          error={errors.confirmPassword ? true : false}
          inputRef={register}
          helperText={errors.confirmPassword ?? ""}
          style={{ marginTop: "10px" }}
        />
        {renderUsageTypeForClassicSignup()}
        {renderTermsAndConditions()}

        <br />
        <Button variant="contained" color="secondary" fullWidth onClick={() => handleSubmit(onSubmit)()} style={{ backgroundColor: "#0CC474" }}>
          Sign Up
        </Button>
      </div>
    );
  };

  return (
    <FormControl>
      {renderFormBody()}

      <div style={{ marginTop: "15px", textAlign: "center", cursor: "pointer" }}>
        <ul style={{ listStyle: "none" }}>
          <li>
            <Link
              underline="hover"
              onClick={() => {
                history.push("/signin");
              }}
            >
              Have an account? Sign in
            </Link>
          </li>
          <li>
            <Link
              underline="hover"
              onClick={() => {
                history.push("/invite");
              }}
            >
              Have an Invitation Code?
            </Link>
          </li>
        </ul>
      </div>
    </FormControl>
  );
};

export default withRouter(SignupForm);
