import { Divider, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import DropdownComponent from "../../Components/DropdownComponent";
import { getFinanceBaskets } from "../../store/actions/financeActions";
import { getInvoiceById } from "../../store/actions/invoiceActions";
import { getSupplier } from "../../store/actions/supplierActions";
import BusinessDetailsDialog from "./BusinessDetailsDialog";
import InvoiceDetailsDialog from "./InvoiceDetailsDialog";

import InvoicePDF from "../Invoice/InvoicePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

// const initBeforeUnLoad = (showExitPrompt) => {
//   window.onbeforeunload = (event) => {
//     if (showExitPrompt) {
//       const e = event || window.event;
//       e.preventDefault();
//       if (e) {
//         e.returnValue = "";
//       }
//       return "";
//     }
//   };
// };

export default function SimpleCard({ data, dispatch, saveRequest, history }) {
  const classes = useStyles();

  const { control } = useForm();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [openBusinessDetailsDialog, setOpenBusinessDetailsDialog] = useState(false);
  const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
  const [supplierDetails, setSupplierDetails] = useState(null);

  let suppDetails = useSelector((state) => state.supplier.singleSupplier);
  const invoiceDetails = useSelector((state) => state.invoice.singleInvoice);
  const basketDetails = useSelector((state) => state.finance.financeBasket);

  const [selectedInvoice, setSelectedInvoice] = useState({});

  useEffect(() => {
    dispatch(getFinanceBaskets({}));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setSelectedInvoice(invoiceDetails);
    // eslint-disable-next-line
  }, [invoiceDetails]);

  useEffect(() => {
    setSupplierDetails(suppDetails);
  }, [suppDetails]);

  useEffect(() => {
    if (!data || data.length === 0 || !selectedSupplier) {
      setSupplierDetails(null);
    }
  }, [data, selectedSupplier]);

  const addRequestToBasket = (requestId) => {
    let findRequest = data.find((d) => d.id === requestId);

    let dataToSend = {
      supplier: findRequest.originated_account.businessId,
      supplier_name: findRequest.originated_account.company_name,
      request_type: findRequest.request_type,
      requestId: findRequest.id,
      total_amount: findRequest.total_amount,
    };

    dispatch(saveRequest(dataToSend));
  };

  const getSuppliersFromRequests = () => {
    let suppliers = [];

    suppliers = data.map((data) => {
      return {
        value: data.originated_account.businessId,
        label: data.originated_account.company_name,
      };
    });

    var duplicateRemover = new Set();

    var distinctArrObj = suppliers.filter((obj) => {
      if (duplicateRemover.has(JSON.stringify(obj))) return false;
      duplicateRemover.add(JSON.stringify(obj));
      return true;
    });

    return distinctArrObj;
  };

  const getInvoiceDetails = (invId) => {
    setSelectedInvoice({});
    dispatch(getInvoiceById(invId));
    setOpenInvoiceDetails(true);
  };

  const checkIfItemExistsInBasket = (supplier, basketId) => {
    if (basketDetails) {
      let supplierBasket = basketDetails.find((basket) => basket.supplier === supplier);
      if (supplierBasket && supplierBasket.supplier === supplier) {
        if (supplierBasket["request_ids"].includes(basketId)) {
          return true;
        } else return false;
      }
    }
  };

  const retrieveSelectedSupplier = () => {
    if (selectedSupplier) {
      let findRequestedBySelectedSupplier = data && data.filter((data) => data.originated_account.businessId === selectedSupplier);

      return (
        <>
          {findRequestedBySelectedSupplier &&
            findRequestedBySelectedSupplier.length > 0 &&
            findRequestedBySelectedSupplier.map((d) => (
              <Card className={classes.root}>
                <CardContent>
                  <Grid container>
                    <Grid xs={4}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom></Typography>
                      <Typography variant="h5" component="h2">
                        Total Invoice Amount : {d.total_amount}
                        {/* id {d.id} selectedSup {selectedSupplier} */}
                      </Typography>
                    </Grid>

                    <Grid xs={8}>
                      {" "}
                      <Button variant="outlined"> Customer Name: {d.invoice_details && d.invoice_details.customer_name} </Button>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid xs={8}>
                      <Typography className={classes.pos} color="textSecondary">
                        {d.request_type === "factoring" ? "Factoring" : "Discounting"}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      {checkIfItemExistsInBasket(selectedSupplier, d.id) === true && (
                        <>
                          <DoneIcon style={{ color: "green" }} />
                          <span style={{ color: "green" }}> Added to Basket</span>{" "}
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Typography variant="body2" component="p">
                    Turnover amount: {d.turnover_amount}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  {openInvoiceDetails && invoiceDetails?.id === d.invoice_id ? (
                    <>
                      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                        <PDFDownloadLink
                          document={<InvoicePDF invoiceData={invoiceDetails} />}
                          fileName={invoiceDetails?.invoice_number}
                          style={{
                            textDecoration: "none",
                            padding: "16px",
                            color: "#4a4a4a",
                            backgroundColor: "#f2f2f2",
                            border: "1px solid #4a4a4a",
                            borderRadius: "20px",
                            width: "120px",
                            height: "50px",
                          }}
                        >
                          {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download")}
                        </PDFDownloadLink>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button onClick={() => getInvoiceDetails(d.invoice_id)} variant="outlined" color="secondary">
                        Invoice Details PDF
                      </Button>
                    </>
                  )}
                  {/* <Button onClick={() => getInvoiceDetails(d.invoice_id)}>Invoice Details</Button> */}
                  <Button onClick={() => addRequestToBasket(d.id)} variant="outlined" color="primary">
                    Add to Basket
                  </Button>
                </CardActions>
              </Card>
            ))}
        </>
      );
    }
  };

  useEffect(() => {
    if (selectedSupplier) {
      dispatch(getSupplier(selectedSupplier));
    }
    // eslint-disable-next-line
  }, [selectedSupplier]);

  history.listen((location, action) => {
    setSelectedInvoice(null);
    setSelectedSupplier(null);
  });

  return (
    <>
      {data && data.length > 0 && (
        <DropdownComponent
          required
          id="supplier"
          name="supplier"
          label="Choose supplier"
          items={getSuppliersFromRequests()}
          control={control}
          onChange={(e) => {
            setSelectedSupplier(e.target.value);
          }}
        />
      )}
      {supplierDetails && Object.keys(supplierDetails).length > 0 && (
        <>
          <Button onClick={() => setOpenBusinessDetailsDialog(true)} color="secondary">
            Click to see Company Details
          </Button>

          <BusinessDetailsDialog business={supplierDetails} open={openBusinessDetailsDialog} setOpen={setOpenBusinessDetailsDialog} title="Company Details" />
        </>
      )}
      {/* {selectedInvoice && Object.keys(selectedInvoice).length > 0 && (
        <InvoiceDetailsDialog invoice={selectedInvoice} open={openInvoiceDetails} setOpen={setOpenInvoiceDetails} title="Invoice Details" />
      )} */}

      {selectedSupplier && retrieveSelectedSupplier()}
    </>
  );
}
