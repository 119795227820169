import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../../Components/BasePage";
import TableComponent from "../../../../Components/Table/TableComponent";
import { getPayedaTokenLogs } from "../../../../store/actions/bankingActions";
import { Typography, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const TransferMoney = ({ business }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  let transactionsLoading = useSelector((state) => state.banking.loading);
  let tokenLogs = useSelector((state) => state.banking.tokenLogs);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.permissions?.tokenSpending?.view) {
      dispatch(getPayedaTokenLogs());
    }
    // eslint-disable-next-line
  }, []);

  const headCells = [
    { id: "amount_spent", label: "Amount Spent" },
    { id: "token_type", label: "Type" },
    { id: "spent_date", label: "Date" },
  ];

  const renderTransactionsTable = () => {
    return (
      <>
        <TableComponent
          data={tokenLogs}
          selected={selected}
          setSelected={setSelected}
          headCells={headCells}
          isLoading={transactionsLoading}
          shouldGetData={false}
          shouldPaginate={false}
          shouldSearch={false}
          shouldView={true}
        />
      </>
    );
  };

  const renderPackageInfo = () => {
    return (
      <>
        <Grid container>
          <Grid item lg={4}>
            {business && (
              <>
                <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
                  <Typography variant="h6">Payment Token: </Typography>
                  <Typography>
                    Balance:
                    {parseInt(parseFloat(business?.payeda_package?.payment_token?.Balance) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                  <Typography>
                    More Balance:
                    {parseInt(parseFloat(business?.payeda_package?.payment_token?.more_token) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                  <Typography>
                    Total Purchased:
                    {parseInt(parseFloat(business?.payeda_package?.payment_token?.Credit) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                  <Typography>
                    Total Spent:
                    {parseInt(parseFloat(business?.payeda_package?.payment_token?.Debit) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                </Alert>
                <small>
                  <strong>*Payment Token: </strong> Number of unpaid invoices to be paid by customers
                </small>
              </>
            )}
          </Grid>
          <Grid item lg={4}>
            {" "}
            {business && (
              <>
                {" "}
                <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
                  <Typography variant="h6">Transfer Token: </Typography>
                  <Typography>
                    Balance:
                    {parseInt(parseFloat(business?.payeda_package?.transfer_money_token?.Balance) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                  <Typography>
                    More Balance:
                    {parseInt(parseFloat(business?.payeda_package?.transfer_money_token?.more_token) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                  <Typography>
                    Total Purchased :{parseInt(parseFloat(business?.payeda_package?.transfer_money_token?.Credit) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                  <Typography>
                    Total Spent:
                    {parseInt(parseFloat(business?.payeda_package?.transfer_money_token?.Debit) / parseFloat(business?.payeda_package?.commission_rate)) || 0}
                  </Typography>
                </Alert>
                <small>
                  <strong>*Transfer Token: </strong> Number of transfer money can be made
                </small>
              </>
            )}
          </Grid>

          <Grid item lg={4}>
            {business && (
              <>
                {" "}
                <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
                  <Typography variant="h6">Bank Token: </Typography>

                  <Typography>Balance: {business.payeda_package?.bank_token?.Balance || 0}</Typography>
                  <Typography>Total Purchased: {business.payeda_package?.bank_token?.Credit || 0}</Typography>
                  <Typography>Total Spent: {business.payeda_package?.bank_token?.Debit || 0}</Typography>
                </Alert>
                <small>
                  <strong>*Bank Token: </strong> Number of bank accounts that can be connected to Payeda
                </small>
              </>
            )}
          </Grid>
        </Grid>

        <br />
      </>
    );
  };

  return (
    <BasePage navigation="Dashboard/Payeda Token Logs" business={business} canView={user?.permissions?.tokenSpending?.view}>
      {renderPackageInfo()}
      {renderTransactionsTable()}
      {/* {!tokenLogs || (tokenLogs.length === 0 && "No data found")} */}
    </BasePage>
  );
};

export default TransferMoney;
