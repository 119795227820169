import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Typography, TextField, Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Swal from "sweetalert2";
import DropdownComponent from "../../Components/DropdownComponent";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import { updateBidDate } from "../../store/actions/financingInvoicesAction";
import { useUiContext } from "../../Contexts/UiContext";
import axios from "axios";
import { API_BASE } from "./../../store/env";
import { useHistory } from "react-router-dom";

const BidDetailsDialog = ({ open, setOpen, selected, setSelected }) => {
  const { register, control, handleSubmit, reset } = useForm();
  const { setErrors } = useUiContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());

  function convertDate(date, start) {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let hour = String(today.getTime);
    var yyyy = today.getFullYear();
    let timeString = start ? "00:00:01" : "23:59:00";
    return new Date(yyyy + "-" + mm + "-" + dd + " " + timeString).toUTCString();
  }

  const onSubmit = async (data) => {
    let startDate = new Date(data.issued_date).toISOString().substring(0, 10);
    let dueDate = new Date(data.due_date).toISOString().substring(0, 10);
    let todaysDate = new Date().toISOString().substring(0, 10);

    if (startDate < todaysDate) {
      setErrors({ general: "Bid start date cannot be less than today's date" });
      return false;
    }
    if (dueDate < todaysDate) {
      setErrors({ general: "Bid close date cannot be less than today's date" });
      return false;
    }

    let invoiceIds = selected.map((invoice) => invoice.id);

    let dataToSend = {
      start_date: await convertDate(data.issued_date, true),
      due_date: await convertDate(data.due_date, false),
      invoiceIds: invoiceIds,
    };

    console.log("dueData, ", dataToSend);
    dispatch(updateBidDate(dataToSend));
    setSelected([]);
    setOpen(false);
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={""} description={""} onSubmit={handleSubmit(onSubmit)} withActions={true}>
      <Grid container style={{ width: "400px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <KeyboardDatePicker
              inputVariant="outlined"
              margin="dense"
              id="issued_date"
              name="issued_date"
              label="Bid Opening Date"
              value={selectedIssuDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              onChange={(date) => setSelectedIssueDate(date)}
              inputRef={register}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />{" "}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <KeyboardDatePicker
              margin="dense"
              inputVariant="outlined"
              id="due_date"
              name="due_date"
              label="Bid Closing Date"
              value={selectedDueDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              onChange={(date) => setSelectedDueDate(date)}
              inputRef={register}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </DialogComponent>
  );
};

export default BidDetailsDialog;
