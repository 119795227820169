import { Divider, Grid, TextField, Typography, Hidden } from "@material-ui/core";

import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Radio from "@material-ui/core/Radio";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import countryList from "react-select-country-list";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import PhoneInputComponent from "../../Components/PhoneInputComponent";

const customStyles = {
  menu: () => ({
    backgroundColor: "white",
  }),
};

const ContactDialog = ({
  open,
  setOpen,
  title,
  description,
  errors,
  onSubmit,
  defaultValues,
  business,
  isEdit,
  setSelectedCustomerGroupId,
  selectedCustomerGroupId,
  renderCustomerGroups,
}) => {
  const { register, control, handleSubmit, reset } = useForm();

  let { address: businessAddress, currency } = business.company_details;

  const [countryValue, setCountryValue] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [countryLabel, setCountryLabel] = useState("");
  const [platformUsage, setPlatformUsage] = useState("");
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (business && business.account_settings) {
      let platformUsage = business?.account_settings?.invoice_settings?.platform_usage;
      if (platformUsage) {
        setPlatformUsage(platformUsage.isErp ? "erp" : "manual");
      }
    }
  }, [business]);
  const getCountries = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountryCode(value.value);
    setCountryLabel(value.label);
    setCountryValue(value);
  };

  useEffect(() => {
    setCountryValue(defaultValues?.country ? defaultValues?.country : "");

    setCountryCode(defaultValues?.country_code ? defaultValues?.country_code : business?.country_code ? business.country_code : "");
    setCountryLabel(defaultValues?.country ? defaultValues?.country : businessAddress?.country ? businessAddress?.country : "");

    reset(defaultValues);
  }, [defaultValues, reset, business, businessAddress?.country]);

  const handleChange = (event) => {
    let val = event.target.value;
    if (val === "byTyping") setSelectedCustomerGroupId("");
    setValue(val);
  };

  const renderGroupAddingSelection = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Choose how to select group for your contact</FormLabel>
        <RadioGroup aria-label="erp" name="gender1" value={value} onChange={handleChange}>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={6}>
              <div>
                <FormControlLabel value="fromList" control={<Radio />} label="From Group List" />
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div>
                <FormControlLabel value="byTyping" control={<Radio />} label="By Entering" />
              </div>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    );
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description={description} onSubmit={handleSubmit(onSubmit)} withActions={true}>
      <div style={{ width: "450px" }}>
        <TextField
          margin="dense"
          variant="outlined"
          required
          autoFocus
          id="name"
          name="name"
          label="Company Name or Full Name"
          error={errors.name ? true : false}
          helperText={errors.name ?? ""}
          fullWidth
          inputRef={register}
        />
        <br />

        <Select
          required
          styles={customStyles}
          id="country"
          name="country"
          options={getCountries}
          value={countryValue}
          onChange={changeHandler}
          placeholder={countryValue || "Select a country"}
          inputRef={register}
        />
        <Hidden>
          <TextField
            required
            readOnly
            variant="outlined"
            id="country"
            name="country"
            label="Country "
            value={countryLabel}
            error={errors.Country ? true : false}
            helperText={errors.Country ?? ""}
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={true}
            style={{ display: "none" }}
          />
        </Hidden>

        <br />

        <div>
          <PhoneInputComponent errors={errors} register={register} control={control} />
          <Typography color={errors.phone ? "error" : "default"} variant="caption" component="p">
            {errors.phone ? errors.phone : "Required for invitation request."}
          </Typography>
        </div>

        <Divider />

        <TextField margin="dense" id="email" name="email" label="Email" helperText="Optional" fullWidth variant="outlined" inputRef={register} />
        <br />
        <br />

        {renderGroupAddingSelection()}
        {value === "fromList" && renderCustomerGroups}

        {value === "byTyping" && (
          <>
            <TextField
              margin="dense"
              variant="outlined"
              required
              autoFocus
              id="new_contact_name"
              name="new_contact_name"
              label="Enter new contact name"
              error={errors.new_contact_name ? true : false}
              helperText={errors.new_contact_name ?? ""}
              fullWidth
              inputRef={register}
            />
          </>
        )}

        <br />
      </div>
    </DialogComponent>
  );
};

export default ContactDialog;
