import { Divider, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import Box from "@material-ui/core/Box";

const BusinessDetailsDialog = ({ business, open, setOpen, title }) => {
  let { company_details } = business;
  let { address } = company_details;

  const renderDirectorDocuments = () => {
    let fields = [];
    let i = 1;
    for (let director of business.directors) {
      fields.push(
        <>
          <Box
            borderColor="grey"
            width={1}
            border={2}
            borderRadius={16}
            style={{
              padding: 16,
              margin: 16,
              display: "inline-block",
              borderStyle: "dashed",
            }}
          >
            <Grid xs={12} style={{ marginTop: "15px" }}>
              <b>Director {i}</b>
            </Grid>
            <Grid xs={12}>
              <Typography style={{ marginTop: "15px", fontSize: "15px", fontWeight: "bold" }}>{director.full_name}</Typography>
            </Grid>
            <Grid xs={6}>Proof of address</Grid>
            <Grid xs={6}>
              {director.address_proof ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(director.address_proof);
                  }}
                >
                  Download the proof of address
                </Link>
              ) : (
                <Typography>No files uploaded</Typography>
              )}
            </Grid>
            <Grid xs={6}>Front Page of ID</Grid>
            <Grid xs={6}>
              {director.id_front_page ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(director.id_front_page);
                  }}
                >
                  Download the Front page of ID
                </Link>
              ) : (
                <Typography>No files uploaded</Typography>
              )}
            </Grid>
            <Grid xs={6}>Rear Page of ID</Grid>
            <Grid xs={6}>
              {director.id_rear_page ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(director.id_rear_page);
                  }}
                >
                  Download the Rear page of ID
                </Link>
              ) : (
                <Typography>No files uploaded</Typography>
              )}
            </Grid>
          </Box>
        </>
      );
      i++;
    }
    return fields;
  };
  const renderShareholderDocuments = () => {
    let fields = [];
    let i = 1;
    for (let director of business.shareholders) {
      fields.push(
        <>
          <Box
            borderColor="grey"
            width={1}
            border={2}
            borderRadius={16}
            style={{
              padding: 16,
              margin: 16,
              display: "inline-block",
              borderStyle: "dashed",
            }}
          >
            {" "}
            <Grid xs={12} style={{ marginTop: "15px" }}>
              <b>Shareholder {i}</b>
            </Grid>
            <Grid xs={12}>
              <Typography style={{ fontSize: "15px", fontWeight: "bold", marginTop: "15px" }}>{director.full_name}</Typography>
            </Grid>
            <Grid xs={6}>Proof of address</Grid>
            <Grid xs={6}>
              {director.address_proof ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(director.address_proof);
                  }}
                >
                  Download the proof of address
                </Link>
              ) : (
                <Typography>No files uploaded</Typography>
              )}
            </Grid>
            <Grid xs={6}>Front Page of ID</Grid>
            <Grid xs={6}>
              {director.id_front_page ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(director.id_front_page);
                  }}
                >
                  Download the Front page of ID
                </Link>
              ) : (
                <Typography>No files uploaded</Typography>
              )}
            </Grid>
            <Grid xs={6}>Rear Page of ID</Grid>
            <Grid xs={6}>
              {director.id_rear_page ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(director.id_rear_page);
                  }}
                >
                  Download the Rear page of ID
                </Link>
              ) : (
                <Typography>No files uploaded</Typography>
              )}
            </Grid>
          </Box>
        </>
      );
      i++;
    }
    return fields;
  };

  const renderCompanyFiles = () => {
    return (
      <>
        <Box
          borderColor="grey"
          width={1}
          border={2}
          borderRadius={16}
          style={{
            padding: 16,
            margin: 16,
            display: "inline-block",
            borderStyle: "dashed",
          }}
        >
          <Grid xs={6} style={{ marginTop: "15px" }}>
            <Typography>Bank Statement</Typography>
          </Grid>
          <Grid xs={6} style={{ marginTop: "15px" }}>
            {business?.financial_informations[business?.financial_informations.length - 1].bank_statement ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(business.financial_informations[business.financial_informations.length - 1].bank_statement);
                }}
              >
                Download the bank statement
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>
            <Typography>Company Certificate</Typography>
          </Grid>
          <Grid xs={6}>
            {business.company_details.company_certificate ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(business.company_details.company_certificate);
                }}
              >
                Download the Company certificate
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>{" "}
        </Box>
        <Box
          borderColor="grey"
          width={1}
          border={2}
          borderRadius={16}
          style={{
            padding: 16,
            margin: 16,
            display: "inline-block",
            borderStyle: "dashed",
          }}
        >
          {" "}
          <Grid xs={6} style={{ marginTop: "15px" }}>
            <Typography>Address: </Typography>
          </Grid>
          <Grid xs={6} style={{ marginTop: "15px" }}>
            {address.address_line_1 + " " + address.address_line_2 + " " + address.country}
          </Grid>
        </Box>
      </>
    );
  };

  const renderDetails = () => {
    return (
      <>
        <Grid xs={12}>
          <Typography>Company Number: {business.company_details.company_reg_number}</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography>Company Number: {business.company_details.company_reg_number}</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography>Turnover Year: {business.financial_informations[business.financial_informations.length - 1].year}</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography>Turnover Amount: {business.financial_informations[business.financial_informations.length - 1].turnover_amount}</Typography>{" "}
        </Grid>
        <Grid xs={12}>
          <Typography>
            Total Number of Employee:
            {business.financial_informations[business.financial_informations.length - 1].number_of_employee}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description="" withActions={false}>
      <Grid container>
        {business?.company_details && (
          <>
            <Box
              borderColor="grey"
              width={1}
              border={2}
              borderRadius={16}
              style={{
                padding: 16,
                margin: 16,
                display: "inline-block",
                borderStyle: "dashed",
              }}
            >
              {renderDetails()}
            </Box>

            {/* <Typography variant="h6" style={{ marginTop: "20px", textAlign: "center" }}>
              Directors:
            </Typography> */}

            {renderDirectorDocuments()}

            {renderShareholderDocuments()}

            {/* <Typography variant="h6" style={{ marginTop: "20px", textAlign: "center" }}>
              Shareholders:
            </Typography> */}
            {/* <Typography variant="h6" style={{ marginTop: "20px", textAlign: "center" }}>
              Company Files:
            </Typography> */}

            {business?.financial_informations && renderCompanyFiles()}
          </>
        )}
      </Grid>
    </DialogComponent>
  );
};

export default BusinessDetailsDialog;
