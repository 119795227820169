import React from 'react';

import TableComponent from '../../../Components/Table/TableComponent';
import FinanceTableToolbar from '../FinanceTableToolbar';

const headCells = [
    { id: "customerFullNameOrCompanyName", label: "Customer" },
    { id: "supplierFullNameOrCompanyName", label: "Supplier(Full Name or Company Name)" },
    { id: "dueDate", label: "Due Date" },
    { id: "amount", label: "Amount" },
];

const CreditRequestedInvoicesTable = ({ creditRequestedInvoices,setInvoiceDetailsDialogOpen, selected, setSelected,setEditDialogOpen, setInviteDialogOpen, setDeleteDialogOpen }) => {

const renderToolbar = () => {
        return (
            <FinanceTableToolbar
                numSelected={selected ? selected.length : 0}
                header="Credit Requests"
                setInvoiceDetailsDialogOpen={setInvoiceDetailsDialogOpen}
            />
        )
    }

    return (
        <TableComponent
            toolbar={renderToolbar()}
            data={creditRequestedInvoices}
            selected={selected}
            setSelected={setSelected}
            headCells={headCells}
        />
    )
}

export default CreditRequestedInvoicesTable
