import { IconButton, Tooltip, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useEffect, useState } from "react";
import checkIfHasActiveMembership from "../../util/permissions/checkIfHasActiveMembership";
import AddButton from "../../Components/shared/AddButton";
import TableToolbarComponent from "../../Components/Table/TableToolbarComponent";
import { usePermissionsContext } from "../../Contexts/PermissionsContext";
import canPerformAction from "../../util/permissions/canPerformAction";
import { updateCustomersFromErp } from "../../store/actions/customerActions";

const CustomersTableToolbar = ({
  business,
  numSelected,
  selected,
  setErrors,
  header,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  dispatch,
  userHasMembership,
  renderCustomerGroups,
  setAssignToGroupDialogOpen,
}) => {
  const { permissions } = usePermissionsContext();
  const [openImportCustomerDialog, setOpenImportCustomerDialog] = useState(false);

  const handleInviteDialog = () => {
    let flag = 1;
    if (numSelected > 1) {
      for (let sel of selected) {
        if (!sel.email) {
          setErrors({
            general: "In bulk invitations, the email of the invitees must be entered.",
          });
          flag = 0;
        }
      }
    } else if (numSelected === 1) {
      if (!selected[0].phone && !selected[0].email) {
        flag = 0;
        setErrors({
          general: "Please enter either phone or email for your invitee",
        });
      }
    }
    if (flag === 1) {
      setInviteDialogOpen(true);
    }
  };

  const handleOpenImportDialog = () => {
    if (business && business?.account_settings?.invoice_settings?.platform_usage) {
      let platform_usage = business?.account_settings?.invoice_settings?.platform_usage;

      if (platform_usage.isErp) {
        if (!business?.account_settings?.invoice_settings?.cloud_app.isSynced) {
          setErrors({
            general: "Please sync your cloud app in settings",
          });

          return false;
        } else {
          dispatch(updateCustomersFromErp());
        }
      }
    }
  };

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {numSelected > 0 ? (
          <>
            <Tooltip title="Message to Your Contact">
              <IconButton onClick={handleInviteDialog} style={{ color: "#14980B" }}>
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            {numSelected === 1 && (
              <>
                <>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => setEditDialogOpen(true)} style={{ color: "#1F036D" }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>

                {/* {canPerformAction("customers", permissions, "edit") && (
                  <>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setAssignToGroupDialogOpen(true)}>Assign To Group</IconButton>
                    </Tooltip>
                  </>
                )} */}
              </>
            )}
            {business && (
              <>
                <Tooltip title="Delete">
                  <IconButton onClick={() => setDeleteDialogOpen(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ) : (
          <>
            {business && (
              <Grid container>
                <Grid
                  item
                  style={{
                    backgroundColor: "#FEEBF2",
                    borderRadius: "20px",
                    marginTop: "10px",
                  }}
                  xs={12}
                  lg={6}
                  md={6}
                >
                  <div style={{ padding: 15 }}>{renderCustomerGroups}</div>
                </Grid>
              </Grid>
            )}
            {business && (
              <div>
                <AddButton onClick={() => setCreateDialogOpen(true)} />
              </div>
            )}
          </>
        )}
      </>
    </TableToolbarComponent>
  );
};

export default CustomersTableToolbar;
