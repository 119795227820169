import React, { useState } from "react";
import { Paper, Grid, Button, Typography, FormControlLabel, Box } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
// import { ExcelRenderer } from "react-excel-renderer";

import LoadingIcon from "../../Components/LoadingIcon";
import HeaderComponent from "../../Components/HeaderComponent";
import { useSupplierStyles } from "./SupplierStyles";

const ImportSupplierComponent = ({ setSuppliers, title, loading, errors }) => {
  const classes = useSupplierStyles();
  const [file, setFile] = useState([]);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    // if (file) {
    //   setFile(file);
    //   ExcelRenderer(file, (err, resp) => {
    //     if (err) {
    //     } else {
    //       let data = [];
    //       let rows = resp.rows.slice(2);
    //       rows.forEach((row) => {
    //         if (row[0]) {
    //           data.push({
    //             taxIdNo: `${row[0] === undefined ? "" : row[0]}`,
    //             erp_no: `${row[1] === undefined ? "" : row[1]}`,
    //             name: `${row[2] === undefined ? "" : row[2]}`, //Company Name of Full Name
    //             repName: `${row[3] === undefined ? "" : row[3]}`,
    //             repSurname: `${row[4] === undefined ? "" : row[4]}`,
    //             email: `${row[5] === undefined ? "" : row[5]}`,
    //             faxNo: `${row[6] === undefined ? "" : row[6]}`,
    //             taxAdmin: `${row[7] === undefined ? "" : row[7]}`,
    //             phone: `${row[8] === undefined ? "" : row[8]}`,
    //             country: `${row[9] === undefined ? "" : row[9]}`,
    //             city: `${row[10] === undefined ? "" : row[10]}`,
    //           });
    //         }
    //       });

    //       setSuppliers(data);
    //     }
    //   });
    // }
  };

  return loading ? (
    <LoadingIcon />
  ) : (
    <>
      <Paper className={classes.paper}>
        <HeaderComponent title={title} icon={<ReceiptIcon fontSize="large" />} />
      </Paper>
      <Paper className={classes.paper}>
        <Grid container className={classes.grid} spacing={2}>
          <Grid item xs={12}>
            <>
              <h1>Importing Excel Component</h1>
              <Grid item>
                <Grid
                  span={8}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5%",
                  }}
                ></Grid>
                <Grid span={8}>
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/payeda-e2b59.appspot.com/o/payedaDocuments%2Fpayeda_customer_supplier_import.xls?alt=media&token=f844d6e7-7daa-44cb-9bd1-6dc56ac5079f"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Sample excel sheet
                  </a>
                </Grid>
              </Grid>
              <div>
                <FormControlLabel
                  control={
                    <Box border={1} borderRadius={16} className={classes.box}>
                      <Typography variant="body1" color={errors ? "error" : "textPrimary"}>
                        {file ? file.fileName : ""}
                      </Typography>
                      <Button variant="contained" component="label">
                        Upload
                        <input name="csvFile" type="file" onChange={handleFileSelect} style={{ display: "none" }} />
                      </Button>
                    </Box>
                  }
                  style={{ width: "fit-content" }}
                  label={file ? file.name : "Select a file to upload"}
                />
              </div>
            </>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ImportSupplierComponent;
