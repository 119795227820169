const initialState = {
  finances: [],
  creditRequestedInvoicesDetails: [],
  factoringRequestedInvoicesDetails: [],
  pendingBankApprovalRequests: [],
  basketDetails: [],
};

const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FINANCE":
      return {
        ...state,
        finances: action.payload,
      };
    case "SET_CREDIT_REQUESTED_INVOICES":
      return {
        ...state,
        creditRequestedInvoicesDetails: action.payload,
      };
    case "FETCH_FACTORING_REQUESTED_INVOICES":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_FACTORING_REQUESTED_INVOICES":
      return {
        ...state,
        isLoading: false,

        factoringRequestedInvoicesDetails: action.payload,
      };
    case "SET_PENDING_BANK_APPROVAL_REQUESTS":
      return {
        ...state,
        pendingBankApprovalRequests: action.payload,
      };
    case "FINANCE_BASKET_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "FINANCE_BASKET_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        financeBasket: action.payload,
      };
    case "FINANCE_BASKET_ID":
      return {
        ...state,
        financeBasketID: action.payload,
      };
    case "FINANCE_BASKET_REQUEST_IDS":
      return {
        ...state,
        financeBasketRequestIds: action.payload,
      };
    case "FINANCE_BASKET_DETAILS_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        financeBasketDetails: action.payload,
      };
    case "FINANCE_OFFERS_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        financeOffers: action.payload,
      };
    case "SET_FINANCE_BASKETS":
      return {
        ...state,
        isLoading: false,
        financeBaskets: action.payload,
      };
    case "BASKET_DETAILS":
      return {
        ...state,
        basketDetails: action.payload,
      };
    case "SELECTED_SUPPLIER_FOR_FINANCE":
      return {
        ...state,
        selectedSupplier: action.payload,
      };
    case "CREATING_OFFER":
      return {
        ...state,
        offerLoading: true,
      };
    case "CREATED_OFFER":
      return {
        ...state,
        offerLoading: false,
      };
    case "OFFER_CREATION_FAILED":
      return {
        ...state,
        offerLoading: false,
      };
    default:
      return state;
  }
};

export default financeReducer;
