import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useUiContext } from "../Contexts/UiContext";

const AlertNotification = () => {
  const { errors, error, clearErrors, clearSuccess } = useUiContext();
  const { enqueueSnackbar } = useSnackbar();
  let success = useSelector((state) => state?.ui?.success);
  let message = useSelector((state) => state?.ui?.message);
  const renderServerErrors = (errors, variant) => {
    let err = errors.error;
    if (err === "Error while making request: connect ECONNREFUSED 64.233.167.95:443. Error code: ECONNREFUSED") {
      return false;
    }
    if (typeof err === "string") {
      enqueueSnackbar(err, { variant });
    } else {
      for (const errorKey in err) {
        enqueueSnackbar(err[errorKey], { variant });
      }
    }

    clearErrors();
  };

  const renderUIErrors = (errors, variant) => {
    if (!errors.general) {
      for (const errorKey in errors) {
        enqueueSnackbar(errors[errorKey], { variant });
      }
    }
  };

  const renderGeneralErrors = (errors, variant) => {
    let err = errors.general;
    enqueueSnackbar(err, { variant });
  };

  const renderSuccess = (variant = "success") => {
    if (success) {
      enqueueSnackbar(message, { variant });
      clearSuccess();
    }
  };

  useEffect(() => {
    if (errors && error && errors.general && !errors.error && Object.keys(errors).length === 1) {
      renderGeneralErrors(errors, "error");
    }
    // eslint-disable-next-line
  }, [errors, errors.general]);

  useEffect(() => {
    if (errors && error && !errors.error) {
      renderUIErrors(errors, "error");
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    if (errors && error && errors.error) {
      renderServerErrors(errors, "error");
    }
    // eslint-disable-next-line
  }, [errors.error]);

  useEffect(() => {
    if (success && message) {
      renderSuccess("success");
    }
    // eslint-disable-next-line
  }, [success]);
  return (
    <>
      {/* {errors.error && renderServerErrors(errors, "error")} */}
      {/* {errors && error && !errors.error && enqueueSnackbar("errors", "error")} */}
    </>
  );
};

export default AlertNotification;
