import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import getPermissionStates from "../../util/permissions/getPermissionStates";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import MemberSignupDetails from "./MemberSignupDetails";
import MemberPermissions from "./MemberPermissions";
import { createMember, editMember } from "../../store/actions/memberActions";
import { API_BASE } from "./../../store/env";
import { useUiContext } from "./../../Contexts/UiContext";
import { memberSchema } from "../../util/yup/yupSchemas";
import verifySchema from "../../util/yup/verifySchema";

const MembersDialog = ({ open, setOpen, title, description, selected, data: dataExternal, addOrEdit, setSelected, accountType, user }) => {
  const dispatch = useDispatch();

  const { errors, setErrors, clearErrors } = useUiContext();

  const [page, setPage] = useState(1);

  const [data, setData] = React.useState({
    name: "",
    surname: "",
    email: "",
    phone: "",

    accountType: accountType,
    permissions: getPermissionStates("Business").data,
  });

  useEffect(() => {
    if (dataExternal) {
      setData(dataExternal);
    }
  }, [dataExternal]);

  const handleCheckClick = (e) => {
    const section = e.target.name;
    const id = e.target.id;

    var reg = new RegExp(`${section}`, "gi");
    const cleared = id.replace(reg, "");

    let temp = { ...data };
    temp["permissions"][`${section}`][`${cleared}`] = !temp["permissions"][`${section}`][`${cleared}`];
    let selectedSection = temp["permissions"][`${section}`];

    // If one of the section is true view should be true automatically

    if (
      selectedSection.create ||
      selectedSection.edit ||
      selectedSection.delete ||
      selectedSection.remove ||
      selectedSection.askCredit ||
      selectedSection.makePayment ||
      selectedSection.askFactoring ||
      selectedSection.deny
    ) {
      if ("view" in selectedSection) {
        selectedSection.view = true;
      }
    }
    setData(temp);
  };
  const checkIfEmailExists = async (email) => {
    try {
      if (!email) {
        setErrors({ general: "Please enter email" });
        return false;
      }
      let request = await axios.get(`${API_BASE}/members/find-by-email`, {
        params: {
          email: email,
        },
      });
      let response = request.data;
      if (response.isExists === true) {
        setErrors({ general: "Please enter different email" });
      } else setPage(2);
    } catch (error) {}
  };

  const handleChange = (e) => {
    let val = e.target.value;
    let id = e.target.id;
    let temp = { ...data };
    temp[id] = val;
    setData(temp);
  };

  const handlePhoneChange = (phone) => {
    let temp = { ...data };
    temp["phone"] = phone;
    setData(temp);
  };

  const handleSubmit = async () => {
    if (addOrEdit === "add") {
      await dispatch(createMember(data));
    } else {
      await dispatch(editMember(data, data.id));
      setSelected([]);
    }
    setOpen(false);
    setPage(1);
    setData({
      name: "",
      surname: "",
      email: "",
      phone: "",

      accountType: accountType,
      permissions: getPermissionStates("Business").data,
    });
  };

  const nextPage = async () => {
    if (page === 1 && addOrEdit === "add") {
      const valid = await verifySchema(memberSchema, data, clearErrors, setErrors);
      if (!valid) return false;
      checkIfEmailExists(data.email);
    } else {
      setPage(2);
    }
  };

  const previousPage = () => {
    setPage(1);
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description={description} withActions={false}>
      {page === 1 && (
        <>
          <MemberSignupDetails nextPage={nextPage} data={data} handleChange={handleChange} handlePhoneChange={handlePhoneChange} selected={selected} />
        </>
      )}

      {page === 2 && (
        <>
          <MemberPermissions
            previousPage={previousPage}
            permissions={data["permissions"]}
            handleCheckClick={handleCheckClick}
            accountType={accountType}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </DialogComponent>
  );
};

export default MembersDialog;
