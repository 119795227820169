import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Button } from "@material-ui/core";

import { useDialogComponentStyles } from "../ComponentStyles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

//This can be changed so it accepts "dialogActions" as prop and renders it (similar to TableComopnent accepting toolbar/footer)
const DialogComponent = (props) => {
  let { open, setOpen, title, description, onSubmit, children, withActions, backgroundColor, submitButtonText, isDisabled } = props;
  const classes = useDialogComponentStyles();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.dialog }}
      PaperProps={{
        style: {
          backgroundColor: backgroundColor || "white",
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {title}
        <IconButton aria-label="close" onClick={() => setOpen(false)} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <>
          <DialogContentText id="dialog-description">{description}</DialogContentText>
          {children}
        </>
      </DialogContent>
      {withActions && (
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} disabled={isDisabled} style={{ backgroundColor: "#67e698" }}>
            {submitButtonText || "Submit"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogComponent;
