import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import TableComponent from "../../../../Components/Table/TableComponent";
import { getInvoicesFromCloud } from "../../../../store/actions/invoiceActions";

const outgoingInvoicesTableHeadCells = [
    { id: "invoice_number", label: "Invoice Number" },
    { id: "customer_name", label: "Customer Name" },
    { id: "issued_date", label: "Issue Date" },
    { id: "due_date", label: "Due Date" },
    { id: "allStatusDetails", label: "Status" },

    { id: "amount", label: "Amount to be Paid" },
    { id: "manual" },
];

const Invoices = ({ invoices, isLoading }) => {
    const [selected, setSelected] = useState([]);
    const [totalUnpaidInvoices, setTotalUnpaidInvoices] = useState(0);
    const [totalPaidInvoices, setTotalPaidInvoices] = useState(0);
    const [unpaidLength, setUnpaidLength] = useState(0);
    const [paidLength, setPaidLength] = useState(0);

    useEffect(() => {
        let unpaidTotal = 0;
        let paidTotal = 0;
        let unpaindLength = 0;
        let paidLength = 0;
        if (invoices.length > 0) {
            for (let inv of invoices) {
                if (inv.status === 'Awaiting Payment') {
                    unpaindLength++;
                    unpaidTotal = unpaidTotal + parseFloat(inv.amount)
                } else if (inv.status === 'Paid') {
                    paidLength++;
                    paidTotal = paidTotal + parseFloat(inv.amount)
                }

            }

            setTotalUnpaidInvoices(parseFloat(unpaidTotal).toFixed(2))
            setTotalPaidInvoices(parseFloat(paidTotal).toFixed(2))
            setUnpaidLength(unpaindLength)
            setPaidLength(paidLength)
        }

    }, [invoices]);

    return (
        <div>
            <br />
            {/* <Typography variant="h4">{invoices.length} invoices found</Typography> */}
            <Typography variant="h4">{unpaidLength} unpaid invoices found. Total is: <strong>{totalUnpaidInvoices}</strong> </Typography>
            <Typography variant="h4">{paidLength} paid invoices found. Total is: <strong>{totalPaidInvoices}</strong></Typography>

            <br />

            <TableComponent
                data={invoices}
                isLoading={isLoading}
                shouldView={true}
                headCells={outgoingInvoicesTableHeadCells}
                selected={selected}
                setSelected={setSelected}
                searchBarText="Search invoices"
                shouldGetData={false}
                shouldPaginate={true}
                shouldSearch={true}
            />
        </div>
    )
}

export default Invoices