import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Button, TextField } from "@material-ui/core";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

const ImportOutgoingFromCloudDialog = ({
  open,
  setOpen,
  description,
  title,
  onSubmit,
  renderCustomersLists,
  selectedCustomer,
  getInvoicesFromCloud,
  cloudInvoices,
  history,
}) => {
  const dispatch = useDispatch();

  const [selectedCreateDate, setSelectedCreateDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [isWithInvoiceNumber, setIsWithInvoiceNumber] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();

  const [loading, setLoading] = useState(false);

  const error = useSelector((state) => state.ui.error);
  const getInvoices = () => {
    let dataToSend;

    if (!isWithInvoiceNumber) {
      dataToSend = {
        createdDate: selectedCreateDate.toISOString(),
        dueDate: selectedDueDate.toISOString(),
        from: "cloud",
      };
    } else {
      dataToSend = {
        from: "cloud",
        invoiceNumber: invoiceNumber,
      };
    }
    setLoading(true);
    dispatch(getInvoicesFromCloud(dataToSend));
    history.push("/dashboard/cloud-invoices");
    setLoading(false);
    setOpen(false);
  };

  // if(!error) setLoading(false);
  useEffect(() => {
    if (cloudInvoices) {
    }
  }, [cloudInvoices]);

  useEffect(() => {
    if (error) setLoading(false);
  }, [error]);

  const renderWithDate = () => {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={12} lg={12}>
              {renderCustomersLists}
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <KeyboardDatePicker
                inputVariant="outlined"
                disableFuture
                id="editDate"
                name="editDate"
                openTo="year"
                format="yyyy-MM-dd"
                label="First Date"
                value={selectedCreateDate}
                views={["year", "month", "date"]}
                onChange={(date) => setSelectedCreateDate(date)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <KeyboardDatePicker
                inputVariant="outlined"
                id="dueDate"
                name="dueDate"
                label="Last Date"
                value={selectedDueDate}
                openTo="year"
                format="yyyy-MM-dd"
                views={["year", "month", "date"]}
                onChange={(date) => setSelectedDueDate(date)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}></Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </>
    );
  };

  const renderWithInvoiceNumber = () => {
    return (
      <>
        <TextField
          variant="outlined"
          label="Invoice Number"
          type="text"
          onChange={(e) => setInvoiceNumber(e.target.value)}
          fullWidth
        />
      </>
    );
  };
  const handlePageChange = (pageType) => {
    if (pageType === "invoiceNumber") {
      setIsWithInvoiceNumber(true);
    } else if (pageType === "date") {
      setIsWithInvoiceNumber(false);
    }
  };

  const renderSelection = () => {
    return (
      <>
        <Button
          onClick={() => {
            handlePageChange("date");
          }}
          color={isWithInvoiceNumber ? "secondary" : "primary"}
        >
          Get with Date Range
        </Button>
        <Button
          onClick={() => {
            handlePageChange("invoiceNumber");
          }}
          color={!isWithInvoiceNumber ? "secondary" : "primary"}
        >
          Get With Invoice Number
        </Button>
      </>
    );
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      withActions={false}
    >
      {renderSelection()}
      {loading && "We are getting your invoices.."}

      {!loading && (
        <>
          <div style={{ marginTop: "35px" }}>
            {!isWithInvoiceNumber && renderWithDate()}
            {isWithInvoiceNumber && renderWithInvoiceNumber()}
          </div>
          <div style={{ float: "right", marginTop: "35px" }}>
            <Button
              color="secondary"
              onClick={() => {
                getInvoices();
              }}
              variant="outlined"
            >
              GET INVOICES
            </Button>
          </div>
        </>
      )}
    </DialogComponent>
  );
};

export default ImportOutgoingFromCloudDialog;
