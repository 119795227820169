import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import React, { useState } from "react";
import HeaderComponent from "./../../Components/HeaderComponent";
import { useActivitLogStyle } from "./ActivityLogsStyle";
import ActivityLogsTable from "./ActivityLogsTable";

const Members = ({ activityLogs }) => {
  const classes = useActivitLogStyle();
  const [selected, setSelected] = useState([]);

  return (
    <div className={classes.content2}>
      <div className={classes.contentContainer}>
        <div className={classes.contentIn}>
          <HeaderComponent
            title="Activity Logs"
            icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
          />

          <ActivityLogsTable
            logs={activityLogs}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default Members;
