import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import SimpleTable from "../../Components/Table/SimpleTable";
import { addProduct } from "../../store/actions/productActions";
import ProductDialog from "../Product/ProductDialog";
import UploadComponent from "./../../Components/UploadComponent";
import { useUiContext } from "./../../Contexts/UiContext";
import verifySchema from "./../../util/yup/verifySchema";
import { invoiceLineSchema, productSchema } from "./../../util/yup/yupSchemas";
import AddInvoiceLineComponent from "./AddInvoiceLineComponent";

const headCells = [
  { id: "product", label: "Product" },
  { id: "product_quantity", label: "Quantity" },
  { id: "unit_price", label: "Unit Price" },
  { id: "line_total_amount", label: "Line Total Amount" },
  { id: "line_vat_amount", label: "Line Vat Amount" },
  { id: "subtotal_amount", label: "Subtotal Amount" },
];

const CreateInvoiceComponent = (props) => {
  const { errors, loading, clearErrors, setErrors } = useUiContext();
  const { dropdownComponent, createButtonComponent, onSubmit, handleSubmit, supplierName, customerName } = props;
  const { register } = props;
  const { type } = props;

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const products = useSelector((state) => state.product.products);

  const [addInvoiceImage, setAddInvoiceImage] = useState(false);
  const [invoiceLines, setInvoiceLines] = useState([]);
  // eslint-disable-next-line
  let [totalRateOfVat, setTotalRateOfVat] = useState(0);
  // eslint-disable-next-line
  let [totalDiscount, setTotalDiscount] = useState(0);

  let [totalSubtotalAmount, setTotalSubtotalAmount] = useState(0);
  let [totalAmount, setTotalAmount] = useState(0);
  let [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  let [totalVatAmount, setTotalVatAmount] = useState(0);

  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());

  const [createProductDialog, setCreateProductDialogOpen] = useState(false);

  const handleInvoiceLineAdd = async (data) => {
    let id = shortid.generate();
    var details = { ...data, id };

    let valid = await verifySchema(invoiceLineSchema, data, clearErrors, setErrors);

    if (valid) {
      setInvoiceLines((oldArray) => [...oldArray, details]);
    }
  };

  useEffect(() => {
    let calculatedSubTotal = 0;
    let calculatedLineTotal = 0;
    let calculatedDiscount = 0;
    let calculatedVatAmount = 0;

    if (invoiceLines && invoiceLines.length > 0) {
      for (let line of invoiceLines) {
        calculatedSubTotal = currency(line.subtotal_amount)
          .add(calculatedSubTotal)
          .format({
            symbol: "",
          });
        calculatedLineTotal = currency(line.line_total_amount)
          .add(calculatedLineTotal)
          .format({
            symbol: "",
          });
        calculatedDiscount = currency(line.line_discount_amount)
          .add(calculatedDiscount)
          .format({
            symbol: "",
          });
        calculatedVatAmount = currency(line.line_vat_amount)
          .add(calculatedVatAmount)
          .format({
            symbol: "",
          });
      }
    }

    setTotalSubtotalAmount(calculatedSubTotal);
    setTotalAmount(calculatedLineTotal);
    setTotalDiscountAmount(calculatedDiscount);
    setTotalVatAmount(calculatedVatAmount);
  }, [invoiceLines]);

  const handleCalculateAmount = async (data) => {
    let dataToSend = {
      ...data,

      subtotal_amount: currency(totalSubtotalAmount).format({ symbol: "" }),
      total_vat_amount: currency(totalVatAmount).format({ symbol: "" }),
      total_discount_amount: currency(totalDiscountAmount).format({
        symbol: "",
      }),
      amount_to_be_paid: currency(totalAmount).format({ symbol: "" }),
      invoice_lines: invoiceLines,
    };

    await onSubmit(dataToSend);
  };

  const onCreateNew = async (data) => {
    data.currency = "GBP";
    const valid = await verifySchema(productSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateProductDialogOpen(false);
      dispatch(addProduct(data));
    }
  };

  const createProductButton = () => {
    return (
      <Typography style={{ marginLeft: 70, color: "red" }}>
        Add New Product
        <Tooltip title="Create Product">
          <IconButton onClick={() => setCreateProductDialogOpen(true)}>
            <GroupAddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ margin: 40 }}>
        <FormControl style={{ display: "flex" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {dropdownComponent}
            </Grid>
            <Grid item xs={12} sm={6}>
              {createButtonComponent}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <div className="textfield-container">
                <p className="invoice-title">Supplier Details</p>
                <TextField
                  label="Company Name"
                  name="supplier_name"
                  value={supplierName}
                  inputRef={register({ required: true, minLength: 2 })}
                  error={errors.supplier_name && true}
                  helperText={errors.supplier_name && "Invalid Input"}
                  size="small"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <div className="textfield-container">
                <p className="invoice-title">Customer Details</p>
                <TextField
                  label="Customer Name"
                  name="customer_name"
                  value={customerName}
                  inputRef={register({ required: true, minLength: 2 })}
                  error={errors.customer_name && true}
                  helperText={errors.customer_name && "Invalid Input"}
                  size="small"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Currency"
                  name="currency"
                  value="GBP"
                  inputRef={register({ required: true, minLength: 2 })}
                  error={errors.currency && true}
                  helperText={errors.currency && "Invalid Input"}
                  size="small"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
              <div className="textfield-container">
                <p className="invoice-title">Invoice Details</p>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  margin="dense"
                  id="issued_date"
                  name="issued_date"
                  label="Issue Date"
                  value={selectedIssuDate}
                  openTo="year"
                  format="yyyy-MM-dd"
                  size="small"
                  views={["year", "month", "date"]}
                  error={errors.issued_date ? true : false}
                  helperText={errors.issued_date ?? false}
                  inputRef={register}
                  onChange={(date) => setSelectedIssueDate(date)}
                  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  margin="dense"
                  inputVariant="outlined"
                  id="due_date"
                  name="due_date"
                  label="Due Date"
                  value={selectedDueDate}
                  openTo="year"
                  format="yyyy-MM-dd"
                  size="small"
                  views={["year", "month", "date"]}
                  error={errors.due_date ? true : false}
                  helperText={errors.due_date ?? false}
                  inputRef={register}
                  onChange={(date) => setSelectedDueDate(date)}
                  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
            </Grid>
          </Grid>
          {type && (
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormControlLabel
                control={<Checkbox id="usePayedaNumber" checked={props.usePayedaInvoiceNumber} onChange={() => props.setUsePayedaInvoiceNumber(!props.usePayedaInvoiceNumber)} />}
                label="Use Payeda invoice number"
              />
            </Grid>
          )}

          {props.usePayedaInvoiceNumber && <Typography>{props.nextInvoiceNumber}</Typography>}
          {(!type || !props.usePayedaInvoiceNumber) && (
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField
                label="# Invoice Number"
                name="invoice_number"
                inputRef={register({
                  minLength: 2,
                })}
                error={errors.invoice_number && true}
                helperText={errors.invoice_number && "Invalid Input"}
                size="small"
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6} lg={4} style={{ marginLeft: "-60px", marginTop: "50px" }}>
            {createProductButton()}{" "}
          </Grid>

          <ProductDialog
            open={createProductDialog}
            setOpen={setCreateProductDialogOpen}
            title="Create Product"
            description="Create a new product."
            onSubmit={onCreateNew}
            errors={errors}
          />
          <div style={{ marginTop: "50px" }}>
            <AddInvoiceLineComponent
              errors={errors}
              loading={loading}
              products={products}
              invoiceLines={invoiceLines}
              setInvoiceLines={setInvoiceLines}
              onInvoiceLineAdd={handleInvoiceLineAdd}
              totalAmount={totalAmount}
              totalRateOfVat={totalRateOfVat}
              totalDiscount={totalDiscount}
            />
          </div>
          {invoiceLines.length > 0 && (
            <>
              <SimpleTable data={invoiceLines} setData={setInvoiceLines} headCells={headCells} products={products} canDelete={true} />
            </>
          )}
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12} md={4} lg={4}>
              <p>
                Amount:
                {currency(totalSubtotalAmount).format({ symbol: "" })}
              </p>
              <p>Total Amount: {currency(totalAmount).format({ symbol: "" })}</p>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox id="invoiceImageCheckbox" checked={addInvoiceImage} onChange={() => setAddInvoiceImage(!addInvoiceImage)} />}
              label="Add Invoice Image"
            />
          </Grid>
          {addInvoiceImage && (
            <Grid item xs={12} container>
              <UploadComponent label="Image of the invoice" name="invoiceImage" id="invoiceImage" register={register} errors={errors} />
            </Grid>
          )}
          <Button variant="contained" color="primary" style={{ margin: 20 }} onClick={handleSubmit((data) => handleCalculateAmount(data))} fullWidth>
            Add
          </Button>
        </FormControl>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default CreateInvoiceComponent;
