import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from 'react-flags-select';

const DropdownLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('');

  const changeLanguage = lng => {
    setLanguage(lng)
    let lang = lng.toLowerCase();
    i18n.changeLanguage(lang);
  };

  return (
    <ReactFlagsSelect
        countries={["TR", "GB"]}
        customLabels={{"TR": "TR-TUR","GB": "EN-GB"}}
        placeholder="Select Language"
        showSelectedLabel={true}
        onSelect={(code)=> changeLanguage(code)}
        selected={language}
    />
  );
};

export default DropdownLanguage;
