import { Button, Divider, Paper, TextField, Typography, Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkoutPaymentRequest } from "../../store/actions/bankingActions";
import { useUiContext } from "./../../Contexts/UiContext";
import { UI_API_BASE, API_BASE } from "./../../store/env";
import { useCheckoutStyles } from "./InvoiceStyles";
import InvoicePDF from "./InvoicePDF";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useHistory } from "react-router-dom";

const ViewInvoice = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState({});
  const [paymentLink, setPaymentLink] = useState("");

  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { errors, setErrors } = useUiContext();
  const classes = useCheckoutStyles();
  const location = useLocation();

  const getInvoiceDetails = async (invoiceId) => {
    let request = await axios.get(`${API_BASE}/invoices/payment-request`, {
      params: {
        invoiceId: invoiceId,
      },
    });
    if (request.status === 200) {
      let invoice = request.data;
      setInvoiceDetails({ ...invoice, id: invoiceId });
      setLoading(false);
      return invoice;
    } else {
      setLoading(false);
      setErrors({ general: "Wrong link submitted" });
      return null;
    }
  };

  const getBusinessDetails = async (customerId, supplierId) => {
    setLoading(true);

    var customerRequest = await axios.post(`${API_BASE}/get-business-details`, { id: customerId });
    var supplierRequest = await axios.post(`${API_BASE}/get-business-details`, { id: supplierId });

    if (customerRequest.status === 200) {
      let customerDetails = customerRequest.data;

      setValue("email", customerDetails.director?.email);
      setValue("phone", customerDetails.director?.phone);
      setLoading(false);

      setCustomerDetails({ ...customerDetails, id: customerId });
    }

    if (supplierRequest.status === 200) {
      let supplierDetails = supplierRequest.data;
      setLoading(false);

      setSupplierDetails({ ...supplierDetails, id: supplierId });
    }
  };

  useEffect(() => {
    setLoading(true);

    var id = new URLSearchParams(location.search).get("id");
    (async () => {
      if (id) {
        var invoice = await getInvoiceDetails(id);
        if (invoice) {
          await getBusinessDetails(invoice.customer, invoice.supplier);
        } else {
          //Invoice not found / Invoice id not provided
        }
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (invoiceDetails) {
      let id = invoiceDetails.id;
      setPaymentLink(`${UI_API_BASE}/checkout?id=${id}`);
    }
    // eslint-disable-next-line
  }, [invoiceDetails]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width >= 1280) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, [isMobile]);

  return (
    <>
      {loading && <h4>Loading...</h4>}

      {!invoiceDetails.amount && !loading && <h2>No invoice found</h2>}
      <div style={{ float: "right", marginRight: "15px", marginBottom: "15px" }}>
        {invoiceDetails.amount && paymentLink && (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#4169E1",
              color: "white",
              width: "150px",
            }}
            onClick={() => window.location.replace(paymentLink)}
          >
            Pay now
          </Button>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} lg={12} md={12}>
          {invoiceDetails && invoiceDetails?.amount && (
            <>
              {!isMobile && (
                <PDFViewer width="100%" height="1000">
                  <InvoicePDF invoiceData={invoiceDetails} isCheckout={true} />
                </PDFViewer>
              )}
              <div
                style={{
                  float: "right",
                  marginRight: "15px",
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
              >
                {isMobile && (
                  <PDFDownloadLink
                    document={<InvoicePDF invoiceData={invoiceDetails} />}
                    fileName={invoiceDetails.invoice_number}
                    style={{
                      textDecoration: "none",
                      padding: "16px",
                      color: "#4a4a4a",
                      backgroundColor: "#f2f2f2",
                      border: "1px solid #4a4a4a",
                      width: "120px",
                      height: "50px",
                    }}
                  >
                    {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download Now")}
                  </PDFDownloadLink>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewInvoice;
