import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../Components/BasePage";
import { addProduct, editProduct, getAllProducts, removeProducts } from "../../store/actions/productActions";
import ConfirmDeleteDialog from "./../../Components/Dialogs/ConfirmDeleteDialog";
import { useUiContext } from "./../../Contexts/UiContext";
import verifySchema from "./../../util/yup/verifySchema";
import { productSchema } from "./../../util/yup/yupSchemas";
import ProductDialog from "./ProductDialog";
import ProductsTable from "./ProductsTable";

const Products = ({ business }) => {
  const dispatch = useDispatch();

  const { errors, setErrors, clearErrors } = useUiContext();

  const [selected, setSelected] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const products = useSelector((state) => state.product);
  const isLastPage = useSelector((state) => state.business.lastPage);
  const user = useSelector((state) => state.user);
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getAllProducts());
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const onCreateNew = async (data) => {
    data.currency = "GBP";
    const valid = await verifySchema(productSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateDialogOpen(false);
      dispatch(addProduct(data));
    }
  };

  const onEdit = async (data) => {
    const valid = await verifySchema(productSchema, data, clearErrors, setErrors);
    if (valid) {
      const dataToSend = { id: selected[0].id, ...data };
      setEditDialogOpen(false);
      dispatch(editProduct(dataToSend));
      setSelected([]);
    }
  };

  const onDelete = async () => {
    let productsToDelete = selected.map((prod) => prod.id);
    removeProducts({ products: productsToDelete });
    setSelected([]);
    setDeleteDialogOpen(false);
  };

  return (
    <BasePage navigation="Dashboard/Products" business={business} canView={user?.permissions?.product?.view}>
      <div>
        <ProductsTable
          products={products.products}
          selected={selected}
          setSelected={setSelected}
          setCreateDialogOpen={setCreateDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          isLoading={products.isLoading}
          getAllData={getAllProducts}
          islastPage={isLastPage}
          business={business}
        />

        <ProductDialog open={createDialogOpen} setOpen={setCreateDialogOpen} title="Create Product" description="Create a new product." onSubmit={onCreateNew} errors={errors} />

        <ProductDialog
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          title="Edit Product"
          description="Edit existing product."
          onSubmit={onEdit}
          errors={errors}
          defaultValues={selected[0]}
        />

        <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} />
      </div>
    </BasePage>
  );
};

export default Products;
