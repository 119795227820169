import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import TableComponent from "../../../Components/Table/TableComponent";
import { getAllUsers } from "../../../store/actions/adminActions";

const headCells = [
  { id: "fullName", label: "Full Name" },
  { id: "email", label: "E-mail" },
  { id: "emailVerified", label: "Email Verified" },

  { id: "businessId", label: "Business" },
];

const UsersTable = ({ selected, setSelected, allUsers, isLoading }) => {
  const dispatch = useDispatch();

  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getAllUsers());
    }
    // eslint-disable-next-line
  }, [afterRender]);

  return (
    <TableComponent
      data={allUsers}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      isLoading={isLoading}
      getAllData={getAllUsers}
      shouldGetData={false}
      shouldView={true}
    />
  );
};

export default UsersTable;
