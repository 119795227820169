import React from 'react';
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";

import TableComponent from '../../Components/Table/TableComponent';
import MembersTableToolbar from './MembersTableToolbar';

const headCells = [
    { id: "name", label: "Name" },
    { id: "surname", label: "Surname" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "isActive", label: "Status"}
];

const searchOptions = [
    { default: true, id: "company_name_lower", label: "Company Name" },
    { id: "full_name_lower", label: "Representative Name" },
    { id: "erp_no_lower", label: "Erp Number"}
];

const MembersTable = ({ members, selected, setSelected, setCreateDialogOpen, setEditDialogOpen, setDeleteDialogOpen, isLoading, isLastPage, getAllData }) => {
    const { permissions } = usePermissionsContext();

    const renderToolbar = () => {
        return (
            <MembersTableToolbar
                numSelected={selected ? selected.length : 0}
                header="Members"
                setCreateDialogOpen={setCreateDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                setDeleteDialogOpen={setDeleteDialogOpen}
                permissions={permissions}
            />
        )
    }

    return (
        <TableComponent
            toolbar={permissions?.member ? renderToolbar(): false}
            data={members}
            selected={selected}
            setSelected={setSelected}
            headCells={headCells}
            searchOptions={searchOptions}
            isLoading={isLoading}
            isLastPage={isLastPage}
            getAllData = {getAllData}
            searchBarText="Search Members"
            shouldGetData={true}
            shouldPaginate={true}
            shouldView={permissions?.member ? permissions.member?.view : false}

        />
    )
}
export default MembersTable
