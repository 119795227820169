import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import { fetchCloudBankAccounts, updateCloudErpSettings } from "../../store/actions/invoiceActions";

const CloudErpTenantsDialog = ({
  open,
  setOpen,
  title,
  description,

  business,
  setErrors,
  userTenant,
  setUserTenant,
  selectedTenant,
  setTenantName,
}) => {
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState("xero");

  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);

  let isLoading = useSelector((state) => state.invoice.isLoading);
  let businessLoading = useSelector((state) => state.user.isLoading);
  let tenants = useSelector((state) => state.invoice.tenants);
  let cloudBankAccounts = useSelector((state) => state.invoice.cloudBankAccounts);

  const onSubmit = (data) => {
    if (value === "xero") {
      if (!cloudBankAccounts && tenants.length > 0) {
        setUserTenant(data.tenantId);
        let findTenantName = tenants.find((t) => t.tenantId === data.tenantId);

        setTenantName(findTenantName.tenantName);
        let dataToSend = {
          tenantId: data.tenantId,
          application: "xero",
        };
        // TODO: check if re-connecting xero and uses the same tenant ID
        if (business?.account_settings?.invoice_settings?.cloud_app?.canReConnect) {
          if (data.tenantId !== business?.account_settings?.invoice_settings?.cloud_app.tenant_id) {
            setErrors({ general: "You can only connect previous Xero account that connected before" });
            return false;
          }
        }
        dispatch(fetchCloudBankAccounts(dataToSend));
      }

      if (cloudBankAccounts && cloudBankAccounts.length > 0 && selectedBankAccountId) {
        let findBankAcctDetails = cloudBankAccounts.find((acct) => acct.AccountID === selectedBankAccountId);
        const now = new Date();

        // UTC Date String
        now.toUTCString(); // "Sun, 30 May 2021 14:59:15 GMT"

        // ISO String
        now.toISOString(); // "2021-05-30T14:59:15.449Z"

        let valid = false;
        if (findBankAcctDetails) {
          if (findBankAcctDetails.CurrencyCode !== "GBP") {
            setErrors({
              general: `Selected Bank Account currency should be GBP, but you chose ${findBankAcctDetails.CurrencyCode}`,
            });
            valid = false;
            return false;
          } else {
            valid = true;
          }
        }

        let dataToSubmit = {
          invoice_settings: {
            cloud_app: {
              isSynced: true,
              application: "xero",
              bank_account_id: selectedBankAccountId,
              tenant_id: userTenant,
              tenant_name: selectedTenant,
              updatedAt: now,
              bank_account_details: {
                ...findBankAcctDetails,
              },
            },
          },
        };
        if (valid && findBankAcctDetails) {
          dispatch(updateCloudErpSettings(dataToSubmit));
          setOpen(false);
        }
      }
    }
  };

  const getTenants = () => {
    if (tenants.length > 0) {
      var tenantsResult = tenants.map((connection) => {
        return {
          value: connection.tenantId,
          label: connection.tenantName,
        };
      });

      return tenantsResult;
    }
  };

  const renderTenants = () => {
    return (
      <>
        <h3>Choose your account to get invoices from</h3>

        <DropdownComponent
          required
          id="tenantId"
          name="tenantId"
          label="Choose your account to get invoices from"
          items={tenants.length > 0 && getTenants()}
          // error={errors.tenantId ? true : false}
          // helperText={errors.tenantId ?? ""}
          control={control}
        />
      </>
    );
  };

  const fetchBankAccounts = () => {
    let accounts = [];
    if (cloudBankAccounts && cloudBankAccounts.length > 0) {
      accounts = cloudBankAccounts.map((acct) => {
        return {
          value: acct.AccountID,
          label: acct.Name + " - " + acct.CurrencyCode,
        };
      });
      return accounts;
    }
  };
  const renderBankAccounts = () => {
    return (
      <>
        {" "}
        <h3>Select your bank account to receive payments into</h3>
        <DropdownComponent
          required
          id="bankAccountId"
          name="bankAccountId"
          label="Choose your bank account to sync invoices"
          items={cloudBankAccounts && fetchBankAccounts()}
          onChange={(e) => setSelectedBankAccountId(e.target.value)}
          // error={errors.tenantId ? true : false}
          // helperText={errors.tenantId ?? ""}
          control={control}
        />
      </>
    );
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit((data) => onSubmit(data))}
      withActions={business?.account_settings?.invoice_settings?.cloud_app?.isSynced ? false : true}
    >
      {isLoading ? (
        "Loading"
      ) : (
        <>
          {isLoading && businessLoading && "Fetching your information..."}
          {business?.account_settings?.invoice_settings?.cloud_app?.isSynced ? (
            "synced"
          ) : (
            <>
              <div style={{ width: "380px", minHeight: "150px" }}>
                {!isLoading && tenants && tenants?.length > 0 && (!cloudBankAccounts || cloudBankAccounts.length < 1) && renderTenants()}

                {cloudBankAccounts && cloudBankAccounts.length === 0 && (
                  <>
                    <Typography>Sorry, we couldn't find a bank account to receive payments into in your Xero account...</Typography>

                    <Typography>Please add a bank account in Xero to start receiving payments</Typography>
                    {/* <Typography>Once you add an account you can restart the process!</Typography> */}
                  </>
                )}
                {cloudBankAccounts && cloudBankAccounts.length > 0 && renderBankAccounts()}
              </div>
            </>
          )}
        </>
      )}
    </DialogComponent>
  );
};

export default CloudErpTenantsDialog;
