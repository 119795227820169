import React, { useState } from "react";
import { useSelector } from "react-redux";

import UsersTable from "./UsersTable";

const Users = () => {
  const allUsers = useSelector((state) => state.admin.users);
  const isLoading = useSelector((state) => state.admin.isLoading);
  const [selected, setSelected] = useState([]);

  return (
    <UsersTable
      selected={selected}
      setSelected={setSelected}
      allUsers={allUsers}
      isLoading={isLoading}
    />
  );
};

export default Users;
