import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";

const BusinessFilesDialog = ({ open, setOpen, title, description, business }) => {
  // const renderRequestMissingFiles = () => {
  //   return (
  //     <>
  //       <Grid item xs={3} style={{ color: "red" }}>
  //         Company didn't provide it
  //       </Grid>
  //       {/* <Grid item xs={3}>
  //         <Button
  //           variant="outlined"
  //           color="secondary"
  //           style={{ padding: 0, fontSize: "small" }}
  //           onClick={() => handleRequest()}
  //         >
  //           Request
  //         </Button>
  //       </Grid> */}
  //     </>
  //   );
  // };

  // let renderDownloadFiles = (fileName) => {
  //   return (
  //     <Link
  //       component="button"
  //       variant="body2"
  //       onClick={() => {
  //         window.open(fileName);
  //       }}
  //     >
  //       Download the file
  //     </Link>
  //   );
  // };

  const renderDirectorDocuments = () => {
    let fields = [];
    let i = 1;
    for (let director of business.directors) {
      fields.push(
        <>
          <Grid xs={12} style={{ marginTop: "10px" }}>
            <b>Director {i}</b>
          </Grid>
          <Grid xs={12}>
            <Typography>{director.full_name}</Typography>
          </Grid>
          <Grid xs={6}>Proof of address</Grid>
          <Grid xs={6}>
            {director.address_proof ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.address_proof);
                }}
              >
                Download the proof of address
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Front Page of ID</Grid>
          <Grid xs={6}>
            {director.id_front_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_front_page);
                }}
              >
                Download the Front page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Rear Page of ID</Grid>
          <Grid xs={6}>
            {director.id_rear_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_rear_page);
                }}
              >
                Download the Rear page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
        </>
      );
      i++;
    }
    return fields;
  };
  const renderShareholderDocuments = () => {
    let fields = [];
    let i = 1;
    for (let director of business.shareholders) {
      fields.push(
        <>
          <Grid xs={12}>
            <b>Shareholder {i}</b>
          </Grid>
          <Grid xs={12}>
            <Typography>{director.full_name}</Typography>
          </Grid>
          <Grid xs={6}>Proof of address</Grid>
          <Grid xs={6}>
            {director.address_proof ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.address_proof);
                }}
              >
                Download the proof of address
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Front Page of ID</Grid>
          <Grid xs={6}>
            {director.id_front_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_front_page);
                }}
              >
                Download the Front page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Rear Page of ID</Grid>
          <Grid xs={6}>
            {director.id_rear_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_rear_page);
                }}
              >
                Download the Rear page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
        </>
      );
      i++;
    }
    return fields;
  };

  const renderCompanyFiles = () => {
    return (
      <>
        <Grid xs={6}>
          <Typography>Bank Statement</Typography>
        </Grid>
        <Grid xs={6}>
          {business.company_details.bank_statement ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.bank_statement);
              }}
            >
              Download the bank statement
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>
        <Grid xs={6}>
          <Typography>Company Certificate</Typography>
        </Grid>
        <Grid xs={6}>
          {business.company_details.company_certificate ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.company_certificate);
              }}
            >
              Download the Company certificate
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>

        <Grid xs={6}>
          {business.company_details.id_front_page ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.id_front_page);
              }}
            >
              Download the Front Page of ID
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>

        <Grid xs={6}>
          {business.company_details.id_rear_page ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.id_rear_page);
              }}
            >
              Download the Rear Page of ID
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>
      </>
    );
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description={description} withActions={false}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {business?.company_details?.company_reg_number && (
            <Typography>
              <strong>Company Registration Number:</strong> {business?.company_details?.company_reg_number}
            </Typography>
          )}
        </Grid>
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          Directors:
        </Typography>
        {renderDirectorDocuments()}
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          Shareholders:
        </Typography>
        {renderShareholderDocuments()}
        <Grid xs={12}>
          <Typography variant="h6" style={{ marginTop: "10px" }}>
            Company Files:
          </Typography>
        </Grid>

        {renderCompanyFiles()}
      </Grid>
      {/* <Grid container spacing={3} justify="left" alignItems="left">
        <Grid item xs={6}>
          Board Decision:
        </Grid>
        {boardDecision === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(boardDecision)}
        <Grid item xs={6}>
          Front side of ID
        </Grid>
        {idFront === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(idFront)}

        <Grid item xs={6}>
          Rear side of ID
        </Grid>
        {idRear === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(idRear)}

        <Grid item xs={6}>
          Tax signboard
        </Grid>
        {taxSignboard === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(taxSignboard)}

        <Grid item xs={6}>
          signature Circular
        </Grid>
        {signatureCircular === ""
          ? renderRequestMissingFiles()
          : renderDownloadFiles(signatureCircular)}
      </Grid> */}
    </DialogComponent>
  );
};

export default BusinessFilesDialog;
