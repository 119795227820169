import React, { useState } from "react";
import { Zoom } from "react-awesome-reveal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Avatar,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useUiContext } from "../../../Contexts/UiContext";
import { invitationSignup } from "../../../store/actions/userActions";
import { invitationSignupSchema } from "../../../util/yup/yupSchemas";
import verifySchema from "./../../../util/yup/verifySchema";
import { useInvitationSignupStyles } from "./../LoginStyles";
import BackToHome from "../BackToHome";

//TODO Use translations rather than hardcoded text

const InvitationSignup = ({ history }) => {
  const classes = useInvitationSignupStyles();
  const { errors, setErrors, clearErrors } = useUiContext();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [conditionsAccepted, setConditionsAccepted] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ValidationType = ({ type }) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <Typography className={classes.errorMessage} style={{ color: "red" }}>
          {ErrorMessage}
        </Typography>
      </Zoom>
    );
  };

  const onSubmit = async (data) => {
    data.termsAndConditionAccepted = conditionsAccepted;
    let valid = await verifySchema(invitationSignupSchema, data, clearErrors, setErrors);
    if (valid) {
      dispatch(invitationSignup(data, history));
    }
  };

  const getInvitationCode = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get("code");
    return code;
  };

  const renderAccountTypesRadio = () => {
    return (
      <RadioGroup
        // valueSelected={this.state.accountType}
        aria-label="accountType"
        name="accountType"
        id="accountType"
        inputRef={register}
        defaultValue="Business"
        row
      >
        <FormControlLabel value="Business" control={<Radio />} label="Business" inputRef={register} />
        <FormControlLabel value="Personal" control={<Radio />} label="Personal" inputRef={register} />
      </RadioGroup>
    );
  };

  const renderTermsAndConditions = () => {
    return (
      <div>
        {errors.termsAndConditionAccepted !== undefined && (
          <div>
            <span id="access-code-error" style={{ color: "red" }}>
              {errors.termsAndConditionAccepted}
            </span>
          </div>
        )}
        <FormControlLabel
          control={
            <Checkbox
              id="termsAndConditionAccepted"
              name="termsAndConditionAccepted"
              checked={conditionsAccepted}
              onChange={() => setConditionsAccepted(!conditionsAccepted)}
              color="primary"
            />
          }
          label={
            <div>
              <span style={{ fontSize: "12px" }}>accept the </span>
              <a style={{ fontSize: "15px", fontWeight: "bold" }} href="assets/Payeda_End_User_Terms_of_Service.pdf" target="_blank">
                terms of use
              </a>
              <span style={{ fontSize: "12px" }}> and </span>
              <a style={{ fontSize: "15px", fontWeight: "bold" }} href="assets/Payeda_Privacy Policy.pdf" target="_blank">
                privacy policy
              </a>
            </div>
          }
        />
      </div>
    );
  };
  return (
    <>
      <BackToHome />
      <Grid container component="main" className={classes.root} spacing={5}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} elevation={1} square style={{ paddingRight: "30px" }}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <FormControl className={classes.form}>
              <TextField
                required
                variant="outlined"
                id="inviteCode"
                name="inviteCode"
                label="Invitation Code"
                error={errors.inviteCode ? true : false}
                helperText={errors.inviteCode ?? ""}
                inputRef={register}
                defaultValue={getInvitationCode()}
              />
              <br />
              <TextField variant="outlined" required id="name" name="name" label="Name" error={errors.name ? true : false} helperText={errors.name ?? ""} inputRef={register} />
              <br />
              <TextField
                variant="outlined"
                required
                id="surname"
                name="surname"
                label="Surname"
                error={errors.surname ? true : false}
                helperText={errors.surname ?? false}
                inputRef={register}
              />
              <br />

              <TextField
                variant="outlined"
                required
                id="email"
                name="email"
                label="Email"
                error={errors.email ? true : false}
                helperText={errors.email ?? false}
                inputRef={register}
              />
              <br />
              <TextField
                required
                fullWidth
                variant="outlined"
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                error={errors.password ? true : false}
                inputRef={register}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <ValidationType type="password" />
              <TextField
                required
                variant="outlined"
                id="confirmPassword"
                name="confirmPassword"
                label="Repeat Password"
                type="password"
                error={errors.confirmPassword ? true : false}
                helperText={errors.confirmPassword ?? false}
                inputRef={register}
              />
              <br />
              {renderAccountTypesRadio()}
              {/* <TextField
                        required
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        error={errors.password ? true : false}
                        helperText={errors.password ?? false}
                        inputRef={register}
                    /> */}
              {renderTermsAndConditions()}
              <Button variant="contained" color="secondary" onClick={() => handleSubmit(onSubmit)()} className={classes.submit}>
                Sign Up
              </Button>
            </FormControl>
          </div>
          <div style={{ marginTop: "15px", textAlign: "center", cursor: "pointer" }}>
            <ul style={{ listStyle: "none" }}>
              <li>
                <Link
                  underline="hover"
                  onClick={() => {
                    history.push("/signin");
                  }}
                >
                  Have an account? Sign in
                </Link>
              </li>
              <li>
                <Link
                  underline="hover"
                  onClick={() => {
                    history.push("/signup");
                  }}
                >
                  Sign up with an invitation code
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(InvitationSignup);
