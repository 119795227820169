import axios from "axios";
import { FINANCING_API_BASE, API_BASE } from "../env";
import { setErrors, setSuccess } from "./uiActions";
import { getBusinessDetails } from "./businessActions";

export const getAllFinancingInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: "FINANCING_INVOICES_DATA_FETCH_START" });

    let invoices = await axios.get(`${API_BASE}/financing/invoices`);

    dispatch({ type: "FINANCING_INVOICES_DATA_FETCHED", payload: invoices.data.result });
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const requestFinancingOnInvoices = (details) => async (dispatch) => {
  try {
    dispatch({ type: "FINANCING_INVOICES_DATA_FETCH_START" });

    await axios.get(`${API_BASE}/financing/financing-request`);
    dispatch(getAllFinancingInvoices());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const updateBidDate = (details) => async (dispatch) => {
  try {
    await axios.put(`${API_BASE}/financing/update-bid`, details);

    dispatch(getAllFinancingInvoices());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllFinancingOffers = () => async (dispatch) => {
  try {
    let offers = await axios.get(`${API_BASE}/financing/supplier/offers`);

    dispatch({ type: "FINANCING_OFFERS", payload: offers.data });
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const acceptOffer = (body) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/financing/supplier/accept-offer`, body);
    dispatch(setSuccess("Accepted offer"));
    dispatch(getAllFinancingOffers());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors(err.response.data));
    } else {
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateBalanceSheetAndChargesList = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "put",
      url: `${API_BASE}/financing/update-balance-sheet`,
      data: details,
    });
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
