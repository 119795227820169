import React, { useState, useEffect } from "react";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import currency from "currency.js";
import { AsyncPaginate } from "react-select-async-paginate";

import BasePage from "../../../../Components/BasePage";
import TableComponent from "../../../../Components/Table/TableComponent";
import DropdownComponent from "../../../../Components/DropdownComponent";

import { useForm } from "react-hook-form";
import {
  getAllBusinesses,
  getAllPayedaTransactions,
} from "../../../../store/actions/adminActions";

import loadOptions from "../../../../Components/DropDown/loadOptions";

const headCells = [
  { id: "date", label: "Date" },
  { id: "amount", label: "Amount" },

  { id: "beneficiary_name", label: "Beneficiary Name" },
  { id: "beneficiary_email", label: "Beneficiary Mail" },

  { id: "remitter_name", label: "Remitter Name" },
  { id: "remitter_mail", label: "Remitter Mail" },
];

const searchItems = [
  {
    value: "beneficiary",
    label: "As Beneficiary",
  },
  {
    value: "remitter",
    label: "As Remitter",
  },
];

const TransactionsByUser = ({ business }) => {
  const [selected, setSelected] = useState([]);
  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [selectedSearchType, setSelectedSearchType] = useState(null);
  const [businessesForSelectBox, setBusinessesForSelectbox] = useState([]);

  const [value, onChange] = useState(null);

  let dispatch = useDispatch();
  let { register, errors, control } = useForm();

  let isLoading = useSelector((state) => state.admin.isLoading);
  let allTransactions = useSelector((state) => state.admin.allTransactions);

  let allBusinesses = useSelector((state) => state.admin.businessDetails);

  const handleGetData = () => {
    let queryStrings = {
      type: selectedSearchType,
      date_gt: selectedIssuDate.toJSON().slice(0, 10),
      date_lt: selectedDueDate.toJSON().slice(0, 10),
    };

    dispatch(getAllPayedaTransactions({ ...queryStrings }));
  };

  useEffect(() => {
    if (selectedSearchType !== null) {
      handleGetData();
    }
  }, [selectedDueDate, selectedIssuDate, selectedSearchType]);

  useEffect(() => {
    dispatch(getAllBusinesses());
  }, []);

  useEffect(() => {
    if (allBusinesses.length > 0) {
    }
  }, [allBusinesses]);
  const renderTotalAmount = () => {
    return (
      <div>
        <p>Total Amount : {allTransactions.totalAmount}</p>
        <p>0.7 % of Total Amount : {allTransactions.percentage7}</p>
        {/** TODO: % 20 VAT ile çarp */}
        <p>
          0.3 % of Total Amount : {allTransactions.percentage3} - % 20 VAT
          Included Amount:{" "}
          {currency(allTransactions.percentage3)
            .multiply(1.2)
            .format({ symbol: "" })}
        </p>
      </div>
    );
  };
  const renderCriteriaSelection = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <AsyncPaginate
            value={value}
            loadOptions={loadOptions}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <DropdownComponent
            required
            fullWidth
            id="bankAccountId"
            name="bankAccountId"
            label="Select a search type"
            items={searchItems}
            onChange={(e) => {
              setSelectedSearchType(e.target.value);
            }}
            error={errors.bankAccountId ? true : false}
            helperText={errors.bankAccountId ?? ""}
            control={control}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BasePage navigation="Dashboard/All Transcations" business={business}>
        {!isLoading &&
          allTransactions &&
          allTransactions.allData.length > 0 &&
          renderTotalAmount()}
        {renderCriteriaSelection()}
        <TableComponent
          data={[]}
          isLoading={isLoading}
          selected={selected}
          setSelected={setSelected}
          headCells={headCells}
          shouldGetData={false}
          shouldView={true}
        />
      </BasePage>
    </MuiPickersUtilsProvider>
  );
};

export default TransactionsByUser;
