import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import DialogComponent from "../../Components/Dialogs/DialogComponent";

const customStyles = {
  menu: () => ({
    backgroundColor: "white",
  }),
};

const ContactDialog = ({ open, setOpen, title, description, errors, onSubmit, setSelectedCustomerGroupId, selectedCustomerGroupId, renderContactGroups }) => {
  const { register, control, handleSubmit, reset } = useForm();

  return (
    <DialogComponent open={open} setOpen={setOpen} title={title} description={description} onSubmit={handleSubmit(onSubmit)} withActions={true}>
      <div style={{ width: "450px" }}>
        <Typography>Choose from the list</Typography>
        {renderContactGroups}
        <br />
        <Typography>Or Enter new contact group name</Typography>

        {/* <TextField
          margin="dense"
          variant="outlined"
          required
          autoFocus
          id="new_contact_name"
          name="new_contact_name"
          label="Enter new contact name"
          error={errors.new_contact_name ? true : false}
          helperText={errors.new_contact_name ?? ""}
          fullWidth
          inputRef={register}
        /> */}
      </div>
    </DialogComponent>
  );
};

export default ContactDialog;
