
const initialState = {
    isLoading: false,
    members: [],
    errorMessage: null,
    
};

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case "MEMBER_DATA_FETCH_STARTED":
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
            };

        case "MEMBER_DATA_FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                members: action.payload,
            };
        case  "SINGLE_MEMBER_DATA_FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                member: action.payload,
            }
        default:
            return state;
    }
}