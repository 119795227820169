import DateFnsUtils from "@date-io/date-fns";
import { Grid, Button } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../../Components/BasePage";
import DropdownComponent from "../../../../Components/DropdownComponent";
import TableComponent from "../../../../Components/Table/TableComponent";
import { getAllPayedaTransactions } from "../../../../store/actions/adminActions";

const headCells = [
  { id: "date", label: "Date" },
  { id: "amount", label: "Amount" },

  { id: "beneficiary_name", label: "Beneficiary Name" },
  { id: "beneficiary_email", label: "Beneficiary Mail" },

  { id: "remitter_name", label: "Remitter Name" },
  { id: "remitter_mail", label: "Remitter Mail" },
];

const searchItems = [
  {
    value: "package_payment",
    label: "Package Payments",
  },
  {
    value: "transfer_money",
    label: "Transfer Money",
  },
  {
    value: "invoice_payment",
    label: "Invoice Payments",
  },
];

const Transactions = ({ business }) => {
  const [selected, setSelected] = useState([]);
  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [selectedSearchType, setSelectedSearchType] = useState(null);

  let dispatch = useDispatch();
  let { errors, control } = useForm();

  let isLoading = useSelector((state) => state.admin.isLoading);
  let allTransactions = useSelector((state) => state.admin.allTransactions);

  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  const handleGetData = async () => {
    if ((await isValidDate(selectedIssuDate)) && (await isValidDate(selectedDueDate))) {
      let queryStrings = {
        type: selectedSearchType,
        date_gt: selectedIssuDate?.toJSON().slice(0, 10),
        date_lt: selectedDueDate?.toJSON().slice(0, 10),
      };

      dispatch(getAllPayedaTransactions({ ...queryStrings }));
    }
  };


  const renderTotalAmount = () => {
    return (
      <div>
        <p>Total Amount : {allTransactions.totalAmount}</p>
        <p>0.7 % of Total Amount : {allTransactions.percentage7}</p>
        {/** TODO: % 20 VAT ile çarp */}
        <p>
          0.3 % of Total Amount : {allTransactions.percentage3} - % 20 VAT Included Amount:{" "}
          {currency(allTransactions.percentage3)
            .multiply(1.2)
            .format({ symbol: "" })}
        </p>
      </div>
    );
  };
  const renderCriteriaSelection = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <KeyboardDatePicker
            inputVariant="outlined"
            margin="dense"
            id="issued_date"
            name="issued_date"
            label="From"
            value={selectedIssuDate}
            openTo="year"
            format="yyyy-MM-dd"
            size="small"
            views={["year", "month", "date"]}
            onChange={(date) => setSelectedIssueDate(date)}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />{" "}
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={3}>
          <KeyboardDatePicker
            margin="dense"
            inputVariant="outlined"
            id="due_date"
            name="due_date"
            label="To"
            value={selectedDueDate}
            openTo="year"
            format="yyyy-MM-dd"
            size="small"
            views={["year", "month", "date"]}
            onChange={(date) => setSelectedDueDate(date)}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <DropdownComponent
            required
            fullWidth
            id="bankAccountId"
            name="bankAccountId"
            label="Select a search type"
            items={searchItems}
            onChange={(e) => {
              setSelectedSearchType(e.target.value);
            }}
            error={errors.bankAccountId ? true : false}
            helperText={errors.bankAccountId ?? ""}
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button variant="outlined" color="primary" style={{ marginTop: "10px" }} onClick={() => handleGetData()}>
            Get Data
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BasePage navigation="Dashboard/All Transcations" business={business} canView={true}>
        {!isLoading && allTransactions && allTransactions.allData.length > 0 && renderTotalAmount()}
        {renderCriteriaSelection()}
        <TableComponent
          data={allTransactions?.allData}
          isLoading={isLoading}
          selected={selected}
          setSelected={setSelected}
          headCells={headCells}
          shouldGetData={false}
          shouldView={true}
        />
      </BasePage>
    </MuiPickersUtilsProvider>
  );
};

export default Transactions;
