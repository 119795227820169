import { Grid } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import ButtonBase from "../../../Components/shared/ButtonBase";
import InvoicePDF from "../InvoicePDF";
import canPerformAction from "./../../../util/permissions/canPerformAction";
import { sendApprovalRequest } from "../../../store/actions/invoiceActions";
const OutgoingInvoicesFooter = ({
  selected,
  setSelected,
  business,
  permissions,
  setErrors,
  dispatch,
  history,
  selectedCustomer,
  updateInvoiceStatus,
  setDownloadPDF,
  downloadPDF,
  setOpenFinancialInformationsDialog,
}) => {
  const handleInvoiceStatusChange = (action) => {
    let errors = {};

    if (!business.financial_informations || business.financial_informations.length < 1) {
      setOpenFinancialInformationsDialog(true);
    } else {
      let notApprovedInvoices = selected.filter((s) => !s.statusDetails.isApproved);

      let requestableInvoices = selected.filter((s) => s.statusDetails.payment === "Pending" && !s.statusDetails.isFactoringRequested && !s.statusDetails.isDiscountingRequested);

      // todo: Remove comment  on this later

      // if (notApprovedInvoices.length > 0) {
      //   errors = { general: "Please only select approved invoices!" };
      // }

      if (requestableInvoices.length > 0) {
        let requestableIds = [];

        for (let invoice of requestableInvoices) {
          requestableIds.push(invoice.id);
        }

        let dataToSend = {
          request_info: {
            invoice_ids: requestableIds,
            request_type: "factoring",
          },
        };
        dispatch(updateInvoiceStatus(dataToSend, history, selectedCustomer));
      }
    }

    setErrors(errors);
    setSelected([]);
  };

  const handleSendApproval = () => {
    if (selected && selected.length > 0) {
      dispatch(sendApprovalRequest(selected));
    }
  };

  return (
    <Grid container spacing={0}>
      {selected && selected.length === 1 && (
        <Grid item xs={12} sm={3} md={3} lg={3}>
          {selected[0] && downloadPDF ? (
            <>
              <div style={{ marginTop: "15px" }}>
                <PDFDownloadLink
                  document={<InvoicePDF invoiceData={selected[0]} />}
                  fileName={selected[0].invoice_number}
                  style={{
                    textDecoration: "none",
                    padding: "16px",
                    color: "#4a4a4a",
                    backgroundColor: "#f2f2f2",
                    border: "1px solid #4a4a4a",
                    borderRadius: "20px",
                    width: "120px",
                    height: "50px",
                  }}
                >
                  {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download")}
                </PDFDownloadLink>
              </div>
            </>
          ) : (
            <>
              <ButtonBase tooltip="Prepare PDF" text="PDF" backColor="#00AFA1" textColor="white" onClick={() => setDownloadPDF(true)} fontSize="400" />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default OutgoingInvoicesFooter;
