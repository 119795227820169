import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import BasePage from "../../../Components/BasePage";
import { getUsersAssignedPackages } from "../../../store/actions/adminActions";
import BusinessesToolbar from "./BusinessesToolbar";
import PackageDetailsDialog from "./PackageDetailsDialog";
import VerifiedBusinessesTable from "./VerifiedBusinessesTable";
import { useEffect } from "react";

const BusinessesAssignedPackages = ({ denyBusiness, business }) => {
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const [businessPackagesDialogOpen, setOpenBusinessPackagesDialog] = useState(false);

  const verifiedBusinesses = useSelector((state) => state.admin.allUsersWithAssignedPackages);

  //   const renderToolbar = () => {
  //     return (
  //       <BusinessesToolbar
  //         numSelected={selected ? selected.length : 0}
  //         header="Verified Businesses"
  //         setBusinessFilesDialogOpen={setBusinessFilesDialogOpen}
  //         selected={selected[0]}
  //         handleDenyBusiness={handleDenyBusiness}
  //       />
  //     );
  //   };

  useEffect(() => {
    dispatch(getUsersAssignedPackages());
  }, [])

  return (
    <BasePage navigation="Dashboard/Users-Assigned-Packages" business={business} canView={true}>
      {selected.length === 1 && (
        <Button color="primary" style={{ float: "right" }} onClick={() => setOpenBusinessPackagesDialog(true)} variant="contained">
          See Package Details
        </Button>
      )}

      <VerifiedBusinessesTable
        // toolbar={renderToolbar()}
        businessesToDeny={verifiedBusinesses}
        selected={selected}
        setSelected={setSelected}
      />

      {verifiedBusinesses && selected.length > 0 && (
        <PackageDetailsDialog open={businessPackagesDialogOpen} selected={selected} setSelected={setSelected} setOpen={setOpenBusinessPackagesDialog} business={selected[0]} />
      )}
    </BasePage>
  );
};

export default BusinessesAssignedPackages;
