import React from 'react';
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

import TableToolbarComponent from './../../Components/Table/TableToolbarComponent';

const MembersTableToolbar = ({ numSelected, header, setCreateDialogOpen, setEditDialogOpen, setDeleteDialogOpen }) => {

    return (
        <TableToolbarComponent
            header={header}
            numSelected={numSelected}
        >
            <>
                {numSelected > 0 ?
                    <>
                        {numSelected === 1 && (
                            <>
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={() => setEditDialogOpen(true)}
                                        style={{ color: "#1F036D" }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </>    
                        )}
                            <Tooltip title="Delete">
                                <IconButton onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                    </>
                    :
                    <>
                        <Tooltip title="Add">
                            <IconButton onClick={() => setCreateDialogOpen(true)}>
                                <AddIcon style={{ color: "#3C44B1" }} />
                            </IconButton>
                        </Tooltip>                
                    </>
                }
            </>
        </TableToolbarComponent >
    )
}

export default MembersTableToolbar




