import { makeStyles } from "@material-ui/core/styles";

export const useTableHeaderStyles = makeStyles((theme) => ({
  head: {
    borderRadius: "50px",
  },
  tableHead: {
    fontWeight: "600",
    backgroundColor: "#F6F7F9",
  },
}));

export const useSimpleTableStyles = makeStyles((theme) => ({
  tableHead: {
    fontWeight: "600",
  },
  tableBody: {
    textAlignLast: "center",
  },
}));

export const useTablePaginationStyles = makeStyles((theme) => ({
  more: {
    position: "relative",
    top: "5px",
    textAlign: "center",
    borderRadius: "15px",
  },

  next: {
    textDecoration: "none",
    display: "inline-block",
    padding: "8px 16px",
    backgroundColor: "#00AFA1",
    color: "white",
    "&:hover": {
      backgroundColor: "#ddd",
      color: "black",
    },
    borderRadius: "15px",
  },

  prev: {
    textDecoration: "none",
    display: "inline-block",
    padding: "8px 16px",
    backgroundColor: "#f1f1f1",
    color: "black",
    borderRadius: "15px",
  },
}));

// theme.palette.type === "light"
// ? {
//     color: theme.palette.secondary.main,
//     backgroundColor: lighten(theme.palette.secondary.light, 0.85),
// }
// :
export const useTableToolbarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: "#d3d9db",
    borderRadius: "20px 20px 0 0",
  },
  marginLeft: "0px",
}));

export const useTableFooterStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: "10px",
    width: "230px",
    fontSize: "15px",
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  tableBody: {
    marginTop: "10px",
    borderRadius: "25px",
  },
  tablecell: {
    fontSize: "40pt",
    color: "red",
  },
  root: {
    width: "100%",
    marginTop: "20px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: 10,
    borderRadius: "3%",
  },
  table: {
    width: "100%",
  },
}));
