import styled from "styled-components";

export const PricingCalculatorContainer = styled("div")``;
export const PricingBox = styled("div")`
  -webkit-box-shadow: 15px 5px 25px rgb(239 231 227 / 50%);
  box-shadow: 5px 5px 25px rgb(239 231 227 / 50%);
  padding: 48px;
  border-radius: 8px;
`;

export const CalcItem = styled("div")`
  margin-bottom: 36px;
`;

export const CalculatorItem = styled("div")`
  font-weight: 600;
  line-height: 1.45;
  font-family: "Soleil", Helvetica, Arial, sans-serif !important;
`;
export const SliderContainer = styled("div")`
  width: 100%; /* Width of the outside container */
`;
export const FormGroup = styled("form")`
  width: 100%;
  max-width: 520px;

  @media only screen and (max-width: 1045px) {
    max-width: 100%;
    margin-top: 2rem;
  }
`;

export const Span = styled("input")`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 25px;

  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
  &::-webkit-slider-thumber {
    -webkit-appearance: none;
    appearance: none;
    width: 955px;
    height: 25px;
    background: red;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;ButtonContainer
  }
`;

export const ButtonContainer = styled("div")`
  position: relative;
  padding: 20px;
  margin: 10px;
  border: none;
  font-size: 20px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  background-color: #5d5fef;
  float: right;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: ${(p) => p.hoverColor || "#FF828E"};
    background-color: ${(p) => p.hoverColor || "#FF828E"};
    cursor: pointer;
  }
  @media only screen and (max-width: 414px) {
    padding-top: 0.75rem;
  }
`;
