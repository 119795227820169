import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PDFDownloadLink } from "@react-pdf/renderer";

import BasePage from "../../../Components/BasePage";
import TableComponent from "../../../Components/Table/TableComponent";
import TableToolbarComponent from "../../../Components/Table/TableToolbarComponent";
import { getIncomingReceipts, getOutgoingReceipts } from "../../../store/actions/invoiceActions";
import ReceiptPDF from "./ReceiptPDF";

const incomingSearchOptions = [
  { default: true, id: "total_amount", label: "Total Amount" },
  { default: true, id: "beneficiary_name_lower", label: "Payee Name" },
];

const outgoingSearchOptions = [
  { default: true, id: "total_amount", label: "Total Amount" },
  { default: true, id: "remitter_name_lower", label: "Payer Name" },
  { default: true, id: "remitter_name_lower", label: "remitter_erp_lower" },
];
const headCells = [
  { id: "created_at", label: "Payment Date" },
  { id: "amount", label: "Total Amount" },
  { id: "supplier_name", label: "Paid To" },
  { id: "customer_name", label: "Paid By" },
  { id: "type", label: "Type" },
];

const ReceiptsTable = ({ business }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const receipts = useSelector((state) => state.invoice);

  let receiptType;
  let url = window.location.pathname.split("/")[2];
  const user = useSelector((state) => state.user);

  const [afterRender, setAfterRender] = useState(false);

  const permission = url === "incoming-receipts" ? user?.permissions?.incomingInvoice?.viewReceipt : user?.permissions?.outgoingInvoice?.viewReceipt;

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      if (url === "incoming-receipts") dispatch(getIncomingReceipts({}));
      else dispatch(getOutgoingReceipts({}));
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const renderToolbar = () => {
    return (
      <TableToolbarComponent header="Receipts" numSelected={selected.length}>
        {selected && (
          <>
            <PDFDownloadLink
              document={<ReceiptPDF invoiceData={selected[0]} receiptType={receiptType} />}
              fileName={selected[0].id}
              style={{
                textDecoration: "none",
                padding: "10px",
                color: "#4a4a4a",
                backgroundColor: "#f2f2f2",
                border: "1px solid #4a4a4a",
              }}
            >
              {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download")}
            </PDFDownloadLink>
          </>
        )}
      </TableToolbarComponent>
    );
  };

  return (
    <BasePage navigation="Dashboard/Receipts" business={business} canView={permission}>
      <TableComponent
        toolbar={selected && selected.length === 1 && renderToolbar()}
        data={url === "incoming-receipts" ? receipts.incomingReceipts : receipts.outgoingReceipts}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        searchOptions={url === "incoming-receipts" ? incomingSearchOptions : outgoingSearchOptions}
        searchbarText="Search Receipts"
        getAllData={url === "incoming-receipts" ? getIncomingReceipts : getOutgoingReceipts}
        shouldGetData={false}
        shouldPaginate={true}
        shouldView={true}
        isLoading={receipts.isLoading}
      />
    </BasePage>
  );
};
export default ReceiptsTable;
