import React from "react";
import { MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

const DropdownComponent = ({
  items,
  register,
  change,
  control,
  defaultValue,
  ...rest
}) => {
  return (
    <Controller
      render={(props) => (
        <TextField
          select
          fullWidth
          margin="dense"
          variant="outlined"
          defaultValue={defaultValue}
          onChange={(value) => {
            props.onChange(value);
          }}
          {...rest}
        >
          {items.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      control={control}
      {...rest}
    />
  );
};

export default DropdownComponent;
