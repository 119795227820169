import { Divider, Grid, TextField, Typography, Hidden } from "@material-ui/core";

import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Radio from "@material-ui/core/Radio";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import countryList from "react-select-country-list";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import PhoneInputComponent from "../../Components/PhoneInputComponent";

const NotificationsDetailsDialog = ({ open, setOpen, selected }) => {
  return (
    <DialogComponent open={open} setOpen={setOpen} title="" description="" withActions={false}>
      <div style={{ width: "450px" }}>
        {selected &&
          selected.detail &&
          selected.detail.map((s) => {
            return <Typography style={{ marginBottom: "10px" }}>{s}</Typography>;
          })}
      </div>
    </DialogComponent>
  );
};

export default NotificationsDetailsDialog;
