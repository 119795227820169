
const initialState = {
    isLoading: false,
    business: {},
    errorMessage: null,
};

//TODO burası businessReducer olarak degistirilecek.
export default function reducer(state=initialState, action) {
    switch(action.type) {
        case "BUSINESS_DATA_FETCH_STARTED":
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
            };
        case "BUSINESS_DATA_FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                business: action.payload,
            };
        default:
            return state;
    }
}