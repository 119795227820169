import React from "react";
import { useForm } from "react-hook-form";
import { Paper, Typography, Button, Grid, TextField, } from "@material-ui/core";
import { useLinkAccountStyle } from "./MergeAccountsStyle";

const InvitationCodeStep = ({ onSubmit, previousStep, defaultValues }) => {
  const { register, handleSubmit } = useForm({ defaultValues: defaultValues });
  const classes = useLinkAccountStyle();

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          You need to enter your invitation code to merge it into this account
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id="invitationCode"
                name="invitationCode"
                label="Invitation Code"
                inputRef={register}
                fullWidth
              />
              <Button variant="contained" style={{backgroundColor: "#FF5D91", color: "white"}} type="submit" className={classes.buttonNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default InvitationCodeStep;
