import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissionsContext } from "../../../Contexts/PermissionsContext";
import { useUiContext } from "../../../Contexts/UiContext";
import { getOutgoingInvoices, removeOutgoingInvoices, sendApprovalRequest } from "../../../store/actions/invoiceActions";
import InvoicesListComponent from "../InvoicesListComponent";

const CloudInvoices = ({ products, businessId, history }) => {
  const { setErrors } = useUiContext();

  const { permissions } = usePermissionsContext();

  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const cloudInvoices = useSelector((state) => state.invoice.cloudInvoices);

  const handleSendApproval = () => {
    if (cloudInvoices && cloudInvoices.length > 0) {
      dispatch(sendApprovalRequest(cloudInvoices));
    }
  };
  const renderToolbar = () => {
    return (
      <>
        <Button variant="outlined" color="primary" onClick={() => handleSendApproval()}>
          {" "}
          Send All invoices for approval
        </Button>
      </>
    );
  };

  const renderGoBack = () => {
    return (
      <>
        <Button onClick={() => history.push("/dashboard/outgoing-invoices")}>
          <ArrowBackIcon /> Go back
        </Button>
      </>
    );
  };

  const renderList = () => {
    return (
      <>
        <InvoicesListComponent
          invoices={cloudInvoices}
          toolbar={renderToolbar()}
          header="Outgoing Invoices"
          businessId={businessId}
          getAllData={getOutgoingInvoices}
          removeInvoices={removeOutgoingInvoices}
          selected={selected}
          setSelected={setSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          products={products}
          dispatch={dispatch}
          setErrors={setErrors}
          shouldView={permissions?.outgoingInvoice?.view}
          shouldGetData={false}
          shouldPaginate={false}
          shouldSearch={false}
          paginateRedis={false}
        />
      </>
    );
  };

  return (
    <>
      {renderGoBack()}
      {renderList()}
    </>
  );
};

export default CloudInvoices;
