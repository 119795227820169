import { IconButton, Tooltip, Typography } from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { addCustomer, getCustomers } from "../../../store/actions/customerActions";
import { createOutgoingInvoice, getSingleOutgoingInvoices } from "../../../store/actions/invoiceActions";
import { getAllProducts } from "../../../store/actions/productActions";
import CreateInvoiceComponent from "../CreateInvoiceComponent";
import DropdownComponent from "./../../../Components/DropdownComponent";
import { usePermissionsContext } from "./../../../Contexts/PermissionsContext";
import { useUiContext } from "./../../../Contexts/UiContext";
import verifySchema from "./../../../util/yup/verifySchema";
import { customerSchema, outgoingInvoiceSchema } from "./../../../util/yup/yupSchemas";
import CustomerDialog from "./../../Customer/CustomerDialog";

const CreateOutgoingInvoice = ({ history, business }) => {
  const { permissions } = usePermissionsContext();

  const { register, control, watch, handleSubmit, setValue } = useForm();
  const { errors, loading, clearErrors, setErrors } = useUiContext();
  const [createCustomerDialogOpen, setCreateCustomerDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);

  const [usePayedaInvoiceNumber, setUsePayedaInvoiceNumber] = useState(true);
  const [manuelInvoice, setManueInvoice] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCustomers({}));
    dispatch(getAllProducts());
    dispatch(getSingleOutgoingInvoices());
    // eslint-disable-next-line
  }, []);

  const customers = useSelector((state) => state.customer.customers);

  const renderCustomersList = () => {
    if (loading) {
      return [{ value: "null", label: "Loading..." }];
    }
    if (customers && customers.length > 0) {
      let list = customers.map((cust) => {
        return { value: cust.id, label: cust.name };
      });
      return list;
    } else {
      return [{ value: "null", label: "No customers" }];
    }
  };

  const customerDropdown = () => {
    return (
      <DropdownComponent
        required
        id="customer"
        name="customer"
        label="Select a Customer"
        items={renderCustomersList()}
        error={errors.customer ? true : false}
        helperText={errors.customer ?? ""}
        onChange={(e) => {
          setSelectedCustomer(e.target.value);
        }}
        control={control}
      />
    );
  };

  const createCustomerButton = () => {
    return (
      permissions.customer.create && (
        <Typography style={{ marginLeft: 70, color: "red" }}>
          Add New Customer
          <Tooltip title="Create Customer">
            <IconButton onClick={() => setCreateCustomerDialogOpen(true)}>
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      )
    );
  };

  const onCreateNewCustomer = async (data) => {
    const valid = await verifySchema(customerSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateCustomerDialogOpen(false);
      dispatch(addCustomer(data));
    }
  };

  const onSubmit = async (data) => {
    const dataToSend = { ...data };
    dataToSend.customer = selectedCustomer;
    dataToSend.currency = "GBP";

    dataToSend.usePayedaInvoiceNumber = usePayedaInvoiceNumber;
    usePayedaInvoiceNumber ? (dataToSend.invoice_number = manuelInvoice) : (dataToSend.invoice_number = data.invoice_number);

    if (data.invoice_lines.length > 0) {
      dataToSend.invoice_lines = JSON.stringify(data.invoice_lines);
    }

    if (data.invoiceImage) {
      if (data.invoiceImage.item(0)) {
        dataToSend.invoice_image = data.invoiceImage.item(0);
      }
    }

    if (parseFloat(data.amount_to_be_paid) === 0) {
      setErrors({ general: "Total amount can not be 0" });
      return false;
    }

    const valid = await verifySchema(outgoingInvoiceSchema, dataToSend, clearErrors, setErrors);

    if (valid) {
      let fd = new FormData();
      for (var key in dataToSend) {
        fd.append(key, dataToSend[key]);
      }

      dispatch(createOutgoingInvoice(fd, history));
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      const customerDet = customers.find((customer) => customer.id === selectedCustomer);
      setCustomerDetails(customerDet);
    }
    // eslint-disable-next-line
  }, [selectedCustomer]);

  useEffect(() => {
    if (business && business.account_settings) {
      let lastInvoiceNumber = business.account_settings.invoice_settings.last_manuel_invoice || "INVPYD-0";
      let numberOfInvoice = Number(lastInvoiceNumber.split("-")[1]);
      let increasedInvoice = numberOfInvoice + 1;
      let newInvoice = `INVPYD-${increasedInvoice}`;
      setManueInvoice(newInvoice);
    }
  }, [business]);
  return (
    <BasePage navigation="Dashboard/CreateOutgoingInvoice" business={business} canView={user?.permissions?.outgoingInvoice?.create}>
      {business && business.company_details && (
        <>
          <CreateInvoiceComponent
            title="Outgoing Invoice"
            type={true}
            dropdownComponent={customerDropdown()}
            createButtonComponent={createCustomerButton()}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            register={register}
            watch={watch}
            control={control}
            setValue={setValue}
            nextInvoiceNumber={manuelInvoice}
            supplierName={business?.company_details?.name}
            customerName={customerDetails ? customerDetails?.name : ""}
            usePayedaInvoiceNumber={usePayedaInvoiceNumber}
            setUsePayedaInvoiceNumber={setUsePayedaInvoiceNumber}
          />
          <CustomerDialog
            open={createCustomerDialogOpen}
            setOpen={setCreateCustomerDialogOpen}
            title="Create new customer"
            description="Create new customer to start receiving payments."
            onSubmit={onCreateNewCustomer}
            business={business}
            errors={errors}
          />
        </>
      )}
    </BasePage>
  );
};

export default CreateOutgoingInvoice;
