import { IconButton, Tooltip, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ChatIcon from "@material-ui/icons/Chat";
import React, { useEffect, useState } from "react";
import checkIfHasActiveMembership from "../../util/permissions/checkIfHasActiveMembership";
import AddButton from "./../../Components/shared/AddButton";
import TableToolbarComponent from "./../../Components/Table/TableToolbarComponent";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";
import canPerformAction from "./../../util/permissions/canPerformAction";
import ImportCustomerFromErpDialog from "./ImportCustomerFromErpDialog";
import { updateCustomersFromErp } from "../../store/actions/customerActions";

const CustomersTableToolbar = ({
  business,
  numSelected,
  selected,
  setErrors,
  header,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  dispatch,
  userHasMembership,
  renderCustomerGroups,
  setIsMessage,
}) => {
  const { permissions } = usePermissionsContext();
  const [openImportCustomerDialog, setOpenImportCustomerDialog] = useState(false);

  const handleInviteDialog = (type) => {
    let flag = 1;
    if (numSelected === 1) {
      if (!selected[0].phone && !selected[0].email) {
        flag = 0;
        setErrors({
          general: "Please enter either email for your customer",
        });
        return false;
      }
    }
    if (flag === 1) {
      setInviteDialogOpen(true);
    }

    if (type === "message") setIsMessage(true);
  };

  const handleOpenImportDialog = () => {
    if (business && business?.account_settings?.invoice_settings?.platform_usage) {
      let platform_usage = business?.account_settings?.invoice_settings?.platform_usage;

      if (platform_usage.isErp) {
        if (!business?.account_settings?.invoice_settings?.cloud_app.isSynced) {
          setErrors({
            general: "Please sync your cloud app in settings",
          });

          return false;
        } else {
          dispatch(updateCustomersFromErp());
        }
      }
    }
  };

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {numSelected > 0 ? (
          <>
            <Tooltip title="Invite">
              <IconButton onClick={() => handleInviteDialog("invite")} style={{ color: "#14980B" }}>
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send message to customer">
              <IconButton onClick={() => handleInviteDialog("message")} style={{ color: "blue" }}>
                <ChatIcon />
              </IconButton>
            </Tooltip>
            {numSelected === 1 && (
              <>
                {canPerformAction("customers", permissions, "edit") && (
                  <>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setEditDialogOpen(true)} style={{ color: "#1F036D" }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )}
            {business && business?.account_settings?.invoice_settings?.platform_usage?.isManuel && (
              <>
                {canPerformAction("customers", permissions, "remove") && (
                  <Tooltip title="Delete">
                    <IconButton onClick={() => setDeleteDialogOpen(true)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {business && business?.account_settings?.invoice_settings?.platform_usage.isErp && userHasMembership && business?.receive_payment_details && (
              <Grid container>
                <Grid
                  item
                  style={{
                    backgroundColor: "#FEEBF2",
                    borderRadius: "20px",
                    marginTop: "10px",
                  }}
                  xs={12}
                  lg={6}
                  md={6}
                >
                  <div style={{ padding: 15 }}>{renderCustomerGroups}</div>
                </Grid>
                {/* <Grid item xs={12} lg={6} md={6}>
                  {canPerformAction("customers", permissions, "create") && (
                    <>
                      <Tooltip title="Import" style={{ float: "right" }}>
                        <IconButton onClick={() => handleOpenImportDialog()}>Update From Erp</IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid> */}
              </Grid>
            )}
            {business && business.account_settings && business.account_settings.invoice_settings.platform_usage.isManuel && userHasMembership && business?.receive_payment_details && (
              <>
                {canPerformAction("customers", permissions, "create") && (
                  <>
                    {/* <Tooltip title="Import">
                        <IconButton
                          component={Link}
                          to="/dashboard/import-customers"
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </Tooltip> */}
                    {canPerformAction("customers", permissions, "create") && (
                      <div>
                        <AddButton onClick={() => setCreateDialogOpen(true)} />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <ImportCustomerFromErpDialog open={openImportCustomerDialog} setOpen={setOpenImportCustomerDialog} />
      </>
    </TableToolbarComponent>
  );
};

export default CustomersTableToolbar;
