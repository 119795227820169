import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyHouseInformation } from "./../../../store/actions/businessActions";

//TODO needs translations
function VerifyBusinessWithCompanyHouse({
  errors,
  business,
  isPathUpdate,
  companyHouseResult,
}) {
  const dispatch = useDispatch();

  const [companyRegNumber, setCompanyRegNumber] = useState("");
  const loadingButton = useSelector((state) => state.ui.loadingButton);

  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    if (business && business.company_details) {
      setCompanyRegNumber(business.company_details.company_reg_number);
    }
  }, [business]);

  useEffect(() => {
    if (isPathUpdate && companyRegNumber !== "") {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(
        setTimeout(() => {
          if (
            companyRegNumber !== "" ||
            companyRegNumber !== undefined ||
            companyRegNumber !== null
          ) {
            dispatch(getCompanyHouseInformation(companyRegNumber, true));
            // setValue("company_reg_number", companyRegNumber);
          }
        }, 1000)
      );
    } else {
    }
    // eslint-disable-next-line
  }, [companyRegNumber]);

  const checkForCompayNumber = (data) => {
    dispatch({
      type: "SET_COMPANY_HOUSE_INFORMATION",
      payload: {},
    });
    setCompanyRegNumber(companyRegNumber);
    dispatch(getCompanyHouseInformation(companyRegNumber));
  };

  const displayCompanyHouseResult = () => {
    return (
      <>
        <Typography>
          Company Name:
          <span style={{ color: "green" }}>
            {companyHouseResult.company_name}
          </span>
        </Typography>
        <Typography>
          Region:{" "}
          <span style={{ color: "green" }}>
            {companyHouseResult?.registered_office_address?.region ||
              companyHouseResult?.registered_office_address?.city ||
              companyHouseResult?.registered_office_address?.locality}
          </span>
        </Typography>
        <Typography>
          Address:{" "}
          <span style={{ color: "green" }}>
            {companyHouseResult?.registered_office_address?.address_line_1}{" "}
            {companyHouseResult?.registered_office_address?.address_line_2}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "green" }}></span>
        </Typography>
        <Typography>
          Postal Code:{" "}
          <span style={{ color: "green" }}>
            {companyHouseResult?.registered_office_address?.postal_code}
          </span>
        </Typography>
      </>
    );
  };

  return (
    <form>
      <Grid container spacing={2}>
        {!isPathUpdate && (
          <>
            <Grid item xs={4} lg={4} md={4}>
              <TextField
                required
                variant="outlined"
                id="company_reg_number"
                name="company_reg_number"
                label="Company Number"
                error={errors.company_reg_number ? true : false}
                helperText={errors.company_reg_number ?? ""}
                type="text"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={companyRegNumber}
                onChange={(e) => setCompanyRegNumber(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} lg={2} md={2}>
              <Button
                variant="outlined"
                color="primary"
                style={{ height: "55px" }}
                // onClick={checkForCompayNumber}
                onClick={() => checkForCompayNumber()}
                disabled={loadingButton}
              >
                {loadingButton && <CircularProgress />}
                {!loadingButton && "Verify Company"}
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={12} lg={4} md={4}>
          {companyHouseResult?.registered_office_address &&
            displayCompanyHouseResult()}
        </Grid>
      </Grid>
    </form>
  );
}

export default VerifyBusinessWithCompanyHouse;
