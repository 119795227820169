import React from 'react'
import { Redirect } from 'react-router-dom';

import LoadingIcon from './../../../Components/LoadingIcon';
import InvitationSignup from './InvitationSignup';

const InvitationLoginPage = ({ authenticated, invitationSignup, loading }) => {

    return  (
        authenticated ? <Redirect to="/dashboard" /> :
            loading ? <LoadingIcon /> :
                <InvitationSignup invitationSignup={invitationSignup} />
    )
            
    
}

export default InvitationLoginPage;