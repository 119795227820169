const initialState = {
  notifications: [],

  isLoading: false,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        isLoading: false,
        notifications: action.payload,
      };
    case "SET_UNREAD_NOTIFICATIONS":
      return {
        ...state,
        isLoading: false,
        unreadnotifications: action.payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
