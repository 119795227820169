import { Grid, TextField, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import NumberFormatCustom from "../../Components/NumberFormatCustom";
import UploadComponent from "../../Components/UploadComponent";
import DropdownComponent from "../../Components/DropdownComponent";

import { updateFinancialInformations } from "../../store/actions/businessActions";

import { getTransactions } from "../../store/actions/bankingActions";
import { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const BankStatementUploadPreferenceDialog = ({ open, setOpen, business, setBankStatementPDF, bankStatementPDF, setFromConnectedBankAccounts, setErrors }) => {
  const { register, handleSubmit, control, errors } = useForm();
  let dispatch = useDispatch();
  const bankAccounts = useSelector((state) => state.banking.bankAccounts);
  const bankAccountLoading = useSelector((state) => state.banking.isLoading);
  const transactions = useSelector((state) => state.banking.transactions);
  const transactionLoading = useSelector((state) => state.banking.isLoading);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pdfRendering, setPdfRendering] = useState(false);

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [approve, setApprove] = useState(false);
  const [openiningBalance, setOpeningBalance] = useState(0);

  useEffect(() => {
    if (transactions && transactions.results) {
      let balance = transactions?.results && transactions?.results.length > 0 && transactions?.results[transactions.results.length - 1]?.running_balance?.amount;

      setOpeningBalance(balance);
    }
  }, [transactions]);

  useEffect(() => {
    if (transactions && transactions.results && selectedOption === "fromConnectedAccounts") {
      let openingBalance = transactions?.results && transactions?.results.length > 0 && transactions?.results[transactions.results.length - 1]?.running_balance?.amount;

      let datas = [
        {
          Date: "2021-12-12",
          Description: "Payment",
          Type: "Credit",
          Amount: -30,
        },
        {
          Date: "2021-12-12",
          Description: "Payment",
          Type: "Credit",
          Amount: -30,
        },
      ];
      const columns = [
        { title: "Date", dataKey: "Date" },
        { title: "Description", dataKey: "Description" },
        { title: "Type", dataKey: "Type" },
        { title: "Amount", dataKey: "Amount" },
        { title: "Balance", dataKey: "Balance" },
      ];

      var rows = transactions?.results?.map((data) => ({
        Date: data?.timestamp,
        Description: data?.description,
        Type: data?.transaction_type,
        Amount: data?.amount,
        Balance: data?.running_balance.amount,
      }));
      var doc = new jsPDF("p", "pt");
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.autoTable(columns, rows, {
        startY: doc.pageCount > 1 ? 200 : 200,
        margin: { horizontal: 10 },
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        columnStyles: { email: { columnWidth: "wrap" } },
        theme: "striped",
        showHead: "everyPage",
        didDrawPage: function(data) {
          // Header
          data.settings.margin.top = 200;

          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.text("Bank Statement/" + business?.receive_payment_details.trueLayerDetails.provider.display_name, data.settings.margin.left, 22);
          doc.setFontSize(15);

          doc.text(business?.company_details?.name, data.settings.margin.left, 55);
          doc.setFontSize(10);

          var address1 = doc.splitTextToSize(business?.company_details?.address.address_line_1, 250);

          var address2 = doc.splitTextToSize(business?.company_details?.address.address_line_2, 250);

          var address3 = doc.splitTextToSize(business?.company_details?.address.region_province + " " + business?.company_details?.address.post_code, 250);

          doc.text(address1 + " " + address2, data.settings.margin.left, 66);
          doc.text(address3, data.settings.margin.left, 77);

          doc.text(
            "Statement Period: " + new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 3).toISOString().split("T")[0] + " to " + new Date().toISOString().split("T")[0],
            data.settings.margin.left,
            121
          );
          doc.text("Opening Balance: " + openingBalance, data.settings.margin.left, 141);
          doc.setFontSize(10);

          doc.text("Account Number: " + business?.receive_payment_details?.trueLayerDetails?.account_number?.number, 300, 44);

          doc.text("Sort Code: " + business?.receive_payment_details?.trueLayerDetails?.account_number?.sort_code, 300, 66);
          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();

          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
      });
      var blob = new Blob([doc.output()], { type: "application/pdf" });
      let blobAsFiles = new File([blob], "application/pdf", {
        lastModified: new Date().getTime(),
        type: "application/pdf",
      });

      setPdfRendering(false);
      setOpen(false);

      setBankStatementPDF(blobAsFiles);
      doc.save("BankStament.pdf");
    }
  }, [transactions]);

  useEffect(() => {
    if (selectedOption === "fromConnectedAccounts" && approve) {
      console.log("bankAccounts", bankAccounts);
      if (bankAccounts && bankAccounts.length > 1) {
        getSelectedTransactions();
        setOpenApproveDialog(false);
      } else {
        setErrors({ general: "You do not have any connected account" });
        setSelectedOption(null);
        return false;
      }
    }
  }, [selectedOption, approve]);

  const getSelectedTransactions = () => {
    if (business && business?.receive_payment_details?.receive_payment_account_id) {
      let businessSelectedAccount = business && business?.receive_payment_details?.receive_payment_account_id;

      let selectedAccountInfo = bankAccounts.find((accounts) => accounts.id === businessSelectedAccount);

      setSelectedAccount(selectedAccountInfo);
      let token = selectedAccountInfo.token;
      const params = [
        {
          to: new Date().toISOString().split("T")[0],
          from: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 3).toISOString().split("T")[0],
          accountId: businessSelectedAccount,
          tokenId: token,
        },
      ];
      dispatch(getTransactions(params));
      setPdfRendering(true);
    }
  };

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
    if (val === "fromConnectedAccounts") {
      setFromConnectedBankAccounts(true);
      setOpenApproveDialog(true);
    } else {
      setFromConnectedBankAccounts(false);
      setPdfRendering(false);
    }
  };

  const renderApprove = () => {
    return (
      <DialogComponent open={openApproveDialog} setOpen={setOpenApproveDialog} title="" description="" withActions={false}>
        We will prepare a latest 3 month bank statement for your account with the following information, do you approve?
        <br />
        <strong>Account number:</strong> {business && business.company_details.banking[0].account_number} <strong>Sort code:</strong>{" "}
        {business && business.company_details.banking[0].sort_code}
        <br />
        <Button variant="outlined" color="secondary" style={{ float: "right" }} onClick={() => setApprove(true)}>
          Approve
        </Button>
      </DialogComponent>
    );
  };
  const renderSelection = () => {
    return (
      <form>
        <div>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}>Choose upload selection</strong>
          </Typography>
          <div>
            <input
              id="fromConnectedAccounts"
              name="uploadSelectionOption"
              value="fromConnectedAccounts"
              checked={selectedOption === "fromConnectedAccounts"}
              type="radio"
              onChange={(e) => handleRadioChange(e)}
            />
            <label for="uploadSelectionOption">From Connected Bank Accounts </label>
          </div>
          <div>
            <input id="manually" name="uploadSelectionOption" value="manually" checked={selectedOption === "manually"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="uploadSelectionOption">Select from your device</label>
          </div>
        </div>
      </form>
    );
  };

  const renderBankAccountSelection = () => {};

  const renderManuallyUpload = () => {
    return <input name="id_front_page" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setBankStatementPDF(e.target.files)} />;
  };

  return (
    <>
      <DialogComponent open={open} setOpen={setOpen} title="Choose upload selection" onSubmit={() => setOpen(false)} description="" withActions={true}>
        {pdfRendering ? (
          "Preparing your pdf"
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {renderSelection()}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {selectedOption === "manually" && renderManuallyUpload()}
                {/* {selectedOption === "fromConnectedAccounts" && renderAccounts()} */}
              </Grid>
            </Grid>
          </>
        )}
        {renderApprove()}
      </DialogComponent>
    </>
  );
};

export default BankStatementUploadPreferenceDialog;
