import { Avatar, Button, Card, CardContent, CardMedia, Grid, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import LoadingDialog from "../../Components/Dialogs/LoadingDialog";
import PaymentConfirmationDialog from "../../Components/Dialogs/PaymentConfirmationDialog";
import { getSelfBankAccounts, getUserRemainingBalance, makePaymentRequest } from "../../store/actions/bankingActions";
import { getSupplier } from "../../store/actions/supplierActions";
import verifySchema from "../../util/yup/verifySchema";
import { paymentBankAccount } from "../../util/yup/yupSchemas";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const InvoiceDetailsDialog = ({ open, setOpen, rows }) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  return (
    <DialogComponent open={open} setOpen={setOpen} title="Invoice Details" description="" withActions={false}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Customer Name</TableCell>
              <TableCell align="right">Due Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.invoice_number}>
                <TableCell component="th" scope="row">
                  {row.invoice_number}
                </TableCell>
                <TableCell align="right">{row.amount_to_be_paid}</TableCell>
                <TableCell align="right">{row.customer_name}</TableCell>
                <TableCell align="right">{row.due_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogComponent>
  );
};

export default InvoiceDetailsDialog;
