// eslint-disable-next-line

import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../Components/Table/TableComponent";
import { acceptFinancingOffer, downloadExcellFile, financingRequestedInvoices } from "../../store/actions/invoiceActions";
import { useUiContext } from "./../../Contexts/UiContext";
import FinancingInvoicesToolbar from "./FinancingInvoicesToolbar";
const headCells = [
  { id: "finance_name", label: "Finance" },
  // { id: "invoiceIds", label: "Invoices" },

  {
    id: "advance_payment_percentage",
    label: "Advance Payment % ",
  },
  { id: "advance_payment_amount", label: "Advance Payment Amount" },
  { id: "advance_payment_maturity", label: "Advance Payment Maturity" },
  { id: "expense_amount", label: "Expense Amount" },

  { id: "remaining_payment_amount", label: "Remaining Payment Amount" },
  { id: "remaining_payment_maturity", label: "Maturity" },

  // { id: "expense_amount", label: "- Amount" },

  { id: "totalRequestedAmount", label: "Requested" },
  { id: "offeredTotalAmount", label: "Offered" },
  { id: "status", label: "Status" },
];

const FinancingRequestedInvoicesTable = ({ history }) => {
  let dispatch = useDispatch();

  const { setErrors } = useUiContext();

  let isLoading = useSelector((state) => state.invoice.isLoading);
  let financingInvoices = useSelector((state) => state.invoice.financingInvoices);
  const [selected, setSelected] = React.useState([]);
  useEffect(() => {
    dispatch(financingRequestedInvoices());
  }, []);
  const acceptOffer = () => {
    if (selected.length === 1) {
      if (selected[0].status !== "Awaiting") {
        setErrors({
          general: "You can't accept invoice",
        });
        setSelected([]);
        return;
      }
      dispatch(acceptFinancingOffer(selected[0].id));
      setSelected([]);
    }
  };
  const getExcell = (action) => {
    let ids = [];
    if (action === "one") {
      ids.push(selected[0].id);
    } else if (action === "all") {
      for (let data of financingInvoices) {
        ids.push(data.id);
      }
    }

    dispatch(downloadExcellFile(ids));
  };

  const renderToolbar = () => {
    return <FinancingInvoicesToolbar data={financingInvoices} selected={selected} numSelected={selected.length} getExell={getExcell} acceptOffer={acceptOffer} />;
  };

  return (
    <>
      {/* <div>
        {selected.length === 0 && <Button onClick={() => getExcell("all")}>Download All Offers </Button>}
        {selected.length === 1 && (
          <>
            {" "}
            <Button onClick={() => acceptOffer()}>Accept</Button> <Button onClick={() => getExcell("one")}>Get Excell</Button>{" "}
          </>
        )}
      </div> */}

      <TableComponent
        toolbar={renderToolbar()}
        data={financingInvoices}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        getAllData={financingRequestedInvoices}
        isLoading={isLoading}
        searchbarText="Search suppliers"
        shouldGetData={true}
        shouldPaginate={false}
        shouldView={true}
        statusOptipns={false}
      />
    </>
  );
};
export default FinancingRequestedInvoicesTable;
