import React from "react";
import { TextField, Typography, Divider, Grid, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";

import DialogComponent from "../../../Components/Dialogs/DialogComponent";

const BankAgreementDialog = ({
  open,
  setOpen,
  title,
  description,
  selected,
  denyOrApproveRequests,
}) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {

    let invoiceId = selected[0]?.id;
  
    var details = {
      invoiceId,
      isApprovedByBank: true,
      agreementDate:data.agreementDate,
      agreedAmount:data.agreedAmount
    };

    denyOrApproveRequests(details);
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Enter Agreement Details</Typography>
          <Divider />
          <br />
        </Grid>
        <Grid item xs={12}>
          <>
            <TextField
              InputLabelProps={{ shrink: true }}
              id="agreementDate"
              name="agreementDate"
              label="agreement Date"
              type="date"
              fullWidth={true}
              inputRef={register}
            />
            <TextField
              reqired
              fullWidth
              id="agreedAmount"
              name="agreedAmount"
              label="Agreed Amount"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              inputRef={register}
            />
          </>
          <Divider />
          <br />
          <Button></Button>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default BankAgreementDialog;
