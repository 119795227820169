import axios from "axios";
import { setSuccess, setErrors, clearErrors, clearSuccess } from "./uiActions";
import { API_BASE } from "../env";

// axios.defaults.withCredentials = true;

let tokenBearer = localStorage.getItem("FBIdToken");
axios.defaults.headers.common["Authorization"] = tokenBearer;

export const addCustomer = (details) => async (dispatch) => {
  try {
    let customers = await axios.post(`${API_BASE}/customer`, details);
    dispatch({
      type: "CUSTOMER_DATA_FETCH_SUCCESS",
      payload: customers.data.allCustomers,
    });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added new customer."));
    dispatch(clearSuccess());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const importCustomersFromErp = () => async (dispatch) => {
  let details = {
    from: "erp",
    data: [],
  };

  try {
    dispatch({ type: "CUSTOMER_IMPORT_STARTED" });
    await axios.post(`${API_BASE}/customers`, details);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully imported customer(s)."));
    dispatch(getCustomers({}));
    dispatch({ type: "CUSTOMER_IMPORT_FINISHED" });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
//mus be fixed
export const addCustomers = (details, history) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    await axios.post(`${API_BASE}/add-customers`, details);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added new customer(s)."));
    dispatch(getCustomers());
    history.push("/dashboard/customers");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
//mus be fixed
export const updateCustomersFromErp = () => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "CUSTOMER_DATA_FETCH_STARTED" });

    let data = await axios.post(`${API_BASE}/update-erp`);
    console.log("data", data);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully updated customer(s)."));
    dispatch(getCustomers([]));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const editCustomer = (details) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    let response = await axios.put(`${API_BASE}/customer`, details);
    dispatch(clearErrors());
    dispatch({
      type: "CUSTOMER_DATA_FETCH_SUCCESS",
      payload: response.data.allCustomers,
    });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getCustomerByErpNumber = (erpNumber) => async (dispatch) => {
  try {
    dispatch({ type: "CUSTOMER_DATA_FETCH_STARTED" });

    let customers = await axios.get(`${API_BASE}/customer/erp`, {
      params: {
        erpNumbers: erpNumber,
      },
    });

    dispatch({
      type: "SINGLE_CUSTOMER_DATA_FETCH_SUCCESS",
      payload: customers.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getCustomer = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: "CUSTOMER_DATA_FETCH_STARTED" });

    let customers = await axios.get(`${API_BASE}/customer`, {
      params: {
        customerId: customerId,
      },
    });
    dispatch({
      type: "SINGLE_CUSTOMER_DATA_FETCH_SUCCESS",
      payload: customers.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getCustomers = ({ ...params }) => async (dispatch) => {
  console.log("params", params);
  try {
    dispatch({ type: "CUSTOMER_DATA_FETCH_STARTED" });

    let customers = await axios.get(`${API_BASE}/customers`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        limit: 100, // to test
      },
    });

    dispatch({
      type: "CUSTOMER_DATA_FETCH_SUCCESS",
      payload: customers.data.allCustomers,
    });
    dispatch({
      type: "SET_LAST_PAGE",
      payload: customers.data.lastPage.isLastPage,
    });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getCustomersBySearchQuery = (q) => async (dispatch) => {
  console.log("params", q);
  try {
    dispatch({ type: "CUSTOMER_DATA_FETCH_STARTED" });

    let customers = await axios.get(`${API_BASE}/customers/search`, {
      params: {
        searchField: q,
      },
    });

    dispatch({
      type: "CUSTOMER_DATA_FETCH_SUCCESS",
      payload: customers.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getCustomerGroups = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: "CUSTOMER_GROUP_DATA_FETCH_STARTED" });

    let customers = await axios.get(`${API_BASE}/customers/xero-contact-groups`, {
      params: {
        group_id: groupId,
      },
    });
    if (!groupId) {
      dispatch({
        type: "CUSTOMER_GROUP_DATA_FETCH_SUCCESS",
        payload: customers.data,
      });
    } else {
      dispatch({
        type: "CUSTOMER_DATA_FETCH_SUCCESS",
        payload: customers.data,
      });
    }

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const removeCustomers = (customers) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    let response = await axios.post(`${API_BASE}/remove-customers`, customers);
    dispatch({
      type: "CUSTOMER_DATA_FETCH_SUCCESS",
      payload: response.data.allCustomers,
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed customer(s)."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
