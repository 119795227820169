import { Button, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import { getUserBankAccounts } from "./../../store/actions/bankingActions";

const SupplierBankAccountDialog = ({
  open,
  setOpen,
  dispatch,
  useSelector,
  addSupplierBankAccount,
  errors,
  selected,
  title,
  description,
}) => {
  const { register, control, handleSubmit, setValue } = useForm();

  let [editOrAdd, setEditOrAdd] = React.useState("add");
  let [selectedBankAccount, setSelectedBankAccount] = useState(null);

  //fetcing supplier bank accounts
  useEffect(() => {
    if (selected) {
      dispatch(getUserBankAccounts(selected.id));
    }
    // eslint-disable-next-line
  }, [selected]);

  let suppBankAccounts = useSelector((state) => state.banking.userBankAccounts);

  const supplierBankAccounts = () => {
    if (suppBankAccounts && suppBankAccounts.length > 0) {
      let list = suppBankAccounts.map((cust) => {
        return { value: cust.account_id, label: cust.display_name };
      });
      return list;
    } else {
      return [{ value: "null", label: "No customers" }];
    }
  };

  const onSubmit = (data) => {
    if (editOrAdd === "add") {
      let allDetails = { ...data, supplier_id: selected.id, currency: "GBP" };

      //TODO: check for length OF ACCT NUMBER AND SORTCODE
      dispatch(addSupplierBankAccount(allDetails));
      setOpen(false);
    }
  };

  useEffect(() => {
    if (selectedBankAccount) {
      let selectedBankName = suppBankAccounts.find(
        (x) => x.account_id === selectedBankAccount
      );
      if (selectedBankName) {
        setValue("account_number", selectedBankName.account_number.number);
        setValue("sort_code", selectedBankName.account_number.sort_code);
        setValue("display_name", selectedBankName.display_name);
      }
    }
    // eslint-disable-next-line
  }, [selectedBankAccount, editOrAdd]);

  useEffect(() => {});
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={editOrAdd === "edit" ? false : true}
    >
      <>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              id="type"
              value="add"
              onClick={() => setEditOrAdd("add")}
            >
              Add New
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              id="type"
              value="edit"
              onClick={() => setEditOrAdd("edit")}
            >
              View
            </Button>
          </Grid>
        </Grid>
      </>

      {editOrAdd === "edit" ? (
        <>
          <DropdownComponent
            required
            id="customer"
            name="customer"
            label="Select a bank account"
            items={supplierBankAccounts()}
            error={errors.customer ? true : false}
            helperText={errors.customer ?? ""}
            onChange={(e) => {
              setSelectedBankAccount(e.target.value);
            }}
            control={control}
          />
          {selectedBankAccount && (
            <>
              <TextField
                required
                fullWidth
                margin="dense"
                id="display_name"
                name="display_name"
                label="Display Name"
                error={errors.display_name ? true : false}
                inputRef={register}
                helperText={errors.display_name ?? ""}
              />
              <TextField
                required
                autoFocus
                margin="dense"
                id="account_number"
                name="account_number"
                label="Account Number"
                error={errors.account_number ? true : false}
                helperText={errors.account_number ?? ""}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
              <TextField
                required
                fullWidth
                margin="dense"
                id="sort_code"
                name="sort_code"
                label="Sort Code"
                error={errors.sort_code ? true : false}
                helperText={errors.sort_code ?? ""}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
              />
            </>
          )}
        </>
      ) : (
        <>
          <TextField
            required
            fullWidth
            margin="dense"
            id="display_name"
            name="display_name"
            label="Display Name"
            error={errors.display_name ? true : false}
            inputRef={register}
            helperText={errors.display_name ?? ""}
          />
          <TextField
            required
            autoFocus
            margin="dense"
            id="account_number"
            name="account_number"
            label="Account Number"
            error={errors.account_number ? true : false}
            helperText={errors.account_number ?? ""}
            inputRef={register}
            fullWidth
          />
          <br />

          <TextField
            required
            fullWidth
            margin="dense"
            id="sort_code"
            name="sort_code"
            label="Sort Code"
            error={errors.sort_code ? true : false}
            inputRef={register}
            helperText={errors.sort_code ?? ""}
          />
        </>
      )}
    </DialogComponent>
  );
};
export default SupplierBankAccountDialog;
