import { Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFactoringRequestedInvoices, getFinanceBasketData, getFinanceBaskets, saveRequest } from "../../../store/actions/financeActions";
import FinanceCards from "../FinanceCards";
import FinanceSearchFilter from "../FinanceSearchFilter";
import { useFinanceStyles } from "../FinanceStyles";
import DropdownComponent from "../../../Components/DropdownComponent";
import { useForm } from "react-hook-form";
import FinancingInvoicesTable from "../SearchResult/FinancingInvoicesTable";
import BusinessDetailsDialog from "../BusinessDetailsDialog";
import { getSupplier } from "../../../store/actions/supplierActions";
import FinanceBasketTableToolbar from "../FinanceBasketTableToolbar";

const FactoringRequestedInvoices = ({ history }) => {
  const classes = useFinanceStyles();
  const dispatch = useDispatch();
  let { errors, control } = useForm();

  let isLoading = useSelector((state) => state.finance.isLoading);
  let data = useSelector((state) => state.finance.factoringRequestedInvoicesDetails);
  let suppDetails = useSelector((state) => state.supplier.singleSupplier);

  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [openBusinessDetailsDialog, setOpenBusinessDetailsDialog] = useState(false);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    suppliersList();
    allInvoicesForTable();
  }, [data]);

  useEffect(() => {
    if (selectedSupplier) {
      dataForFITable();
      getBaskets();
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (selectedSupplier && invoices.length > 0) {
      setSelected([]);
      dispatch(getSupplier(invoices[0].supplier_doc_id));
    }
  }, [invoices]);

  useEffect(() => {
    setSupplierDetails(suppDetails);
  }, [suppDetails]);

  useEffect(() => {
    if (selected.length === 1) {
      dispatch({ type: "SELECTED_SUPPLIER_FOR_FINANCE", payload: selected[0].supplier_name });
    }
  }, [selected]);

  useEffect(() => {
    getBaskets();
  }, []);

  const getBaskets = () => {
    dispatch(getFinanceBaskets());
  };

  const suppliersList = async () => {
    let invoices = data?.financing_invoices;
    if (invoices && Object.keys(invoices).length > 0) {
      let allSuppliers = Object.keys(invoices);
      let result = await Promise.all(
        allSuppliers.map((supplier) => {
          return { value: supplier, label: supplier };
        })
      );

      setSuppliers(result);
    }
  };

  const dataForFITable = async () => {
    if (data?.financing_invoices && selectedSupplier) {
      let suppliersInvoices = data.financing_invoices[selectedSupplier];
      let invoices = [];
      for (let inv of suppliersInvoices) {
        inv.invoice.bid_opens_at = inv.bid_opens_at;
        inv.invoice.bid_closes_at = inv.bid_closes_at;
        inv.invoice.customer_name = inv?.invoice?.customer?.name;
        inv.invoice.supplier = inv.invoice.supplier_doc_id;
        inv.invoice.financing_request_id = inv.id;
        inv.invoice.supplier_name = selectedSupplier;

        invoices.push(inv.invoice);
      }
      setInvoices(await sortInvoicesByDueDate(invoices));
    }
  };

  const allInvoicesForTable = async () => {
    let supplierKeys = data?.financing_invoices ? Object.keys(data.financing_invoices) : [];
    let allInvoices = [];

    for (let supplierKey of supplierKeys) {
      let invoices = data.financing_invoices[supplierKey];
      for (let inv of invoices) {
        inv.invoice.bid_opens_at = inv.bid_opens_at;
        inv.invoice.bid_closes_at = inv.bid_closes_at;
        inv.invoice.customer_name = inv?.invoice?.customer?.name;
        inv.invoice.supplier = inv.invoice.supplier_doc_id;
        inv.invoice.financing_request_id = inv.id;
        inv.invoice.supplier_name = supplierKey;
        allInvoices.push(inv.invoice);
      }
    }

    setInvoices(await sortInvoicesByDueDate(allInvoices));
  };

  const sortInvoicesByDueDate = async (invoices) => {
    await invoices.sort(function(a, b) {
      var dateA = new Date(a.due_date),
        dateB = new Date(b.due_date);
      return dateA < dateB ? 1 : -1;
    });
    return invoices;
  };

  // ?????
  // is this cleaning payload when route changes?
  // history.listen((location, action) => {
  //   dispatch({ type: "SET_FACTORING_REQUESTED_INVOICES", payload: [] });
  // });

  return (
    <div className={classes.root}>
      <FinanceSearchFilter getInv={getFactoringRequestedInvoices} dispatch={dispatch} />
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          {data?.count && (
            <>
              <div style={{ marginTop: "20px", borderStyle: "groove" }}>
                <Typography style={{ fontSize: "20px" }}>
                  Totally <strong>{data.count}</strong> invoices found
                </Typography>
              </div>
            </>
          )}

          {suppliers.length > 0 && (
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <DropdownComponent
                required
                fullWidth
                id="sup"
                name="sup"
                label="Select a supplier"
                items={suppliers}
                onChange={(e) => {
                  setSelectedSupplier(e.target.value);
                  dispatch({ type: "SELECTED_SUPPLIER_FOR_FINANCE", payload: e.target.value });
                }}
                control={control}
              />
            </div>
          )}
          {selectedSupplier && (
            <Button onClick={() => setOpenBusinessDetailsDialog(true)} color="secondary">
              Click to see Company Details
            </Button>
          )}

          {supplierDetails && openBusinessDetailsDialog && (
            <BusinessDetailsDialog business={supplierDetails} open={openBusinessDetailsDialog} setOpen={setOpenBusinessDetailsDialog} title="Company Details" />
          )}
          {invoices.length > 0 && <FinancingInvoicesTable data={invoices} selected={selected} setSelected={setSelected} />}
        </>
      )}

      {/* <FinanceCards history={history} data={FactoringRequestedInvoices} dispatch={dispatch} saveRequest={saveRequest} setData={setFactoringRequestedInvoices} /> */}
    </div>
  );
};

export default FactoringRequestedInvoices;
