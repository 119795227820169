import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TableComponent from "../../Components/Table/TableComponent";
import { getFinanceBaskets } from "../../store/actions/financeActions";
import FinanceBasketTableToolbar from "./FinanceBasketTableToolbar";

const headCells = [
  { id: "supplier_name", label: "Supplier" },
  { id: "total_invoices_in_basket", label: "Total Invoice in Basket" },
  { id: "total_amount", label: "Total Amount" },
];

const FinanceBasketTable = () => {
  let dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [tableData, setTableData] = useState([]);

  let isLoading = useSelector((state) => state.finance.isLoading);
  let financeBaskests = useSelector((state) => state.finance.financeBasket);

  useEffect(() => {
    dispatch(getFinanceBaskets());
  }, []);

  useEffect(() => {
    if (financeBaskests?.baskets?.length > 0) {
      setTableData(financeBaskests?.baskets);
    }
  }, [financeBaskests]);

  const financeBasketTableToolbar = () => {
    return <FinanceBasketTableToolbar header="Finance Baskets" selected={selected} setSelected={setSelected} />;
  };

  return (
    <>
      <TableComponent
        toolbar={financeBasketTableToolbar()}
        data={tableData}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isLoading={isLoading}
        getAllData={getFinanceBaskets}
        searchBarText="Search customers"
        shouldGetData={true}
        shouldPaginate={false}
        shouldView={true}
      />
    </>
  );
};
export default FinanceBasketTable;
