import {
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import countryList from "react-select-country-list";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../../Components/DropdownComponent";
import PhoneInputComponent from "./../../../Components/PhoneInputComponent";
import { v1 as uuidv1 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectSearch from "react-select-search";
import NumberFormatCustom from "../../../Components/NumberFormatCustom";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersBySearchQuery } from "../../../store/actions/customerActions";
import currency from "currency.js";
import { createPackage } from "../../../store/actions/packageActions";

const packageTypes = [
  {
    label: "Business membership",
    value: "business_membership",
  },
  {
    label: "Transfer money membership",
    value: "transfer_money_membership",
  },
  {
    label: "More Transfer",
    value: "transfer_money_token",
  },
  {
    label: "More Payment",
    value: "payment_token",
  },
];

const packageCategories = [
  {
    label: "Business",
    value: "business",
  },
  {
    label: "Individual",
    value: "individual",
  },
];

const packageInvividualSubCategories = [
  {
    label: "Standard",
    value: "standard",
  },
  {
    label: "Special Person",
    value: "special_person",
  },
  {
    label: "Payeda Community",
    value: "payeda_community",
  },
];

const packageBusinessSubCategories = [
  {
    label: "Standard",
    value: "standard",
  },
  {
    label: "E-commerce",
    value: "ecommerce",
  },
  {
    label: "Special Supplier",
    value: "special_supplier",
  },
  {
    label: "Payeda Community/NGO member",
    value: "payeda_community",
  },
];
const PackageDialog = ({
  open,
  setOpen,
  title,
  description,
  setErrors,
  errors,
  onSubmit,
  defaultValues,
  business,
  isEdit,
  selectedPackage,
}) => {
  const { register, control, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const [selectedPackageType, setSelectedPackageType] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();

  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFature] = useState();
  const [supplierToSearch, setSupplierToSearch] = useState();
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState();

  const customers = useSelector((state) => state.customer.customers);

  const inputEl = React.useRef(null);

  useEffect(() => {
    if (supplierToSearch?.length > 3) {
      dispatch(getCustomersBySearchQuery(supplierToSearch));
    }
  }, [supplierToSearch]);

  useEffect(() => {
    if (isEdit && selectedPackage?.packageAddingOption) {
      setSelectedCategory(selectedPackage.package_category);
      setSelectedSubCategory(selectedPackage.package_subcategory);
      setSelectedPackageType(selectedPackage.package_type);
      setSelectedOption(selectedPackage.packageAddingOption);

      let allFeatures = selectedPackage?.package_features.map((f) => {
        return { id: uuidv1(), name: f };
      });
      setFeatures(allFeatures || []);
    }
  }, [isEdit, selectedPackage]);

  const handleAddFeature = () => {
    const feature = { id: uuidv1(), name: newFeature };
    setFeatures([...features, feature]);
    inputEl.current.value = "";
  };

  const handleDeleteFeature = (id) => {
    setFeatures(features.filter((fe) => fe.id !== id));
  };

  const renderAddFeatures = () => {
    return (
      <>
        Features:
        {features &&
          features.map((f) => {
            return (
              <>
                <Typography>
                  {f.name}{" "}
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDeleteFeature(f.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>{" "}
                </Typography>
              </>
            );
          })}
        <TextField
          margin="dense"
          variant="outlined"
          required
          autoFocus
          id="feature"
          name="feature"
          label="Type a feature"
          fullWidth
          inputRef={inputEl}
          onChange={(e) => {
            setNewFature(e.target.value);
          }}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleAddFeature()}
        >
          Add feature
        </Button>
        <br />
      </>
    );
  };

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
  };

  const renderOptionForPackage = () => {
    return (
      <form>
        <div>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}>
              Choose package style
            </strong>
          </Typography>
          <div>
            <input
              name="packageAddingOption"
              id="general"
              value="general"
              checked={
                selectedOption === "general" ||
                (isEdit && selectedPackage?.packageAddingOption === "general")
              }
              type="radio"
              onChange={(e) => handleRadioChange(e)}
            />
            <label for="packageAddingOption">General package</label>
          </div>
          <div>
            <input
              name="packageAddingOption"
              id="offer"
              value="offer"
              checked={
                selectedOption === "offer" ||
                (isEdit && selectedPackage?.packageAddingOption === "offer")
              }
              type="radio"
              onChange={(e) => handleRadioChange(e)}
            />
            <label for="packageAddingOption">Special offer</label>
          </div>
        </div>
      </form>
    );
  };

  const handleSearchSupplier = () => {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={customers}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          setSelectedCustomer(newValue);
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for customer"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSupplierToSearch(e.target.value);
            }}
          />
        )}
      />
    );
  };

  const handleAddPackage = (data) => {
    let dataToSend = {};

    if (!selectedOption || selectedOption === "") {
      setErrors({ general: "Please select package adding option" });
      return false;
    }
    //  else if (selectedOption === "offer") {
    //   if (!selectedCustomer) {
    //     setErrors({ general: "Please select a customer to set an offer" });
    //   }
    // }
    if (selectedPackageType === "business_membership") {
      dataToSend = {
        bank_token: data.bank_token,
        payment_token: data.payment_token,
        transfer_money_token: data.transfer_money_token,
        package_fee: currency(data.package_fee)
          .format({ symbol: "" })
          .toString(),
        package_name: data.package_name,
        monthly_quota: data.monthly_quota || "",
        expire_duration: 90,
        expire_duration_type: "day",
        shouldExpire: true,
        commission_rate: data.commission_rate,
      };
    } else if (selectedPackageType === "transfer_money_membership") {
      dataToSend = {
        bank_token: data.bank_token,
        transfer_money_token: data.transfer_money_token,
        package_fee: currency(data.package_fee)
          .format({ symbol: "" })
          .toString(),
        package_name: data.package_name,
        monthly_quota: data.monthly_quota || "",
        expire_duration: 90,
        expire_duration_type: "day",
        shouldExpire: true,
        commission_rate: data.commission_rate,
      };
    } else if (selectedPackageType === "transfer_money_token") {
      dataToSend = {
        transfer_money_token: data.transfer_money_token,
        package_fee: currency(data.package_fee)
          .format({ symbol: "" })
          .toString(),
        package_name: data.package_name,
      };
    } else if (selectedPackageType === "payment_token") {
      dataToSend = {
        payment_token: data.payment_token,
        package_fee: currency(data.package_fee)
          .format({ symbol: "" })
          .toString(),
        package_name: data.package_name,
      };
    } else {
      setErrors({ general: "Please select a package type" });
      return false;
    }
    dataToSend.packageAddingOption = selectedOption;
    dataToSend.package_type = selectedPackageType;
    dataToSend.package_category = selectedCategory;
    dataToSend.package_subcategory = selectedSubCategory;
    dataToSend.discount_rate = 0;
    dataToSend.package_features = features.map((f) => {
      return f.name;
    });

    if (isEdit && selectedPackage) {
      dataToSend.isEdit = true;
      dataToSend.package_id = selectedPackage.id;
    }
    dispatch(createPackage(dataToSend, isEdit));
    setOpen(false);
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit((data) => handleAddPackage(data))}
      withActions={true}
    >
      {renderOptionForPackage()}
      <br />
      {/* {selectedOption === "offer" && handleSearchSupplier()} */}

      <DropdownComponent
        required
        id="package_category"
        name="package_category"
        label="Choose package category"
        items={packageCategories}
        defaultValue={isEdit && selectedPackage?.package_category}
        control={control}
        onChange={(e) => {
          setSelectedCategory(e.target.value);
        }}
        inputRef={register}
      />
      {selectedCategory && (
        <DropdownComponent
          required
          id="package_sub_category"
          name="package_sub_category"
          label="Choose package sub category"
          defaultValue={isEdit && selectedPackage?.package_subcategory}
          items={
            selectedCategory === "business"
              ? packageBusinessSubCategories
              : packageInvividualSubCategories
          }
          control={control}
          onChange={(e) => {
            setSelectedSubCategory(e.target.value);
          }}
          inputRef={register}
        />
      )}
      {selectedCategory && selectedSubCategory && (
        <DropdownComponent
          required
          id="package_type"
          name="package_type"
          label="Choose a Package Type"
          defaultValue={isEdit && selectedPackage?.package_type}
          items={packageTypes}
          control={control}
          onChange={(e) => {
            setSelectedPackageType(e.target.value);
          }}
          inputRef={register}
        />
      )}

      <Typography style={{ width: "300px" }}></Typography>
      {selectedPackageType && (
        <>
          <TextField
            margin="dense"
            variant="outlined"
            required
            autoFocus
            id="package_name"
            name="package_name"
            defaultValue={
              isEdit && selectedPackage?.package_name
                ? selectedPackage?.package_name
                : ""
            }
            label="Package Name"
            fullWidth
            inputRef={register}
          />

          {["business_membership", "transfer_money_membership"].includes(
            selectedPackageType
          ) && (
            <>
              {selectedOption === "offer" && (
                <TextField
                  margin="dense"
                  variant="outlined"
                  autoFocus
                  id="monthly_quota"
                  name="monthly_quota"
                  label="Monthly Quota"
                  defaultValue={
                    isEdit && selectedPackage?.monthly_quota
                      ? selectedPackage?.monthly_quota
                      : ""
                  }
                  fullWidth
                  inputRef={register}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              )}

              <TextField
                margin="dense"
                variant="outlined"
                required
                autoFocus
                id="commission_rate"
                name="commission_rate"
                label="Comission Rate (%)"
                defaultValue={
                  isEdit && selectedPackage?.commission_rate
                    ? selectedPackage?.commission_rate
                    : ""
                }
                fullWidth
                inputRef={register}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <TextField
                margin="dense"
                variant="outlined"
                required
                autoFocus
                id="bank_token"
                name="bank_token"
                label="Bank Token (Number of banks that can be connected)"
                defaultValue={
                  isEdit && selectedPackage?.bank_token
                    ? selectedPackage?.bank_token
                    : ""
                }
                fullWidth
                inputRef={register}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </>
          )}

          {["business_membership", "payment_token"].includes(
            selectedPackageType
          ) && (
            <TextField
              margin="dense"
              variant="outlined"
              required
              autoFocus
              id="payment_token"
              name="payment_token"
              label="Payment Token (GBP)"
              defaultValue={
                isEdit && selectedPackage?.payment_token
                  ? selectedPackage?.payment_token
                  : ""
              }
              fullWidth
              inputRef={register}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          )}
          {[
            "business_membership",
            "transfer_money_membership",
            "transfer_money_token",
          ].includes(selectedPackageType) && (
            <TextField
              margin="dense"
              variant="outlined"
              required
              autoFocus
              id="transfer_money_token"
              name="transfer_money_token"
              label="Transfer Money Token (GBP)"
              defaultValue={
                isEdit && selectedPackage?.transfer_money_token
                  ? selectedPackage?.transfer_money_token
                  : ""
              }
              fullWidth
              inputRef={register}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          )}
          <TextField
            margin="dense"
            variant="outlined"
            required
            autoFocus
            id="package_fee"
            name="package_fee"
            label="Package Fee (GBP)"
            defaultValue={
              isEdit && selectedPackage?.package_fee
                ? selectedPackage?.package_fee
                : ""
            }
            fullWidth
            inputRef={register}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          {renderAddFeatures()}
        </>
      )}

      <br />
    </DialogComponent>
  );
};

export default PackageDialog;
