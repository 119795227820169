import { Grid, TextField, Typography } from "@material-ui/core";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import NumberFormatCustom from "../../Components/NumberFormatCustom";
import UploadComponent from "../../Components/UploadComponent";
import { createFinancingOffer } from "../../store/actions/financeActions";
import { financingOfferSchema } from "../../util/yup/yupSchemas";
import verifySchema from "../../util/yup/verifySchema";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector } from "react-redux";

const FinanceOfferDialog = ({ open, setOpen, title, description, errors, setErrors, history, clearErrors, dispatch, total, basketInformations }) => {
  const { register, control, handleSubmit } = useForm();

  const offerLoading = useSelector((state) => state.finance.offerLoading);

  const [advancePaymentAmount, setAdvancePaymentAmount] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [remainingAmount, setRemainingAmount] = useState("");

  const [advancePercentage, setAdvancePercentage] = useState("");
  const [expensePercentage, setExpensePercentage] = useState("");
  const [remainingPercentage, setRemainingPercentage] = useState("");
  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());

  function convertUTCDateToLocalDate(date) {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    let timeString = "23:59:00";

    return new Date(yyyy + "-" + mm + "-" + dd + " " + timeString).toUTCString();
  }
  const onSubmit = async (data) => {
    let dataToSend = { ...data };
    dataToSend.basket_id = basketInformations[0].basket_id;
    dataToSend.supplier_id = basketInformations[0].supplier_id;
    dataToSend.valid_until = await convertUTCDateToLocalDate(selectedIssuDate);

    const valid = await verifySchema(financingOfferSchema, dataToSend, clearErrors, setErrors);
    if (valid) {
      let fd = new FormData();
      for (const [key, value] of Object.entries(dataToSend)) {
        if (key === "offer_pdf" && value.item(0) !== null) {
          dataToSend[key] = value.item(0);
        }
      }
      for (var key in dataToSend) {
        fd.append(key, dataToSend[key]);
      }
      dispatch(createFinancingOffer(fd, history));
      // setOpen(false);
    }
  };

  useEffect(() => {
    if ((expenseAmount !== "" || expenseAmount !== "0.00") && expensePercentage !== "") {
      let totalAdvanceAndExpenseAmount = currency(advancePaymentAmount)
        .add(expenseAmount)
        .format({ symbol: "" });
      let findRemainingAmount = currency(total)
        .subtract(totalAdvanceAndExpenseAmount)
        .format({ symbol: "" });
      setRemainingAmount(findRemainingAmount);

      let totalPercentage = currency(advancePercentage)
        .add(expensePercentage)
        .format({ symbol: "" });

      let calculateRemainingPercentage = currency(100)
        .subtract(totalPercentage)
        .format({ symbol: "" });
      setRemainingPercentage(calculateRemainingPercentage);
    } else {
      setRemainingPercentage("");
      setRemainingAmount("");
    }
    // eslint-disable-next-line
  }, [expenseAmount]);

  const calculatePerct = (e, advance, expense, remaning) => {
    if (advance) {
      let amount = currency(total)
        .multiply(e)
        .divide(100);
      setAdvancePercentage(currency(e).format({ symbol: "" }));
      setAdvancePaymentAmount(currency(amount).format({ symbol: "" }));
    } else if (expense) {
      let amount = currency(total)
        .multiply(e)
        .divide(100);
      setExpensePercentage(currency(e).format({ symbol: "" }));
      setExpenseAmount(currency(amount).format({ symbol: "" }));
    }
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={true}
      isDisabled={offerLoading}
      submitButtonText={offerLoading ? "Creating" : "Submit"}
    >
      <Typography style={{ marginBottom: "20px" }}>Total: {total}</Typography>

      <Grid container spacing={3}>
        <Grid xs={12}>Advance Amount: </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            required
            label="Percentage"
            variant="outlined"
            name="advance_payment_percentage"
            id="advance_payment_percentage"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => calculatePerct(e.target.value, true, false, false)}
            inputRef={register}
            error={errors.advance_payment_percentage ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Amount"
            variant="outlined"
            value={advancePaymentAmount}
            name="advance_payment_amount"
            id="advance_payment_amount"
            InputProps={{
              readOnly: true,
            }}
            inputRef={register}
            error={errors.advance_payment_amount ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Maturity"
            variant="outlined"
            name="advance_payment_maturity"
            id="advance_payment_maturity"
            inputRef={register}
            error={errors.advance_payment_maturity ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={5} md={5} lg={5}>
          <DropdownComponent
            required
            id="advance_payment_maturity_type"
            name="advance_payment_maturity_type"
            label="Maturity Type"
            items={[
              { value: "day", label: "DAY" },
              { value: "month", label: "MONTH" },
              { value: "year", label: "YEAR" },
            ]}
            error={errors.advance_payment_maturity_type ? true : false}
            control={control}
          />
        </Grid>

        <Grid xs={12}>Expense Amount: </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Percentage"
            variant="outlined"
            name="factoring_expense_percentage"
            id="factoring_expense_percentage"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => calculatePerct(e.target.value, false, true, false)}
            inputRef={register}
            error={errors.factoring_expense_percentage ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Amount"
            variant="outlined"
            value={expenseAmount}
            name="factoring_expense_amount"
            id="factoring_expense_amount"
            InputProps={{
              readOnly: true,
            }}
            inputRef={register}
            error={errors.factoring_expense_amount ? true : false}
          />
        </Grid>

        <Grid xs={12}>Remaining Amount: </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Percentage"
            variant="outlined"
            value={remainingPercentage}
            name="remaining_payment_percentage"
            id="remaining_payment_percentage"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            inputRef={register}
            error={errors.remaining_payment_percentage ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Amount"
            variant="outlined"
            value={remainingAmount}
            name="remaining_payment_amount"
            id="remaining_payment_amount"
            InputProps={{
              readOnly: true,
            }}
            inputRef={register}
            error={errors.remaining_payment_amount ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            label="Maturity"
            variant="outlined"
            name="remaining_payment_maturity"
            id="remaining_payment_maturity"
            inputRef={register}
            error={errors.remaining_payment_maturity ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <DropdownComponent
            required
            id="remaining_payment_maturity_type"
            name="remaining_payment_maturity_type"
            label="Maturity Type"
            items={[
              { value: "day", label: "DAY" },
              { value: "month", label: "MONTH" },
              { value: "year", label: "YEAR" },
            ]}
            error={errors.remaining_payment_maturity_type ? true : false}
            control={control}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <KeyboardDatePicker
              inputVariant="outlined"
              margin="dense"
              id="issued_date"
              name="issued_date"
              label="Valid until"
              value={selectedIssuDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              onChange={(date) => setSelectedIssueDate(date)}
              inputRef={register}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />{" "}
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid>
          <UploadComponent id="offer_pdf" name="offer_pdf" label="Pdf version of your offer" register={register} />
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default FinanceOfferDialog;
