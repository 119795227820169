import { Button } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import ImportInvoicesDialog from "./ImportInvoicesDialog";
import axios from "axios";
import { API_BASE } from "./../../store/env";
import { useDispatch } from "react-redux";
import { getFinancingRequestedInvoices } from "../../store/actions/invoiceActions";
import TableComponent from "./../../Components/Table/TableComponent";
import FinancingInvoicesToolbar from "./FinancingInvoicesToolbar";
import BidDetailsDialog from "./BidDetailsDialog";
import { getAllFinancingInvoices } from "../../store/actions/financingInvoicesAction";

const outgoingInvoicesTableHeadCells = [
  { id: "invoice_number", label: "Invoice Number" },
  { id: "customer_name", label: "Customer Name" },
  { id: "issued_date", label: "Issue Date" },
  { id: "due_date", label: "Due Date" },
  { id: "factoring_status", label: "Status" },

  { id: "amount_to_be_paid", label: "Amount to be Paid" },
];

const RequestFinancingPage = () => {
  const dispatch = useDispatch();

  let business = useSelector((state) => state.business);
  let financingInvoices = useSelector((state) => state.financing.financingInvoices);

  const [isUsingErpPlatform, setIsUsingErpPlatform] = useState(false);
  const [openImportInvoicesDialog, setOpenImportInvoicesDialog] = useState(false);
  const [shouldAwaitImport, setShouldAwaitImport] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openBidDetailsDialog, setOpenBidDetailsDialog] = useState(false);
  useEffect(() => {
    getFinancingInvoices();
  }, []);
  useEffect(() => {
    let platformUsage = business?.account_settings?.invoice_settings?.platform_usage;

    if (platformUsage) {
      if (platformUsage.isErp) setIsUsingErpPlatform(true);
      else setIsUsingErpPlatform(false);
    }
  }, [business]);

  const getFinancingInvoices = () => {
    dispatch(getAllFinancingInvoices());
  };

  const renderToolbar = () => {
    return (
      <FinancingInvoicesToolbar
        selected={selected}
        numSelected={selected.length}
        header="Request Financing"
        dispatch={dispatch}
        setOpenBidDetailsDialog={setOpenBidDetailsDialog}
      />
    );
  };
  return (
    <>
      <Button color="primary" variant="outlined" onClick={() => setOpenImportInvoicesDialog(true)}>
        Add invoice to list
      </Button>

      <TableComponent
        toolbar={renderToolbar()}
        data={financingInvoices}
        selected={selected}
        setSelected={setSelected}
        headCells={outgoingInvoicesTableHeadCells}
        shouldGetData={false}
        shouldPaginate={true}
        shouldView={true}
      />
      <ImportInvoicesDialog
        setShouldAwaitImport={setShouldAwaitImport}
        shouldAwaitImport={shouldAwaitImport}
        open={openImportInvoicesDialog}
        setOpen={setOpenImportInvoicesDialog}
        getFinancingInvoices={getFinancingInvoices}
        business={business}
      />
      <BidDetailsDialog open={openBidDetailsDialog} setOpen={setOpenBidDetailsDialog} selected={selected} setSelected={setSelected} />
    </>
  );
};

export default RequestFinancingPage;
