import React from "react";

import { Divider, Typography, Button } from "@material-ui/core";

import DialogComponent from "../../Components/Dialogs/DialogComponent";

const ConnectedErpApplicationDetailsDialog = ({ open, setOpen, title, description, errors, defaultValues, business, setErrors, setDeleteDialogOpen, ReSyncOrDisconnectButton }) => {
  const renderSyncedAccountDetails = () => {
    return (
      <>
        <Typography> Connected Application: {business?.account_settings?.invoice_settings?.cloud_app?.application}</Typography>
        <Typography> Connected Tenant: {business?.account_settings?.invoice_settings?.cloud_app?.tenant_name}</Typography>
        <Typography> Connected Bank Account ID: {business?.account_settings?.invoice_settings?.cloud_app?.bank_account_id}</Typography>
        <Divider />
        <Typography>
          {" "}
          <strong>Connected Bank Account Details : </strong>
          <br />
          Account Name: {business?.account_settings?.invoice_settings?.cloud_app?.bank_account_details?.Name}
          <br />
          Account Number: {business?.account_settings?.invoice_settings?.cloud_app?.bank_account_details?.BankAccountNumber}
          <br />
          Account Currency: {business?.account_settings?.invoice_settings?.cloud_app?.bank_account_details?.CurrencyCode}
        </Typography>
      </>
    );
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={""} description={""} withActions={false}>
      {renderSyncedAccountDetails()}
      <ReSyncOrDisconnectButton />
    </DialogComponent>
  );
};

export default ConnectedErpApplicationDetailsDialog;
