import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from './../../../util/constants/general';

export const useVerifyAccountPageStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 0,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        paddingLeft: drawerWidth + 40,

    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export const useAccountStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    content2: {
        display: "flex",
        flex: "1 1 auto",
        overlflow: "hidden",
        paddingTop : 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
          }

    },
    contentContainer: {
        display : "flex",
        flex : "1 1 auto",
        overflow : "hidden"
    },
    contentIn: {
        flex : "1 1 auto",
        height : "100%",
        overflow : "auto"
    },
    box: {
        padding: 10,
        margin: 10,
        display: "inline-block",
        borderStyle: "dashed",
        width: "min-content"
    },
    grid: {
        flexGrow: 1,
        margin: "10px",
        width: "80%",
    },
    paper: {
        width: "100%",
        color: theme.palette.text.secondary,
    },
}));



