import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useLoadingIconStyles } from './ComponentStyles';

const LoadingIcon = (props) => {
    let { sidebarOpen } = props;
    let classes = useLoadingIconStyles();
    return (
        (<main className={clsx(classes.content, {
            [classes.contentShift]: sidebarOpen
        })}>
            <CircularProgress
                variant="indeterminate"
            />
        </main >)
    )
}

const mapStateToProps = state => (
    {
        sidebarOpen: state?.ui?.sidebarOpen
    }
)

export default connect(mapStateToProps)(LoadingIcon);
