import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useVerifyAccountPageStyles } from './AccountStyles';
import { useUiContext } from './../../../Contexts/UiContext';

//TODO needs to be translated
const VerifyAccountPage = ({ user }) => {
    const classes = useVerifyAccountPageStyles();
    const { sidebarOpen } = useUiContext();

    return (
        <main className={clsx(classes.content, {
            [classes.contentShift]: sidebarOpen
        })}>
            <Grid container>
                {user.business ? (
                    <>
                        <Typography variant="h5" component="h2">Your account is waiting for admin approval to be active</Typography>
                        <Typography>We will inform you by e-mail when your account is approved.</Typography>
                    </>
                ): 
                (
                    <>
                        <Typography variant="h5" component="h2">Create a business account to get started</Typography>
                    </>
                )}
            </Grid>
        </main>
    )
}

export default VerifyAccountPage
