import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import TableComponent from "../../../../Components/Table/TableComponent";

const headCells = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "erp_no", label: "Erp No" },
    { id: "invite_information", label: "Invite" },
    // { id: "is_joined", label: "Joined"}
];

const Customers = ({ customers, isLoading }) => {
    const [selected, setSelected] = useState([]);

    return (
        <div>
            <br />
            <Typography variant="h4">{customers.length} customers found</Typography>
            <br />

            <TableComponent
                data={customers}
                isLoading={isLoading}
                shouldView={true}
                headCells={headCells}
                selected={selected}
                setSelected={setSelected}
                searchBarText="Search customers"
                shouldGetData={false}
                shouldPaginate={true}
                shouldSearch={true}
            />
        </div>
    )
}

export default Customers