import { Grid, Link, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import DialogComponent from "../../../Components/Dialogs/DialogComponent";

const BusinessBankAcctDetailsDialog = ({ open, setOpen, selected }) => {
  const renderAllAccounts = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid>
            {selected?.bank_statement ? (
              <>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(selected.bank_statement);
                  }}
                >
                  Download the bank statement
                </Link>
              </>
            ) : (
              <Typography style={{ color: "red" }}>
                No bank statement uploaded
              </Typography>
            )}
          </Grid>
          {selected?.accounts &&
            selected?.accounts.map((acct) => {
              return (
                <>
                  <Grid item xs={8} lg={8}>
                    <p>Display Name: {acct.display_name}</p>
                    <p>
                      Account Details: {acct.account_number.number} -{" "}
                      {acct.account_number.sort_code}
                    </p>
                  </Grid>
                  {acct?.isReceivePaymentAccount && (
                    <Grid item xs={2} lg={2}>
                      <p>
                        Matches <DoneIcon style={{ color: "green" }} />
                      </p>
                    </Grid>
                  )}
                </>
              );
            })}
        </Grid>
      </>
    );
  };
  return (
    <DialogComponent open={open} setOpen={setOpen} withActions={false}>
      {renderAllAccounts()}
    </DialogComponent>
  );
};

export default BusinessBankAcctDetailsDialog;
