import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { UI_API_BASE } from "../../store/env";
import { getPaymentResult } from "../../store/actions/bankingActions";
const PaymentResult = ({ paymentID, paymentHasError }) => {
  const dispatch = useDispatch();

  const [afterRender, setAfterRender] = useState(false); // internal state
  const [retryCount, setRetryCount] = useState(0);
  const [html, setHtml] = useState("");
  let paymentLoading = useSelector((state) => state.banking.paymentLoading);

  let result = useSelector((state) => state.banking);

  const getResultOnPaymentID = () => {
    // if (paymentID) {
    //   dispatch(getPaymentResult(paymentID));
    // }
    if (!paymentLoading) {
      dispatch({ type: "PAYMENT_RESULT_LOADING" });
      window.location.reload();
    }
  };

  useEffect(() => {
    setAfterRender(true); // (1) will be called after DOM rendered
  }, []);

  // <button onClick={()=> alert("hey")}>Download receipt</button>

  const DownloadReceipt = () => {
    alert("hohho");
  };

  // <button id="rc">Download receipt</button>

  useEffect(() => {
    console.log(window.location.href.indexOf("plans"));
    console.log(window.location.href.indexOf("transfer-money"));
    let receiptUrl = `${UI_API_BASE}/view-receipt?payment_id=${paymentID}`;
    let h = `<p>
    Payment order has been placed successfully. You can follow the result from our console</p> <br/><b>Your Receipt ID: </b>${paymentID} <br/> `;

    if (window.location.href.indexOf("plans") < 0 && window.location.href.indexOf("transfer-money") < 0) {
      console.log("goes here");

      console.log(window.location.href.indexOf("plans"));
      console.log(window.location.href.indexOf("transfer-money"));
      h = h + ` <br/> <a href=${receiptUrl}> Get your receipt</a>`;
    }
    setHtml(h);
  }, [paymentID]);

  const successAlert = () => {
    Swal.fire({
      title: "Payeda Confirmation Message",
      html: html,

      icon: "success",
      confirmButtonText: "Go to Console",
    }).then(async (val) => {
      if (val.isConfirmed) {
        window.location.reload();
        window.location.href = `${UI_API_BASE}/dashboard/banking-logs`;
      }
    });
  };

  const errorAlert = async () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      confirmButtonText: "RETRY",
      showCancelButton: true,
      cancelButtonText: "Go to dashboard",
    }).then(async (val) => {
      if (val.isConfirmed) {
        await getResultOnPaymentID();
      }
      if (val.isDismissed) {
        window.location.href = `${UI_API_BASE}/dashboard`;
      }
    });
  };

  const loadingAlert = () => {
    Swal.fire({
      title: "",
      text: "Loading...",
      showSpinner: true,
      footer: "Do not close this window, this may take a few minutes.",
      icon: "https://www.boasnotas.com/img/loading2.gif",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  };

  useEffect(() => {
    if (paymentLoading) {
      loadingAlert();
    }
    // eslint-disable-next-line
  }, [paymentLoading]);

  useEffect(() => {
    if (afterRender && result.paymentResponse.success === true) {
      successAlert();
      // document.getElementById("rc").onclick = function() {
      //   DownloadReceipt();
      // };
    } else if (afterRender && (result.paymentResponse.success === false || result.paymentFailed === true || paymentHasError)) {
      errorAlert();
    }
    // eslint-disable-next-line
  }, [result.paymentResponse, result.paymentFailed]);

  useEffect(() => {
    if (paymentHasError) {
      errorAlert();
    }
    // eslint-disable-next-line
  }, [paymentHasError]);

  // useEffect(() => {
  //   if (result.paymentFailed) {
  //     if (!paymentLoading && retryCount <= 3) {
  //       console.log("getting result on error");
  //       dispatch(getPaymentResult(paymentID));
  //       setRetryCount(retryCount + 1);
  //     }
  //     if (retryCount > 2) {
  //       errorAlert();
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [result, result.paymentFailed]);

  return <></>;
};

export default PaymentResult;
