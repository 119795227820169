import DateFnsUtils from "@date-io/date-fns";
import { Grid, Button } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../../Components/BasePage";
import DropdownComponent from "../../../../Components/DropdownComponent";
import TableComponent from "../../../../Components/Table/TableComponent";
import { getAllPayedaTransactions, getUnfinishedPayments } from "../../../../store/actions/adminActions";

const headCells = [
  { id: "count", label: "Number of Incomplete" },

  { id: "beneficiary_name", label: "Beneficiary Name" },
  { id: "business_admin_email", label: "Business Admin Email" },

  { id: "membershipStatus", label: "Membership Status" },
  { id: "balance", label: "Remaining Balance" },
  { id: "totalCredited", label: "Total Credit" },
];

const UnfinishesPayments = ({ business }) => {
  let dispatch = useDispatch();
  const [selected, setSelected] = useState([]);

  let payments = useSelector((state) => state.admin.unfinishedPayments);
  useEffect(() => {
    dispatch(getUnfinishedPayments());
  }, []);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BasePage navigation="Dashboard/Incompilete Payments" business={business} canView={true}>
        <TableComponent data={payments} selected={selected} setSelected={setSelected} headCells={headCells} shouldGetData={false} shouldView={true} />
      </BasePage>
    </MuiPickersUtilsProvider>
  );
};

export default UnfinishesPayments;
