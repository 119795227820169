import { makeStyles } from "@material-ui/core/styles";

export const useTimeLineStyles = makeStyles((theme) => ({
  timePaper: {
    padding: "6px 16px",
    boxShadow: "5px 3px 20px rgba(0,0,0,0.16)",
  },

  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },

  getStartedStyle: {
    marginTop: "20px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginLeft: "100px",
    },
    // boxShadow: "5px 3px 20px rgba(0,0,0,0.16)",
    padding: "16px",
  },
}));
