import { CHECKBOX_ID_INCREMENTS_BUS, CHECKBOX_LABELS_BUS, SECTION_HEADERS_BUS } from "../permissions/business";
import { CHECKBOX_ID_INCREMENTS_FIN, CHECKBOX_LABELS_FIN, SECTION_HEADERS_FIN } from "../permissions/finance";
import { CHECKBOX_ID_INCREMENTS_ADMIN, CHECKBOX_LABELS_ADMIN, SECTION_HEADERS_ADMIN } from "../permissions/admin";

const getPermissionFields = (accountType) => {
  let checkBoxIdIncrements, checkBoxLabels, sectionHeaders;

  if (accountType === "Business" || accountType === "Personal") {
    checkBoxIdIncrements = CHECKBOX_ID_INCREMENTS_BUS;
    checkBoxLabels = CHECKBOX_LABELS_BUS;
    sectionHeaders = SECTION_HEADERS_BUS;
  } else if (accountType === "Finance") {
    checkBoxIdIncrements = CHECKBOX_ID_INCREMENTS_FIN;
    checkBoxLabels = CHECKBOX_LABELS_FIN;
    sectionHeaders = SECTION_HEADERS_FIN;
  } else if (accountType === "Admin") {
    checkBoxIdIncrements = CHECKBOX_ID_INCREMENTS_ADMIN;
    checkBoxLabels = CHECKBOX_LABELS_ADMIN;
    sectionHeaders = SECTION_HEADERS_ADMIN;
  }

  return { checkBoxIdIncrements, checkBoxLabels, sectionHeaders };
};

export default getPermissionFields;
