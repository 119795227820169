import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "../../../Components/DropdownComponent";
import { usePermissionsContext } from "../../../Contexts/PermissionsContext";
import { useUiContext } from "../../../Contexts/UiContext";
import { getSelfBankAccounts, getUserRemainingBalance } from "../../../store/actions/bankingActions";
import { getMergedAccounts } from "../../../store/actions/businessActions";
import { approveInvoice, getIncomingInvoices, removeIncomingInvoices } from "../../../store/actions/invoiceActions";
import InvoicesListComponent from "../InvoicesListComponent";
import IncomingInvoicesFooter from "./IncomingInvoicesFooter";
import IncomingInvoicesToolbar from "./IncomingInvoicesToolbar";
import MakePaymentDialog from "../MakePaymentDialog";
import BasePage from "../../../Components/BasePage";

const statuses = [
  {
    value: "Awaiting Payment",
    label: "Awaiting Payment",
  },
  {
    value: "Pending Approval",
    label: "Pending Approval",
  },
  { value: "Paid", label: "Paid" },
];

const IncomingInvoices = ({ businessId, finances, availableInstitutions, makePayment }) => {
  const { setErrors, errors, clearErrors } = useUiContext();
  const { control } = useForm();

  const user = useSelector((state) => state.user);
  let business = useSelector((state) => state.business);

  const dispatch = useDispatch();

  const { permissions } = usePermissionsContext();
  const [selected, setSelected] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState("all_suppliers");
  const [selectedStatus, setSelectedStatus] = useState("Awaiting Payment");

  const [currentPage, setCurrentPage] = useState(1);
  const [shouldPaginateRedis, setShouldPaginateRedis] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [prevInterval, setPrevInternal] = useState(null);
  const [shouldGetData, setShouldGetData] = useState(true);
  const [concatData, setConcatData] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [makePaymentDialogOpen, setMakePaymentDialogOpen] = useState(false);
  const [afterRender, setAfterRender] = useState(false);

  let suppliers = useSelector((state) => state.supplier.suppliers);

  const paidIncomingInvoices = useSelector((state) => state.invoice.paidIncomingInvoices);
  const pendingIncomingInvoices = useSelector((state) => state.invoice.pendingIncomingInvoices);
  const paginateRedis = useSelector((state) => state.invoice.paginateRedis);

  const isLoading = useSelector((state) => state.invoice.isLoading);
  const mergedAccounts = useSelector((state) => state.business.mergedAccts);

  useEffect(() => {
    setAfterRender(true);
  }, []);

  useEffect(() => {
    if (user?.permissions?.incomingInvoice?.view) {
      dispatch(getMergedAccounts({}));
      dispatch(getUserRemainingBalance());
      dispatch(getSelfBankAccounts());
      dispatch(getIncomingInvoices([{ statusField: "Awaiting Payment" }]));
      dispatch(getIncomingInvoices([{ statusField: "Paid" }]));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newArray = [];
    if (paidIncomingInvoices && paidIncomingInvoices.length > 0) {
      Array.prototype.push.apply(newArray, paidIncomingInvoices);
    }
    if (pendingIncomingInvoices && pendingIncomingInvoices.length > 0) {
      Array.prototype.push.apply(newArray, pendingIncomingInvoices);
    }
    setConcatData(newArray);
  }, [paidIncomingInvoices, pendingIncomingInvoices]);

  useEffect(() => {
    setShouldPaginateRedis(paginateRedis);
    // eslint-disable-next-line
  }, [paginateRedis]);

  const getInvoices = () => {
    if (user?.permissions?.incomingInvoice?.view) {
      dispatch(getIncomingInvoices([{ statusField: "Awaiting Payment" }]));
      dispatch(getIncomingInvoices([{ statusField: "Paid" }]));
    }
  };

  const prepareDataToDisplay = () => {
    let filteredInvoices = [];

    if (shouldGetData && concatData && concatData.length > 0) {
      if (selectedSupplier === "all_suppliers") {
        filteredInvoices = concatData.filter((invoice) => invoice.status === selectedStatus);
      } else {
        filteredInvoices = concatData.filter((invoice) => invoice.status === selectedStatus && invoice.supplier === selectedSupplier);
      }
    }
    var uniq = {};

    var arrFiltered = filteredInvoices.filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true));
    if (!makePaymentDialogOpen) setDataForTable(arrFiltered);
    else return;
  };

  useEffect(() => {
    prepareDataToDisplay();
  }, [selectedSupplier, selectedStatus, shouldGetData, concatData, makePaymentDialogOpen]);

  // useEffect(() => {
  //   let intervalRef;
  //   if (afterRender && user?.permissions?.incomingInvoice?.view && !makePaymentDialogOpen) {
  //     intervalRef = setInterval(() => {
  //       getInvoices();
  //     }, 5000);
  //     setPrevInternal(intervalRef);
  //     return () => clearInterval(intervalRef);
  //   }
  // }, [afterRender, makePaymentDialogOpen]);

  // clear all intervals except the last one created so it keeps getting the data with the last status selected
  useEffect(() => {
    if (prevInterval !== null) {
      for (let i = 0; i < prevInterval; i++) {
        clearInterval(i);
      }
    }
  }, [prevInterval]);

  // clear all intervals when make payment dialog open to prevent cleaning the form
  useEffect(() => {
    if (makePaymentDialogOpen) {
      for (let i = 0; i <= prevInterval; i++) {
        clearInterval(i);
      }
    }
  }, [makePaymentDialogOpen]);

  const getSuppliers = () => {
    let suppliers = [{ value: "all_suppliers", label: "All Suppliers" }];
    if (mergedAccounts && mergedAccounts.length > 0) {
      mergedAccounts &&
        mergedAccounts.forEach((acct) => {
          suppliers.push({
            value: acct.inviter_id,
            label: acct.inviter_name,
          });
        });
    }

    return suppliers;
  };

  const renderSupplierSelectionDropbox = () => {
    return (
      <>
        <DropdownComponent
          required
          fullWidth
          id="selectedInformation"
          name="selectedInformation"
          label="Select a suppplier"
          items={getSuppliers()}
          onChange={(e) => {
            setSelectedSupplier(e.target.value);
          }}
          error={errors.selectedInformation ? true : false}
          helperText={errors.selectedInformation ?? ""}
          control={control}
          defaultValue={selectedSupplier}
        />
      </>
    );
  };

  const renderStatusSelectionDropbox = () => {
    return (
      <>
        <DropdownComponent
          required
          fullWidth
          id="selectedStatus"
          name="selectedStatus"
          label="Select a status"
          items={statuses}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
          error={errors.selectedStatus ? true : false}
          helperText={errors.selectedStatus ?? ""}
          control={control}
          defaultValue="Awaiting Payment"
        />
      </>
    );
  };

  const renderTableToolbar = () => {
    return (
      <IncomingInvoicesToolbar
        numSelected={selected ? selected.length : 0}
        header="Incoming Invoices"
        setDeleteDialogOpen={setDeleteDialogOpen}
        selectedInvoice={selected}
        suppliers={suppliers}
        permissions={permissions}
        setSelectedInvoices={setSelected}
        errors={errors}
        setErrors={setErrors}
        clearErrors={clearErrors}
        availableInstitutions={availableInstitutions}
        makePayment={makePayment}
        renderSupplierSelection={renderSupplierSelectionDropbox()}
        renderStatusSelection={renderStatusSelectionDropbox()}
        getIncomingInvoices={getIncomingInvoices}
        dispatch={dispatch}
        setCurrentPage={setCurrentPage}
        makePaymentDialogOpen={makePaymentDialogOpen}
        setMakePaymentDialogOpen={setMakePaymentDialogOpen}
      />
    );
  };

  const renderFooter = () => {
    return (
      <IncomingInvoicesFooter
        selected={selected}
        setSelected={setSelected}
        businessId={businessId}
        permissions={permissions}
        setErrors={setErrors}
        updateInvoiceStatus={approveInvoice}
        selectedSupplier={selectedSupplier && selectedSupplier.split("/")[1]}
        finances={finances}
        setDownloadPDF={setDownloadPDF}
        downloadPDF={downloadPDF}
        dispatch={dispatch}
      />
    );
  };

  const renderList = () => {
    return (
      <>
        <InvoicesListComponent
          toolbar={renderTableToolbar()}
          title="incoming"
          invoices={dataForTable}
          header="Incoming Invoices"
          businessId={businessId}
          selectedFieldId={selectedSupplier}
          removeInvoices={removeIncomingInvoices}
          footer={renderFooter()}
          selected={selected}
          setSelected={setSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          getAllData={getIncomingInvoices}
          dispatch={dispatch}
          setErrors={setErrors}
          shouldView={permissions?.incomingInvoice?.view}
          shouldGetData={shouldGetData}
          setShouldGetData={setShouldGetData}
          shouldPaginate={true}
          shouldSearch={false}
          isLoading={false}
          paginateRedis={shouldPaginateRedis}
          selectedStatus={selectedStatus}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          makePaymentDialogOpen={makePaymentDialogOpen}
          setMakePaymentDialogOpen={setMakePaymentDialogOpen}
        />
      </>
    );
  };

  return (
    <BasePage navigation="Dashboard/Incoming Invoices" business={business} canView={user?.permissions?.incomingInvoice?.view}>
      {renderList()}

      {makePaymentDialogOpen && (
        <MakePaymentDialog
          open={makePaymentDialogOpen}
          setOpen={setMakePaymentDialogOpen}
          selectedInvoices={selected}
          suppliers={suppliers}
          errors={errors}
          setErrors={setErrors}
          clearErrors={clearErrors}
          availableInstitutions={availableInstitutions}
          submitPayment={makePayment}
          //   // handleSubmit={handlePaymentSubmit}
          //   // handleCancel={() => setPaymentDialogOpen(false)}
        />
      )}
    </BasePage>
  );
};

export default IncomingInvoices;
