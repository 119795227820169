import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import shortid from "shortid";
import BasePage from "../../Components/BasePage";
import ConfirmDeleteDialog from "../../Components/Dialogs/ConfirmDeleteDialog";
import { invitePeople } from "../../store/actions/businessActions";
import { addSupplier, addSupplierBankAccount, editSupplier, editSupplierBankAccount, getSuppliers, removeSuppliers } from "../../store/actions/supplierActions";
import { supplierSchema } from "../../util/yup/yupSchemas";
import InviteDialog from "../Customer/InviteDialog";
import { useUiContext } from "./../../Contexts/UiContext";
import verifySchema from "./../../util/yup/verifySchema";
import SupplierBankAccountDialog from "./SupplierBankAccountDialog";
import SupplierDialog from "./SupplierDialog";
import SuppliersTable from "./SuppliersTable";

const selectSuppliers = createSelector(
  (state) => ({
    ...state,
  }),
  (state) => state.supplier
);
// const selectLastPage = createSelector(
//   (state) => state.lastPage,
// )

const Suppliers = ({ business, businessId, history }) => {
  const dispatch = useDispatch();

  const suppliers = useSelector((state) => selectSuppliers(state));
  const user = useSelector((state) => state.user);

  // const isLastPage = useSelector((state) =>selectLastPage(state));

  const isLastPage = true;
  const { errors, setErrors, clearErrors } = useUiContext();

  const [selected, setSelected] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [bankAccountDialogOpen, setBankAccountDialogOpen] = useState(false);
  const [msgToCustomer, setMsgToCustomer] = useState("Join to Payeda and upload invoices for me ");
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      // dispatch(getSuppliers({}));
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const onCreateNew = async (data) => {
    !data.country ?? (data.country = business.country);
    const valid = await verifySchema(supplierSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateDialogOpen(false);
      dispatch(addSupplier(data));
    }
  };

  const onEdit = async (data) => {
    const valid = await verifySchema(supplierSchema, data, clearErrors, setErrors);
    if (valid) {
      const dataToSend = { id: selected[0].id, ...data };
      setEditDialogOpen(false);
      dispatch(editSupplier(dataToSend));
      setSelected([]);
    }
  };

  const onDelete = async () => {
    let suppliersToDelete = selected.map((suppliers) => suppliers.id);
    let details = { suppliers: suppliersToDelete };
    dispatch(removeSuppliers(details));
    setSelected([]);
    setDeleteDialogOpen(false);
  };

  //TODO this always uses phone option even though you select email option. When email is selected use email option!
  const onInvite = async () => {
    if (selected.length === 1 && selected[0].whatsappChecked) {
      //TODO: check if user has already invited
      var inviteeInfo = selected[0];
      var inviteePhone = inviteeInfo.phone;

      var inviteCode;
      if (selected[0].invite_information.invited) {
        inviteCode = selected[0].invite_information.invitation_code;
      } else {
        inviteCode = shortid.generate();
      }
      window.open("https://api.whatsapp.com/send?phone=" + inviteePhone + "&text=" + msgToCustomer + " https://payeda-dev-8fa74.web.app/invite?code=" + inviteCode);

      selected[0].invite_information.invitation_code = inviteCode;

      dispatch(invitePeople(selected));
    } else {
      // eger toplu gönderim olacaksa (email) kod backend'de üretilecek ve mail atılacak
      selected[0].businessName = business.company_details.name;
      selected[0].msgToCustomer = msgToCustomer;
      dispatch(invitePeople(selected, true));
    }

    setInviteDialogOpen(false);
    setSelected([]);
  };

  return (
    <BasePage navigation="Dashboard/Suppliers" business={business} canView={user?.permissions?.supplier?.view}>
      <div>
        <>
          <SuppliersTable
            suppliers={suppliers.suppliers}
            setCreateDialogOpen={setCreateDialogOpen}
            setEditDialogOpen={setEditDialogOpen}
            setInviteDialogOpen={setInviteDialogOpen}
            setDeleteDialogOpen={setDeleteDialogOpen}
            setBankAccountDialogOpen={setBankAccountDialogOpen}
            selected={selected}
            setSelected={setSelected}
            isLoading={suppliers.isLoading}
            getAllData={getSuppliers}
            islastPage={isLastPage}
            history={history}
          />
          {business.company_details && (
            <>
              <SupplierDialog
                open={createDialogOpen}
                setOpen={setCreateDialogOpen}
                title="Create new suppliers"
                description="Create new suppliers to start receiving payments."
                onSubmit={onCreateNew}
                errors={errors}
                business={business}
              />

              <SupplierDialog
                open={editDialogOpen}
                setOpen={setEditDialogOpen}
                title="Edit suppliers"
                description="Edit details of the suppliers."
                onSubmit={onEdit}
                errors={errors}
                defaultValues={selected[0]}
                suppliers={suppliers}
                business={business}
              />
            </>
          )}
        </>

        <InviteDialog
          title="Invite the suppliers"
          description="Invite the suppliers to start bla bla"
          selected={selected}
          setSelected={setSelected}
          onSubmit={onInvite}
          open={inviteDialogOpen}
          setOpen={setInviteDialogOpen}
          msgToCustomer={msgToCustomer}
          setMsgToCustomer={setMsgToCustomer}
        />

        <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} />

        <SupplierBankAccountDialog
          open={bankAccountDialogOpen}
          setOpen={setBankAccountDialogOpen}
          errors={errors}
          selected={selected[0]}
          suppliers={suppliers}
          dispatch={dispatch}
          useSelector={useSelector}
          addSupplierBankAccount={addSupplierBankAccount}
          editSupplierBankAccount={editSupplierBankAccount}
        />
      </div>
    </BasePage>
  );
};

export default Suppliers;
