import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from './../../../../util/constants/general';

export const useLinkAccountStyle = makeStyles((theme) => ({
    root: {
        width: "100%",
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    accordionContent: {
        display: "block",
    },
    content: {
        flexGrow: 0,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        paddingLeft: drawerWidth + 40,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    footer: {
        margin: 10,
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    buttonBack: {
        marginTop: "20px",
        marginBottom: "10px",
        marginLeft: "10px",
        display: "flex",
        width: "50px",
        justifyContent: "flex-end",
        flexDirection: "column",
    },
    buttonNext: {
        marginTop: "10px",
        float: "right"
    },

    stepper: {
        padding: theme.spacing(3, 0, 5),
    },

    reviewPaper: {
        margin: "auto"
    }
}));

export const useReviewYourCodeStyles = makeStyles((theme) => ({
    avatar: {
        height: 100,
        width: 100
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));
