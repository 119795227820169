const initialState = {
  isLoading: false,
  authenticated: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AUTHENTICATED":
      return {
        ...state,
        authenticated: true,
      };
    case "SET_UNAUTHENTICATED":
      return {
        ...initialState,
      };
    case "USER_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "USER_DATA_FETCH_FAILED":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_USER":
      return {
        ...state,
        authenticated: true,
        isLoading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
