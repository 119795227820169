import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import MakePaymentDialog from "../MakePaymentDialog";
import TableToolbarComponent from "./../../../Components/Table/TableToolbarComponent";
import canPerformAction from "./../../../util/permissions/canPerformAction";
import { usePermissionsContext } from "./../../../Contexts/PermissionsContext";

const IncomingInvoicesToolbar = ({
  numSelected,
  header,
  errors,
  setErrors,
  clearErrors,
  suppliers,
  selectedInvoice,

  availableInstitutions,
  makePayment,
  renderSupplierSelection,
  renderStatusSelection,
  getIncomingInvoices,
  dispatch,
  setMakePaymentDialogOpen,
  makePaymentDialogOpen,
}) => {
  const { permissions } = usePermissionsContext();

  const handleOpenMakePayment = () => {
    if (!numSelected || numSelected === 0) {
      setErrors({ general: "Please select invoices to make payment" });
      return false;
    }
    let filterPaid = selectedInvoice.filter((invoice) => invoice.statusDetails.status === "Paid");
    if (filterPaid.length === 0) setMakePaymentDialogOpen(true);
    else setErrors({ general: "Choose only unpaid invoices!" });
  };

  const handleGetLatestInvoices = () => {
    // setCurrentPage(1);
    dispatch(getIncomingInvoices([{ cleanRedis: true }]));
  };

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {/* {numSelected > 0 &&
          canPerformAction("incominginvoices", permissions, "remove") && (
            <Tooltip title="Delete">
              <IconButton onClick={() => setDeleteDialogOpen(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )} */}
        {numSelected ? (
          <>
            {canPerformAction("incomingInvoices", permissions, "makePayment") && (
              <>
                <Button onClick={() => handleOpenMakePayment()} color="secondary" variant="contained" style={{ float: "right" }}>
                  Make Payment
                </Button>
                {makePaymentDialogOpen && (
                  <MakePaymentDialog
                    open={makePaymentDialogOpen}
                    setOpen={setMakePaymentDialogOpen}
                    selectedInvoices={selectedInvoice}
                    suppliers={suppliers}
                    errors={errors}
                    setErrors={setErrors}
                    clearErrors={clearErrors}
                    availableInstitutions={availableInstitutions}
                    submitPayment={makePayment}
                    //   // handleSubmit={handlePaymentSubmit}
                    //   // handleCancel={() => setPaymentDialogOpen(false)}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid
                container
                xs={12}
                lg={7}
                md={7}
                spacing={3}
                style={{
                  backgroundColor: "#FEEBF2",
                  borderRadius: "20px",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} lg={6} md={6}>
                  {renderSupplierSelection}
                </Grid>
                <Grid item xs={12} lg={4} md={4}>
                  {renderStatusSelection}
                </Grid>
              </Grid>

              <Grid item xs={12} lg={2} md={2} style={{ marginLeft: "40px" }}>
                {/* <Button
                  onClick={() => handleGetLatestInvoices()}
                  color="secondary"
                  variant="outlined"
                  style={{ float: "right" }}
                >
                  Get all invoices
                </Button> */}
              </Grid>
              <Grid item xs={12} lg={2} md={2}>
                {canPerformAction("incomingInvoices", permissions, "makePayment") && (
                  <>
                    <Button onClick={() => handleOpenMakePayment()} color="primary" variant="outlined" style={{ float: "right" }}>
                      Make Payment
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    </TableToolbarComponent>
  );
};

export default IncomingInvoicesToolbar;
