import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const AddInvoiceLineComponent = ({
  errors,
  loading,
  onInvoiceLineAdd,
  invoiceLines,
  setInvoiceLines,
  totalAmount,
  totalrate_of_vat,
  totalDiscount,
  products,
  isLoading,
}) => {
  const { register, control, watch, handleSubmit, setValue } = useForm();

  const watchProduct = watch("product");
  const watchQuantity = watch("product_quantity");
  const watchDiscount = watch("rate_of_discount");

  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [lineTotalAmount, setLineTotalAmount] = useState(0);
  const [lineVatAmount, setLineVatAmount] = useState(0);
  const [productName, setProductName] = useState("");
  const [lineUnitPrice, setLineUnitPrice] = useState(0);
  const [lineDiscountAmount, setLineDiscountAmount] = useState(0);

  //run once to setup initial values
  useEffect(() => {
    setValue("product_quantity", 1);
    setValue("rate_of_discount", 0);
    setValue("discount_amount", 0);
    setValue("unit_price", 0);
  }, [watchProduct, products, setValue]);

  useEffect(() => {
    let selectedProduct;

    if (products && watchProduct) {
      selectedProduct =
        products && products.find((x) => x.id === watchProduct.id);
    }

    if (selectedProduct) {
      let unitTotalAmount = currency(watchQuantity).multiply(
        selectedProduct.unit_price
      ).value;

      let discountRate = currency(watchDiscount)
        .divide(100)
        .multiply(unitTotalAmount).value;
      let discountedTotalAmount = currency(unitTotalAmount).subtract(
        discountRate
      ).value;

      let vatRate = currency(selectedProduct.rate_of_vat)
        .divide(100)
        .multiply(discountedTotalAmount).value;

      setLineVatAmount(vatRate);

      let subTotalAmount = currency(discountedTotalAmount).add(vatRate).value;

      setSubTotalAmount(currency(unitTotalAmount).value);

      setLineTotalAmount(subTotalAmount);
      setLineDiscountAmount(discountRate);
      setLineUnitPrice(selectedProduct.unit_price);
      setValue("unit_price", selectedProduct.unit_price);
      setValue("rate_of_vat", selectedProduct.rate_of_vat);
      setProductName(selectedProduct.product_name);
    }
    // eslint-disable-next-line
  }, [
    watchProduct,
    products,
    watchDiscount,
    watchQuantity,
    subTotalAmount,
    lineUnitPrice,
  ]);

  const handLineAadd = (data) => {
    let lineToAdd = {
      subtotal_amount: subTotalAmount,
      product: productName,
      product_quantity: data.product_quantity,
      unit_price: lineUnitPrice,
      line_vat_amount: lineVatAmount,
      line_total_amount: lineTotalAmount,
      line_discount_amount: lineDiscountAmount,
    };

    onInvoiceLineAdd(lineToAdd);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={3}>
        {/** TODO: paginate products here as well*/}
        {products && (
          <Controller
            render={(props) => (
              <Autocomplete
                {...props}
                options={products}
                getOptionLabel={(option) => option.product_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a product"
                    variant="outlined"
                  />
                )}
                onChange={(_, data) => props.onChange(data)}
              />
            )}
            name="product"
            id="product"
            control={control}
          />
        )}
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <TextField
          required
          fullWidth
          id="product_quantity"
          name="product_quantity"
          label="Quantity"
          error={errors.product_quantity ? true : false}
          helperText={errors.product_quantity ?? false}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <CurrencyTextField
          label="Unit Price"
          id="unit_price"
          name="unit_price"
          variant="standard"
          value={lineUnitPrice}
          currencySymbol="£"
          outputFormat="string"
          inputProps={{ ref: register }}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <TextField
          fullWidth
          id="rate_of_discount"
          name="rate_of_discount"
          label="Discount"
          error={errors.rate_of_discount ?? false}
          helperText={errors.rate_of_discount ?? false}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
      </Grid>

      <Grid item xs={12} md={2} lg={2}>
        <TextField
          fullWidth
          id="rate_of_vat"
          name="rate_of_vat"
          label="VAT Percentage"
          error={errors.rate_of_vat ?? false}
          helperText={errors.rate_of_vat ?? false}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
      </Grid>

      <Button
        variant="outlined"
        color="secondary"
        style={{ margin: 20 }}
        onClick={handleSubmit(handLineAadd)}
        fullWidth
      >
        Add Invoice Line
      </Button>
    </Grid>
  );
};

export default AddInvoiceLineComponent;
