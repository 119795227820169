import React from "react";
import clsx from "clsx";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTableToolbarStyles } from "./TableStyles";

const TableToolbarComponent = ({ numSelected, header, children }) => {
  const classes = useTableToolbarStyles();

  return (
    <Toolbar
    className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
    })}
>
      {numSelected > 0 ? (
        <Typography className={classes.menuButton}>
          {numSelected} items selected
        </Typography>
      ) : null}
      <Typography variant="h6" className={classes.title}></Typography>
      {children}
    </Toolbar>
  );
};

export default TableToolbarComponent;
