import React from "react";
import TableComponent from "./../../Components/Table/TableComponent";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";
import ProductsTableToolbar from "./ProductsTableToolbar";

const headCells = [
  { id: "product_name", label: "Product Name" },
  { id: "unit_price", label: "Unit Price" },
  { id: "currency", label: "Currency" },
  { id: "rate_of_vat", label: "Rate of Vat" },
  { id: "category", label: "Category" },
];

const searchOptions = [
  { default: true, id: "category_lower", label: "Category" },
  { id: "product_name_lower", label: "Product Name" },
  { id: "rate_of_vat_lower", label: "Rate of Vat" },
  { id: "unit_price_lower", label: "Unit Price" },
];

const ProductsTable = ({ products, business, getAllData, isLoading, isLastPage, selected, setSelected, setCreateDialogOpen, setEditDialogOpen, setDeleteDialogOpen }) => {
  const { permissions } = usePermissionsContext();

  const renderToolbar = () => {
    return (
      <ProductsTableToolbar
        numSelected={selected ? selected.length : 0}
        header="Products"
        setCreateDialogOpen={setCreateDialogOpen}
        setEditDialogOpen={setEditDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        business={business}
      />
    );
  };

  return (
    <TableComponent
      toolbar={renderToolbar()}
      data={products}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      isLoading={isLoading}
      getAllData={getAllData}
      islastPage={isLastPage}
      searchOptions={searchOptions}
      shouldGetData={false}
      shouldPaginate={true}
      shouldView={permissions?.product?.view}
    />
  );
};

export default ProductsTable;
