import { Grid, Link, TextField } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";

import { Col } from "antd";
import axios from "axios";

import { React, useEffect, useState } from "react";
import { Slide, Zoom } from "react-awesome-reveal";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ButtonContainer, PricingCalculatorContainer, PricingBox, CalcItem, SliderContainer, CalculatorItem, Span } from "./styles";
import currency from "currency.js";
import { useUiContext } from "../../../Contexts/UiContext";
import { API_BASE } from "../../../store/env";
import MakePaymentDialog from "../../Invoice/MakePaymentDialog";
import { useHistory } from "react-router-dom";

const PricingCalculator = () => {
  const business = useSelector((state) => state.business);
  const { errors, sidebarOpen, clearErrors, setErrors } = useUiContext();
  const history = useHistory();
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);
  const [receivePaymentAmount, setReceivePaymentAmount] = useState(business.canReceivePayment ? 10 : 0);
  const [convertedReceivePaymentAmount, setConvertedReceivePaymentAmount] = useState(receivePaymentAmount);

  const [transferMoneyAmount, setTransferMoneyAmount] = useState(10);
  const [convertedTransferMoneyAmount, setConvertedTransferMoneyAmount] = useState(transferMoneyAmount);

  const [oldTransferBalance, setOldTransferBalance] = useState(0);
  const [oldPaymentBalance, setOldPaymentBalance] = useState(0);

  const [commissionRate, setCommissionRate] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [makePaymentDialogOpen, setMakePaymentDialogOpen] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [packId, setPackId] = useState("");

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }, []);

  useEffect(() => {
    if (business && business.payeda_package) {
      let BPackage = business.payeda_package;
      if (BPackage?.commission_rate) setCommissionRate(BPackage?.commission_rate);
      if (BPackage?.transfer_money_token) {
        let transferTokenBalance = parseFloat(BPackage.transfer_money_token.Balance || 0).toFixed(2);
        let moreTokenBalance = parseFloat(BPackage.transfer_money_token.more_token || 0).toFixed(2);
        setOldTransferBalance(parseFloat(transferTokenBalance) + parseFloat(moreTokenBalance));
      }
      if (BPackage?.payment_token) {
        let paymentTokenBalance = parseFloat(BPackage.payment_token.Balance || 0).toFixed(2);
        let moreTokenBalance = parseFloat(BPackage.payment_token.more_token || 0).toFixed(2)
        setOldPaymentBalance(parseFloat(paymentTokenBalance) + parseFloat(moreTokenBalance));
      }
    }
  }, [business]);

  const handleAnnualChange = (ev, val) => {
    setConvertedReceivePaymentAmount(val);
    setReceivePaymentAmount(val);
  };
  const handleTransactionFeeChange = (ev, val) => {
    setConvertedTransferMoneyAmount(val);
    setTransferMoneyAmount(val);
  };

  const handleBuyTokens = async () => {
    if (transferMoneyAmount === 0 && receivePaymentAmount === 0) {
      setErrors({ general: "Please sepecify either receive payment or transfer money balance" });
      return false;
    }

    if (transferMoneyAmount || receivePaymentAmount) {
      let dataToSend = {
        transferMoneyAmount,
        receivePaymentAmount: business.canReceivePayment ? receivePaymentAmount : 0,
        pk_type: "more_tokens",
      };
      try {
        setIsLoading(true);
        let response = await axios({
          method: "post",
          url: `${API_BASE}/v1/packages/payment`,
          data: dataToSend,
        });
        if (response.data.invoiceIds) {
          setIsLoading(false);
          setSelectedInvoices([...response.data.invoiceIds]);
          setPackId(response.data.package_id);
          setMakePaymentDialogOpen(true);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors({ general: error?.response?.data?.error?.general });
      }
    }
  };

  const calculateTransferMoneyBalance = () => {
    let selectedBalance = parseInt(transferMoneyAmount);
    let oldBalance = parseInt(oldTransferBalance / parseFloat(commissionRate));
    return selectedBalance + oldBalance;
  };

  const calculateREceivePaymentBalance = () => {
    let selectedBalance = parseInt(receivePaymentAmount);
    let oldBalance = parseInt(oldPaymentBalance / parseFloat(commissionRate));

    return selectedBalance + oldBalance;
  };
  // console.log((parseInt(receivePaymentAmount) + parseInt(transferMoneyAmount)) * parseFloat(commissionRate), receivePaymentAmount, transferMoneyAmount, commissionRate);
  return (
    <PricingCalculatorContainer id="pricing">
      {!business?.payeda_package && (
        <Alert severity="info" style={{ fontSize: "18px", backgroundColor: "#FAFAFA" }}>
          To upgrade your tokens you must have a membership package. <Link onClick={() => history.push("/dashboard/buy-plans")}>Click here</Link> to see membership packages
        </Alert>
      )}

      <div style={{ textAlign: "center", alignItems: "center" }}>
        <h3 style={{ fontSize: "35px", color: "black", fontWeight: "900", fontFamily: "ProximaNova,sans-serif!important" }}>Upgrade Token Balance</h3>
        <p style={{ fontSize: "25px" }}>
          Amount to pay{" "}
          <strong style={{ color: "#9867C5" }}>
            {((parseInt(receivePaymentAmount) + parseInt(transferMoneyAmount)) * parseFloat(commissionRate)).toLocaleString("en-US", { style: "currency", currency: "GBP" })}
          </strong>{" "}
        </p>

        <div>
          <span style={{ textDecoration: "underline" }}>Your current payment token* balance:</span>
          <strong> {parseInt(parseFloat(oldPaymentBalance / parseFloat(commissionRate))) || 0}</strong>
          <br />
          <br />
          <span style={{ textDecoration: "underline" }}>Your current transfer token* balance:</span>{" "}
          <strong>{parseInt(parseFloat(oldTransferBalance / parseFloat(commissionRate))) || 0}</strong>
        </div>
        <PricingBox>
          {business?.canReceivePayment && (
            <>
              <CalcItem>
                <SliderContainer>
                  <Typography id="discrete-slider-always" gutterBottom>
                    Payment Token* Balance: {convertedReceivePaymentAmount}
                  </Typography>
                  <Slider defaultValue={receivePaymentAmount} max={1000} step={100} onChange={handleAnnualChange} aria-labelledby="discrete-slider-always" />{" "}
                </SliderContainer>
              </CalcItem>
            </>
          )}
          <CalcItem>
            <SliderContainer>
              <Typography id="discrete-slider-always" gutterBottom>
                Transfer Token* Balance: {convertedTransferMoneyAmount}
              </Typography>
              <Slider defaultValue={transferMoneyAmount} max={100} step={10} onChange={handleTransactionFeeChange} aria-labelledby="discrete-slider-always" />{" "}
            </SliderContainer>
          </CalcItem>
          <div style={{ backgroundColor: "#B3EDD7", padding: "10px", borderRadius: "25px", fontFamily: "Soleil, Helvetica, Arial, sans-serif!important" }}>
            {business?.canReceivePayment && (
              <>
                <CalculatorItem style={{ marginBottom: "10px" }}>
                  <span style={{ color: "grey" }}>Your payment token balance will be:</span> {calculateREceivePaymentBalance() || 0}
                  {/* {((receivePaymentAmount / 100) * transferMoneyAmount).toLocaleString("en-US", { style: "currency", currency: "GBP" })} */}
                </CalculatorItem>
              </>
            )}
            <CalculatorItem>
              <span style={{ color: "grey" }}>Your transfer token balance will be: </span>
              {calculateTransferMoneyBalance() || 0}{" "}
            </CalculatorItem>
          </div>
          <br />
          <strong>*Payment Token: </strong> Number of unpaid invoices to be paid by customers
          <br />
          <strong>*Transfer Token: </strong> Number of transfer money can be made
          <br />
        </PricingBox>
      </div>
      <ButtonContainer onClick={() => handleBuyTokens()}>Proceed to Payment</ButtonContainer>

      {selectedInvoices.length > 0 && makePaymentDialogOpen && (
        <MakePaymentDialog
          open={makePaymentDialogOpen}
          setOpen={setMakePaymentDialogOpen}
          selectedInvoices={selectedInvoices}
          errors={errors}
          setErrors={setErrors}
          clearErrors={clearErrors}
          redirectURI="payment-result/plans"
          payeda_package_id={packId}
        />
      )}
    </PricingCalculatorContainer>
  );
};

export default withTranslation()(PricingCalculator);
