import React from "react";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";

// import CustomersTableToolbar from "./CustomersTableToolbar";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import BusinessFinancialInformationsDialog from "./BusinessFinancialInformationsDialog";
import TableComponent from "../../Components/Table/TableComponent";

const headCells = [
  { id: "year", label: "Year" },
  { id: "turnover_amount", label: "Turnover Amount" },
  { id: "currency", label: "Currency" },
  { id: "number_of_employee", label: "Number of Employee" },
  { id: "bank_statement", label: "Statement" },

  { id: "created_at", label: "Added date" },

  // { id: "is_joined", label: "Joined"}
];

const FinancialInformationsTable = ({}) => {
  let business = useSelector((state) => state.account.business);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [
    openFinancialInformationsDialog,
    setOpenFinancialInformationsDialog,
  ] = useState(false);
  useEffect(() => {
    if (business?.financial_informations?.length > 0) {
      let reversedArray = business.financial_informations.reverse();
      setData(reversedArray);
    }
  }, [business]);

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setOpenFinancialInformationsDialog(true)}
      >
        Add Financial Information
      </Button>
      <br />
      <TableComponent
        data={data}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        shouldGetData={false}
        shouldPaginate={false}
        shouldSearch={false}
        shouldView={true}
      />

      <BusinessFinancialInformationsDialog
        dispatch={dispatch}
        setOpen={setOpenFinancialInformationsDialog}
        open={openFinancialInformationsDialog}
        business={business}
      />
    </>
  );
};
export default FinancialInformationsTable;
