import React, { useState } from "react";

import { Grid, Typography, Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import DialogComponent from "../../Components/Dialogs/DialogComponent";
import { useInvoiceSettings } from "./SettingStyles";
import { updateVatPercentage } from "../../store/actions/invoiceActions";
const InvoiceSettings = ({ open, setOpen, external, setErrors, history }) => {
  const classes = useInvoiceSettings();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [defaultV, setDefaultV] = useState(0);

  const onSubmit = async (data) => {
    if (Number(data.vat_percentage) <= 0) {
      setErrors({ general: "Vat can not be 0 or less" });
      return false;
    } else if (Number(data.vat_percentage) >= 100) {
      setErrors({ general: "Vat can not be 100 or more" });
      return false;
    }

    await dispatch(updateVatPercentage(data));
    history.push("/dashboard/settings");
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} withActions={false}>
      <form>
        <Grid container spacing={3} className={classes.gridRoot}>
          <Grid item xs={12}>
            <Typography variant="h5">Invoice Settings</Typography>
          </Grid>
          {/* <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="serial"
                    name="serial"
                    label="Serial"
                    defaultValue={external.accountSettings?.invoiceSettings?.serial}
                    inputRef={register}
                />
                </Grid> */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="vat_percentage"
              name="vat_percentage"
              label="Vat(%)"
              defaultValue={external?.account_settings?.invoice_settings?.vat_percentage || 0}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              inputRef={register}
            />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" className={classes.button} fullWidth onClick={handleSubmit((data) => onSubmit(data))}>
          Update
        </Button>
      </form>
    </DialogComponent>
  );
};

export default InvoiceSettings;
