import axios from "axios";
import { setSuccess, setErrors, clearErrors, startLoading } from "./uiActions";
import { API_BASE } from "../env";

export const addSupplier = (details) => async (dispatch) => {
  try {
    let supplier = await axios({
      method: "post",
      url: `${API_BASE}/supplier`,
      data: details,
    });

    dispatch({
      type: "SUPPLIER_DATA_FETCH_SUCCESS",
      payload: supplier.data.allSuppliers,
    });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added new supplier."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data.error));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//must be fixed
export const addSuppliers = (details, history) => async (dispatch) => {
  try {
    await axios({
      method: "post",
      url: `${API_BASE}/add-suppliers`,
      data: details,
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added new supplier(s)."));
    dispatch(getSuppliers());
    history.push("/dashboard/suppliers");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editSupplier = (details) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    let supplier = await axios.put(`${API_BASE}/supplier`, details);
    dispatch({
      type: "SUPPLIER_DATA_FETCH_SUCCESS",
      payload: supplier.data.allSuppliers,
    });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully edited supplier."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getSupplier = (supplierId) => async (dispatch) => {
  try {
    dispatch({ type: "SUPPLIER_DATA_FETCH_STARTED" });

    let suppliers = await axios.get(`${API_BASE}/supplier`, {
      params: {
        supplierId: supplierId,
      },
    });
    console.log(suppliers);
    dispatch({
      type: "SINGLE_SUPPLIER_DATA_FETCH_SUCCESS",
      payload: suppliers.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getSuppliers = ({ ...params }) => async (dispatch) => {
  console.log("gettingsupplier");
  try {
    dispatch({ type: "SUPPLIER_DATA_FETCH_STARTED" });

    let suppliers = await axios.get(`${API_BASE}/suppliers`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        limit: 3, // to test
      },
    });

    dispatch({
      type: "SUPPLIER_DATA_FETCH_SUCCESS",
      payload: suppliers.data.allSuppliers,
    });
    dispatch({ type: "SET_LAST_PAGE", payload: suppliers.data.lastPage });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeSuppliers = (suppliers) => async (dispatch) => {
  try {
    let response = await axios.post(`${API_BASE}/remove-suppliers`, suppliers);
    dispatch({
      type: "SUPPLIER_DATA_FETCH_SUCCESS",
      payload: response.data.allSuppliers,
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed suppliers(s)."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const addSupplierBankAccount = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await axios.post(`${API_BASE}/supplier/bank-account`, details);
    dispatch(setSuccess("Added new bank account for the supplier."));
    dispatch({
      type: "SUPPLIER_DATA_FETCH_SUCCESS",
      payload: response.data.allSuppliers,
    });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editSupplierBankAccount = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/edit-supplier-bank-account`, details);
    dispatch(getSuppliers());
    dispatch(setSuccess("Edited the bank account details for supplier."));
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
