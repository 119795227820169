import { makeStyles } from "@material-ui/core/styles";

export const useNotificationPopupStyle = makeStyles((theme) => ({
  notificationContainer: {
    display: "flex",
    alignItems: "center",
  },
  notificationContained: {
    borderRadius: 45,
    height: 45,
  },
  notificationContainedShadowless: {
    boxShadow: "none",
  },
  notificationIconContainer: {
    minWidth: 45,
    height: 45,
    borderRadius: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
  },
  notificationIconContainerContained: {
    fontSize: 18,
    color: "#FFFFFF80",
  },
  notificationIconContainerRounded: {
    marginRight: theme.spacing(2),
  },
  containedTypography: {
    color: "white",
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  extraButton: {
    color: "white",
    "&:hover, &:focus": {
      background: "transparent",
    },
  },
}));

export const useNotificationsStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content2: {
    display: "flex",
    flex: "1 1 auto",
    overlflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentIn: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  box: {
    padding: 10,
    margin: 10,
    display: "inline-block",
    borderStyle: "dashed",
    width: "min-content",
  },
  grid: {
    flexGrow: 1,
    margin: "10px",
    width: "80%",
  },
}));
