import axios from "axios";
import Swal from "sweetalert2";
import { API_BASE } from "../env";
import { getCustomers } from "./customerActions";
import { getSuppliers } from "./supplierActions";
import { clearErrors, setErrors, setSuccess, startLoading } from "./uiActions";
import { getUserData } from "./userActions";

// axios.defaults.withCredentials = true;

let tokenBearer = localStorage.getItem("FBIdToken");
axios.defaults.headers.common["Authorization"] = tokenBearer;

export const createBusiness = (details, history, isUpdate, shouldShowAwaitForApprovalMessage) => async (dispatch) => {
  try {
    dispatch({ type: "BUSINESS_CREATE_STARTED" });
    let businessDetails = await axios({
      method: "post",
      url: `${API_BASE}/create-business`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch({ type: "BUSINESS_CREATE_SUCCESS", payload: businessDetails });
    dispatch(getUserData());
    dispatch(getBusinessDetails());
    if (!isUpdate) {
      dispatch(setSuccess("Successfully created new business."));
    } else {
      dispatch(setSuccess("Successfully updated the business."));
    }
    dispatch(clearErrors());
    dispatch(startLoading());
    let businessHtml = `<p>
    Please wait for your account approval, we will inform you by e-mail`;
    let customerHtml = "You can now start managing your invoices and make payment securely";
    let html;

    if (shouldShowAwaitForApprovalMessage) html = businessHtml;
    else html = customerHtml;

    Swal.fire({
      title: "We saved your information successfully!",
      html: html,
      icon: "success",
    });
    history.push("/dashboard");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch({ type: "BUSINESS_CREATE_FAILED" });

      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateBusiness = (details, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/update-business`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch(getUserData());
    dispatch(getBusinessDetails());
    dispatch(setSuccess("Successfully updated business information."));
    dispatch(clearErrors());
    dispatch(startLoading());
    history.push("/dashboard/verify-account");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getCompanyHouseInformation = (number, isUpdate) => async (dispatch) => {
  try {
    dispatch({ type: "START_LOADING_BUTTON" });

    let response = await axios.get(`${API_BASE}/getCompanyInformation`, {
      params: {
        companyNumber: number,
        isUpdate: isUpdate,
      },
    });
    console.log("getCompanyHouseInformation", response);
    dispatch({
      type: "SET_COMPANY_HOUSE_INFORMATION",
      payload: response.data.businessData,
    });
    // dispatch(getBusinessDetails());

    dispatch(setSuccess("Successfully verified business"));
    dispatch({ type: "STOP_LOADING_BUTTON" });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch({ type: "STOP_LOADING_BUTTON" });
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getHMRCInformation = (taxId, isUpdate, textFieldName, textFieldValue, setShouldRenderCompanyInformation, setShouldAskForCompanyDetails) => async (dispatch) => {
  try {
    dispatch({ type: "START_LOADING_BUTTON" });

    let response = await axios.get(`${API_BASE}/getHMRCInformation`, {
      params: {
        vat_number: taxId,
        isUpdate: isUpdate,
        searchField: textFieldName,
        searchValue: textFieldValue,
      },
    });
    console.log("getHMRCInformation", response);
    dispatch({
      type: "SET_HMRC_INFORMATION",
      payload: response.data,
    });
    // dispatch(getBusinessDetails());

    if (textFieldName === "vat_number") {
      dispatch(setSuccess("Successfully verified tax id"));
    }
    dispatch({ type: "STOP_LOADING_BUTTON" });
    setShouldRenderCompanyInformation(true);
    setShouldAskForCompanyDetails(true);
    dispatch(clearErrors());
  } catch (err) {
    dispatch({
      type: "SET_HMRC_INFORMATION",
      payload: null,
    });
    if (err.response) {
      console.log(err.response);
      dispatch({ type: "STOP_LOADING_BUTTON" });
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getBusinessDetails = () => async (dispatch) => {
  try {
    dispatch({ type: "BUSINESS_DATA_FETCH_STARTED" });
    // let errorData = {
    //     error: {
    //       confirm_password: "Passwords must match",
    //       account_type: "Invalid account type",
    //     },
    //     success: false,
    //   };
    // dispatch(setErrors(errorData));

    let business = await axios.get(`${API_BASE}/business`);
    let businessDetails = { ...business.data };

    dispatch({ type: "BUSINESS_DATA_FETCH_SUCCESS", payload: businessDetails });

    //TODO burada bütün functionları dispatch yapmak yerine ilgili componentin içersinde çagıralım.

    // dispatch(getCustomers());
    // dispatch(getSuppliers());
    // dispatch(getMembers());
    // dispatch(getIncomingInvoices());
    // dispatch(getIncomingReceipts());
    // dispatch(getOutgoingReceipts());
    // dispatch(getOutgoingInvoices());
    // dispatch(getNotifications());
    // dispatch(getUnreadNotifications());
    // dispatch(getActivityLogs());
    // dispatch(getSelfBankAccounts());
    // dispatch(getAllProducts());
    // dispatch(getAllFinanceAccounts());  // to list All Bank names in the dropdown
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getPersonalBusinessDetails = () => async (dispatch) => {
  try {
    let business = await axios.get(`${API_BASE}/business`);
    let businessDetails = { ...business.data };
    console.log("person", businessDetails);
    //TODO butun business bilgilerini "axios.get(`${API_BASE}/business`);" yaptigindaki requestte al ki tekrardan asagidakileri cagirma
    dispatch({ type: "SET_BUSINESS", payload: businessDetails });
    // dispatch(getSuppliers());
    // dispatch(getMembers());
    // dispatch(getAllProducts());
    // dispatch(getIncomingInvoices());
    // dispatch(getIncomingReceipts());
    // dispatch(getNotifications());
    // dispatch(getUnreadNotifications());
    // dispatch(getActivityLogs());
    // dispatch(getSelfBankAccounts());
    // dispatch(getAllFinanceAccounts());  // to list All Bank names in the dropdown
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateLogo = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/update-logo`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully uploaded new logo."));
    //TODO Return new business details in the request above and set it rather than calling the "getBusinessDetails" all over again
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateDocuments = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios({
      method: "post",
      url: `${API_BASE}/update-documents`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully updated documents."));
    //TODO Return new business details in the request above and set it rather than calling the "getBusinessDetails" all over again
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//TODO What is the difference between this and creating a Personal account in the signup? (if no difference why not one endpoint/function ?)
export const createPerson = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let business = await axios({
      method: "post",
      url: `${API_BASE}/create-person`,
      data: details,
      headers: { "Content-Type": "multipart/form-data" },
    });

    let businessData = business.data.data;
    dispatch({ type: "SET_BUSINESS", payload: businessData });
    dispatch(setSuccess("Successfully created personal account."));
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const invitePeople = (details, isSupplier) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/invite-people`, details);
    dispatch(setSuccess("Successfully invited"));
    if (isSupplier) {
      dispatch(getSuppliers({}));
    } else {
      dispatch(getCustomers({}));
    }
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const mergeAccounts = (details, history) => async (dispatch) => {
  console.log("details");
  try {
    dispatch({ type: "MERGING_STARTED" });
    const success = await axios.post(`${API_BASE}/merge-accounts`, {
      inviteCode: details,
    });
    if (success.data === "success") {
      dispatch({ type: "MERGING_SUCCESS" });
      dispatch(setSuccess("Successfully merged accounts"));
      dispatch(getMergedAccounts({}));
      dispatch(getBusinessDetails());
      dispatch(clearErrors());
      history.push("/dashboard/merged-accounts");
      dispatch({ type: "INVITER_DATA_FETCH_SUCCESS", payload: null });
    }
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getInviterInformation = (details) => async (dispatch) => {
  try {
    dispatch({ type: "INVITER_DATA_FETCH_STARTED" });
    let business = await axios.post(`${API_BASE}/get-inviter-information`, details);
    console.log("RESULT", business);
    dispatch({ type: "INVITER_DATA_FETCH_SUCCESS", payload: business.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updatePreferences = (details) => async (dispatch) => {
  try {
    let business = await axios.put(`${API_BASE}/update-preferences`, details);
    let businessDetails = { ...business.data };
    dispatch({ type: "SET_BUSINESS", payload: businessDetails });
    dispatch(setSuccess("Successfully updated"));
    dispatch(getBusinessDetails());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getMergedAccounts = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "MERGED_ACCOUNTS_DATA_FETCH_STARTED" });

    const success = await axios.get(`${API_BASE}/get-merged-accounts`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        limit: 10, // to test
      },
    });
    dispatch({
      type: "MERGED_ACCOUNTS_DATA_FETCH_SUCCESS",
      payload: success.data.allSuppliers,
    });
    dispatch({ type: "SET_LAST_PAGE", payload: success.data.lastPage });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateFinancialInformations = (details) => async (dispatch) => {
  console.log("details", details);
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/update-financial-information`,
      data: details,
    });
    dispatch(setSuccess("Successfully updated"));

    dispatch(getBusinessDetails());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getBusinessStat = () => async (dispatch) => {
  try {
    let response = await axios({
      method: "get",
      url: `${API_BASE}/business/generalStat`,
    });

    dispatch({ type: "BUSINESS_STATISTICS_SUCCESS", payload: response.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getAllPackages = (dashboardType) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    let response = await axios({
      method: "get",
      url: `${API_BASE}/v1/packages`,
      params: {
        dashboardType: dashboardType,
      },
    });

    dispatch({ type: "BUSINESS_PLANS", payload: response.data });

    // dispatch(setSuccess("Successfully bought package"))

    // dispatch(getBusinessDetails())

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const buyBusinessPlan = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/v1/packages/payment`,
      data: details,
    });
    // dispatch(setSuccess("Successfully bought package"))

    // dispatch(getBusinessDetails())

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getContacts = (Id) => async (dispatch) => {
  try {
    dispatch({ type: "START_FETCHING_CONTACTS" });
    let data = await axios({
      method: "get",
      url: `${API_BASE}/business/get-contacts`,
      params: {
        groupId: Id || null,
      },
    });
    console.log("data", data);
    dispatch({ type: "SET_CONTACTS", payload: data.data.allContacts });
    dispatch({ type: "SET_CONTACT_GROUPS", payload: data.data.allGroups });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const addContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/add-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully added new contact"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const mailToContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/mail-to-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully sent messages"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const editContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/edit-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully edited the contact"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeContact = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/remove-contact`,
      data: details,
    });
    dispatch(setSuccess("Successfully removed the contacts"));
    dispatch(getContacts());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateReceivePaymentAccount = (details) => async (dispatch) => {
  try {
    // const success = await axios.post(`${API_BASE}/update-financial-information`, details);
    await axios({
      method: "post",
      url: `${API_BASE}/business/update-receive-payment-details`,
      data: details,
    });
    dispatch(setSuccess("Successfully edited receive payment account details"));
    dispatch(getBusinessDetails());
  } catch (err) {
    if (err.response) {
      if (err.response) {
        console.log(err.response);
        dispatch(setErrors(err.response.data));
      }
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
