import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTableFooterStyles } from './TableStyles';

function TableFooterComponent({ children }) {
  const classes = useTableFooterStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </div>
  );
}

export default TableFooterComponent;
