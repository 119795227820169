import { makeStyles } from "@material-ui/core/styles";

export const useInvitationLoginStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "5vh",
    flexGrow: 1,
  },
}));

export const useInvitationSignupStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",

    [theme.breakpoints.down("md")]: {
      marginTop: "-250px",
    },
  },
  image: {
    backgroundImage: "url(/img/2.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: "200px",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#0CC474",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "10px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0CC474",
  },
}));

export const useInvitationSigninStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    textAlign: "center",
    maxHeight: 900,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "35vw",
    },
  },
}));

export const useSignupPageStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: "100px",
    },
  },
  timePaper: {
    padding: "6px 16px",
    boxShadow: "5px 3px 20px rgba(0,0,0,0.16)",
  },

  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },

  getStartedStyle: {
    marginTop: "20px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginLeft: "100px",
    },
    // boxShadow: "5px 3px 20px rgba(0,0,0,0.16)",
    padding: "16px",
  },
  image: {
    backgroundImage: "url(/img/yeni1.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FFFFFF",
    backgroundPosition: "center",
    marginBottom: "155px",
    // width: "100%",
  },
  signUpPaper: {
    height: "100vh",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("md")]: {
      marginLeft: "100px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  avatar: {
    backgroundColor: "#0CC474",
  },
}));

export const useSignupFormStyles = makeStyles((theme) => ({
  root: {
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    marginLeft: "50px",
    padding: "20px",
  },
  signin: {
    padding: 10,
    margin: 5,
    textAlign: "right",
  },
  errorMesage: {
    display: "block",
    fontWeight: "600",
    color: "#ff825c!important",
  },
}));

export const useSigninPageStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/img/6.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: "200px",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: "200px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0CC474",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
