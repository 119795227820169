import React from "react";
import { Card, Paper, Typography } from "@material-ui/core";

import { useHeaderComponentStyles } from "./ComponentStyles";

const HeaderComponent = ({ title, icon }) => {
    const classes = useHeaderComponentStyles();

    return (
        <Paper elevation={5} square className={classes.root}>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>{icon}</Card>
                <div className={classes.pageTitle}>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                </div>
            </div>
        </Paper>
    );
}

export default HeaderComponent;