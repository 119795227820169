import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BusinessStepper from "./BusinessStepper";

export default function CreateBusinessPage({ accountType, history, user, createBusiness, updateBusiness, getCompanyHouseInformation, business }) {
  const location = useLocation();
  const methods = useForm({ shouldUnregister: false });

  const [shouldRender, setShouldRender] = useState(false);
  const [isPathUpdate, setisPathUpdate] = useState(undefined);

  const [updateType, setUpdateType] = useState(null);

  const [isSoleTrader, setIsSoleTrader] = useState(null);

  useEffect(() => {
    const pathName = window.location.pathname.split("/")[2];

    setisPathUpdate(pathName === "update-business" ? true : false);
    let updateType1 = new URLSearchParams(location.search).get("type");
    setUpdateType(updateType1);
    if (user.business && pathName === "create-business") {
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (isPathUpdate !== undefined) {
      setShouldRender(true);
    }
  }, [isPathUpdate]);

  let companyHouseResult = useSelector((state) => state.business.companyHouseResult);

  return (
    <>
      {shouldRender && isPathUpdate !== undefined && (
        <>
          {" "}
          <FormProvider {...methods}>
            <BusinessStepper
              accountType={accountType}
              history={history}
              user={user}
              createBusiness={createBusiness}
              updateBusiness={updateBusiness}
              business={business}
              companyHouseResult={companyHouseResult}
              getCompanyHouseInformation={getCompanyHouseInformation}
              isPathUpdate={isPathUpdate}
              updateType={updateType}
              isSoleTrader={isSoleTrader}
              setIsSoleTrader={setIsSoleTrader}
            />
          </FormProvider>
        </>
      )}
    </>
  );
}
