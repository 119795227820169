import { Avatar, FormControl, Grid, Paper, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React from "react";
import { FcMoneyTransfer } from "react-icons/fc";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import LoadingIcon from "./../../Components/LoadingIcon";
import BackToHome from "./BackToHome";
import { useSignupPageStyles } from "./LoginStyles";
import SignupForm from "./SignupForm";

//TODO use translations
const SignupPage = ({ loading, authenticated, history }) => {
  const classes = useSignupPageStyles();

  return authenticated ? (
    <Redirect to="/dashboard" />
  ) : loading ? (
    <LoadingIcon />
  ) : (
    <>
      <BackToHome />
      <Grid container component="main" className={classes.root} spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={5} elevation={5} square>
          <div className={classes.signUpPaper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <SignupForm />
          </div>
        </Grid>
        <Box component={Grid} item lg={5}>
          <div className={classes.getStartedStyle}>
            <Typography
              style={{
                fontSize: "22px",
                color: "#0CC474",

                fontWeight: "bold",
              }}
            >
              <strong>START RECEIVING PAYMENTS IN 3 STEPS</strong>
            </Typography>

            <div>
              <Timeline align="alternate">
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "white" }}>
                      <RiNumber1 size="40px" color="#0CC474" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.timePaper}>
                      <Typography variant="h6" component="h1" style={{ fontSize: "22px", color: "#7F8F9E" }}>
                        CREATE YOUR PAYEDA ACCOUNT
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "white" }}>
                      <RiNumber2 size="40px" color="#0CC474" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.timePaper}>
                      <Typography variant="h6" component="h1" style={{ fontSize: "22px", color: "#7F8F9E" }}>
                        UPLOAD or CREATE
                      </Typography>
                      <Typography variant="h6" component="h1" style={{ fontSize: "22px", color: "#7F8F9E" }}>
                        YOUR INVOICES
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "white" }}>
                      <RiNumber3 size="40px" color="#0CC474" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.timePaper}>
                      <Typography variant="h6" component="h1" style={{ fontSize: "22px", color: "#7F8F9E" }}>
                        SEND INVITATIONS TO YOUR CUSTOMERS
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "white" }}>
                      <FcMoneyTransfer size="30px" />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.timePaper}>
                      <Typography variant="h6" component="h1" style={{ fontSize: "22px", color: "#7F8F9E" }}>
                        RECEIVE PAYMENT
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
            {/* <div style={{marginTop: "30px", textAlign: "left"}}>
              <div style={{padding: "30px"}}>
              <Typography style={{ fontSize: "15px", color: "black" }}>
              CREATE YOUR PAYEDA ACCOUNT
            </Typography>
          <Divider />

            <Typography style={{ fontSize: "15px", color: "black" }}>
              CONNECT THE ACCOUNT TO YOUR ACCOUNTING SOFTWARE
            </Typography>
          <Divider />

            <Typography style={{ fontSize: "15px", color: "black" }}>
                SEND INVITATIONS TO YOUR CUSTOMERS TO GET PAYMENT
            </Typography>
          <Divider />
              </div>
            
          </div> */}
          </div>
        </Box>
        {/* <Grid item xs={false} sm={false} lg={7} className={classes.image} /> */}
      </Grid>
    </>
  );
};

export default SignupPage;
