import React, { useState } from "react";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import clsx from "clsx";

import { useAdminStyles } from "../AdminStyles";
import HeaderComponent from "../../../Components/HeaderComponent";
import { useUiContext } from "../../../Contexts/UiContext";

import FinancesTable from "./FinancesTable";

const Finances = ({ allFinances}) => {
  const classes = useAdminStyles();
  const { sidebarOpen } = useUiContext();

  const [selected, setSelected] = useState([]);

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: sidebarOpen,
      })}
    >
      <div className={classes.root}>
        <HeaderComponent
          title="Finances"
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />

        <FinancesTable
          finances={allFinances}
          selected={selected}
          setSelected={setSelected}
        />
      
      </div>
    </main>
  );
};

export default Finances;
