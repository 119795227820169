export const setErrors = (data) => (dispatch) => {

   
    dispatch({ type: 'SET_ERRORS', payload: data });
};

export const setSuccess = (data) => (dispatch) => {
    dispatch({ type: 'SET_SUCCESS', payload: data });
}

export const clearErrors = () => (dispatch) => {
    dispatch({ type: 'CLEAR_ERRORS' });
}

export const clearSuccess = () => (dispatch) => {
    dispatch({ type: 'CLEAR_SUCCESS' });
}

export const showSidebar = () => (dispatch) => {
    dispatch({ type: "SHOW_SIDEBAR" });
}

export const hideSidebar = () => (dispatch) => {
    dispatch({ type: "HIDE_SIDEBAR" });
}

export const startLoading = () => (dispatch) => {
    dispatch({ type: "LOADING_UI" });
}

export const stopLoading = () => (dispatch) => {
    dispatch({ type: "STOP_LOADING_UI" });
}


