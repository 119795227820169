import { Button, Tooltip } from "@material-ui/core";
import React from "react";

const ButtonBase = ({ onClick, text, backColor, textColor, tooltip, fontSize, customWidth }) => {
  return (
    <>
      <Tooltip title={tooltip}>
        <Button
          variant="contained"
          onClick={onClick}
          style={{
            backgroundColor: backColor || "red",
            color: textColor || "white",
            borderRadius: "20px",
            width: customWidth || "120px",
            height: "50px",
          }}
        >
          {text || "buttonDefaultText"}
        </Button>
      </Tooltip>
    </>
  );
};

export default ButtonBase;
