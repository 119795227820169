import { Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import DialogComponent from "./../../../../Components/Dialogs/DialogComponent";

const AddBankAccountDialog = ({
  open,
  setOpen,
  defaultValues,
  handleGetAuthUrl,
  authUrl,
  authUrlLoading,
}) => {
  const { reset } = useForm();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title=""
      description=""
      withActions={false}
      backgroundColor={"lightBlue"}
    >
      <div style={{ width: "500px", height: "100px" }}>
        <div style={{ margin: "20px" }}>
          {!authUrlLoading && (
            <Button
              variant="outlined"
              style={{ margin: "auto", width: "450px", fontSize: "20px" }}
              onClick={handleGetAuthUrl}
            >
              Connect your bank
            </Button>
          )}
          {authUrlLoading && (
            <Typography style={{ textAlign: "center", fontSize: "18px" }}>
              You will be redirected to authorize your bank account
            </Typography>
          )}
        </div>
      </div>
    </DialogComponent>
  );
};
export default AddBankAccountDialog;
