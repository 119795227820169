import { Button, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";

const AddButton = ({ onClick, text }) => {
  return (
    <>
      <Tooltip title="Add">
        <Button
          variant="contained"
          onClick={onClick}
          style={{
            backgroundColor: "#04AA6D",
            color: "white",
            borderRadius: "20px",
          }}
          endIcon={<AddIcon />}
        >
          {text ? text : "Add"}
        </Button>
      </Tooltip>
    </>
  );
};

export default AddButton;
