import axios from "axios";

import { setSuccess, setErrors, clearErrors, startLoading, clearSuccess } from "./uiActions";
import { API_BASE, UI_API_BASE } from "../env";
import Swal from "sweetalert2";
import { getOutgoingInvoices } from "./invoiceActions";
export const sendContactMail = (data, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/contact`, data);
    dispatch(setSuccess("Your message has been sent! Thanks for reaching us!"));
    dispatch(clearErrors());
    history.push("/");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const sendPasswordResetLink = (data, setOpen) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/user/reset-password-link`, data);
    dispatch(setSuccess("We have sent a link to your email to create a new password"));
    setOpen(false);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const resetPassword = (data, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/user/reset-password`, data);
    dispatch(setSuccess("We have changed your password successfully"));

    setTimeout(() => {
      window.location.href = `${UI_API_BASE}/signin`;
    }, 1.0 * 1000);

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      dispatch(setErrors({ general: "The Password Reset Link Has Expired" }));

      // console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const sendVerifyEmailLink = (data, setOpen) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/user/verify-email`, data);
    dispatch(setSuccess("We have sent a link to your email to verify your email"));
    setOpen(false);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const signupUser = (userData, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/signup`, userData);
    // dispatch(
    //   setSuccess(
    //     "Your information is saved! Please verify your e-mail address to continue."
    //   )
    // );

    Swal.fire({
      title: "We saved your information successfully!",
      text: "We have sent an email with a confirmation link to your email address.",
      icon: "success",
    });
    dispatch(clearErrors());
    history.push("/signin");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const invitationSignup = (userData, history) => async (dispatch) => {
  console.log(userData);
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/invitation-sign-up`, userData);

    Swal.fire({
      title: "We saved your information successfully!",
      text: "We have sent an email with a confirmation link to your email address.",
      icon: "success",
    });
    // dispatch(setSuccess("Successfully created new user."));
    dispatch(clearSuccess());
    dispatch(clearErrors());
    history.push("/signin");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//TODO this function was not existing before, create appropriate backend and make this work as intended
export const invitationSignin = (userData, history) => async (dispatch) => {
  console.log(userData);
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/sign-up`, userData);
    dispatch(setSuccess("Successfully created new user."));
    dispatch(clearSuccess());
    dispatch(clearErrors());
    history.push("/signin");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const signinUser = (userData, history) => async (dispatch) => {
  try {
    dispatch({ type: "USER_DATA_FETCH_STARTED" });
    var response = await axios.post(`${API_BASE}/signin`, userData);
    setAuthorizationHeader(response.data.token);
    dispatch(clearErrors());
    dispatch(clearSuccess());
    dispatch(getUserData());
    history.push("/dashboard");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
      dispatch({ type: "USER_DATA_FETCH_FAILED" });
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const signoutUser = (history) => async (dispatch) => {
  try {
    await localStorage.removeItem("FBIdToken");

    // history.push("/signin");

    Swal.fire({
      html: "Signing out…",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios.post(`${API_BASE}/signout`);
    window.location.href = "/signin";

    Swal.close();

    // dispatch({ type: "SET_USER"});
    // dispatch({ type: "BUSINESS_DATA_FETCH_SUCCESS" });
    dispatch(clearErrors());
    dispatch({ type: "HIDE_SIDEBAR" });
    dispatch(setSuccess("Signed out."));
    dispatch(clearErrors());
    dispatch(clearSuccess());

    // dispatch({ type: "SET_USER" });
    // dispatch({ type: "SET" });
    dispatch({ type: "SET_UNAUTHENTICATED" });
    // window.location.reload();
    localStorage.removeItem("dashboardType");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//TODO burada kullanıcı tipine göre detayları çekmeye gerek yok, componentlar içerisinde gerekli dispatchler yapılacak
//TODO sadece /user endpoitine request atılsın...
export const getUserData = () => async (dispatch) => {
  try {
    dispatch({ type: "USER_DATA_FETCH_STARTED" });

    let user = await axios.get(`${API_BASE}/user`);
    let userDetails = { ...user.data };
    dispatch({ type: "SET_USER", payload: userDetails.user });
    if (userDetails.user.business && userDetails.user.business !== "" && userDetails.user.accountType === "Business") {
      // dispatch(getOutgoingInvoices({}));
    }
    dispatch({
      type: "BUSINESS_DATA_FETCH_SUCCESS",
      payload: userDetails.business,
    });

    dispatch(clearErrors());
    // //TODO Personal account type supported? Same thing with "Business"?
    // if ((userDetails.business && userDetails.business !== "" ) || userDetails.accountType==="Admin") {
    //     switch (userDetails.accountType) {
    //         case "Admin":
    //             dispatch(getAdminDetails());
    //             break;
    //         case "Business":
    //             dispatch(getBusinessDetails());
    //             break;
    //         case "Finance":
    //             dispatch(getFinanceDetails());
    //             break;
    //         case "Personal":
    //             dispatch(getBusinessDetails());
    //             break;
    //         default:
    //             break;
    //     }
    // }
    // dispatch({ type: "SHOW_SIDEBAR" });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateUserInformation = (userData) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/update-profile`, userData);
    dispatch(setSuccess("Successfully saved information"));
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

// const getUserGeoLocation = async() => {

//     //creating function to load ip address from the API
//       const res = await axios.get('https://geolocation-db.com/json/')
//       console.log("geoloc", res.data);

// }

const setAuthorizationHeader = async (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);

  axios.defaults.headers.common["Authorization"] = FBIdToken;
};
