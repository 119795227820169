import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import TableComponent from "../../../Components/Table/TableComponent";
import { getAllBusinessBankAccounts } from "../../../store/actions/adminActions";
import BusinessBankAcctDetailsDialog from "./BusinessBankAcctDetailsDialog";

const headCells = [
  { id: "date", label: "Date" },
  { id: "user_email", label: "User Email" },
  { id: "account_type", label: "Account type" },
  { id: "receive_payment_acct_details", label: "Acount Details" },

  { id: "business_name", label: "Business Name" },
  { id: "data_api_full_name", label: "Account Holder Name" },

  { id: "account_number_matches", label: "Matches" },
];

//TODO: search criterias will be added
const BusinessBankAccounts = ({ business }) => {
  const [selected, setSelected] = useState([]);
  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [selectedSearchType, setSelectedSearchType] = useState(null);

  const [openBankAccountDetails, setOpenBankAccountDetails] = useState(false);

  let dispatch = useDispatch();

  let isLoading = useSelector((state) => state.admin.isLoading);
  let allBankAccounts = useSelector((state) => state.admin.allBankAccounts);

  const handleGetData = () => {
    let queryStrings = {
      type: selectedSearchType,
      date_gt: selectedIssuDate.toJSON().slice(0, 10),
      date_lt: selectedDueDate.toJSON().slice(0, 10),
    };

    dispatch(getAllBusinessBankAccounts({ ...queryStrings }));
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const renderDetailsButton = () => {
    return (
      <>
        <Button variant="outlined" color="secondary" style={{ float: "right" }} onClick={() => setOpenBankAccountDetails(true)}>
          Details
        </Button>
      </>
    );
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BasePage navigation="Dashboard/All Bank Accounts" business={business} canView={true}>
        {selected.length === 1 && renderDetailsButton()}
        <TableComponent data={allBankAccounts} isLoading={isLoading} selected={selected} setSelected={setSelected} headCells={headCells} shouldGetData={false} shouldView={true} />

        <BusinessBankAcctDetailsDialog open={openBankAccountDetails} setOpen={setOpenBankAccountDetails} selected={selected[0]} />
      </BasePage>
    </MuiPickersUtilsProvider>
  );
};

export default BusinessBankAccounts;
