import Avatar from "@material-ui/core/Avatar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import Menus from "./Menus";

const drawStyles = (theme) => {
  return {
    drawerPaper: {
      width: theme.drawer.width,
      backgroundColor: "#252F3E",
      color: "white",
      borderRight: "0px",
      boxShadow:
        "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px",
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.drawer.miniWidth,
    },
    logo: {
      cursor: "pointer",
      fontSize: 22,
      color: "white",
      lineHeight: "50px",
      fontWeight: 300,
      backgroundColor: "#252F3E",
      textAlign: "center",
      height: "50px",
    },
    avatarRoot: {
      textAlign: "center",
      backgroundColor: "#1B2330",
      padding: "16px 0 16px 0px",
    },
    avatarRootMini: {
      padding: "15px 0 10px 10px",
    },
    avatarIcon: {
      float: "left",
      display: "block",
      boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.2)",
    },
    avatarSpan: {
      paddingTop: 8,
      paddingLeft: 24,
      display: "block",
      color: "white",
      fontWeight: 300,
      textShadow: "1px 1px #444",
    },
    menuItem: {
      color: "white",
      fontSize: 14,
    },
    profileUserpic: {
      marginTop: "10px",
      float: "none",
      margin: "0 auto",

      borderRadius: "50% !important",
    },
    profileUsertitle: {
      textAlign: "center",
      marginTop: "20px",
    },
    profileUsertitleName: {
      color: "#5a7391",
      fontSize: "16px",
      fontWeight: "600",
      marginBottom: "7px",
    },
    applicationHeader: {
      color: "rgba(255, 255, 255, 0.7)",
      fontWeight: "600",
      letterSpacing: "0.025em",
      marginTop: "30px",
      marginBottom: "10px",
      marginLeft: "17px",
    },
  };
};

const LeftDrawer = (props) => {
  let {
    user,
    business,
    navDrawerOpen,
    handleChangeNavDrawer,
    classes,
    theme,
  } = props;

  let { isLoading: loadingUserData } = user;
  let { isLoading: loadingBusinessData } = business;

  const drawerContent = () => (
    <div className={classes.root}>
      <div className={classes.logo} id="payedaLogo">
        PAYEDA
      </div>

      {/* <Avatar
          src={business?.company_details?.logo}
          size={navDrawerOpen ? 48 : 32}
          classes={{ root: classes.avatarIcon }}
        />

        <span className={classes.avatarSpan}>
          {user?.name} {user?.surname}
        </span> */}
      {navDrawerOpen && (
        <>
          <div
            className={classNames(
              classes.avatarRoot,
              !navDrawerOpen && classes.avatarRootMini
            )}
          >
            <div className={classes.profileUserpic}>
              <Avatar
                src={business?.company_details?.logo}
                className={classes.profileUserpic}
              />
            </div>

            <div className={classes.profileUsertitle}>
              <div className={classes.profileUsertitleName}>
                {business?.company_details?.name || business?.bankName}
              </div>
              <div className={classes.profileUsertitleJob}>
                {user?.name} {user?.surname}
              </div>
            </div>
          </div>

          <div className={classes.applicationHeader}>Applications</div>
        </>
      )}

      <Menus menus={props.menus} navDrawerOpen={navDrawerOpen} />
    </div>
  );

  return (
    <div className={classes.root}>
      {/* Mobile screen */}
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={navDrawerOpen}
          onClose={handleChangeNavDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {/* should close drawer modal as well when click on menu */}
          {drawerContent(handleChangeNavDrawer)}
        </Drawer>
      </Hidden>

      {/* Desktop screen */}
      <Hidden smDown>
        <Drawer
          open={navDrawerOpen}
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !navDrawerOpen && classes.drawerPaperClose
            ),
          }}
        >
          {!loadingUserData && !loadingBusinessData
            ? drawerContent()
            : "loading"}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default withStyles(drawStyles, { withTheme: true })(LeftDrawer);
