import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

import { useTableHeaderStyles } from './TableStyles';

const TableHeaderComponent = (props) => {
    const { onSelectAllClick, numSelected, rowCount, allowSelect, headCells } = props;
    const classes = useTableHeaderStyles();

    return (
        <TableHead className={classes.head}>
            <TableRow>
                {allowSelect && <TableCell padding="checkbox" style={{ backgroundColor: "#F6F7F9" }}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                        style={{color: 'black'}}
                    />
                </TableCell>}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding="default"
                        className={classes.tableHead}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default TableHeaderComponent;