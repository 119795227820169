import React from "react";

import {
  FormControl,
  Grid,
  Button,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import getPermissionFields from "../../util/permissions/getPermissionFields";

const MemberPermissions = ({
  handleCheckClick,
  permissions,
  accountType,
  previousPage,
  handleSubmit,
}) => {
  const {
    checkBoxIdIncrements,
    checkBoxLabels,
    sectionHeaders,
  } = getPermissionFields(accountType);

  const createCheckboxes = (
    permissions,
    handleCheckClick,
    section,
    checkBoxIdIncrements,
    checkBoxLabels
  ) => {
    const checkBoxes = [];

    for (const action in permissions[`${section}`]) {
      const id = action + checkBoxIdIncrements[`${section}`];
      const label = checkBoxLabels[`${section}`][`${action}`];
      checkBoxes.push(
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              id={`${id}`}
              name={`${section}`}
              checked={permissions[`${section}`][`${action}`]}
              onChange={handleCheckClick}
            />
          }
          label={`${label}`}
          labelPlacement="end"
        />
      );
    }

    return checkBoxes;
  };

  const createPermissionSections = (
    permissions,
    handleCheckClick,
    sectionHeaders,
    checkBoxIdIncrements,
    checkBoxLabels
  ) => {
    const cards = [];

    for (const section in permissions) {
      cards.push(
        <Grid item xs={6}>
          <FormGroup>
            <FormLabel
              component="legend"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              {sectionHeaders[`${section}`]}
            </FormLabel>
            {createCheckboxes(
              permissions,
              handleCheckClick,
              section,
              checkBoxIdIncrements,
              checkBoxLabels
            )}
          </FormGroup>
        </Grid>
      );
    }
    return cards;
  };

  return (
    <FormControl component="fieldset">
      <h1>Permissions</h1>
      <Grid container spacing={1}>
        {createPermissionSections(
          permissions,
          handleCheckClick,
          sectionHeaders,
          checkBoxIdIncrements,
          checkBoxLabels
        )}
      </Grid>
      <Grid container spacing={1}>
        <Grid xs={6}>
          <Button variant="contained" color="primary" onClick={previousPage}>
            Prev
          </Button>
        </Grid>
        <Grid xs={6}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default MemberPermissions;
