const BackToHome = () => {
  return (
    <div class="backBody">
      <a href="http://payeda.net" class="back">
        <div></div>

        <img src="/img/payeda_logo_new.png" height="50px" weight="50px" />

        <span>Back to home</span>
      </a>
    </div>
  );
};

export default BackToHome;
