import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../../Components/BasePage";
import TableComponent from "../../../../Components/Table/TableComponent";
import { getBankingLogs } from "../../../../store/actions/bankingActions";

const TransferMoney = ({ business }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  let bankingLoading = useSelector((state) => state.banking.loading);
  let bankingLogs = useSelector((state) => state.banking.bankingLogs);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.permissions?.bankingLog?.view) dispatch(getBankingLogs());
    // eslint-disable-next-line
  }, []);

  const headCells = [
    { id: "date", label: "Date" },
    { id: "amount_sent", label: "Amount" },
    { id: "beneficiary_name", label: "Beneficiary" },
    { id: "last_status", label: "Status" },

    // { id: "is_joined", label: "Joined"}
  ];
  const renderTransactionsTable = () => {
    return (
      <>
        <TableComponent
          data={bankingLogs}
          selected={selected}
          setSelected={setSelected}
          headCells={headCells}
          isLoading={bankingLoading}
          shouldGetData={false}
          shouldPaginate={false}
          shouldSearch={false}
          shouldView={true}
        />
      </>
    );
  };

  return (
    <BasePage navigation="Dashboard/Payeda Banking Logs" business={business} canView={user?.permissions?.bankingLog?.view}>
      {bankingLoading && "Loading"}
      {bankingLogs && bankingLogs.length > 0 && renderTransactionsTable()}
      {!bankingLogs || (bankingLogs.length === 0 && "No data found")}
    </BasePage>
  );
};

export default TransferMoney;
