
const initialState = {
    isLoading: false,
    suppliers: [],
    errorMessage: null,
    
};

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case "SUPPLIER_DATA_FETCH_STARTED":
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
            };
            
        case "SUPPLIER_DATA_FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                suppliers: action.payload,
            };
        case "SINGLE_SUPPLIER_DATA_FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                singleSupplier: action.payload
        }
        default:
            return state;
    }
}