// import { combineReducers } from 'redux';
// import userReducer from './userReducer'
// import uiReducer from './uiReducer'
// import businessReducer from './businessReducer';
// import adminReducer from "./adminReducer";
// import financeReducer from "./financeReducer";
// import bankingReducer from './bankingReducer';

// import customerReducer from './customerReducer';
// import accountReducer from './accountReducer';
// import invoiceRecuer from './invoiceReducer';
// import supplierReducer from './supplierReducer';
// import productReducer from './productReducer';
// import memberReducer from './memberReducer';

// const allReducers = combineReducers({
//     admin: adminReducer,
//     banking: bankingReducer,
//     business: businessReducer,
//     finance: financeReducer,
//     customer: customerReducer,
//     supplier: supplierReducer,
//     invoice: invoiceRecuer,
//     product: productReducer,
//     member: memberReducer,
//     ui: uiReducer,
//     user: userReducer,
// });

// const rootReducer = (state, action) => {

//     switch (action.type) {
//         case "SET_UNAUTHENTICATED":
//             return state = undefined;
//         default:
//             return allReducers(state, action)
//     }
// };

// export default rootReducer;

import { combineReducers } from "redux";
import userReducer from "./userReducer";
import uiReducer from "./uiReducer";
import businessReducer from "./businessReducer";
import adminReducer from "./adminReducer";
import financeReducer from "./financeReducer";
import bankingReducer from "./bankingReducer";

import customerReducer from "./customerReducer";
import accountReducer from "./accountReducer";
import invoiceRecuer from "./invoiceReducer";
import supplierReducer from "./supplierReducer";
import productReducer from "./productReducer";
import memberReducer from "./memberReducer";
import packageReducer from "./packageReducer";
import notificationReducer from "./notificationReducer";
import financingInvoicesReducer from "./financingInvoicesReducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  banking: bankingReducer,
  business: businessReducer,
  finance: financeReducer,
  customer: customerReducer,
  supplier: supplierReducer,
  account: accountReducer,
  invoice: invoiceRecuer,
  product: productReducer,
  member: memberReducer,
  package: packageReducer,
  notification: notificationReducer,
  financing: financingInvoicesReducer,
  ui: uiReducer,
  user: userReducer,
});

export default rootReducer;
