import React, { useState } from "react";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import clsx from "clsx";

import { useFinanceStyles } from "../FinanceStyles";
import HeaderComponent from "../../../Components/HeaderComponent";
import { useUiContext } from "../../../Contexts/UiContext";
import { usePermissionsContext } from "../../../Contexts/PermissionsContext";

import PendingAgreementApprovalRequestsTable from "./PendingAgreementApprovalRequestsTable";
import PendingAgreementTableFooter from "./PendingAgreementTableFooter";
import BankAgreementDialog from "./BankAgreementDialog";
import FinanceTableToolbar from "../FinanceTableToolbar"

const PendingAgreementApprovalRequests = ({
  pendingBankApprovalRequests,
  denyOrApproveRequests,
}) => {
  const classes = useFinanceStyles();
  const { setErrors, sidebarOpen } = useUiContext();
  const { permissions } = usePermissionsContext();

  const [selected, setSelected] = useState([]);
  const [bankAgreementDialog, setBankAgreementDialogOpen] = useState(false);

  const renderFooter = () => {
    return (
      <PendingAgreementTableFooter
        selected={selected}
        setSelected={setSelected}
        permissions={permissions}
        setErrors={setErrors}
        denyOrApproveRequests={denyOrApproveRequests}
        setBankAgreementDialogOpen={setBankAgreementDialogOpen}
      />
    );
  };

  const renderToolbar = () => {
    return (
        <FinanceTableToolbar
            numSelected={selected ? selected.length : 0}
            header="Pending Approval Agreements"
        />
    )
}
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: sidebarOpen,
      })}
    >
      <div className={classes.root}>
        <HeaderComponent
          title="Pending Approval Agreements"
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />

        <PendingAgreementApprovalRequestsTable
          toolbar={renderToolbar()}
          footer={renderFooter()}
          pendingBankApprovalRequests={pendingBankApprovalRequests}
          selected={selected}
          setSelected={setSelected}
        />

        <BankAgreementDialog
          open={bankAgreementDialog}
          selected={selected}
          setSelected={setSelected}
          setOpen={setBankAgreementDialogOpen}
          defaultValues={selected[0]}
          denyOrApproveRequests={denyOrApproveRequests}
        />
      </div>
    </main>
  );
};

export default PendingAgreementApprovalRequests;
