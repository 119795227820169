const initialState = {
  isLoading: false,
  customers: [],
  errorMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CUSTOMER_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case "CUSTOMER_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        customers: action.payload,
      };
    case "SINGLE_CUSTOMER_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        customer: action.payload,
      };
    case "CUSTOMER_GROUP_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        customerGroups: action.payload,
      };
    case "CUSTOMER_IMPORT_STARTED":
      return {
        ...state,
        customerImportLoading: true,
      };
    case "CUSTOMER_IMPORT_FINISHED":
      return {
        ...state,
        customerImportLoading: false,
      };
    default:
      return state;
  }
}
