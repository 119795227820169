import React from "react";

import TableComponent from "../../../Components/Table/TableComponent";

const headCells = [
  { id: "name", label: "Name" },
  { id: "country_city", label: "Country" },
  { id: "account_type", label: "Account Type" },

  { id: "business_model", label: "Business Model" },
  { id: "awaiting_type", label: "Awaiting For" },
  { id: "user_email", label: "User Email" },

  { id: "update_type", label: "Update" },
];

const searchOptions = [
  { default: true, id: "company_name_lower", label: "Company Name" },
  { id: "full_name_lower", label: "Representative Name" },
  { id: "erp_no_lower", label: "Erp Number" },
];

const PendingApprovalBusinessesTable = ({ businessesToApprove, getAllData, selected, setSelected, toolbar }) => {
  return (
    <TableComponent
      toolbar={toolbar}
      data={businessesToApprove}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      getAllData={getAllData}
      searchOptions={searchOptions}
      shouldView={true}
      shouldGetData={false}
    />
  );
};

export default PendingApprovalBusinessesTable;
