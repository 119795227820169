import { Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { createOutgoingInvoices } from "../../../store/actions/invoiceActions";
import ImportInvoiceComponent from "../ImportInvoiceComponent";
import DialogComponent from "./../../../Components/Dialogs/DialogComponent";
import { useUiContext } from "./../../../Contexts/UiContext";
import InvoiceComponent from "./../InvoiceComponent";
import { useImportInvoiceStyles } from "./../InvoiceStyles";

const ImportOutgoingInvoice = ({
  history,
  business,
  tenants,
  cloudInvoices,
}) => {
  let dispatch = useDispatch();

  let importSuccess = useSelector((state) => state.invoice.importSuccess);

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);

  const classes = useImportInvoiceStyles();
  const { loading, errors } = useUiContext();

  const handleShowInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setInvoiceDialogOpen(true);
  };
  useEffect(() => {
    if (importSuccess === "success") {
      setInvoices([]);
    }
  }, [importSuccess]);

  const handleSubmitInvoices = () => {
    let data = [];

    invoices.forEach((invoice) => {
      let serialised = JSON.stringify(invoice.invoice_lines);
      invoice["invoice_lines"] = serialised;
      data.push(invoice);
    });
    dispatch(createOutgoingInvoices({ data }, history, setInvoices));
  };

  const renderInvoices = () => {
    return (
      <Paper
        className={classes.invoicePaper}
        style={{ padding: "10px" }}
        elevation={4}
      >
        {invoices &&
          invoices.map((invoice) => (
            <Grid container spacing={3} key={invoice.invoice_number}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Invoice No: {invoice.invoice_number}
                </Typography>
                <Typography variant="body2">
                  Issued Date: {invoice.issued_date}
                </Typography>
                <Typography variant="body2">
                  Due Date: {invoice.due_date}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1"></Typography>
                <Typography variant="body2">
                  Erp No: {invoice.erp_no}
                </Typography>
                <Button
                  onClick={() => handleShowInvoice(invoice)}
                  variant="outlined"
                >
                  Show
                </Button>
              </Grid>
            </Grid>
          ))}
      </Paper>
    );
  };
  return (
    <BasePage navigation="Dashboard/IncomingInvoices" business={business}>
      <ImportInvoiceComponent
        invoices={invoices}
        setInvoices={setInvoices}
        title="Outgoing Invoice"
        loading={loading}
        tenants={tenants}
        cloudInvoices={cloudInvoices}
        errors={errors}
        business={business}
        history={history}
      />
      <div style={{ margin: "20px" }}>
        {invoices.length > 0 && renderInvoices()}
      </div>

      <DialogComponent
        open={invoiceDialogOpen}
        setOpen={setInvoiceDialogOpen}
        title={`Invoice No: ${selectedInvoice.invoice_number}`}
        withActions={false}
      >
        <InvoiceComponent invoice={selectedInvoice} />
      </DialogComponent>

      <Button
        onClick={() => handleSubmitInvoices()}
        color="primary"
        variant="contained"
        style={{ float: "right" }}
      >
        Submit
      </Button>
    </BasePage>
  );
};

export default ImportOutgoingInvoice;
