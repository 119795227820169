//have own Switch inside for /dashboard/"stuff"
import React, { useState } from "react";

import BasePage from "./../../Components/BasePage";
import { useEffect } from "react";

const HowItWorks = ({ user, business }) => {
  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    if (user.accountType === "Business") {
      if (user.usage_type.isInvoicing) {
        setImageSource("/img/Payeda_How_To_Use_It_Business_23122021.png");
      } else {
        setImageSource("/img/Payeda_How_To_Use_It_MakePaymentUser_Business-Inv.png");
      }
    } else if (user.accountType === "Personal") {
      if (user.usage_type.isTransferMoney) {
        setImageSource("/img/Payeda_How_To_Use_It_Personal_23122021.png");
      } else {
        setImageSource("/img/Payeda_How_To_Use_It_MakePaymentUser_Personal-Inv.png");
      }
    }
  }, [user]);

  return (
    <BasePage navigation="How It Works" business={business} canView={true}>
      {/* {business && business.company_details && renderAwaitinApproval()} */}
      <img src={imageSource} alt="How it works"></img>
    </BasePage>
  );
};

export default HowItWorks;
