import React, { useState, useEffect } from "react";
import { Typography, Paper, TableContainer, Table, TextField, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import { TablePagination } from "@material-ui/core";

import TableHeaderComponent from "./TableHeaderComponents";
import TablePaginationComponent from "./TablePaginationComponent";
import TableBodyComponent from "./TableBodyComponent";
import { useTableStyles } from "./TableStyles";
import Pagination from "@material-ui/lab/Pagination";

const TableComponent = ({
  toolbar,
  footer,
  data: external,
  selected,
  headCells,
  setSelected,
  businessId,
  showDetailsAction,
  isLoading,
  shouldView,
  showPDFAction,
  shouldGetData,
  setShouldGetData,
  handleSearchBar,
}) => {
  const classes = useTableStyles();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setData(external);
  }, [external]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const searchText = e.target.value.toLowerCase().trim();

    // // this field sent only from invoice list component to prevent getting data on search action
    // if (handleSearchBar) {
    //   if (searchText) setShouldGetData(false);
    //   else setShouldGetData(true);
    // }

    const filtered = external.filter((x) => {
      for (const cell in headCells) {
        let field = headCells[cell].id;
        if (typeof x[field] === "string") {
          let match = x[field]
            .toString()
            .toLowerCase()
            .includes(searchText);
          //   console.log("mathc", field, x[field], match);
          if (match) {
            return match;
          }
        }
      }
    });
    console.log("filtered searchh", filtered);
    setData(filtered);
  };

  const handleSelectAllClick = (event) => {
    if (data && event.target.checked) {
      setSelected(data);
      return;
    }
    setSelected([]);
  };

  const renderSearchBar = () => {
    return (
      <Paper className={classes.searchBar}>
        <TextField
          label="Search..."
          fullWidth
          variant="outlined"
          className={classes.searchInput}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          type="text"
        />
      </Paper>
    );
  };

  //TODO date filter does not work
  const renderDateFilter = () => {
    return (
      <Grid container spacing={3} style={{ padding: "15px" }}>
        <Grid item xs={12} sm={6}>
          <TextField InputLabelProps={{ shrink: true }} id="birthday" value="date" label="Start Date" type="date" fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField InputLabelProps={{ shrink: true }} id="birthday" value="date" label="End Date" type="date" fullWidth={true} />
        </Grid>
      </Grid>
    );
  };

  const renderNoResult = () => {
    return (
      (!data || !(data.length > 0)) && (
        <Typography color="error" variant="body1" style={{ textAlign: "center" }}>
          No result
        </Typography>
      )
    );
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {toolbar}
        {/* {renderDateFilter()} */}
        <div style={{ marginTop: "30px" }}>{renderSearchBar()}</div>

        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <TableHeaderComponent
                allowSelect={true}
                classes={classes}
                numSelected={selected ? selected.length : 0}
                rowCount={data ? data.length : 0}
                onSelectAllClick={handleSelectAllClick}
                headCells={headCells}
              />
              {isLoading ? (
                <div style={{ paddingLeft: "10px" }}>Loading</div>
              ) : (
                <>
                  <TableBodyComponent
                    data={data}
                    headCells={headCells}
                    selected={selected}
                    setSelected={setSelected}
                    businessId={businessId}
                    showDetailsAction={showDetailsAction}
                    showPDFAction={showPDFAction}
                    classes={classes}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                </>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          {renderNoResult()}

          {footer}
        </Paper>
      </div>
    </Paper>
  );
};

export default TableComponent;
