import BasePage from "../../Components/BasePage";
import PlansCardContainer from "./PlansCardContainer";
import { useDispatch, useSelector } from "react-redux";
const Plans = ({ business }) => {
  const user = useSelector((state) => state.user);
  return (
    <BasePage navigation="Dashboard/Membership Packages" business={business} canView={user?.permissions?.plan?.view}>
      <h1 style={{ textAlign: "center" }}>Plans & Pricing</h1>
      <PlansCardContainer business={business} />
    </BasePage>
  );
};

export default Plans;
