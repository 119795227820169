import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

const ReviewYourCodeStep = ({
  previousStep,
  onSubmit,
  defaultValues,
  info,
}) => {
  const { data } = info;
  const { inviter, invitee } = data;
  const { invitationCode } = defaultValues;

  const renderReviewCode = () => {
    return (
      <Card>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Typography color="textSecondary" gutterBottom variant="h5">
              YOUR INVITATION CODE:
            </Typography>
            <Typography color="textPrimary" variant="h6">
              {invitationCode}
            </Typography>
          </Box>
          <Divider />
          <Button
            variant="contained"
            color="secondary"
            className="previous"
            onClick={previousStep}
            style={{ marginTop: "20px" }}
          >
            Back
          </Button>
        </CardContent>
      </Card>
    );
  };

  const renderInviterDetails = () => {
    return (
      <Card>
        <CardHeader
          subheader="The information can not be edited"
          title="Inviter Information"
        />
        {/* {(inviterInformation.length === 0 || Object.keys(inviterInformation).length<1 )&& (
          <CardContent>
            <Typography color="secondary" align="center" variant="h5">
              Your code wrong or missing
            </Typography>
          </CardContent>
        )} */}
        <Divider />
        {Object.keys(inviter).length > 1 && (
          <form>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="firstName"
                    defaultValue={inviter.company_details.name ?? "Not given"}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    defaultValue={inviter.company_details.phone ?? "Not given"}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    defaultValue={inviter.company_details.email ?? "Not given"}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    defaultValue={inviter.company_details.address.country}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </form>
        )}
      </Card>
    );
  };

  const renderInviteeDetails = () => {
    return (
      <Card>
        <CardHeader
          subheader="The information can not be edited"
          title="Invitee Information"
        />
        {/* {(inviterInformation.length === 0 || Object.keys(inviterInformation).length<1 )&& (
          <CardContent>
            <Typography color="secondary" align="center" variant="h5">
              Your code wrong or missing
            </Typography>
          </CardContent>
        )} */}
        <Divider />
        {Object.keys(invitee).length > 1 && (
          <form>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="firstName"
                    defaultValue={invitee.company_details.name ?? "Not given"}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    defaultValue={invitee.director.phone ?? "Not given"}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    defaultValue={invitee.director.email ?? "Not given"}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    defaultValue={invitee.company_details.address.country}
                    required
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Divider />
            </CardContent>
          </form>
        )}
      </Card>
    );
  };
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12}>
          {renderReviewCode()}
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          {renderInviterDetails()}
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          {renderInviteeDetails()}
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        style={{ marginBottom: "50px" }}
        p={2}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSubmit(invitationCode)}
        >
          ADD SUPPLIER{" "}
        </Button>
      </Box>
    </Container>
  );
};

export default ReviewYourCodeStep;
