import React from 'react';
import { Tooltip,Button } from "@material-ui/core";

import TableToolbarComponent from './../../Components/Table/TableToolbarComponent';

const FinanceTableToolbar = ({ numSelected, header, setInvoiceDetailsDialogOpen }) => {

    return (
        <TableToolbarComponent
            header={header}
            numSelected={numSelected}
        >
            <>
                {numSelected > 0 ? (
                    <>
                        {numSelected === 1 && (
                               <>
                                  {header !=="Pending Approval Agreements"&&(
                         
                                        <Tooltip title="Details">
                                            <Button
                                                style={{
                                                backgroundColor: "blue",
                                                color: "white",
                                                width: "175px",
                                                marginRight: "5px",
                                                }}
                                                variant="contained"
                                                onClick={() => setInvoiceDetailsDialogOpen(true)}
                                            >
                                                Show Invoice
                                            </Button>
                                        </Tooltip>
                          
                                    )}
                                </> 
                            )}  
                    </>
                ):""}
            </>
        </TableToolbarComponent >
    )
}

export default FinanceTableToolbar




