import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import shortid from "shortid";
import BasePage from "../../Components/BasePage";
import ConfirmDeleteDialog from "../../Components/Dialogs/ConfirmDeleteDialog";
import DropdownComponent from "../../Components/DropdownComponent";

import { addContact, editContact, getContacts, invitePeople, mailToContact, removeContact } from "../../store/actions/businessActions";
import { addCustomer, editCustomer, getCustomerGroups, removeCustomers, getCustomers } from "../../store/actions/customerActions";
import { customerSchema } from "../../util/yup/yupSchemas";
import { useUiContext } from "../../Contexts/UiContext";
import verifySchema from "../../util/yup/verifySchema";
import ContactDialog from "./ContactDialog";
import ContactsTable from "./ContactsTable";
import InviteDialog from "./InviteDialog";
import AssignContactToGroupDialog from "./AssignContactToGroupDialog";
import checkIfHasActiveMembership from "../../util/permissions/checkIfHasActiveMembership";

const Contacts = ({ business, businessId }) => {
  const dispatch = useDispatch();
  const { register, control, handleSubmit } = useForm();

  const contacts = useSelector((state) => state.business.contacts);
  const contactGroups = useSelector((state) => state.business.contactGroups);
  const contactsLoading = useSelector((state) => state.business.contactsLoading || false);

  const isLastPage = useSelector((state) => state.business.lastPage);
  const { errors, setErrors, clearErrors } = useUiContext();

  const [selected, setSelected] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [assignToGroupDialogOpen, setAssignToGroupDialogOpen] = useState(false);
  const [msgToCustomer, setMsgToCustomer] = useState("Join to Payeda and see your invoices I made for you ");
  const [shouldGetData, setShouldGetData] = useState(false);
  const [afterRender, setAfterRender] = useState(false);
  const [selectedCustomerGroupId, setSelectedCustomerGroupId] = useState(null);
  let [userHasMembership, setUserHasMembership] = useState(null);
  const [selectGroupFromTheList, setSelectGroupFromTheList] = useState(false);

  const onCreateNew = async (data) => {
    // !data.country ?? (data.country = business.country);
    // const valid = await verifySchema(
    //   customerSchema,
    //   data,
    //   clearErrors,
    //   setErrors
    // );

    if (!data.email || !data.name) {
      setErrors({ general: "Name or email missing" });
      return false;
    }
    if (true) {
      data.contact_group_id = selectedCustomerGroupId;

      if ((!data.contact_group_id && !data.new_contact_name) || data.contact_group_id === "all_groups") {
        setErrors({ general: "Please enter or select a group" });
        return false;
      }

      if (!data.contact_group_id && data.new_contact_name) data.contact_group_id = data.new_contact_name.toLowerCase();

      delete data.new_contact_name;
      setCreateDialogOpen(false);
      setSelectedCustomerGroupId(null);
      dispatch(addContact(data));
    }
  };

  const onEdit = async (data) => {
    // const valid = await verifySchema(
    //   customerSchema,
    //   data,
    //   clearErrors,
    //   setErrors
    // );
    if (!data.email || !data.name) {
      setErrors({ general: "Name or email missing" });
      return false;
    }
    if (true) {
      if (selectedCustomerGroupId) {
        data.contact_group_id = selectedCustomerGroupId;
      }

      if (!data.contact_group_id && data.new_contact_name) data.contact_group_id = data.new_contact_name.toLowerCase();

      if (!selectedCustomerGroupId && !data.new_contact_name) data.contact_group_id = selected[0].contact_group_id;
      delete data.new_contact_name;

      const dataToSend = { id: selected[0].id, ...data };

      setEditDialogOpen(false);
      dispatch(editContact(dataToSend));
      setSelected([]);
    }
  };

  const onDelete = async () => {
    let contactsToDelete = selected.map((customer) => customer.id);
    let details = { contacts: contactsToDelete };
    dispatch(removeContact(details));
    setSelected([]);
    setDeleteDialogOpen(false);
  };

  const onInvite = async () => {
    if (!msgToCustomer) {
      setErrors({ general: "Please enter your message" });
      return false;
    }
    let dataToSend = {};
    let invitees = [];
    for (let sel of selected) {
      invitees.push({ id: sel.id, email: sel.email });
    }
    dataToSend.invitees = invitees;
    dataToSend.message = msgToCustomer;
    dispatch(mailToContact(dataToSend));
    setInviteDialogOpen(false);
    setSelected([]);
  };

  useEffect(() => {
    setAfterRender(true);
  }, []);

  useEffect(() => {
    if (contactGroups && contactGroups.length > 0 && contactGroups[contactGroups.length - 1].value !== "all_groups") {
      contactGroups.splice(0, 0, { value: "all_groups", label: "All Contacts" });
    }
  }, [contactGroups]);

  useEffect(() => {
    setSelectedCustomerGroupId("");
  }, [createDialogOpen, editDialogOpen]);

  useEffect(() => {
    if (!createDialogOpen && !editDialogOpen) {
      if (selectedCustomerGroupId === "all_groups") {
        dispatch(getContacts());
      } else if (selectedCustomerGroupId) {
        dispatch(getContacts(selectedCustomerGroupId));
      }
    }
  }, [selectedCustomerGroupId]);

  useEffect(() => {
    if (afterRender) dispatch(getContacts());
  }, [afterRender]);
  // useEffect(() => {
  //   if (!contactsLoading) setShouldGetData(true);
  // }, [contactsLoading]);

  // useEffect(() => {
  //   if (!contactsLoading) setShouldGetData(true);
  // }, []);

  const handleGroupOptionChange = (e) => {
    setSelectedCustomerGroupId(e.target.value);
  };

  const renderCustomerGroupSelection = () => {
    return (
      <>
        <FormControl variant="standard" sx={{ m: 5, minWidth: 500 }} fullWidth>
          <InputLabel id="demo-simple-select-standard-label" style={{ marginLeft: "10px" }}>
            Select a contact group{" "}
          </InputLabel>

          <Select
            value={selectedCustomerGroupId}
            onChange={handleGroupOptionChange}
            error={errors.selectedCustomerGroupId ? true : false}
            helperText={errors.selectedCustomerGroupId ?? ""}
            variant="outlined"
          >
            {contactGroups &&
              contactGroups?.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </>
    );
  };

  return (
    <BasePage navigation="Dashboard/Contacts" business={business} canView={true}>
      <div>
        <ContactsTable
          contacts={contacts}
          setCreateDialogOpen={setCreateDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          setInviteDialogOpen={setInviteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          selected={selected}
          setSelected={setSelected}
          setErrors={setErrors}
          shouldGetData={true}
          isLoading={contactsLoading}
          getAllData={getContacts}
          islastPage={isLastPage}
          business={business}
          dispatch={dispatch}
          userHasMembership={userHasMembership}
          renderCustomerGroups={!createDialogOpen && !editDialogOpen ? renderCustomerGroupSelection() : ""}
          setAssignToGroupDialogOpen={setAssignToGroupDialogOpen}
        />
        {business.company_details && (
          <>
            <ContactDialog
              open={createDialogOpen}
              setOpen={setCreateDialogOpen}
              title="Create new contact"
              description="Edit details of the contact."
              onSubmit={onCreateNew}
              errors={errors}
              business={business}
              isEdit={false}
              renderCustomerGroups={!createDialogOpen || !editDialogOpen ? renderCustomerGroupSelection() : ""}
              setSelectedCustomerGroupId={setSelectedCustomerGroupId}
              selectedCustomerGroupId={selectedCustomerGroupId}
              setSelectGroupFromTheList
            />

            <ContactDialog
              open={editDialogOpen}
              setOpen={setEditDialogOpen}
              title="Edit customer"
              description="Edit details of the contact."
              onSubmit={onEdit}
              errors={errors}
              defaultValues={selected[0]}
              business={business}
              isEdit={true}
              renderCustomerGroups={renderCustomerGroupSelection()}
              setSelectedCustomerGroupId={setSelectedCustomerGroupId}
              selectedCustomerGroupId={selectedCustomerGroupId}
              setSelectGroupFromTheList={setSelectGroupFromTheList}
            />

            <InviteDialog
              title="Message to Your Contact"
              description="Message to Your Contact"
              selected={selected}
              setSelected={setSelected}
              onSubmit={onInvite}
              open={inviteDialogOpen}
              setOpen={setInviteDialogOpen}
              msgToCustomer={msgToCustomer}
              setMsgToCustomer={setMsgToCustomer}
            />

            <AssignContactToGroupDialog
              assignToGroupDialogOpen={assignToGroupDialogOpen}
              setAssignToGroupDialogOpen={setAssignToGroupDialogOpen}
              renderCustomerGroupSelection={renderCustomerGroupSelection()}
            />

            <ConfirmDeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} onSubmit={onDelete} />
          </>
        )}
      </div>
    </BasePage>
  );
};

export default Contacts;
