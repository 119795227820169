import axios from "axios";
import Swal from "sweetalert2";

import { API_BASE } from "../env";
import { getBusinessDetails } from "./businessActions";
import { clearErrors, clearSuccess, setErrors, setSuccess, startLoading } from "./uiActions";

import { useSelector } from "react-redux";

const FileDownload = require("js-file-download");

export const getSingleOutgoingInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoice/lastInvoice`);

    dispatch({
      type: "OUTGOING_INVOICE_SINGLE_DATA_FETCH_SUCCESS",
      payload: invoices.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

//incoming invoices
export const getCloundInvoicesForEachSupplier = (contactId, supplierId, pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoice/cloud-incoming`, {
      params: {
        supplierId: supplierId,
        contactId: contactId,
        pageNumber: pageNumber,
      },
    });
    dispatch({
      type: "INCOMING_INVOICE_ERP_DATA_FETCH_SUCCESS",
      payload: invoices.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getInvoiceById = (invoiceId) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoice`, {
      params: {
        invoiceId: invoiceId,
      },
    });

    dispatch({
      type: "INVOICE_SINGLE_DATA_FETCH_SUCCESS",
      payload: invoices.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const createIncomingInvoice = (details, history) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.post(`${API_BASE}/invoices/incoming`, details);

    dispatch({
      type: "INVOICE_DATA_FETCH_SUCCESS",
      payload: invoices.data.data,
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully created invoice(s)."));
    dispatch(clearSuccess());

    history.push("/dashboard/incoming-invoices");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const createIncomingInvoices = (details, history) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.post(`${API_BASE}/invoices/incoming/batch-create`, details);
    dispatch({
      type: "INVOICE_DATA_FETCH_SUCCESS",
      payload: invoices.data.data,
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully created incoming invoice(s)."));
    // dispatch(getIncomingInvoices());
    history.push("/dashboard/incoming-invoices");

    dispatch(clearSuccess());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeIncomingInvoices = (invoices) => async (dispatch) => {
  let data = {
    invoices,
    invoiceType: "incoming",
  };
  try {
    let response = await axios.post(`${API_BASE}/invoices/batch-remove`, data);
    dispatch({
      type: "INCOMING_INVOICE_DATA_FETCH_SUCCESS",
      payload: response.data.allInvoices,
    });

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed invoices(s)."));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getIncomingInvoices = ({ ...params }) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let supplierId = params[0]?.selectedFieldId ? params[0]?.selectedFieldId.split("/")[1] : "all_suppliers";
    let contactId = params[0]?.selectedFieldId ? params[0]?.selectedFieldId.split("/")[0] : null;
    if (params[0]?.selectedFieldId === "all_suppliers") params[0].selectedFieldId = null;

    let invoices = await axios.get(`${API_BASE}/invoices/incoming`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        statusField: params[0]?.statusField ? params[0]?.statusField : "Awaiting Payment",
        selectedFieldId: params[0]?.selectedFieldId ? params[0]?.selectedFieldId : null,
        contactId: contactId,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        pageNumber: params[0]?.pageNumber ? params[0]?.pageNumber : null,
        cleanRedis: params[0]?.cleanRedis ? true : null,

        limit: 100, // to test
      },
    });

    if (invoices.data.length > 0) {
      let status = invoices.data[0].status;
      if (status === "Paid") {
        dispatch({
          type: "INCOMING_INVOICE_PAID_DATA_FETCH_SUCCESS",
          payload: invoices.data,
        });
      } else if (status === "Awaiting Payment") {
        dispatch({
          type: "INCOMING_INVOICE_PENDING_DATA_FETCH_SUCCESS",
          payload: invoices.data,
        });
      }
    }
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getOutgoingReceipts = ({ ...params }) => async (dispatch) => {
  try {
    dispatch(startLoading());
    dispatch({ type: "RECEIPT_DATA_FETCH_STARTED" });

    let invoice = await axios.get(`${API_BASE}/outgoing/receipts`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        statusField: params[0]?.statusField ? params[0]?.statusField : null,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        limit: 10, // to test
      },
    });

    console.log("iwnvoice", invoice);
    dispatch({
      type: "OUTGOING_RECEIPTS_DATA_FETCH_SUCCESS",
      payload: invoice.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getIncomingReceipts = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "RECEIPT_DATA_FETCH_STARTED" });
    let invoice = await axios.get(`${API_BASE}/incoming/receipts`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        limit: 100, // to test
      },
    });

    console.log("invoşce", invoice);
    dispatch({
      type: "INCOMING_RECEIPTS_DATA_FETCH_SUCCESS",
      payload: invoice.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeOutgoingInvoices = (invoices, setDeleteDialogOpen, setSelected, setDeleteLoading) => async (dispatch) => {
  let data = {
    invoices,
    invoiceType: "outgoing",
  };
  try {
    let response = await axios.post(`${API_BASE}/invoices/batch-remove`, data);
    dispatch({
      type: "OUTGOING_INVOICE_DATA_FETCH_SUCCESS",
      payload: response.data.allInvoices,
    });
    dispatch(getOutgoingInvoices([{ statusField: "Awaiting Payment" }]));

    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed invoices(s)."));
    setDeleteDialogOpen(false);
    setDeleteLoading(false);
    setSelected([]);
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const createOutgoingInvoice = (details, history) => async (dispatch) => {
  try {
    dispatch({ type: "OUTGOING_INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.post(`${API_BASE}/invoices/outgoing`, details);
    dispatch({
      type: "OUTGOING_INVOICE_DATA_FETCH_SUCCESS",
      payload: invoices.data.allInvoices,
    });

    dispatch(clearErrors());
    dispatch(getBusinessDetails());

    history.push("/dashboard/outgoing-invoices");
    dispatch(setSuccess("Successfully created invoice(s)."));

    dispatch(clearSuccess());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const handleRequestPayment = (details) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.post(`${API_BASE}/invoices/payment-request`, { data: details });

    dispatch({
      type: "OUTGOING_INVOICE_DATA_FETCH_SUCCESS",
      payload: invoices.data.allInvoices,
    });
    dispatch(getOutgoingInvoices({}));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const handleSendInvoiceReminder = (data) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    await axios.post(`${API_BASE}/invoices/invoice-reminder`, data);
    dispatch(setSuccess("Successfully sent invoice reminder"));

    dispatch(getOutgoingInvoices({}));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const handleSendInvoicePaymentLink = (data) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    await axios.post(`${API_BASE}/invoices/invoice-payment-link`, data);
    dispatch(setSuccess("Successfully sent invoice payment link"));

    dispatch(getOutgoingInvoices({}));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getPaymentRequestedInvoice = (details) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoices/payment-request`, {
      params: {
        invoiceId: details,
      },
    });

    // dispatch({ type: "SELECTED_OUTGOING_INVOICE_STATUS", payload: "Awaiting Payment" });

    dispatch({ type: "REQUESTED_INVOICE", payload: invoices.data });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const createOutgoingInvoices = (details, history) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });
    await axios.post(`${API_BASE}/invoices/outgoing/batch-create`, details);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully created outgoing invoice(s)."));
    dispatch({ type: "IMPORT_SUCESS" });

    dispatch(getOutgoingInvoices({}));
    history.push("/dashboard/outgoing-invoices");
    dispatch(clearSuccess());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getOutgoingInvoices = ({ ...params }) => async (dispatch) => {
  if (params[0]?.selectedFieldId === "all_customers") params[0].selectedFieldId = null;
  try {
    dispatch({ type: "OUTGOING_INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoices/outgoing`, {
      params: {
        lastItemId: params[0]?.lastItemId ? params[0]?.lastItemId : null,
        searchField: params[0]?.searchField ? params[0]?.searchField : null,
        statusField: params[0]?.statusField ? params[0]?.statusField : "Awaiting Payment",
        searchText: params[0]?.searchText ? params[0]?.searchText : null,
        selectedDateOption: params[0]?.selectedDateOption ? params[0]?.selectedDateOption : null,
        selectedFieldId: params[0]?.selectedFieldId ? params[0]?.selectedFieldId : null,
        nextPage: params[0]?.nextPage ? params[0]?.nextPage : null,
        createdDate: params[0]?.createdDate ? params[0]?.createdDate : null,
        dueDate: params[0]?.dueDate ? params[0]?.dueDate : null,
        limit: 50, // to test
      },
    });

    if (params[0]?.statusField === "Paid") {
      dispatch({
        type: "OUTGOING_INVOICE_PAID_DATA_FETCH_SUCCESS",
        payload: invoices.data,
      });
    } else if (params[0]?.statusField === "Awaiting Payment") {
      dispatch({
        type: "OUTGOING_INVOICE_PENDING_DATA_FETCH_SUCCESS",
        payload: invoices.data,
      });
    } else if (params[0]?.statusField === "Approved By Customer") {
      dispatch({
        type: "OUTGOING_INVOICE_APPROVED_DATA_FETCH_SUCCESS",
        payload: invoices.data,
      });
    } else if (params[0]?.statusField === "Factoring Requested") {
      dispatch({
        type: "OUTGOING_INVOICE_FACTORING_DATA_FETCH_SUCCESS",
        payload: invoices.data,
      });
    } else if (params[0].statusField === "Factoring Accepted") {
      dispatch({
        type: "OUTGOING_INVOICE_FACTORING_ACCEPTED_DATA_FETCH_SUCCESS",
        payload: invoices.data,
      });
    }

    dispatch(clearSuccess());

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const importOutgoingInvoicesFromErp = () => async (dispatch) => {
  try {
    dispatch({ type: "OUTGOING_INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoice/import-cloud-erp-invoice`);

    dispatch({
      type: "OUTGOING_INVOICE_DATA_FETCH_SUCCESS",
      payload: invoices.data,
    });

    dispatch(setSuccess("Successfully imported invoices"));
    dispatch(getOutgoingInvoices({}));

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getPastDueInvoices = (dateOption) => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });

    let invoices = await axios.get(`${API_BASE}/invoice/past-due-invoices`, {
      params: {
        selectedDateOption: dateOption,
      },
    });
    console.log("inac", invoices);
    dispatch({
      type: "PAST_DUE_OUTGOING_INVOICE_DATA_FETCH_SUCCESS",
      payload: invoices.data,
    });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getInvoiceDetails = (id) => async (dispatch) => {
  try {
    let invoice = await axios.post(`${API_BASE}/invoice`, id);
    console.log(invoice);
    // dispatch({ type: "SET_OUTGOING_INVOICES", payload: invoice.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateStatusOfInvoice = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/invoices/update-status`, details);
    dispatch(clearErrors());
    if (details.statusDetails.status === "Requested Loan") {
      dispatch(setSuccess("Loan Requested from the bank."));
      dispatch(getIncomingInvoices());
    } else if (details.statusDetails.status === "Rejected") {
      dispatch(setSuccess("Invoice rejected."));
      dispatch(getIncomingInvoices());
    } else if (details.statusDetails.status === "Factoring Requested") {
      dispatch(setSuccess("Factoring Requested"));
      dispatch(getOutgoingInvoices());
    } else if (details.statusDetails.status === "Agreed with bank") {
      dispatch(setSuccess("Invoice status is changed"));
      dispatch(getIncomingInvoices());
    }
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAuthUrl = (data) => async (dispatch) => {
  console.log("data", data);
  try {
    let response = await axios.get(`${API_BASE}/invoice/get-auth-url`, {
      params: {
        application: data.application,
        path: data.path,
      },
    });
    let authUrl = response.data.authUrl;
    dispatch(clearErrors());
    window.location.href = authUrl;
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const exchangeCodeForCloudToken = (data, state) => async (dispatch) => {
  try {
    dispatch({ type: "ACCESS_TOKEN_FETCH_STARTED" });

    let response = await axios.post(`${API_BASE}/invoice/exchange-code`, data);
    dispatch(clearErrors());

    dispatch({ type: "SET_CLOUD_TENANTS", payload: response.data.tenants });

    // if (state === "quickBooks") dispatch(setSuccess("Connected to QuickBooks successfully"));

    dispatch({ type: "ACCESS_TOKEN_FETCH_SUCCESS" });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getTenantsFromCloudApp = (state) => async (dispatch) => {
  try {
    dispatch({ type: "CLOUD_TENANTS_FETCH_STARTED" });

    // let auth_code;

    // if(state === "quickBooks"){
    //    auth_code = localStorage.getItem("quickBooksAccessToken")
    // }else{
    //     auth_code = localStorage.getItem("xeroAccessToken")
    // }

    let tenants = await axios.get(`${API_BASE}/invoice/get-tenants`, {
      params: {
        application: "xero",
      },
    });
    dispatch({ type: "SET_CLOUD_TENANTS", payload: tenants.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const fetchCloudBankAccounts = (params) => async (dispatch) => {
  console.log(params);
  try {
    dispatch({ type: "FETCH_CLOUD_BANK_ACCOUNTS" });

    let response = await axios.get(`${API_BASE}/invoice/cloud/bank-accounts`, {
      params: {
        application: "xero",
        tenantId: params.tenantId,
      },
    });
    dispatch({ type: "SET_CLOUD_BANK_ACCOUNTS", payload: response.data });
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

// manage endpint for retrieving single invoice by invNumber
export const getInvoicesFromCloud = (...params) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_SINGLE_CLOUD_INVOICE" });

    let response = await axios.get(`${API_BASE}/invoice/cloud-outgoing-invoices`, {
      params: {
        createdDate: params[0]?.createdDate ? params[0]?.createdDate : null,
        dueDate: params[0]?.dueDate ? params[0]?.dueDate : null,
        invoiceNumber: params[0].invoiceNumber ? params[0]?.invoiceNumber : null,
      },
    });
    console.log("responseSADSDS", response.data);
    if (params[0].invoiceNumber) {
      dispatch({ type: "SET_SINGLE_CLOUD_INVOICE", payload: response.data });
    } else {
      dispatch({ type: "SET_CLOUD_INVOICES", payload: response.data });
    }
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
      dispatch(clearErrors());
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
      dispatch(clearErrors());
    }
  }
};

export const sendApprovalRequest = (data) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/invoice/sendForApproval`, data);
    dispatch(setSuccess("Successfully sent"));
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
      dispatch(clearErrors());
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
      dispatch(clearErrors());
    }
  }
};

export const requestFinancingonInvoice = (data, history) => async (dispatch) => {
  try {
    let invoice = await axios.post(`${API_BASE}/invoices/request-financing`, data);
    dispatch(setSuccess("Successfully saved"));

    dispatch(getOutgoingInvoices([{ statusField: "Approved By Customer" }]));
    dispatch(getOutgoingInvoices([{ statusField: "Factoring Requested" }]));

    // history.push("/dashboard/outgoing-invoices");
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
// eslint-disable-next-line
export const financingRequestedInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });
    let invoice = await axios.get(`${API_BASE}/invoice/financing-invoices`);
    dispatch({
      type: "FINANCING_INVOICE_DATA_FETCH_SUCCESS",
      payload: invoice.data,
    });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getFinancingOffersById = (offers) => async (dispatch) => {
  console.log("getFinancingOffersById", offers);
  try {
    dispatch({ type: "INVOICE_DATA_FETCH_STARTED" });
    let invoice = await axios.get(`${API_BASE}/invoice/financing-offers`, {
      params: {
        offerIds: offers,
      },
    });
    dispatch({ type: "FINANCING_INVOICE_OFFERS", payload: invoice.data });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const acceptFinancingOffer = (details) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/invoice/accept-offer`, {
      offerId: details,
    });
    dispatch(setSuccess("Successfully saved"));
    dispatch(financingRequestedInvoices({}));
    dispatch(getOutgoingInvoices([{ statusField: "Factoring Accepted" }]));
    dispatch(getOutgoingInvoices([{ statusField: "Factoring Requested" }]));

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const downloadExcellFile = (offerId) => async (dispatch) => {
  try {
    axios({
      url: `${API_BASE}/invoice/download-excell`,
      params: {
        offerId: offerId,
      },
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, `financing_details-${offerId}.xls`);
    });
    // dispatch({type: "FINANCING_EXCELL_FILE", payload: invoice.data})

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const approveInvoice = (data, supplierId) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/invoices/update-status`, data);

    dispatch(setSuccess("Successfully saved"));
    dispatch(getIncomingInvoices([{ selectedFieldId: supplierId, statusField: "Pending Approval" }]));

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateCloudErpSettings = (details) => async (dispatch) => {
  try {
    let business = await axios.post(`${API_BASE}/invoice/update-cloud-erp-information`, details);
    let businessDetails = { ...business.data };
    dispatch({ type: "SET_BUSINESS", payload: businessDetails });
    dispatch(setSuccess("Successfully updated"));
    let html = `<p>
    We will be importing your customers and invoices from your application very shortly!`;
    Swal.fire({
      title: "Successfully connected cloud erp application",
      html: html,
      icon: "success",
    });
    dispatch(getBusinessDetails());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      // console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const revokeCloudErpToken = () => async (dispatch) => {
  try {
    dispatch({ type: "SET_CLOUD_TENANTS", payload: [] });
    dispatch({ type: "SET_CLOUD_BANK_ACCOUNTS", payload: [] });

    await axios.post(`${API_BASE}/invoice/cloud-erp/revoke-token`);

    dispatch(setSuccess("Successfully removed access from cloud erp application"));
    dispatch(getBusinessDetails());

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const updateVatPercentage = (details) => async (dispatch) => {
  try {
    await axios.put(`${API_BASE}/invoice/vat-percentage`, details);

    dispatch(setSuccess("Successfully updated vat percentage"));
    dispatch(getBusinessDetails());

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

// Financing Dashboard
export const getFinancingRequestedInvoices = () => async (dispatch) => {
  try {
    let invoices = await axios.get(`${API_BASE}/invoice/financing-requestable-invoices`);

    dispatch({ type: "FINANCING_INVOICES", payload: invoices.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const updateBidDate = (details) => async (dispatch) => {
  try {
    let invoices = await axios.put(`${API_BASE}/invoice/financing`, details);
    dispatch(setSuccess("Successfully requested factoring on selected invoices"));

    dispatch(getFinancingRequestedInvoices());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
