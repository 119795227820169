import { Document, Font } from "@react-pdf/renderer";
//Vendor
import styled from "@react-pdf/styled-components";
import currency from "currency.js";
import React from "react";
import Noto from "./NotoSans-Regular.ttf";
Font.register({
  family: "Noto Sans",
  format: "truetype",
  src: Noto,
});

const BillPage = styled.Page`
  padding: 80px 40px;
  font-family: "Noto Sans";
`;

const BillDetails = styled.View`
  display: table;
  width: auto;
  margin: 0 auto;
  flex-direction: row;
`;

const BillColumnLeft = styled.View`
  width: 50%;
  padding-right: 50px;
  padding-left: 0px;

  text-align: left;
`;
const BillColumnRight = styled(BillColumnLeft)`
  padding-left: 50px;
  padding-right: 0px;
  text-align: right;
`;

const InvoiceHeading = styled.Text`
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  width: 100%;
`;
const InvoiceNumber = styled.Text`
  color: #444;
  font-size: 12px;
`;

const Details = styled.Text`
  font-size: 12;
  padding: 5px 0;
  line-height: 1.2;
`;

const BillTable = styled.View`
  display: table;
  width: 100%;
`;
const BillRow = styled.View`
  margin: 0 auto;
  flex-direction: row;
  padding: 8px 0;
  width: "100%";
`;
const BillRowHead = styled(BillRow)`
  background-color: #333;
  font-size: 15px;
  border-radius: 2px;
  color: white;
  width: "100%";
`;
const BillDataText = styled.Text`
  width: 40%;
  padding: 0 5px;
  font-size: 12px;
`;

const BillDataLine = styled.Text`
  width: 10%;
  padding: 0 5px;
  font-size: 12px;
`;
const BillDataNum = styled.Text`
  width: 15%;
  padding: 0 5px;
  font-size: 12px;
`;
const BillDataSerial = styled(BillDataNum)`
  width: 2%;
`;
const BillTotal = styled(BillColumnRight)`
  marging-top: 15px;
  padding: 0;
`;

function ReceiptPDF(props) {
  let data = props.invoiceData;

  return (
    <Document>
      <BillPage>
        <BillDetails>
          <BillColumnLeft>
            <Details>Date : {data.sent_at}</Details>
            <Details>
              Amount : GBP {currency(data.amount_sent).format({ symbol: "" })}
            </Details>
            <InvoiceNumber style={{ fontSize: "10px" }}>
              Reference ID: {data.id}
            </InvoiceNumber>
          </BillColumnLeft>

          <BillColumnRight>
            <InvoiceHeading>PAYEDA</InvoiceHeading>
            <InvoiceHeading>RECEIPT</InvoiceHeading>
          </BillColumnRight>
        </BillDetails>
        <BillTable>
          <BillRowHead>
            <BillDataSerial>#</BillDataSerial>
            <BillDataText>Detail</BillDataText>
          </BillRowHead>
        </BillTable>
        <BillRow>
          <BillColumnLeft>
            <Details>Sender: </Details>
            <Details>Name: {data.remitter_name}</Details>
            <Details>Account Number: {data.remitter_account_number} </Details>
            <Details>Sort Code: {data.remitter_sort_code}</Details>
            <BillDataLine> </BillDataLine>

            <Details>Receiver: </Details>
            <Details>Name: {data.beneficiary_name}</Details>
            <Details>
              Account Number: {data.beneficiary_account_number}{" "}
            </Details>
            <Details>Sort Code: {data.beneficiary_sort_code}</Details>
          </BillColumnLeft>
        </BillRow>
        <BillDetails style={{ padding: "0 5px" }}>
          <BillColumnLeft>
            {/* <Details style={{ marginTop: "50px" }}>Note : Thanks</Details> */}
          </BillColumnLeft>
          <BillColumnRight>
            <BillDetails style={{ marginTop: "50px" }}>
              <BillTotal>{/* <Details>Total:</Details> */}</BillTotal>
              <BillTotal>{/* <Details>GBP sds</Details> */}</BillTotal>
            </BillDetails>
          </BillColumnRight>
        </BillDetails>
      </BillPage>
    </Document>
  );
}

export default ReceiptPDF;
