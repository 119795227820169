const map = {
  customers: "customer",
  products: "product",
  suppliers: "supplier",
  incominginvoices: "incomingInvoice",
  outgoinginvoices: "outgoingInvoice",
  creditrequests: "creditRequest",
  factoringrequests: "factoringRequest",
  payment: "payment",
  pendingapprovalagreements: "agreement",
  verifiedbusinesses: "verifiedBusinesses",
  pendingapprovalbusinesses: "pendingApprovalBusinesses",
  finance: "finance",
  mergeaccount: "mergeAccount",
  bankaccounts: "bankAccount",
};

module.exports = (header, permissions, action) => {
  if (permissions) {
    const clearedHeader = header.replace(/\s/g, "").toLowerCase();
    const section = map[clearedHeader];
    return permissions[section][action];
  }
};
