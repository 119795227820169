//TODO Fix this implementation
const initialState = {
  isLoading: false,
  errorMessage: null,
  paymentRequest: {},
  paymentResponse: {},
};

const bankingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BANKING_LOADING":
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case "BANK_ACCOUNTS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        bankAccounts: action.payload,
      };
    case "USER_BANK_ACCOUNTS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        userBankAccounts: action.payload,
      };
    case "PAYMENT_REQUEST_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        paymentRequest: action.payload,
      };
    case "PAYMENT_RESULT_LOADING":
      return {
        ...state,
        paymentLoading: true,
      };
    case "PAYMENT_RESPONSE_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        paymentLoading: false,
        paymentFailed: false,
        paymentResponse: action.payload,
      };
    case "PAYMENT_RESPONSE_FETCH_FAILED":
      return {
        ...state,
        isLoading: false,
        paymentLoading: false,
        paymentFailed: true,
      };
    case "PAYMENT_REQUEST_LOADING":
      return {
        ...state,
        paymentLoading: true,
        errorMessage: null,
      };
    case "BANKING_AUTH_URL_LOADING":
      return {
        ...state,
        authUrlLoading: true,
        errorMessage: null,
      };
    case "BANKING_AUTH_URL_SUCCESS":
      return {
        ...state,
        authUrlLoading: true,
        authUrl: action.payload,
      };
    case "BANKING_AUTH_URL_FAILED":
      return {
        ...state,
        authUrlLoading: false,
      };

    case "EXHANCE_CODE_LOADING":
      return {
        ...state,
        connectBankAccountLoading: true,
        connectBankAccountFailed: false,
        connectBankAccountSuccess: false,
      };

    case "EXHANCE_CODE_SUCCESS":
      return {
        ...state,
        connectBankAccountLoading: false,
        connectBankAccountFailed: false,
        connectBankAccountSuccess: true,
      };

    case "EXHANCE_CODE_FAILED":
      return {
        ...state,
        connectBankAccountLoading: false,
        connectBankAccountFailed: true,
        connectBankAccountSuccess: false,
      };

    case "TRANSACTION_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case "TRANSACTION_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        transactions: action.payload,
      };
    case "TRANSFER_MONEY_REQUEST_LOADING":
      return {
        ...state,
        transferLoading: true,
      };
    case "TRANSFER_MONEY_REQUEST_FETCH_SUCCESS":
      return {
        ...state,
        transferLoading: false,
        transferMoneyRequest: action.payload,
      };
    case "TRANSFER_MONEY_RESPONSE_FETCH_SUCCESS":
      return {
        ...state,
        transferLoading: false,
        transferMoneyResponse: action.payload,
      };

    case "TRANSACTIONS_FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        transactions: action.payload,
      };
    case "TOKEN_LOGS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "TOKEN_LOGS_FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        tokenLogs: action.payload,
      };

    case "BANKING_LOGS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "BANKING_LOGS_FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        bankingLogs: action.payload,
      };
    case "SET_REMAINING_BALANCE":
      return {
        ...state,
        loading: false,
        daily_remaining_balance: action.payload,
      };

    //to be delete...
    case "SET_AWAITING_REAUTH_BANKS":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          awaitingReauth: action.payload,
        },
      };
    case "SET_PAYMENT_RESULT":
      return {
        ...state,
        paymentResult: action.payload,
      };
    case "SET_AVAILABLE_INSTITUTIONS":
      return {
        ...state,
        availableInstitutions: action.payload,
      };
    default:
      return state;
  }
};

export default bankingReducer;
