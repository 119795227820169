import React from "react";
import { Button, Grid, Paper, Toolbar, Typography } from "@material-ui/core";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import clsx from "clsx";

import HeaderComponent from './../../../../Components/HeaderComponent';
import { useUiContext } from './../../../../Contexts/UiContext';
import BusinessDetails from "./BusinessDetails";
import { useBusinessProfileStyles } from "./BusinessProfileStyles";
import EditBusinessDetails from "./EditBusinessDetails";


const BusinessProfile = ({ business, user, updateUserInformation }) => {
  const classes = useBusinessProfileStyles();
  const { sidebarOpen } = useUiContext();

  let [renderUpdate, setRenderUpdate] = React.useState(true);

  const renderMenu = () => {
    return (
      <Toolbar variant="dense">
        <Button
          onClick={() => {
            setRenderUpdate(true);
          }}
        >
          Business
        </Button>
        <Button
          onClick={() => {
            setRenderUpdate(false);
          }}
        >
          Update
        </Button>
      </Toolbar>
    );
  };

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: sidebarOpen,
      })}
    >
      <div className={classes.root}>
        <HeaderComponent
          title="Profile"
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography>
                {business.details &&
                  business.details.logo &&
                  business.details.logo !== "" && (
                    <img
                      src={business.details.logo}
                      className={classes.companyLogo}
                      alt="User profile"
                    />
                  )}
              </Typography>

              <Typography className={classes.userProfileNameSurname}>
                {user.accountType === "Finance" && user.repFullName}
                {business?.representative?.name}{" "}
                {business?.representative?.surname}
              </Typography>
              <Typography className={classes.userProfileRole}>
                {user.accountType === "Finance" ? "Finance" : "Representative"}
              </Typography>
              <Typography className={classes.userProfileContact}>
                <p> {user.email}</p>
                <p>{user.phone}</p>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              {renderMenu()}
              {renderUpdate ? (
                <BusinessDetails business={business} user={user} />
              ) : (
                  <EditBusinessDetails updateUserInformation={updateUserInformation} user={user} />
                )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default BusinessProfile;
