import { IconButton, Tooltip, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import React from "react";
import { useHistory } from "react-router-dom";
import TableToolbarComponent from "./../../../Components/Table/TableToolbarComponent";
import ButtonBase from "./../../../Components/shared/ButtonBase";
import { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "../../Invoice/InvoicePDF";
import ChooseBasketDialog from "./ChooseBasketDialog";

const FinancingInvoicesTableToolbar = ({ numSelected, selected, setErrors, setOpen, header, invoiceData, dropdownData }) => {
  let history = useHistory();
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [openBasketsDialog, setOpenBasketsDialog] = useState(false);
  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <Grid container>
        <>
          {selected.length === 1 && (
            <>
              {" "}
              <Grid item xs={12} sm={6} md={6} lg={4}>
                {invoiceData && downloadPDF ? (
                  <>
                    <PDFDownloadLink
                      document={<InvoicePDF invoiceData={invoiceData} />}
                      fileName={invoiceData.invoice_id}
                      style={{
                        textDecoration: "none",
                        padding: "16px",
                        color: "#4a4a4a",
                        backgroundColor: "#f2f2f2",
                        border: "1px solid #4a4a4a",
                        borderRadius: "20px",
                        width: "120px",
                        height: "50px",
                      }}
                    >
                      {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download")}
                    </PDFDownloadLink>
                  </>
                ) : (
                  <>
                    <ButtonBase
                      tooltip="Prepare Invoice PDF"
                      text="Prepare Invoice PDF"
                      backColor="#00AFA1"
                      textColor="white"
                      onClick={() => setDownloadPDF(true)}
                      fontSize="400"
                      customWidth="200px"
                    />
                  </>
                )}
              </Grid>{" "}
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <>
                  <ButtonBase
                    tooltip="Add to Basket"
                    text="Add To Basket"
                    backColor="#00AFA1"
                    textColor="white"
                    onClick={() => setOpenBasketsDialog(true)}
                    fontSize="400"
                    customWidth="200px"
                  />
                  <ChooseBasketDialog open={openBasketsDialog} setOpen={setOpenBasketsDialog} selectedInvoice={selected[0]} dropdownData={dropdownData} />
                </>
              </Grid>
            </>
          )}
        </>
      </Grid>
    </TableToolbarComponent>
  );
};

export default FinancingInvoicesTableToolbar;
