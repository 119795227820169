import { Button } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import ImportInvoicesDialog from "./ImportInvoicesDialog";
import axios from "axios";
import { API_BASE } from "./../../store/env";
import { useDispatch } from "react-redux";
import { getFinancingRequestedInvoices } from "../../store/actions/invoiceActions";
import TableComponent from "./../../Components/Table/TableComponent";
import FinancingInvoicesToolbar from "./FinancingInvoicesToolbar";
import BidDetailsDialog from "./BidDetailsDialog";
import { acceptOffer, getAllFinancingInvoices, getAllFinancingOffers } from "../../store/actions/financingInvoicesAction";
import InvoiceDetailsDialog from "../Invoice/InvoiceDetailsDialog";

const outgoingInvoicesTableHeadCells = [
  { id: "finance_name", label: "Finance Name" },

  {
    id: "advance_payment_percentage",
    label: "Advance Payment % ",
  },
  { id: "advance_payment_amount", label: "Advance Payment Amount" },
  { id: "advance_payment_maturity", label: "Advance Payment Maturity" },

  { id: "expense_amount", label: "Expense Amount" },

  { id: "remaining_payment_amount", label: "Remaining Payment Amount" },
  { id: "remaining_payment_maturity", label: "Remaining Payment Maturity" },

  { id: "requestedTotalAmount", label: "Requested Amount" },
  { id: "offeredTotalAmount", label: "Offered Amount" },

  { id: "status", label: "Status" },
  { id: "createdAt", label: "Offered at" },
  { id: "valid_until", label: "Valid Until" },
];

const OffersTable = () => {
  const dispatch = useDispatch();

  let business = useSelector((state) => state.business);
  let financingOffers = useSelector((state) => state.financing.financingOffers);

  const [isUsingErpPlatform, setIsUsingErpPlatform] = useState(false);
  const [openImportInvoicesDialog, setOpenImportInvoicesDialog] = useState(false);
  const [shouldAwaitImport, setShouldAwaitImport] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openBidDetailsDialog, setOpenBidDetailsDialog] = useState(false);
  const [openOfferDetails, setOpenOfferDetails] = useState(false);

  useEffect(() => {
    getAllOffers();
  }, []);

  const getAllOffers = () => {
    dispatch(getAllFinancingOffers());
  };

  const handleAcceptOffer = () => {
    if (selected.length == 1) {
      let body = {
        offer_id: selected[0].id,
      };
      dispatch(acceptOffer(body));
    }
  };

  const renderToolbar = () => {
    return (
      <FinancingInvoicesToolbar
        selected={selected}
        numSelected={selected.length}
        header="Offers"
        dispatch={dispatch}
        setOpenBidDetailsDialog={setOpenBidDetailsDialog}
        setOpenOfferDetails={setOpenOfferDetails}
        handleAcceptOffer={handleAcceptOffer}
      />
    );
  };
  return (
    <>
      <TableComponent
        toolbar={renderToolbar()}
        data={financingOffers?.result}
        selected={selected}
        setSelected={setSelected}
        headCells={outgoingInvoicesTableHeadCells}
        shouldGetData={false}
        shouldPaginate={true}
        shouldView={true}
      />

      <InvoiceDetailsDialog open={openOfferDetails} setOpen={setOpenOfferDetails} selected={selected} rows={selected[0]?.invoices || []} />
    </>
  );
};

export default OffersTable;
