import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useUiContext } from "../../../Contexts/UiContext";
import { createBusiness } from "../../../store/actions/businessActions";
import verifySchema from "../../../util/yup/verifySchema";
import { bankAccountSchema, businessSchema, companyCertAndPlatformUsage, directorFilesSchema, directorSchema } from "../../../util/yup/yupSchemas";
import CompanyInformationPage from "./CompanyInformationPage";
import VerifyBusinessWithCompanyHouse from "./VerifyBusinessWithCompanyHouse";
import VerifySoleTraderWithVat from "./VerifySoleTraderWithVat";

const sho1 = {
  well: {
    boxShadow: `10px 3px 10px grey`,
    borderRadius: "20px",
    flexGrow: 2,
    padding: "30px",
  },
};
const BusinessStepper = ({ user, business, isPathUpdate, history, setIsSoleTrader }) => {
  const dispatch = useDispatch();
  const methods = useFormContext();

  const { register, watch, setValue } = methods;
  const form = watch();

  const { setErrors, clearErrors, errors } = useUiContext();

  const businessCreateLoading = useSelector((state) => state.business.businessCreateLoading);
  const companyHouseResult = useSelector((state) => state.business.companyHouseResult);
  const hmrcResult = useSelector((state) => state.business.hmrcResult);

  const [accountType, setAccountType] = useState(null);
  const [usageType, setUsageType] = useState(null);
  const [companyCert, setCompanyCert] = useState(null);
  const [bnkSt, setBnkSt] = useState(null);
  const [companyLogo, setLogo] = useState(null);
  const [idRear, setIdRear] = useState(null);
  const [idFront, setIdFront] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [shouldRenderCompanyInformation, setShouldRenderCompanyInformation] = useState(null);
  const [shouldAskForCompanyDetails, setShouldAskForCompanyDetails] = useState(false);
  const [registrationOption, setRegistrationOption] = useState(null);
  const [utrNo, setUtrNo] = useState("");
  const [textFieldName, setTextFieldName] = useState(null);
  const [textFieldLabel, setTextFieldLabel] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");

  useEffect(() => {
    if (!businessCreateLoading) {
      if (isPathUpdate && business && business.account_type) {
        if (business && business.account_type === "Business") {
          setAccountType("Business");
          setUsageType("receive_payment");
        } else if (business && business.account_type === "Personal") {
          setAccountType("Personal");
          setUsageType("transfer_money");
        }
      } else if (!isPathUpdate && user && user.accountType) {
        if (user.accountType === "Business") {
          setAccountType("Business");
          if (user.usage_type.isInvoicing) {
            setUsageType("receive_payment");
          } else {
            setUsageType("make_payment");
          }
        } else if (user.accountType === "Personal") {
          setAccountType("Personal");
          if (user.usage_type.isTransferMoney) {
            setUsageType("transfer_money");
          } else {
            setUsageType("make_payment");
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [business, user]);

  useEffect(() => {
    if (business && business.company_details) {
      setValue("business_email", business.company_details.email);
      setValue("business_phone", business.company_details.phone);
      setValue("city", business?.company_details?.address?.city);
      setValue("address_line_1", business?.company_details?.address?.address_line_1);
      setValue("address_line_2", business?.company_details?.address?.address_line_2);
    }

    if (business.account_type === "Personal") {
      setValue("full_name", business.directors[0].full_name);
      setValue("passport_number", business.directors[0].passport_number);
      setValue("email", business.directors[0].email);
      setValue("email", business.directors[0].email);

      setValue("director_address_line_1", business.directors[0].address.address_line_1);
      setValue("director_address_line_2", business.directors[0].address.address_line_2);
    }

    // eslint-disable-next-line
  }, [business]);

  useEffect(() => {
    if (registrationOption === "vatNo") {
      setTextFieldName("vat_number");
      setTextFieldLabel("Enter your VAT number");
    }
    if (registrationOption === "passportId") {
      setTextFieldName("passport_id");
      setTextFieldLabel("Enter your passport id number");
    }
    if (registrationOption === "national_insurance_number") {
      setTextFieldName("national_insurance_number");
      setTextFieldLabel("Enter your national insurance number");
    }
    if (registrationOption === "utrNo") {
      setTextFieldName("utr_number");
      setTextFieldLabel("Enter your Unique taxpayer reference");
    }
  }, [registrationOption]);

  useEffect(() => {
    if ((companyHouseResult && Object.keys(companyHouseResult).length > 1) || (hmrcResult && Object.keys(hmrcResult).length > 1)) {
      setShouldRenderCompanyInformation(true);
    } else setShouldRenderCompanyInformation(false);
  }, [companyHouseResult, hmrcResult]);

  const loadingAlert = () => {
    Swal.fire({
      title: "",
      text: "Loading...",
      showSpinner: true,
      footer: "Do not close this window, this may take a few minutes.",
      icon: "https://www.boasnotas.com/img/loading2.gif",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  };

  const onSubmit = async () => {
    let filesValid;
    let businessInfoValid;
    let companyCertValid;
    let bankAcctValid;
    let directorValid;

    let canSubmit = false;

    if (accountType === "Business") {
      if (shouldRenderCompanyInformation) {
        form.accountType = "Business";
        form.company_reg_number = companyHouseResult?.company_number || "";
        if (selectedOption === "soleTrader") {
          form.business_type = "soleTrader";

          form[textFieldName] = textFieldValue;
          form.vat_number = hmrcResult?.target?.vatNumber || "";

          form.registrationOption = registrationOption;
        }
        businessInfoValid = await verifySchema(businessSchema, form, clearErrors, setErrors);

        if (usageType === "receive_payment") {
          form.makeOrReceivePayment = "receivePayment";
          form.company_certificate = companyCert;
          form.bank_statement = bnkSt;
          form.logo = companyLogo;
          form.isSoleTrader = selectedOption === "registeredCH" ? false : true;
          form.registrationOption = registrationOption;

          if (selectedOption === "soleTrader") {
            form.company_reg_number = "";
            if (!textFieldLabel) {
              setErrors({ general: "Please choose verifying option " });
              return false;
            }
            console.log("textfield", textFieldLabel, textFieldValue);
            if (!textFieldValue) {
              setErrors({ general: `${textFieldLabel}` });
              return false;
            }

            if (!idRear || !idFront) {
              setErrors({ general: `Please uplaod front page and rear page of your id` });
              return false;
            }
            form.id_front_page = idFront;
            form.id_rear_page = idRear;
            form[textFieldName] = textFieldValue;
            form.vat_number = hmrcResult?.target?.vatNumber || "";

            form.registrationOption = registrationOption;
            console.log("form", form)
            companyCertValid = true;
          }

          // sole trader does not have to upload company cert
          if (selectedOption !== "soleTrader") companyCertValid = await verifySchema(companyCertAndPlatformUsage, form, clearErrors, setErrors);
          bankAcctValid = await verifySchema(bankAccountSchema, form, clearErrors, setErrors);

          if (selectedOption !== "soleTrader" && !companyCertValid) {
            setErrors({ general: "Please upload company certificate" });
          }
          if (!bankAcctValid) {
            setErrors({
              general: "Please enter all related files for receive payment account",
            });
          }
          if (!form.isErpOrIsManuel) {
            setErrors({ general: "Please choose platform usage type" });
            return false;
          }
        } else {
          form.isSoleTrader = selectedOption === "registeredCH" ? false : true;
          form.makeOrReceivePayment = "makePayment";
          companyCertValid = true;
          bankAcctValid = true;
        }
        if (companyCertValid && bankAcctValid && businessInfoValid) {
          canSubmit = true;
        }
      } else {
        setErrors({ general: "Please verify your company" });
      }
    } else if (accountType === "Personal") {
      form.accountType = "Personal";
      directorValid = await verifySchema(directorSchema, form, clearErrors, setErrors);
      if (usageType === "transfer_money") {
        form.transferMoneyOrBoth = "transferMoney";
        form.id_front_page = idFront;
        form.id_rear_page = idRear;
        form.address_proof = addressProof;
        filesValid = await verifySchema(directorFilesSchema, form, clearErrors, setErrors);
        if (!filesValid) {
          setErrors({ general: "Please enter all files for the director" });
        }
      } else {
        form.makeOrReceivePayment = "makePayment";

        filesValid = true;
      }

      if (filesValid && directorValid) {
        canSubmit = true;
      }
    }

    let dataToSend = {
      ...form,
    };
    console.log("dataToSend: ", form)

    if (isPathUpdate) {
      dataToSend.type = "update";
      dataToSend.update_type = usageType;
    }

    if (canSubmit) {
      for (const [key, value] of Object.entries(dataToSend)) {
        if (key === "logo" || key === "bank_statement" || key === "company_certificate" || key === "id_rear_page" || key === "id_front_page") {
          if (value !== null && value.item(0) !== null) dataToSend[key] = value.item(0);
          else dataToSend[key] = "";
        }
      }

      let data = {};

      if (accountType === "Personal") {
        let officers = [];
        officers.push(form);
        let i = 1;

        for (let o of officers) {
          for (let [key, value] of Object.entries(o)) {
            let newkey = i + "." + key;

            o[`${newkey}`] = value;

            if (key === "id_rear_page" || key === "id_front_page" || key === "address_proof") {
              if (value !== null && value.item(0) !== null) o[`${newkey}`] = value.item(0);
              else o[`${newkey}`] = "";
            }
            delete o[key];
          }
          data = { ...data, ...o };
          i++;
        }
        dataToSend = { ...data, officerLength: 1, accountType: "Personal" };
        if (usageType === "transfer_money") {
          dataToSend.transferMoneyOrBoth = "transferMoney";
        } else {
          dataToSend.makeOrReceivePayment = "makePayment";
        }
        if (isPathUpdate) {
          dataToSend.type = "update";
          dataToSend.update_type = "transfer_money";
        }
      }
      let fd = new FormData();
      for (var key in dataToSend) {
        fd.append(key, dataToSend[key]);
      }

      let shouldShowAwaitForApprovalMessage = false;

      if (dataToSend.makeOrReceivePayment === "receivePayment" || dataToSend.transferMoneyOrBoth === "transferMoney") {
        shouldShowAwaitForApprovalMessage = true;
      } else shouldShowAwaitForApprovalMessage = false;

      loadingAlert();
      dispatch(createBusiness(fd, history, isPathUpdate, shouldShowAwaitForApprovalMessage));
    }
  };

  const renderPersonalRep = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Passport ID or National ID"
            inputProps={{ maxLength: 30 }}
            id="passport_number"
            name="passport_number"
            inputRef={register}
            error={errors.passport_number ? true : false}
            helperText={errors.passport_number ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Full Name"
            id="full_name"
            name="full_name"
            inputRef={register}
            error={errors.full_name ? true : false}
            helperText={errors.full_name ?? ""}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField fullWidth variant="outlined" label="E-mail" id="email" name="email" inputRef={register} error={errors.email ? true : false} helperText={errors.email ?? ""} />
        </Grid>

        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            name="nationality"
            label="Nationality"
            inputRef={register}
            error={errors.nationality ? true : false}
            helperText={errors.nationality ?? ""}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            name="director_address_line_1"
            label="Address Line 1"
            inputRef={register}
            error={errors.director_address_line_1 ? true : false}
            helperText={errors.director_address_line_1 ?? ""}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            name="director_address_line_2"
            label="Address Line 2"
            inputRef={register}
            error={errors.director_address_line_2 ? true : false}
            helperText={errors.director_address_line_2 ?? ""}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <TextField
            name="director_country"
            fullWidth
            variant="outlined"
            label="Country"
            inputRef={register}
            error={errors.director_country ? true : false}
            helperText={errors.director_country ?? ""}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} md={12}>
          <label for="start">Birth date: </label>

          <input fullWidth type="date" id="birthday" name="birthday" max="2999-12-31" ref={register} />
        </Grid>
        {usageType === "transfer_money" ||
          (selectedOption === "soleTrader" && (
            <>
              <>
                {" "}
                <Grid item xs={12} lg={6} md={6}>
                  <small>Front Page:</small> <br />
                  <input name="id_front_page" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setIdFront(e.target.files)} /> <br />
                  <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <small>Rear Page:</small> <br />
                  <input name="id_rear_page" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(e) => setIdRear(e.target.files)} /> <br />
                  <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                </Grid>
              </>

              {selectedOption !== "soleTrader" && (
                <Grid item xs={12} lg={12} md={12}>
                  <small>Proof Of Address:</small> <br />
                  <input
                    name="address_proof"
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={(e) => setAddressProof(e.target.files)}
                  />{" "}
                  <br />
                  <small style={{ fontSize: "13px" }}>{"*Max allowed size is 3 MB"}</small>
                </Grid>
              )}
            </>
          ))}
      </Grid>
    );
  };

  useEffect(() => {
    if (business) {
      if (business.isSoleTrader) {
        setSelectedOption("soleTrader")
      } else if (business.company_details?.company_reg_number) {
        setSelectedOption("registeredCH");

      }
    }
  }, [business])
  const handleRadioChange = (e) => {

    if (business.isSoleTrader) {
      setSelectedOption("soleTrader")
    } else if (business.company_details?.company_reg_number) {
      setSelectedOption("registeredCH");

    } else {
      let val = e.target.value;
      setSelectedOption(val);
    }

  };
  const renderChooseBusinessType = () => {
    return (
      <form>
        <div style={{ marginTop: "30px" }}>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}></strong>
          </Typography>
          <div>
            <input id="soleTrader" name="businessType" value="soleTrader" checked={selectedOption === "soleTrader" || business.isSoleTrader} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="soleTrader">Sole Trader</label>
          </div>
          <div>
            <input id="registeredCH" name="businessType" value="registeredCH" checked={selectedOption === "registeredCH"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="registeredCH">Registered to company house</label>
          </div>
        </div>
      </form>
    );
  };
  return (
    <>
      {" "}
      <form>
        <div style={sho1.well}>
          {accountType === "Business" && (
            <>
              {" "}
              <div style={{ marginBottom: "25px" }}>{renderChooseBusinessType()} </div>
              {selectedOption === "registeredCH" && (
                <VerifyBusinessWithCompanyHouse business={business} isPathUpdate={isPathUpdate} errors={errors} companyHouseResult={companyHouseResult} />
              )}
              {selectedOption === "soleTrader" && (
                <VerifySoleTraderWithVat
                  business={business}
                  isPathUpdate={isPathUpdate}
                  errors={errors}
                  hmrcResult={hmrcResult}
                  setShouldRenderCompanyInformation={setShouldRenderCompanyInformation}
                  setShouldAskForCompanyDetails={setShouldAskForCompanyDetails}
                  setRegistrationOption={setRegistrationOption}
                  registrationOption={registrationOption}
                  utrNo={utrNo}
                  setUtrNo={setUtrNo}
                  setErrors={setErrors}
                  textFieldName={textFieldName}
                  textFieldLabel={textFieldLabel}
                  textFieldValue={textFieldValue}
                  setTextFieldValue={setTextFieldValue}
                />
              )}
              {shouldRenderCompanyInformation && (
                <CompanyInformationPage
                  business={business}
                  errors={errors}
                  accountType="Business"
                  usageType={usageType}
                  setCompanyCert={setCompanyCert}
                  setBnkSt={setBnkSt}
                  setLogo={setLogo}
                  selectedOption={selectedOption}
                  shouldAskForCompanyDetails={shouldAskForCompanyDetails}
                  setIdRear={setIdRear}
                  setIdFront={setIdFront}
                />
              )}
            </>
          )}

          {accountType === "Personal" && <>{renderPersonalRep()}</>}
        </div>

        <Button
          variant="outlined"
          color="primary"
          style={{
            float: "right",
            marginTop: "15px",
            marginRight: "15px",
            marginBottom: "25px",
          }}
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default BusinessStepper;
