import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useEffect, useState } from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import TableToolbarComponent from "../../Components/Table/TableToolbarComponent";

const FinancingInvoicesToolbar = ({ numSelected, selected, header, setOpenBidDetailsDialog, setOpenOfferDetails, handleAcceptOffer }) => {
  let history = useHistory();

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        <Grid container>
          {numSelected > 0 && (
            <>
              {header === "Request Financing" && (
                <Grid item xs={3}>
                  <Tooltip title="Request Financing">
                    <Button onClick={() => setOpenBidDetailsDialog(true)} variant="outlined" color="secondary">
                      Request Financing
                    </Button>
                  </Tooltip>
                </Grid>
              )}
              {header === "Offers" && (
                <>
                  <Grid item xs={3}>
                    <Tooltip title="Offer Details">
                      <Button onClick={() => setOpenOfferDetails(true)} variant="outlined" color="secondary">
                        Invoice Details
                      </Button>
                    </Tooltip>{" "}
                  </Grid>
                  {numSelected === 1 && (
                    <>
                      <Grid item xs={3}>
                        <Tooltip title="Offer Details">
                          <Button
                            onClick={() => {
                              window.open(selected[0].offer_document);
                            }}
                            variant="outlined"
                            style={{ marginRight: "10px", backgroundColor: "blue", color: "white" }}
                          >
                            Download offer pdf
                          </Button>
                        </Tooltip>{" "}
                      </Grid>
                      <Grid item xs={3}>
                        <Tooltip title="Offer Details">
                          <Button onClick={handleAcceptOffer} variant="outlined" style={{ marginRight: "10px", backgroundColor: "green", color: "white" }}>
                            Accept Offer
                          </Button>
                        </Tooltip>{" "}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </>
    </TableToolbarComponent>
  );
};

export default FinancingInvoicesToolbar;
