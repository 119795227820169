const initialState = {
  isLoading: false,
};

//TODO create sepereate reducers for each actions. such as supplierReducer, productReducer etc..
const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BUSINESS_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "BUSINESS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case "BUSINESS_CREATE_STARTED":
      return {
        ...state,
        businessCreateLoading: true,
      };
    case "BUSINESS_CREATE_FAILED":
      return {
        ...state,
        businessCreateLoading: false,
      };
    case "BUSINESS_CREATE_SUCCESS":
      return {
        ...state,
        businessCreateLoading: false,
        ...action.payload,
      };
    case "SET_LAST_PAGE":
      return {
        ...state,
        lastPage: action.payload,
      };
    case "SET_COMPANY_HOUSE_INFORMATION":
      return {
        ...state,
        companyHouseResult: action.payload,
      };
    case "SET_HMRC_INFORMATION":
      return {
        ...state,
        hmrcResult: action.payload,
      };
    case "MERGED_ACCOUNTS_DATA_FETCH_STARTED":
      return {
        ...state,
        mergedLoading: true,
      };
    case "MERGED_ACCOUNTS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        mergedLoading: false,
        mergedAccts: action.payload,
      };
    case "INVITER_DATA_FETCH_STARTED":
      return {
        ...state,
        inviterLoading: true,
      };
    case "INVITER_DATA_FETCH_SUCCESS":
      return {
        ...state,
        inviterLoading: false,
        inviterInformation: action.payload,
      };
    case "BUSINESS_STATISTICS_SUCCESS":
      return {
        ...state,
        loading: false,
        statistics: action.payload,
      };
    case "BUSINESS_PLANS":
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };
    case "START_FETCHING_CONTACTS":
      return {
        ...state,
        contactsLoading: true,
      };
    case "SET_CONTACTS":
      return {
        ...state,
        contactsLoading: false,
        contacts: action.payload,
      };
    case "SET_CONTACT_GROUPS":
      return {
        ...state,
        contactsLoading: false,
        contactGroups: action.payload,
      };
    case "SET_UNAUTHENTICATED":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default businessReducer;
