import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import TableComponent from "../../../Components/Table/TableComponent";
import { getFinanceBasketData } from "../../../store/actions/financeActions";
import FinancingInvoicesTableToolbar from "./FinancingInvoicesTableToolbar";

const headCells = [
  { id: "supplier_name", label: "Supplier" },
  { id: "customer_name", label: "Customer" },
  { id: "amount_to_be_paid", label: "Amount" },
  { id: "due_date", label: "Invoice Due" },
  { id: "bid_opens_at", label: "Bid Opens At" },
  { id: "bid_closes_at", label: "Bid Closes At" },
];

const FinancingInvoicesTable = ({ data, selected, setSelected }) => {
  const [tableData, setTableData] = useState([]);
  let financeBaskests = useSelector((state) => state.finance.financeBasket);
  let selectedSupplier = useSelector((state) => state.finance.selectedSupplier);

  useEffect(() => {
    if (financeBaskests?.baskets?.length > 0) {
      prepareData();
    }
  }, [financeBaskests, selectedSupplier]);

  const prepareData = async () => {
    let body = [];

    if (financeBaskests.baskets.length > 0) {
      for (let basket of financeBaskests.baskets) {
        data = { value: basket.id, label: basket.supplier_name + " " + basket.total_invoices_in_basket + " Invoices in basket", selectedSupplier: basket.supplier_name };
      }
      body.push(data);
    }
    setTableData(body);
  };

  const renderToolbar = () => {
    return <FinancingInvoicesTableToolbar selected={selected} setSelected={setSelected} numSelected={selected.length} invoiceData={selected[0]} dropdownData={tableData} />;
  };

  return <TableComponent toolbar={renderToolbar()} data={data} selected={selected} setSelected={setSelected} headCells={headCells} />;
};

export default FinancingInvoicesTable;
