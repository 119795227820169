import React from "react";
import { Grid, Button } from "@material-ui/core";

import TableFooterComponent from "../../../Components/Table/TableFooterComponent";
import canPerformAction from "../../../util/permissions/canPerformAction";

const PendingAgreementTableFooter = ({
  selected,
  permissions,
  setErrors,
  denyOrApproveRequests,
  setBankAgreementDialogOpen
}) => {
  const handleInvoiceStatusChange = () => {
    let errors = {};
    if (selected.length === 0) {
      errors = { general: "Please select a field!" };
    }

    var details={
      invoiceId:selected[0].id,
      isApprovedByBank:false
    }

    denyOrApproveRequests(details)
    setErrors(errors);
  };


  return (
    <TableFooterComponent>
      <>
        {canPerformAction(
          "Pending Approval Agreements",
          permissions,
          "deny"
        ) && (
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleInvoiceStatusChange()}
            >
              Deny
            </Button>
          </Grid>
        )}

          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setBankAgreementDialogOpen(true)}
            >
              Approve
            </Button>
          </Grid>
 
      </>
    </TableFooterComponent>
  );
};

export default PendingAgreementTableFooter;
