import React, {useState} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import DialogComponent from '../../Components/Dialogs/DialogComponent';
import Button from '@material-ui/core/Button';

function RedirectAISPConsent() {

    let location = useLocation();
    let history = useHistory();
    
    const [consentSucces, setConsentSucces] = useState("");
    const [successDialog, setSuccessDialogOpen] = useState(false);

    React.useEffect(() => {
        // location.search
        let consent = new URLSearchParams(location.search).get('consent')
        let error = new URLSearchParams(location.search).get('error')

        if (consent) {
            setSuccessDialogOpen(true)
            setConsentSucces("Successfully added new  bank account")
        }
        else {
            console.log("Error occurred", error)
        }
    },[location.search])

    const handleClose = () =>{
        setSuccessDialogOpen(false);
        history.push("/dashboard/bank-accounts")
    }
    
    return (
        <DialogComponent
            open={successDialog}
            setOpen={setSuccessDialogOpen}
            withActions={false}
        >
            {consentSucces && <h1>{consentSucces}</h1>}
            <Button variant="outlined" color="secondary" onClick={handleClose}>Okay</Button> 
        </DialogComponent>
    )
}


export default RedirectAISPConsent;