const initialState = {
  loading: false,
  sidebarOpen: false,
  errors: {},
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ERRORS":
      return {
        ...state,
        loading: false,
        errors: { ...action.payload },
        error: true,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        loading: false,
        errors: {},
        error: false,
      };
    case "SET_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };
    case "CLEAR_SUCCESS":
      return {
        ...state,
        loading: false,
        success: false,
        message: {},
      };
    case "LOADING_UI":
      return {
        ...state,
        loading: false,
      };
    case "STOP_LOADING_UI":
      return {
        ...state,
        loading: false,
      };
    case "SHOW_SIDEBAR":
      return {
        ...state,
        sidebarOpen: true,
      };
    case "HIDE_SIDEBAR":
      return {
        ...state,
        sidebarOpen: false,
      };
    case "START_LOADING_BUTTON":
      return {
        ...state,
        loadingButton: true,
      };
    case "STOP_LOADING_BUTTON":
      return {
        ...state,
        loadingButton: false,
      };
    default:
      return state;
  }
};

export default uiReducer;
