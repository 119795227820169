const SECTION_HEADERS_ADMIN = {
    verifiedBusinesses: "Verified Businesses",
    pendingApprovalBusinesses: "Pending Approval Businesses",
    userAccounts: "User Accounts",
    finance: "Finance",
  };
  
  const CHECKBOX_ID_INCREMENTS_ADMIN = {
    verifiedBusinesses: "VerifiedBusinesses",
    pendingApprovalBusinesses: "PendingApprovalBusinesses",
    userAccounts: "UserAccounts",
    finance: "Finance",
  };
  
  const CHECKBOX_LABELS_ADMIN = {
    verifiedBusinesses: {
      view: "View Verified Businesses",
      deny: "Deny Business",
      showFiles: "Show Business Files"
    },
    pendingApprovalBusinesses: {
      view: "View Pending Approval Businesses",
      approve: "Approve Business",
      showFiles: "Show Business Files"
    },
    userAccounts: {
      view: "View All Users",
    },
    finance: {
      view: "View Finance Accounts",
      add: "Add Finance Account"
    },
  };
  
  module.exports = {
    CHECKBOX_LABELS_ADMIN,
    CHECKBOX_ID_INCREMENTS_ADMIN,
    SECTION_HEADERS_ADMIN,
  };
  