import { Button, Divider, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkoutPaymentRequest } from "../../store/actions/bankingActions";
import { useUiContext } from "./../../Contexts/UiContext";
import { API_BASE } from "./../../store/env";
import { useCheckoutStyles } from "./InvoiceStyles";

const Checkout = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState({});

  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { errors, setErrors } = useUiContext();
  const classes = useCheckoutStyles();
  const location = useLocation();

  const getInvoiceDetails = async (invoiceId) => {
    setLoading(true);
    let request = await axios.get(`${API_BASE}/invoices/payment-request`, {
      params: {
        invoiceId: invoiceId,
      },
    });
    if (request.status === 200) {
      let invoice = request.data;
      setInvoiceDetails({ ...invoice, id: invoiceId });
      return invoice;
    } else {
      setErrors({ general: "Wrong link submitted" });
      return null;
    }
  };

  const getBusinessDetails = async (customerId, supplierId) => {
    setLoading(true);

    var customerRequest = await axios.post(`${API_BASE}/get-business-details`, { id: customerId });
    var supplierRequest = await axios.post(`${API_BASE}/get-business-details`, { id: supplierId });

    if (customerRequest.status === 200) {
      let customerDetails = customerRequest.data;

      setValue("email", customerDetails.director?.email);
      setValue("phone", customerDetails.director?.phone);

      setCustomerDetails({ ...customerDetails, id: customerId });
    }

    if (supplierRequest.status === 200) {
      let supplierDetails = supplierRequest.data;
      setSupplierDetails({ ...supplierDetails, id: supplierId });
    }
  };

  useEffect(() => {
    setLoading(true);

    var id = new URLSearchParams(location.search).get("id");
    (async () => {
      if (id) {
        var invoice = await getInvoiceDetails(id);
        if (invoice) {
          await getBusinessDetails(invoice.customer, invoice.supplier);
        } else {
          //Invoice not found / Invoice id not provided
        }
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (invoiceDetails && supplierDetails && customerDetails) {
      setLoading(false);
    }
  }, [invoiceDetails, supplierDetails, customerDetails]);

  let paymentRequest = useSelector((state) => state.banking.paymentRequest);

  useEffect(() => {
    dispatch({
      type: "PAYMENT_REQUEST_FETCH_SUCCESS",
      payload: {},
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentRequest) {
      if (paymentRequest.auth_uri) {
        setTimeout(function() {
          window.location.href = paymentRequest.auth_uri;
        }, 1000);
      }
    }
  }, [paymentRequest]);

  const handlePayment = (data) => {
    let payeeBankAccounts = supplierDetails && supplierDetails.company_details.banking[0];

    let dataToSend = {
      invoiceIDs: [invoiceDetails.id],
      currency: "GBP",
      beneficiary_sort_code: payeeBankAccounts.sort_code,
      beneficiary_account_number: payeeBankAccounts.account_number,
      contactId: invoiceDetails.customer_erp_id || null,
      supplierId: invoiceDetails.supplier || null,
      customerId: invoiceDetails.customer || null,
      remitter_email: data.email,
      remitter_phone: data.phone,
      redirectURI: "payment-result/checkout-payment-result",
    };
    dispatch(checkoutPaymentRequest(dataToSend));
  };
  return loading ? (
    "loading"
  ) : (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={5}>
        <Typography variant="h4">Make a Payment</Typography>
        <br />
        <Typography variant="subtitle2">Invoice Id: {invoiceDetails && invoiceDetails.id}</Typography>
        <br />
        <small>You are paying to: </small>
        <Typography variant="overline" component="p">
          {supplierDetails?.company_details?.name}
        </Typography>
        {/* <br />
        <Divider />
        <br /> */}
        {/* <small>Your Details:</small>
        <br />
        <br />
        <TextField
          id="email"
          name="email"
          label="Email"
          error={errors.email ? true : false}
          helperText={errors.email ?? ""}
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
          variant="outlined"
          Paper
        />
        <br />

        <br />

        <TextField
          id="phone"
          name="phone"
          label="Phone"
          variant="outlined"
          error={errors.phone ? true : false}
          helperText={errors.phone ?? ""}
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        /> */}
        <br />
        <br />
        <Divider />
        <Typography variant="h6" style={{ marginTop: "15px" }}>
          Total to pay: GBP {invoiceDetails && invoiceDetails.amount}
        </Typography>
        <br />
        <Button onClick={handleSubmit((e) => handlePayment(e))} variant="contained" color="primary" fullWidth={true}>
          Pay
        </Button>
      </Paper>
    </div>
  );
};
export default Checkout;
