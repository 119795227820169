import { IconButton, Tooltip, Typography } from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import DropdownComponent from "../../../Components/DropdownComponent";
import { usePermissionsContext } from "../../../Contexts/PermissionsContext";
import { useUiContext } from "../../../Contexts/UiContext";
import { createIncomingInvoice } from "../../../store/actions/invoiceActions";
import { getAllProducts } from "../../../store/actions/productActions";
import { addSupplier, getSuppliers } from "../../../store/actions/supplierActions";
import verifySchema from "../../../util/yup/verifySchema";
import { incomingInvoiceSchema, supplierSchema } from "../../../util/yup/yupSchemas";
import SupplierDialog from "../../Supplier/SupplierDialog";
import CreateInvoiceComponent from "../CreateInvoiceComponent";

const CreateIncomingInvoice = ({ history, business }) => {
  const dispatch = useDispatch();

  const { permissions } = usePermissionsContext();
  const { register, control, watch, handleSubmit, setValue } = useForm();
  const { errors, loading, clearErrors, setErrors } = useUiContext();
  const [createSupplierDialogOpen, setCreateSupplierDialogOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierDetails, setSupplierDetails] = useState(null);
  let suppliers = useSelector((state) => state.supplier.suppliers);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.permissions?.supplier?.view) dispatch(getSuppliers({}));
    if (user?.permissions?.product?.view) dispatch(getAllProducts());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedSupplier) {
      const supplierDet = suppliers.find((supplier) => supplier.id === selectedSupplier);
      setSupplierDetails(supplierDet);
    }
    // eslint-disable-next-line
  }, [selectedSupplier]);

  const renderSuppliersList = () => {
    if (loading) {
      return [{ value: "null", label: "Loading..." }];
    }
    if (suppliers && suppliers.length > 0) {
      let list = suppliers.map((sup) => {
        return { value: sup.id, label: sup.name };
      });
      return list;
    } else {
      return [{ value: "null", label: "No suppliers" }];
    }
  };

  const supplierDropdown = () => {
    return (
      <DropdownComponent
        required
        id="supplier"
        name="supplier"
        label="Select a Supplier"
        items={renderSuppliersList()}
        error={errors.supplier ? true : false}
        helperText={errors.supplier ?? ""}
        onChange={(e) => {
          setSelectedSupplier(e.target.value);
        }}
        control={control}
      />
    );
  };

  const createSupplierButton = () => {
    return (
      permissions?.supplier?.create && (
        <Typography style={{ marginLeft: 70, color: "red" }}>
          Add New Supplier
          <Tooltip title="Create Supplier">
            <IconButton onClick={() => setCreateSupplierDialogOpen(true)}>
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      )
    );
  };

  const onCreateNewSupplier = async (data) => {
    const valid = await verifySchema(supplierSchema, data, clearErrors, setErrors);
    if (valid) {
      setCreateSupplierDialogOpen(false);
      dispatch(addSupplier(data));
    }
  };

  const onSubmit = async (data) => {
    const dataToSend = { ...data };
    dataToSend.supplier = selectedSupplier;
    dataToSend.currency = "GBP";
    if (data.invoice_lines.length > 0) {
      dataToSend.invoice_lines = JSON.stringify(data.invoice_lines);
    }
    if (data.invoiceImage) {
      if (data.invoiceImage.item(0)) {
        dataToSend.invoice_image = data.invoiceImage.item(0);
      }
    }
    const valid = await verifySchema(incomingInvoiceSchema, dataToSend, clearErrors, setErrors);
    if (valid) {
      let fd = new FormData();
      for (var key in dataToSend) {
        fd.append(key, dataToSend[key]);
      }
      dispatch(createIncomingInvoice(fd, history));
    }
  };

  return (
    <BasePage navigation="Dashboard/CreateIncomingInvoice" business={business} canView={user?.permissions?.incomingInvoice?.create}>
      <CreateInvoiceComponent
        title="Incoming Invoice"
        type={false}
        dropdownComponent={supplierDropdown()}
        createButtonComponent={createSupplierButton()}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        register={register}
        watch={watch}
        control={control}
        setValue={setValue}
        customerName={business?.company_details?.name}
        supplierName={supplierDetails ? supplierDetails?.name : ""}
      />

      <SupplierDialog
        open={createSupplierDialogOpen}
        setOpen={setCreateSupplierDialogOpen}
        title="Create new supplier"
        description="Create new supplier to start receiving payments."
        onSubmit={onCreateNewSupplier}
        errors={errors}
        business={business}
      />
    </BasePage>
  );
};

export default CreateIncomingInvoice;
