import { Button, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIconOutlined from "@material-ui/icons/ExitToAppOutlined";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MoreIcon from "@material-ui/icons/MoreVert";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { BsQuestionSquare } from "react-icons/bs";
import { MdPublishedWithChanges } from "react-icons/md";
import { MdOutlineNotifications } from "react-icons/md";

import { FaFileInvoiceDollar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { signoutUser } from "../store/actions/userActions";
import DropdownLanguage from "./DropdownLanguage";
import FastInvoiceDialog from "../pages/Invoice/Outgoing/FastInvoiceDialog/FastInvoiceDialog";
import PaymentQRDialog from "../pages/Invoice/Outgoing/PaymentQRDialog";
import HoverDropDown from "./DropDown/HoverDropDown";
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#FFF",
    width: "100%",
    color: "black",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#FFFF",
    color: "black",
  },
  appBarShift: {
    // marginLeft: theme.drawer.width,
    width: `calc(100% - ${theme.drawer.width}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    color: "black",
  },
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  inputRoot: {
    color: "black",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuPaper: {
    position: "relative",
  },

  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#6EC177",
    fontSize: "14px",
  },
}));

const Header = ({ user, history, navDrawerOpen, handleChangeNavDrawer, handleDashboardChange, unreadnotifications }) => {
  const classes = useStyles();
  const { authenticated } = user;

  const dispatch = useDispatch();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [openAddFastInvoiceDialog, setOpenAddFastInvoiceDialog] = useState(false);
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [QRPaymentLink, setQRPaymentLink] = useState("");
  const [platformUsage, setPlatformUsage] = useState("");
  const [topbarMenu, setTopbarMenu] = useState([]);
  const [customerHasEmail, setCustomerHasEmail] = useState(false);
  const [invoice, setInvoice] = useState("");
  const business = useSelector((state) => state.business);

  useEffect(() => {
    if (business && business?.account_settings) {
      let platformUsage = business?.account_settings?.invoice_settings?.platform_usage;
      if (platformUsage) {
        setPlatformUsage(platformUsage.isErp ? "erp" : "manual");
      }

      let menuitems = [
        { key: "Invoice Dashboard", value: "payedaInvoice" },
        { key: "Bank Dashboard", value: "payedaBank" },
      ];

      if (business.canReceivePayment && !business.isSoleTrader) {
        menuitems.push({ key: "Financing Dashboard", value: "payedaFinancing" });
      }
      setTopbarMenu(menuitems);
    }
  }, [business]);

  const open = Boolean(anchorEl1);

  const handleClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl1(null);
  };

  const handleOpenFastInvoiceDashboard = () => {
    setOpenAddFastInvoiceDialog(true);
  };

  const [val, setVal] = React.useState(localStorage.getItem("dashboardType") || "payedaInvoice");

  const handleDChange = async (event) => {
    setVal(event.target.value);
    localStorage.setItem("dashboardType", event.target.value);
    await handleDashboardChange();
  };

  const renderSwitchDashboard = () => {
    return (
      <>
        <HoverDropDown value={val} handleChange={handleDChange} items={topbarMenu} />
      </>
    );
  };
  const renderMobile = () => {
    return (
      <>
        <Menu
          anchorEl={anchorEl1}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          classes={{ paper: classes.menuPaper }}
        >
          <MenuItem>
            <Button onClick={() => dispatch(signoutUser(history))}>
              <ExitToAppIconOutlined />

              <p>Signout</p>
            </Button>
          </MenuItem>
          <MenuItem>
            <Button onClick={() => history.push("/dashboard/settings")}>
              <SettingsRoundedIcon />
              <p>Settings</p>
            </Button>
          </MenuItem>
          <MenuItem>{topbarMenu.length > 0 && renderSwitchDashboard()}</MenuItem>
          {platformUsage === "manual" && business.account_type === "Business" && business.canReceivePayment && (
            <>
              <MenuItem>
                {" "}
                <Button onClick={() => handleOpenFastInvoiceDashboard()} style={{ marginRight: "40px" }}>
                  <Tooltip title="Fast Invoice">
                    <Badge color="secondary">
                      <FaFileInvoiceDollar size={25} color="#172B4D" />
                      <small style={{ marginTop: "5px", marginLeft: "2px", fontSize: "13px", fontWeight: "bold", color: "#172B4D" }}>Fast Invoice</small>
                    </Badge>
                  </Tooltip>
                </Button>
              </MenuItem>
            </>
          )}
        </Menu>
      </>
    );
  };

  const authenticatedHeader = () => {
    return (
      <Toolbar variant="dense">
        <IconButton className={classes.menuButton} aria-label="Open drawer" onClick={handleChangeNavDrawer}>
          <MenuOpenRoundedIcon />
        </IconButton>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          {platformUsage === "manual" && business.account_type === "Business" && business.canReceivePayment && (
            <>
              <Button onClick={() => handleOpenFastInvoiceDashboard()} style={{ marginRight: "40px" }}>
                <Tooltip title="Fast Invoice">
                  <Badge color="secondary">
                    <FaFileInvoiceDollar size={25} color="#172B4D" />
                    <small style={{ marginTop: "5px", marginLeft: "2px", fontSize: "13px", fontWeight: "bold", color: "#172B4D" }}>Fast Invoice</small>
                  </Badge>
                </Tooltip>
              </Button>
            </>
          )}

          {/* <Button onClick={() => handleDashboardChange()} style={{ marginRight: "40px" }}>
            <Tooltip title="Switch Dashboard">
              <Badge color="secondary">
                <MdPublishedWithChanges size={25} />
                <small style={{ marginTop: "5px", marginLeft: "2px" }}>Switch Dashboard</small>
              </Badge>
            </Tooltip>
          </Button> */}
          {topbarMenu.length > 0 && renderSwitchDashboard()}
          <Button onClick={() => history.push("/dashboard/how-it-works")}>
            <Tooltip title="How it works">
              <Badge color="secondary">
                <BsQuestionSquare size={25} />
                <small style={{ marginTop: "5px", marginLeft: "2px" }}>How It Works</small>
              </Badge>
            </Tooltip>
          </Button>
          <IconButton onClick={() => history.push("/dashboard/notifications")}>
            <Tooltip title="Notifications">
              <Badge color="secondary" badgeContent={unreadnotifications && unreadnotifications.length}>
                <NotificationsNoneIcon />
              </Badge>
            </Tooltip>
          </IconButton>
          {/* <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => setNotificationsMenu(null)}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {(!unreadnotifications || unreadnotifications.length === 0) && <NotificationPopup message="You don't have notifications" typographyVariant="inherit" />}
            {unreadnotifications &&
              unreadnotifications.map((unreadnotification) => (
                <MenuItem key={unreadnotification.createdAt} onClick={() => setNotificationsMenu(null)} className={classes.headerMenuItem}>
                  <NotificationPopup {...unreadnotification} typographyVariant="inherit" />
                </MenuItem>
              ))}
          </Menu>{" "} */}
          <Button onClick={() => history.push("/dashboard/settings")}>
            <Tooltip title="Settings">
              <Badge color="secondary">
                <SettingsRoundedIcon />
              </Badge>
            </Tooltip>
          </Button>
          <Button onClick={() => dispatch(signoutUser(history))}>
            <Tooltip title="Signout">
              <ExitToAppIconOutlined />
            </Tooltip>
          </Button>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton aria-haspopup="true" onClick={handleClick} color="black">
            <MoreIcon />
          </IconButton>
        </div>
        {business.canReceivePayment && (
          <FastInvoiceDialog
            open={openAddFastInvoiceDialog}
            setOpen={setOpenAddFastInvoiceDialog}
            setOpenQRCodeDialog={setOpenQRCodeDialog}
            setQRPaymentLink={setQRPaymentLink}
            setCustomerHasEmail={setCustomerHasEmail}
            setInvoice={setInvoice}
          />
        )}

        <PaymentQRDialog link={QRPaymentLink} open={openQRCodeDialog} setOpen={setOpenQRCodeDialog} customerHasEmail={customerHasEmail} invoice={invoice} />
      </Toolbar>
    );
  };

  const renderNotAuthenticated = () => {
    return (
      <div>
        <Toolbar variant="dense">
          <Button component={Link} to="/" className={classes.font}>
            <Typography variant="h6">Payeda</Typography>
          </Button>
          <Button component={Link} to="/invite">
            Invitation
          </Button>
          <Button component={Link} to="/signup">
            Sign Up
          </Button>
          <div className={classes.grow} />
          <Button component={Link} to="/signin">
            Sign In
          </Button>
          <DropdownLanguage />
        </Toolbar>
      </div>
    );
  };

  return (
    <div>
      <AppBar
        className={classNames({
          [classes.appBar]: authenticated,
          [classes.header]: !authenticated,
          [classes.appBarShift]: navDrawerOpen && authenticated,
        })}
      >
        {renderMobile()}
        {authenticated ? authenticatedHeader() : renderNotAuthenticated()}
      </AppBar>
    </div>
  );
};

export default withRouter(Header);
