import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import { useSimpleTableStyles } from './TableStyles';

const SimpleTable = ({ data, setData, headCells, products, canDelete }) => {
    const classes = useSimpleTableStyles();

    const getProductName = (id) => {
        if (products) {
            let product = products.find(x => x.id === id);
            if (!product) {
                return id;
            }
            let name = product.productName;
            return name
        }
        else {
            return id
        }
    }

    const renderTableCells = (row) => {
        let cells = []
        headCells.forEach(header => {
            for (const [key, value] of Object.entries(row)) {
                if (header.id === key) {
                    //special case to convert productId to product name
                    if (key === "product") {
                        cells.push(<TableCell>{getProductName(value)}</TableCell>)
                        continue;
                    }
                    cells.push(<TableCell>{value}</TableCell>)
                }
            }
        })
        return cells;
    }
    return (
        <TableContainer component={Paper}>
            <Table aria-label="invoice-line-table">
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                padding="default"
                                className={classes.tableHead}
                            >
                                {headCell.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {data && data.length > 0 && data.map((row) => (
                        <TableRow key={row.id}>
                            {renderTableCells(row).map(cell => cell)}
                            {canDelete &&
                                <TableCell>
                                    <IconButton onClick={() => setData(data.filter(x => x.id !== row.id))}>
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SimpleTable
