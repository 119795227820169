import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Typography, TextField, Grid, Divider, Link } from "@material-ui/core";

import Swal from "sweetalert2";
import DropdownComponent from "../../Components/DropdownComponent";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import { getTenantsFromCloudApp } from "../../store/actions/invoiceActions";
import { useUiContext } from "../../Contexts/UiContext";
import axios from "axios";
import { API_BASE } from "./../../store/env";
import { useHistory } from "react-router-dom";
import { updateBalanceSheetAndChargesList } from "../../store/actions/financingInvoicesAction";

const ImportInvoicesDialog = ({ open, setOpen, title, description, errors, code, business, getFinancingInvoices }) => {
  const { register, control, handleSubmit, reset } = useForm();
  const { setErrors } = useUiContext();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(false);
  const [shouldAwaitImport, setShouldAwaitImport] = useState(false);
  const [showInformationDialogOpen, setShowInformationDialogOpen] = useState(false);

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
  };

  const formData = (data) => {
    let fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if ((key === "charges_list" || key === "balance_sheet") && value.item(0) !== null) {
        console.log(value.item(0));
        data[key] = value.item(0);
      }
    }
    for (var key in data) {
      fd.append(key, data[key]);
    }
    console.log("fd", fd);
    return fd;
  };

  const handleGetInvoiceData = async (invoiceNumber) => {
    try {
      let data = { invoice_number: invoiceNumber, import_type: selectedOption === "one" ? "single_invoice" : "all_ledger" };
      let request = await axios.post(`${API_BASE}/financing/financing-request`, data);
      getFinancingInvoices();
      if (request.status === 200) {
        setShouldAwaitImport(false);
        setOpen(false);
      }
      console.log("request", request);
    } catch (error) {
      console.log("error", error.response.data.error.general);
      setShouldAwaitImport(false);
      setErrors({ general: error.response.data.error.general });
      setOpen(true);
      //   setInvoiceNumber(invoiceNumber);
    }
  };

  const onSubmit = async (data) => {
    let fd = await formData(data);

    if (!selectedOption) {
      setErrors({ general: "Please select how you want to import invoices" });
      return false;
    }
    if (selectedOption === "one" && !data.invoice_number) {
      setErrors({ general: "Invoice number shouldn't be empty" });
      return false;
    }

    if (selectedOption === "one") {
      setShouldAwaitImport(true);
      await handleGetInvoiceData(data.invoice_number);
    } else if (selectedOption === "all") {
      setShouldAwaitImport(true);
      setShowInformationDialogOpen(true);
      setOpen(false);
      await handleGetInvoiceData();
    }
    dispatch(updateBalanceSheetAndChargesList(fd));
  };

  useEffect(() => {
    if (showInformationDialogOpen && !open) {
      let html = `<p>
        We will send notification once the process is complete`;
      Swal.fire({
        title: "We have received your request to import all sale invoices. ",
        html: html,
        icon: "success",
      });
    }
  }, [showInformationDialogOpen]);

  const renderLoadingAlert = () => {
    Swal.fire({
      title: "Loading...",
      html: "Please wait...",

      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (shouldAwaitImport && !open) renderLoadingAlert();
    else Swal.close();
  }, [shouldAwaitImport, open]);

  const renderSelection = () => {
    return (
      <form style={{ fontSize: "20px" }}>
        <div>
          <Typography variant="body">
            <strong style={{ color: "steelblue", fontWeight: "bold" }}>Choose how you want to select invoices</strong>
          </Typography>
          <div>
            <input name="importInvoiceOption" id="all" value="all" checked={selectedOption === "all"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="importInvoiceOption">Import all sale ledger</label>
          </div>
          <div>
            <input name="importInvoiceOption" id="one" value="one" checked={selectedOption === "one"} type="radio" onChange={(e) => handleRadioChange(e)} />
            <label for="importInvoiceOption">Select from the sale ledger</label>
          </div>
        </div>
      </form>
    );
  };

  return (
    <DialogComponent open={open} setOpen={setOpen} title={""} description={""} onSubmit={handleSubmit(onSubmit)} withActions={true}>
      <Grid container style={{ width: "600px" }}>
        <Grid item xs={12}>
          {renderSelection()}
        </Grid>
        <Grid item xs={12} style={{ marginTop: "10px" }}>
          {selectedOption === "one" && (
            <>
              <TextField variant="outlined" label="Invoice Number" name="invoice_number" id="invoice_number" type="text" inputRef={register} fullWidth />
            </>
          )}
        </Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <Typography>Upload Balance Sheet</Typography>
          <input type="file" name="balance_sheet" id="balance_sheet" ref={register} />
        </Grid>
        <Grid item xs={6} style={{ marginTop: "10px", textAlign: "center" }}>
          {business?.balance_sheet ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business?.balance_sheet);
              }}
            >
              Download the balance sheet
            </Link>
          ) : (
            <Typography style={{ marginTop: "10px" }}>No bank sheet uploaded</Typography>
          )}
        </Grid>
        <Grid item xs={6} style={{ marginTop: "25px" }}>
          <Typography>Upload Charges List</Typography>
          <input type="file" name="charges_list" id="charges_list" ref={register} />
        </Grid>
        <Grid item xs={6} style={{ marginTop: "25px", textAlign: "center" }}>
          {business?.charges_list ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business?.charges_list);
              }}
            >
              Download the charges list
            </Link>
          ) : (
            <Typography style={{ marginTop: "10px" }}>No charges list uploaded</Typography>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginTop: "15px", padding: "5px" }}>
          <small>By uploading documents, you are agree to share them with financial institutions</small>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default ImportInvoicesDialog;
