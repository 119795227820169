
const initialState = {
    isLoading: false,
    products: [],
    errorMessage: null,
    
};

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case "PRODUCT_DATA_FETCH_STARTED":
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
            };

        case "PRODUCT_DATA_FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                products: action.payload,
            };
        default:
            return state;
    }
}