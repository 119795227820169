import { Route, Switch, useHistory } from "react-router-dom";
import Checkout from "../pages/Invoice/Checkout";
import InvitationLoginPage from "../pages/LoginSignup/Invitation/InvitationLoginPage";
import SigninPage from "../pages/LoginSignup/SigninPage";
import SignupPage from "../pages/LoginSignup/SignupPage";
import PaymentResult from "../util/banking/PaymentResult";
import ViewInvoice from "../pages/Invoice/ViewInvoice";
import ViewReceipt from "../pages/Invoice/Receipt/ViewReceipt";

const Router = ({ user, business, isUserPresent }) => {
  let history = useHistory();
  return (
    <>
      <Switch>
        <Route
          path="/invite"
          render={(props) => (
            <InvitationLoginPage
              isUserPresent={isUserPresent}
              user={user}
              business={business}
              authenticated={user.authenticated}
              businessId={user.business}
              history={history}
              {...props}
            />
          )}
        />
        <Route
          path="/signup"
          render={(props) => (
            <SignupPage isUserPresent={isUserPresent} user={user} business={business} authenticated={user.authenticated} businessId={user.business} history={history} {...props} />
          )}
        />
        <Route
          path="/signin"
          render={(props) => (
            <SigninPage isUserPresent={isUserPresent} user={user} business={business} authenticated={user.authenticated} businessId={user.business} history={history} {...props} />
          )}
        />

        <Route path="/checkout" render={(props) => <Checkout {...props} />} />
        <Route path="/view-invoice" render={(props) => <ViewInvoice {...props} />} />
        {/* <Route path="/view-receipt" render={(props) => <ViewReceipt {...props} />} /> */}

        <Route path="/checkout-payment-result" render={(props) => <PaymentResult {...props} />} />

        {/* <Route exact path="/" render={(props) => <HomePage {...props} history={history} />} /> */}
        {/* <Route component={Error404} /> */}

        {/* {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => routeItem.link)}
            />
          );
        })} */}
      </Switch>
    </>
  );
};

export default Router;
