import React from "react";

import { Button, FormControl, TextField, Grid } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { usePhoneInputStyles } from "../../Components/ComponentStyles";

import { useUiContext } from "../../Contexts/UiContext";

const MemberSignupDetails = ({ nextPage, selected, defaultValues, handleChange, handlePhoneChange, data }) => {
  const { errors } = useUiContext();
  const classes = usePhoneInputStyles();

  return (
    <FormControl>
      <Grid container>
        <TextField
          required
          id="name"
          name="name"
          label="Name"
          defaultValue={data.name}
          error={errors.name ? true : false}
          helperText={errors.name ?? ""}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          required
          id="surname"
          name="surname"
          label="Surname"
          defaultValue={data.surname}
          error={errors.surname ? true : false}
          helperText={errors.surname ?? ""}
          onChange={handleChange}
          fullWidth
          style={{ marginBottom: "10px" }}
        />
        <PhoneInput
          id="phone"
          label="Phone Number"
          value={data.phone}
          error={errors.phone ?? false}
          country={"gb"}
          onChange={handlePhoneChange}
          inputClass={classes.phone}
          containerClass={classes.phone}
          searchClass={classes.phone}
        />
        {!selected && (
          <>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              defaultValue={data.email}
              error={errors.email ? true : false}
              helperText={errors.email ?? ""}
              onChange={handleChange}
              fullWidth
            />
          </>
        )}

        <br />
        <Button variant="contained" color="secondary" onClick={nextPage} style={{ marginTop: "15px" }}>
          Next
        </Button>
      </Grid>
    </FormControl>
  );
};

export default MemberSignupDetails;
