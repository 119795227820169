const prepareBasketDetailData = async (financeBaskests) => {
  let body = [];
  let currentDate = new Date().toISOString();

  if (financeBaskests.baskets > 0) return [];
  if (financeBaskests.baskets.basket_details > 0) return [];

  for (let basket of financeBaskests.baskets) {
    let totalAmount = 0;
    let invoices = [];
    let data = {};
    let basketLength = basket.basket_details.length;

    data.total_invoices_in_basket = basketLength;

    data.id = basket.id;

    if (basketLength > 0) {
      data.supplier_name = basket.basket_details[0].financing_request.supplier.company_name;

      for (let basketDetails of basket.basket_details) {
        let expireDate = new Date(basketDetails.financing_request.bid_closes_at).toISOString();
        let invAmount = basketDetails.financing_request.invoice.amount_to_be_paid;
        let customer_name = basketDetails.financing_request.invoice.customer.name;

        basketDetails.financing_request.invoice.customer_name = customer_name;
        basketDetails.financing_request.invoice.customer = basketDetails.financing_request.invoice.customer_doc_id;
        basketDetails.financing_request.invoice.supplier = basketDetails.financing_request.invoice.supplier_doc_id;
        basketDetails.financing_request.invoice.basket_id = basket.id;
        basketDetails.financing_request.invoice.basket_detail_id = basketDetails.id;

        basketDetails.financing_request.invoice.bid_closes_at = basketDetails.financing_request.bid_closes_at;
        basketDetails.financing_request.invoice.bid_status = expireDate > currentDate ? "Not Expired" : "Expired";

        totalAmount = parseFloat(totalAmount) + parseFloat(invAmount);
        invoices.push(basketDetails.financing_request.invoice);
      }
      data.total_amount = totalAmount;
      data.invoices = invoices;
    }
    body.push(data);
  }
  return body;
};

export default prepareBasketDetailData;
