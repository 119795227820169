const getPermissionSates = (accountType) => {
  if (accountType === "Business" || accountType === "Personal") {
    return {
      data: {
        customer: {
          create: false,
          view: false,
          edit: false,
          remove: false,
        },
        supplier: {
          create: false,
          view: false,
          edit: false,
          remove: false,
        },
        product: {
          create: false,
          view: false,
          edit: false,
          remove: false,
        },
        document: {
          create: false,
          view: false,
        },

        activityLog: {
          view: false,
        },
        bankingLog: {
          view: false,
        },
        tokenSpending: {
          view: false,
        },
        plan: {
          create: false,
          view: false,
        },
        connectCloudErp: {
          create: false,
        },
        incomingInvoice: {
          approve: false,
          askExtension: false,
          create: false,
          deny: false,
          view: false,
          remove: false,
          askCredit: false,
          makePayment: false,
          viewReceipt: false,
        },
        outgoingInvoice: {
          earlyPayment: false,
          extend: false,
          create: false,
          send: false,
          view: false,
          remove: false,
          askFactoring: false,
          viewReceipt: false,
        },
        bankAccount: {
          create: false,
          view: false,
          remove: false,
        },
        transferMoney: {
          create: false,
        },
        // mergeAccount: {
        //   create: true,
        //   view: false,
        // }
      },
    };
  } else if (accountType === "Finance") {
    return {
      data: {
        creditRequest: {
          view: false,
        },
        factoringRequest: {
          view: false,
        },
        agreement: {
          approve: false,
          deny: false,
        },
        log: {
          view: false,
        },
        mergeAccount: {
          create: true,
          view: false,
        },
      },
    };
  } else if (accountType === "Admin") {
    return {
      data: {
        verifiedBusinesses: {
          view: false,
          deny: false,
          showFiles: false,
        },
        pendingApprovalBusinesses: {
          view: false,
          approve: false,
          showFiles: false,
        },
        userAccounts: {
          view: false,
        },
        finance: {
          view: false,
          add: false,
        },
        mergeAccount: {
          create: true,
          view: false,
        },
      },
    };
  }
};

export default getPermissionSates;
