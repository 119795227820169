import React, { useState } from "react";
import { IconButton, Tooltip, Grid, Button } from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import TableComponent from "../../Components/Table/TableComponent";
import TableToolbarComponent from "../../Components/Table/TableToolbarComponent";

const headCells = [
  { id: "title", label: "Title" },
  { id: "message", label: "Message" },
  { id: "created_at", label: "Date" },
  { id: "read", label: "Marked as read" },
];

const NotificationsTable = ({ notifications, selected, setSelected, header, numSelected, filters, markNotification, setOpenDetailsDialog }) => {
  const renderToolbar = () => {
    return (
      <TableToolbarComponent header={header} numSelected={numSelected}>
        <>
          {numSelected === 1 && (
            <>
              <Button color="secondary" variant="outlined" onClick={() => setOpenDetailsDialog(true)}>
                Details
              </Button>
            </>
          )}
          {numSelected === 0 && (
            <>
              <div style={{ width: "180px", float: "right" }}>{filters}</div>
            </>
          )}
          {numSelected > 0 && selected[0].read === false && (
            <>
              <Tooltip title="Mark as read">
                <IconButton style={{ color: "green" }} onClick={markNotification}>
                  <DoneAllIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      </TableToolbarComponent>
    );
  };

  return <TableComponent toolbar={renderToolbar()} data={notifications} selected={selected} setSelected={setSelected} headCells={headCells} />;
};
export default NotificationsTable;
