import React, { useState } from 'react'
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import clsx from "clsx";

import { useFinanceStyles } from '../FinanceStyles';
import HeaderComponent from '../../../Components/HeaderComponent';
import { useUiContext } from '../../../Contexts/UiContext';
import InvoiceDetailsDialog from '../InvoiceDetailsDialog';
import CreditRequestedInvoicesTable from './CreditRequestedInvoicesTable';

const CreditRequestedInvoices = ({ creditRequestedInvoices }) => {
    const classes = useFinanceStyles();
    const { sidebarOpen } = useUiContext();

    const [selected, setSelected] = useState([]);
    const [invoiceDetailsDialogOpen, setInvoiceDetailsDialogOpen] = useState(false);
    
    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: sidebarOpen,
            })}
        >

            <div className={classes.root}>
                <HeaderComponent
                    title="Credit Requests"
                    icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
                />

                <CreditRequestedInvoicesTable
                    creditRequestedInvoices={creditRequestedInvoices}
                    setInvoiceDetailsDialogOpen={setInvoiceDetailsDialogOpen}
                    selected={selected}
                    setSelected={setSelected}
                />

                <InvoiceDetailsDialog
                    open={invoiceDetailsDialogOpen}
                    setOpen={setInvoiceDetailsDialogOpen}
                    defaultValues={selected[0]}
                />       
    
            </div>
        </main>

    )
}

export default CreditRequestedInvoices
