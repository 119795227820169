import axios from "axios";
import { setSuccess, setErrors, clearErrors, startLoading } from "./uiActions";
import { API_BASE } from "../env";
import prepareBasketDetailData from "../../util/helpers/prepareBasketDetailData";

const FileDownload = require("js-file-download");

export const getFinanceDetails = () => (dispatch) => {
  try {
    // dispatch(getLoanRequestedInvoices());
    // dispatch(getFactoringRequestedInvoices());
    // dispatch(getPendingBankApprovalRequests());
    // dispatch(getMembers());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllFinanceAccounts = () => async (dispatch) => {
  try {
    // dispatch(startLoading());
    let finances = await axios.get(`${API_BASE}/all-finances`);
    dispatch({ type: "SET_FINANCE", payload: finances.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getLoanRequestedInvoices = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let invoices = await axios.get(`${API_BASE}/loan-requested-invoices`);
    dispatch({ type: "SET_CREDIT_REQUESTED_INVOICES", payload: invoices.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getFactoringRequestedInvoices = ({ ...params }) => async (dispatch) => {
  console.log("params", params);
  try {
    dispatch({ type: "FETCH_FACTORING_REQUESTED_INVOICES" });

    let invoices = await axios.get(`${API_BASE}/financing/finance/invoices`, {
      params: {
        ...params,
      },
    });
    console.log(invoices);

    dispatch({
      type: "SET_FACTORING_REQUESTED_INVOICES",
      payload: invoices.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
export const getFinanceBaskets = () => async (dispatch) => {
  try {
    let baskets = await axios.get(`${API_BASE}/financing/finance/baskets`);

    dispatch({
      type: "SET_FINANCE_BASKETS",
      payload: baskets.data,
    });
    let preparedData = [];

    console.log("baskets", baskets);
    let result = await prepareBasketDetailData(baskets.data);
    preparedData.push(...result);

    dispatch({ type: "FINANCE_BASKET_FETCH_SUCCESS", payload: { count: preparedData.length, baskets: preparedData } });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const addInvoiceToBasket = (body) => async (dispatch) => {
  try {
    let baskets = await axios.post(`${API_BASE}/financing/finance/baskets`, body);
    console.log(baskets);

    dispatch({
      type: "SET_FINANCE_BASKETS",
      payload: baskets.data,
    });
    dispatch(setSuccess("Successfully added to basket"));

    dispatch(clearErrors());
    dispatch(getFinanceBaskets());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getPendingBankApprovalRequests = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let invoices = await axios.get(`${API_BASE}/pending-approval-requests`);
    console.log(invoices);
    dispatch({
      type: "SET_PENDING_BANK_APPROVAL_REQUESTS",
      payload: invoices.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const denyOrApproveRequests = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.post(`${API_BASE}/deny-approve-agreement`, details);
    dispatch(clearErrors());
    if (details.isApprovedByBank === true) {
      dispatch(setSuccess("Agreement succesfully approved"));
      dispatch(getPendingBankApprovalRequests());
    } else if (details.isApprovedByBank === false) {
      dispatch(setSuccess("Agreement succesfully denied"));
      dispatch(getPendingBankApprovalRequests());
    }
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const saveRequest = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let res = await axios.post(`${API_BASE}/finance/save-requests`, details);
    dispatch(setSuccess("Added"));
    dispatch(getFinanceBaskets());
    console.log("res", res);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const createFinancingOffer = (details, history) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "CREATING_OFFER" });
    let res = await axios.post(`${API_BASE}/financing/finance/offer`, details);
    dispatch(setSuccess("Successfully saved"));
    // dispatch(getAllOffers());
    dispatch(getFinanceBaskets());

    console.log("res", res);
    dispatch(clearErrors());
    dispatch({ type: "CREATED_OFFER" });
    history.push("/dashboard/finance-offers");
  } catch (err) {
    dispatch({ type: "OFFER_CREATION_FAILED" });

    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
      dispatch(clearErrors());
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
      dispatch(clearErrors());
    }
  }
};

export const getBasketInformation = (baskeId) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "FINANCE_BASKET_FETCH_STARTED" });

    let res = await axios.get(`${API_BASE}/finance/get-requests`, {
      params: {
        basketId: baskeId,
      },
    });
    dispatch({
      type: "FINANCE_BASKET_DETAILS_FETCH_SUCCESS",
      payload: res.data,
    });

    console.log("res", res);
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeFromBasket = (basketIds) => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "FINANCE_BASKET_FETCH_STARTED" });

    let res = await axios.post(`${API_BASE}/financing/finance/remove-basket-details`, basketIds);
    dispatch(setSuccess("Successfully removed from basket"));
    dispatch(getFinanceBaskets());
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllOffers = () => async (dispatch) => {
  try {
    // dispatch(startLoading());
    dispatch({ type: "FINANCE_LOADING" });

    let res = await axios.get(`${API_BASE}/financing/finance/offers`);

    dispatch({ type: "FINANCE_OFFERS_FETCH_SUCCESS", payload: res.data });

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const downloadExcellFile = (requestId) => async (dispatch) => {
  try {
    // let invoice = await axios.get(`${API_BASE}/invoice/download-excell`, {
    //     params: {
    //         requestId: requestId,
    //     }
    // });
    let invoice = axios({
      url: `${API_BASE}/finance/download-excell`,
      params: {
        requestId: requestId,
      },
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, `financing_details-${requestId}.csv`);
    });
    console.log("excellResult", invoice);
    // dispatch({type: "FINANCING_EXCELL_FILE", payload: invoice.data})

    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
