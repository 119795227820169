import React from "react";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";

import TableComponent from "../../Components/Table/TableComponent";
import CustomersTableToolbar from "./CustomersTableToolbar";

const headCells = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "erp_no", label: "Erp No" },
  { id: "invite_information", label: "Invite" },
  // { id: "is_joined", label: "Joined"}
];

const searchOptions = [
  { default: true, id: "company_name_lower", label: "Company Name" },
  { id: "full_name_lower", label: "Representative Name" },
  { id: "erp_no_lower", label: "Erp Number" },
];

const CustomersTable = ({
  customers,
  setErrors,
  getAllData,
  getSearchData,
  selected,
  setSelected,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  isLoading,
  isLastPage,
  business,
  dispatch,
  userHasMembership,
  renderCustomerGroups,
  setIsMessage,
}) => {
  const { permissions } = usePermissionsContext();

  const renderToolbar = () => {
    return (
      <CustomersTableToolbar
        numSelected={selected ? selected.length : 0}
        selected={selected}
        business={business}
        setCreateDialogOpen={setCreateDialogOpen}
        setEditDialogOpen={setEditDialogOpen}
        setInviteDialogOpen={setInviteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setErrors={setErrors}
        dispatch={dispatch}
        userHasMembership={userHasMembership}
        renderCustomerGroups={renderCustomerGroups}
        setIsMessage={setIsMessage}
      />
    );
  };

  return (
    <TableComponent
      toolbar={renderToolbar()}
      data={customers}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      searchOptions={searchOptions}
      isLoading={isLoading}
      isLastPage={isLastPage}
      getAllData={getAllData}
      searchBarText="Search customers"
      shouldGetData={false}
      shouldPaginate={true}
      shouldSearch={true}
      shouldView={permissions?.customer?.view}
    />
  );
};
export default CustomersTable;
