// eslint-disable-next-line

import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "../../Components/DropdownComponent";
import {
  getInvoicesFromCloud,
  getTenantsFromCloudApp,
} from "../../store/actions/invoiceActions";
import { useImportInvoiceStyles } from "./InvoiceStyles";

const ImportFromCloud = ({
  errors,
  history,
  setSelectedTenant,
  selectedTenant,
}) => {
  const classes = useImportInvoiceStyles();
  const { register, control, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [selectedCreateDate, setSelectedCreateDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [application, setApplication] = useState("");

  const [renderExcell, setRenderExcell] = useState(false);
  const [renderCloud, setRenderCloud] = useState(false);

  let isLoading = useSelector((state) => state.invoice.isLoading);
  let tenants = useSelector((state) => state.invoice.tenants);

  useEffect(() => {
    dispatch(getTenantsFromCloudApp("xero"));
    // eslint-disable-next-line
  }, []);

  const getTenants = () => {
    if (tenants) {
      setSelectedTenant(tenants[0].tenantId);

      var tenantsResult = tenants.map((connection) => {
        return {
          value: connection.tenantId,
          label: connection.tenantName,
        };
      });

      return tenantsResult;
    }
  };

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const onSubmit = (data) => {
    let path = window.location.pathname.split("/")[2];
    // var state = new URLSearchParams(location.search).get("state");
    let state = localStorage.getItem("application");
    setApplication(state);

    if (data.tenantId) {
      let dataToSend = {
        tenantId: data.tenantId,
        type: path === "import-outgoing-invoice" ? "ACCREC" : "ACCPAY",
        createdDate: selectedCreateDate.toISOString(),
        dueDate: selectedDueDate.toISOString(),
        state: state,
      };
      dispatch(getInvoicesFromCloud(dataToSend));
    }
  };

  const renderGetWithId = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        {tenants && tenants.length > 0 && (
          <DropdownComponent
            required
            id="tenantId"
            name="tenantId"
            label="Choose your account to get invoices from"
            items={getTenants()}
            error={errors.tenantId ? true : false}
            helperText={errors.tenantId ?? ""}
            control={control}
            onChange={(e) => setSelectedTenant(e.target.value)}
          />
        )}
        <TextField
          variant="outlined"
          label="Invoice Number"
          type="text"
          onChange={(e) => setInvoiceNumber(e.target.value)}
          fullWidth
        />
        <div style={{ marginTop: "10px" }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => getInvoicesWithId()}
          >
            Get Invoice
          </Button>
        </div>
      </div>
    );
  };

  const getInvoicesWithId = () => {
    if (invoiceNumber !== "") {
      let dataToSend = {
        tenantId: selectedTenant,
        invoiceNumber: invoiceNumber,
      };
      dispatch(getInvoicesFromCloud(dataToSend));
    }
  };

  const renderGetwithDate = () => {
    return (
      <>
        <Grid container className={classes.grid} spacing={3}>
          <Grid item xs={12}>
            {isLoading ? (
              "Loading"
            ) : (
              <>
                <h1>Import from Cloud</h1>
                {!isLoading && (!tenants || tenants.length < 1) && (
                  <Button onClick={() => history.push("/dashboard/settings")}>
                    You can allow your cloud app by going to settings
                  </Button>
                )}
                {!tenants && application ? (
                  <>
                    <span>Getting Invoices</span>
                    <br />
                  </>
                ) : (
                  tenants &&
                  tenants.length > 0 && (
                    <>
                      <DropdownComponent
                        required
                        id="tenantId"
                        name="tenantId"
                        label="Choose your account to get invoices from"
                        items={getTenants()}
                        error={errors.tenantId ? true : false}
                        helperText={errors.tenantId ?? ""}
                        control={control}
                      />

                      <KeyboardDatePicker
                        inputVariant="outlined"
                        disableFuture
                        id="editDate"
                        name="editDate"
                        openTo="year"
                        format="yyyy-MM-dd"
                        label="First Date"
                        value={selectedCreateDate}
                        views={["year", "month", "date"]}
                        error={errors.editDate ? true : false}
                        helperText={errors.editDate ?? false}
                        inputRef={register}
                        onChange={(date) => setSelectedCreateDate(date)}
                        fullWidth
                      />

                      <KeyboardDatePicker
                        inputVariant="outlined"
                        id="dueDate"
                        name="dueDate"
                        label="Last Date"
                        value={selectedDueDate}
                        openTo="year"
                        format="yyyy-MM-dd"
                        views={["year", "month", "date"]}
                        error={errors.dueDate ? true : false}
                        helperText={errors.dueDate ?? false}
                        inputRef={register}
                        onChange={(date) => setSelectedDueDate(date)}
                        fullWidth
                      />
                    </>
                  )
                )}
                {tenants && tenants.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit((data) => onSubmit(data))}
                    className={classes.button}
                  >
                    {!tenants || tenants.length < 1 ? "" : "Get invoices"}
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const handlePageChange = (pageType) => {
    if (pageType === "excell") {
      setRenderExcell(true);
      setRenderCloud(false);
    } else if (pageType === "cloud") {
      setRenderCloud(true);
      setRenderExcell(false);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Paper className={classes.paper}>
        <div style={{ marginTop: "50px", marginLeft: "50px", padding: "10px" }}>
          <Button
            onClick={() => {
              handlePageChange("excell");
            }}
          >
            Get With Invoice Number
          </Button>
          <Button
            onClick={() => {
              handlePageChange("cloud");
            }}
          >
            Get with Date Range
          </Button>
          <Grid>
            {renderExcell && renderGetWithId()}
            {renderCloud && renderGetwithDate()}
          </Grid>
        </div>
      </Paper>
    </MuiPickersUtilsProvider>
  );
};

export default ImportFromCloud;
