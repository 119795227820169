import { Grid, Link, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import userEvent from "@testing-library/user-event";
import React from "react";
import { useSelector } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { Alert, AlertTitle } from "@material-ui/lab";
import { BUSINESS_MODELS, BUSINESS_TYPES } from "../../../util/constants/companyInformation";

const BusinessDocuments = ({ business }) => {
  let user = useSelector((state) => state.user);

  const renderDirectorDocuments = () => {
    let fields = [];
    let i = 1;
    for (let director of business.directors) {
      fields.push(
        <>
          <Grid xs={12} style={{ marginTop: "10px" }}>
            <b>Director {i}</b>
          </Grid>
          <Grid xs={12}>
            <Typography>{director.full_name}</Typography>
          </Grid>
          <Grid xs={6}>Proof of address</Grid>
          <Grid xs={6}>
            {director.address_proof ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.address_proof);
                }}
              >
                Download the proof of address
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Front Page of ID</Grid>
          <Grid xs={6}>
            {director.id_front_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_front_page);
                }}
              >
                Download the Front page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Rear Page of ID</Grid>
          <Grid xs={6}>
            {director.id_rear_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_rear_page);
                }}
              >
                Download the Rear page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
        </>
      );
      i++;
    }
    return fields;
  };
  const renderShareholderDocuments = () => {
    let fields = [];
    let i = 1;
    for (let director of business.shareholders) {
      fields.push(
        <>
          <Grid xs={12}>
            <b>Shareholder {i}</b>
          </Grid>
          <Grid xs={12}>
            <Typography>{director.full_name}</Typography>
          </Grid>
          <Grid xs={6}>Proof of address</Grid>
          <Grid xs={6}>
            {director.address_proof ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.address_proof);
                }}
              >
                Download the proof of address
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Front Page of ID</Grid>
          <Grid xs={6}>
            {director.id_front_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_front_page);
                }}
              >
                Download the Front page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
          <Grid xs={6}>Rear Page of ID</Grid>
          <Grid xs={6}>
            {director.id_rear_page ? (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(director.id_rear_page);
                }}
              >
                Download the Rear page of ID
              </Link>
            ) : (
              <Typography>No files uploaded</Typography>
            )}
          </Grid>
        </>
      );
      i++;
    }
    return fields;
  };

  const renderCompanyFiles = () => {
    return (
      <>
        <Grid xs={6}>
          <Typography>Bank Statement</Typography>
        </Grid>
        <Grid xs={6}>
          {business.company_details.bank_statement ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.bank_statement);
              }}
            >
              Download the bank statement
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>
        <Grid xs={6}>
          <Typography>Company Certificate</Typography>
        </Grid>
        <Grid xs={6}>
          {business.company_details.company_certificate ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.company_certificate);
              }}
            >
              Download the Company certificate
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>
        <Grid xs={6}>
          {business.company_details.id_front_page ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.id_front_page);
              }}
            >
              Download the Front Page of ID
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>

        <Grid xs={6}>
          {business.company_details.id_rear_page ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(business.company_details.id_rear_page);
              }}
            >
              Download the Rear Page of ID
            </Link>
          ) : (
            <Typography>No files uploaded</Typography>
          )}
        </Grid>
      </>
    );
  };

  console.log("national insurance", business);
  return (
    <BasePage navigation="Dashboard/Documents" business={business} canView={user?.permissions?.document?.view}>
      <div style={{ padding: "10px" }}>
        <Grid container spacing={3}>
          {business && business.company_details && (
            <>
              <Grid xs={12}>
                <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
                  {business.company_details && (
                    <>
                      <>
                        <Typography>
                          <strong>Company Registration Number:</strong> {business.company_details.company_reg_number}
                        </Typography>
                        <br />
                        <Typography>
                          <strong>Vat Number:</strong> {business.company_details?.vat_number}
                        </Typography>
                        <br />
                        <Typography>
                          <strong>National Insurance Number:</strong> {business.company_details?.national_insurance_number}
                        </Typography>
                        <br />
                        <Typography>
                          <strong>Unique Tax Number:</strong> {business.company_details?.utr_number}
                        </Typography>
                        <br />
                        <Typography>
                          <strong>Passport id:</strong> {business.company_details?.passport_id}
                        </Typography>{" "}
                        <br />
                        <Typography>
                          <strong>Phone: </strong> {business.company_details?.phone}
                        </Typography>{" "}
                        <br />
                        <Typography>
                          <strong>Email: </strong> {business.company_details?.email}
                        </Typography>{" "}
                        <br />
                        <Typography>
                          <strong>Address: </strong> {business.company_details?.address?.address_line_1 || ""} {business.company_details?.address.address_line_2 || ""}
                        </Typography>{" "}
                        <br />
                        <Typography>
                          <strong>Business Type: </strong> {BUSINESS_TYPES.find((type) => type.value === business.company_details?.business_type)?.label}
                        </Typography>{" "}
                        <br />
                        <Typography>
                          <strong>Business Model: </strong> {BUSINESS_MODELS.find((type) => type.value === business.company_details?.business_model)?.label}
                        </Typography>{" "}
                        <br />
                      </>
                    </>
                  )}{" "}
                </Alert>
                <br />
                <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
                  <Typography>
                    <strong>Usage Type: </strong>
                  </Typography>
                  Invoicing Type:
                  {business?.account_settings?.invoice_settings?.platform_usage
                    ? business?.account_settings?.invoice_settings?.platform_usage.isErp
                      ? " Cloud Invoicing "
                      : " Manual Invoicing "
                    : ""}
                  {business && (
                    <>
                      Transfer Money:
                      {business?.canTransferMoney ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "red" }} />}
                    </>
                  )}{" "}
                  <br />
                  Make Payment:
                  <DoneIcon style={{ color: "green" }} />
                  {business && business.account_type === "Business" && (
                    <>
                      <br />
                      Receive Payment:
                      {business?.canReceivePayment ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "red" }} />}
                    </>
                  )}{" "}
                  <br />
                  Manage Bank Accounts:
                  <DoneIcon style={{ color: "green" }} />
                </Alert>

                {business?.company_details?.banking[0] && (
                  <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
                    <Typography>
                      <strong> Receive Payment Account Details (Not verified yet): </strong>{" "}
                    </Typography>
                    <br />
                    <strong>Account number:</strong> {business && business.company_details.banking[0].account_number} <strong>Sort code:</strong>{" "}
                    {business && business.company_details.banking[0].sort_code}
                  </Alert>
                )}
              </Grid>
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                {/* Directors: */}
              </Typography>
              {renderDirectorDocuments()}
              {business.account_type === "Business" && (
                <>
                  <Typography variant="h6" style={{ marginTop: "10px" }}>
                    {/* Shareholders: */}
                  </Typography>
                  {renderShareholderDocuments()}
                  <Grid xs={12}>
                    <Typography variant="h6" style={{ marginTop: "10px" }}>
                      Company Files:
                    </Typography>
                  </Grid>

                  {renderCompanyFiles()}
                </>
              )}
            </>
          )}
        </Grid>
      </div>
    </BasePage>
  );
};

export default BusinessDocuments;
