import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";

import { useConfirmDeleteDialogStyles } from "./../ComponentStyles";
import DialogComponent from "./DialogComponent";

const ConfirmDeleteDialog = ({ open, setOpen, onSubmit, deleteHead, deleteText, DeleteTextHtml, shouldRenderHtml, isLoading }) => {
  const classes = useConfirmDeleteDialogStyles();

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      }
      onSubmit={onSubmit}
      withActions={true}
      submitButtonText={isLoading ? "Loading" : "Submit"}
      isDisabled={isLoading ? true : false}
    >
      <Typography variant="h6" style={{ textAlign: "center", marginBottom: "10px" }}>
        {deleteHead || "Are you sure to delete?"}
      </Typography>
      <Typography variant="subtitle2">{deleteText || (!shouldRenderHtml && "You can not undo this operation")}</Typography>
      {shouldRenderHtml && <DeleteTextHtml />}
    </DialogComponent>
  );
};

export default ConfirmDeleteDialog;
