import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import LoadingDialog from "../../Components/Dialogs/LoadingDialog";
import PaymentConfirmationDialog from "../../Components/Dialogs/PaymentConfirmationDialog";
import NumberFormatCustom from "../../Components/NumberFormatCustom";
import { useUiContext } from "../../Contexts/UiContext";
import {
  getUserRemainingBalance,
  transferMoneyRequest,
} from "../../store/actions/bankingActions";
import verifySchema from "../../util/yup/verifySchema";
import { transferMoneySchema } from "../../util/yup/yupSchemas";

const styles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
}));
const TransferMoneyDialog = ({ open, setOpen, business }) => {
  const { register, handleSubmit } = useForm();
  const { clearErrors, setErrors, errors } = useUiContext();
  const classes = styles();

  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

  let paymentLoading = useSelector((state) => state.banking.transferLoading);

  let transferMoneyRequestResult = useSelector(
    (state) => state.banking.transferMoneyRequest
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "TRANSFER_MONEY_REQUEST_FETCH_SUCCESS",
      payload: {},
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (transferMoneyRequestResult) {
      if (transferMoneyRequestResult.auth_uri) {
        setTimeout(function() {
          window.location.href = transferMoneyRequestResult.auth_uri;
        }, 1000);
      }
    }
  }, [transferMoneyRequestResult]);

  const onSend = async () => {
    let data = dataToSubmit;
    data.redirectURI = "payment-result/transfer-money";
    let validData = await verifySchema(
      transferMoneySchema,
      data,
      clearErrors,
      setErrors
    );
    if (validData) {
      dispatch(transferMoneyRequest(data));
      setOpenLoadingDialog(true);
    }
  };

  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const bankAccounts = useSelector((state) => state.banking.bankAccounts);
  const bankAccountLoading = useSelector((state) => state.banking.isLoading);
  let dailyRemainingBalance = useSelector(
    (state) => state?.banking?.daily_remaining_balance
  );

  const handleSelectBankAccount = (e) => {
    setSelectedBankAccount(e.target.value);
  };

  const [selectedAccountNumber, setSelectedAccountNumber] = useState(null);
  const [selectedSortCode, setSelectedSortCode] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const steps = ["Details", "Your Account Details", "Review and Submit"];

  const [activeStep, setActiveStep] = useState(0);
  const [gelenData, setGelenData] = useState({});

  const [openPaymentConfirmDialog, setOpenPaymentConfirmDialog] = useState(
    false
  );
  const [dataToSubmit, setDataToSubmit] = useState(null);

  const handleNext = (data) => {
    setGelenData({ ...gelenData, ...data });
    let accountType = business.account_type;

    if (activeStep === 0) {
      if (
        !data.amount ||
        !data.beneficiary_account_number ||
        !data.beneficiary_sort_code ||
        !data.beneficiary_name ||
        !data.remitter_name
      ) {
        setErrors({ general: "Please fill in the details!" });
        return false;
      } else {
        if (currency(data.amount).intValue < currency("0.01").intValue) {
          setErrors({ general: `The minumum amount you can send is GBP 0.01` });
          return false;
        }
        let paymentLimit = accountType === "Business" ? "10,000" : "1,000";

        if (currency(data.amount).intValue > currency(paymentLimit).intValue) {
          setErrors({
            general: `The maximum amount you can send at once is GBP ${paymentLimit}`,
          });
          return false;
        }

        if (
          currency(dailyRemainingBalance?.remaining_balance).value <
          currency(data.amount).value
        ) {
          setErrors({
            general: `The maximum amount you can send is ${dailyRemainingBalance?.remaining_balance}`,
          });
          return false;
        }

        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 1) {
      if (!selectedOption) {
        setErrors({ general: "Please select a payment option" });
        return false;
      }
      if (selectedOption === "fromConnectedAccounts") {
        if (!selectedAccountNumber || !selectedSortCode) {
          setErrors({ general: "Please select a payment account" });
          return false;
        } else {
          data.remitter_account_number = selectedAccountNumber;
          data.remitter_sort_code = selectedSortCode;

          setGelenData({ ...data, ...gelenData });

          setSelectedAccountNumber(data.remitter_account_number);
          setSelectedSortCode(data.remitter_sort_code);
        }
      } else if (selectedOption === "byManuallyTyping") {
        if (!data.remitter_account_number || !data.remitter_sort_code) {
          setErrors({ general: "Please enter account number and sort code" });
          return false;
        }
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2) {
      setDataToSubmit(gelenData);
      setOpenPaymentConfirmDialog(true);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleRadioChange = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
  };

  useEffect(() => {
    if (activeStep === 1) {
      if (selectedOption === "fromConnectedAccounts") {
        if (selectedBankAccount) {
          let selectedAccount = bankAccounts.filter(
            (acct) => acct.id === selectedBankAccount
          );
          setSelectedAccountNumber(selectedAccount[0].account_number);
          setSelectedSortCode(selectedAccount[0].sort_code.replace(/-/g, ""));
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedBankAccount]);

  // useEffect(() => {
  //   dispatch(getUserRemainingBalance());
  //   // eslint-disable-next-line
  // }, []);

  const ReviewDetails = () => {
    return (
      <div style={{ marginLeft: "50px", fontWeight: "bold" }}>
        <br />
        <Typography>From: </Typography>

        <Typography>
          Account Number:{" "}
          {gelenData.remitter_account_number || selectedAccountNumber}
        </Typography>
        <Typography>
          Sort Code: {gelenData.remitter_sort_code || selectedSortCode}
        </Typography>
        <br />
        <Divider style={{ fontWeight: "bold", background: "black" }} />
        <br />

        <Typography>To: </Typography>
        <Typography>{gelenData.beneficiary_name}</Typography>

        <Typography>
          {" "}
          Account Number: {gelenData.beneficiary_account_number}
        </Typography>
        <Typography>Sort Code: {gelenData.beneficiary_sort_code}</Typography>
        <br />
        <Divider style={{ fontWeight: "bold", background: "black" }} />
        <br />
        <Typography>
          <strong>Amount to Transfer: </strong>
          {gelenData.amount}
        </Typography>
        <br />
        <Divider style={{ fontWeight: "bold", background: "black" }} />
        <br />
      </div>
    );
  };

  const userConnectedAccounts = () => {
    return (
      <>
        {bankAccountLoading && "Loading..."}
        {!bankAccountLoading &&
          (!bankAccounts || bankAccounts.length === 0) &&
          "No data found"}
        {!bankAccountLoading &&
          bankAccounts &&
          bankAccounts
            .filter(
              (acct) =>
                currency(acct.balance).value > currency(gelenData.amount) &&
                acct.account_type === "TRANSACTION"
            )
            .map((acct) => {
              return (
                <Card
                  variant="outlined"
                  style={{
                    display: "inline-block",
                    marginLeft: "50px",
                    width: "150px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    id={acct.id}
                    name="selectedBankAccountID"
                    value={acct.id}
                    checked={selectedBankAccount === acct["id"]}
                    type="radio"
                    onChange={(e) => handleSelectBankAccount(e)}
                  />
                  <label for="selectedBankAccountID">
                    {acct.provider_name}
                  </label>

                  <CardMedia align="center">
                    <Avatar alt="Remy Sharp" src={acct.logo} />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontSize: "15px" }}
                    >
                      {acct.account_number}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      style={{ fontSize: "12px" }}
                    >
                      {acct.sort_code}
                    </Typography>{" "}
                    <Typography
                      variant="subtitle1"
                      align="center"
                      style={{ fontSize: "12px", color: "darkgreen" }}
                    >
                      Balance:
                      <strong> {acct.balance}</strong>
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
      </>
    );
  };

  const BeneficiaryDetails = () => {
    return (
      <div style={{ marginLeft: "50px" }}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} style={{ paddingTop: "10px" }}>
              <TextField
                label="Amount"
                variant="outlined"
                name="amount"
                id="amount"
                defaultValue={gelenData.amount}
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                inputRef={register}
                error={errors.amount ? true : false}
                helperText={errors.amount ?? ""}
                // onChange={(e)=> checkIfHasBalance(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12} style={{ paddingTop: "10px" }}>
              <Typography>Beneficiary: </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} style={{ paddingTop: "10px" }}>
              <TextField
                fullWidth
                defaultValue={gelenData.beneficiary_name}
                name="beneficiary_name"
                id="beneficiary_name"
                label="Full Name"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                error={errors.beneficiary_name ? true : false}
                helperText={errors.beneficiary_name ?? ""}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} style={{ paddingTop: "10px" }}>
              <TextField
                fullWidth
                defaultValue={gelenData.beneficiary_account_number}
                name="beneficiary_account_number"
                id="beneficiary_account_number"
                label="Account Number"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                error={errors.beneficiary_account_number ? true : false}
                helperText={errors.beneficiary_account_number ?? ""}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} style={{ paddingTop: "10px" }}>
              <TextField
                fullWidth
                defaultValue={gelenData.beneficiary_sort_code}
                name="beneficiary_sort_code"
                id="beneficiary_sort_code"
                label="Sort Code"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                error={errors.beneficiary_sort_code ? true : false}
                helperText={errors.beneficiary_sort_code ?? ""}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12} style={{ paddingTop: "10px" }}>
              <Typography>Remitter: </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} style={{ paddingTop: "10px" }}>
              <TextField
                fullWidth
                defaultValue={gelenData.remitter_name}
                name="remitter_name"
                id="remitter_name"
                label="Your Full Name"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                error={errors.remitter_name ? true : false}
                helperText={errors.remitter_name ?? ""}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const userBankAccountForm = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} md={6} style={{ paddingTop: "10px" }}>
            <TextField
              fullWidth
              defaultValue={gelenData.remitter_account_number}
              name="remitter_account_number"
              id="remitter_account_number"
              label="Account Number"
              variant="outlined"
              inputRef={register}
              InputLabelProps={{ shrink: true }}
              error={errors.remitter_account_number ? true : false}
              helperText={errors.remitter_account_number ?? ""}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} style={{ paddingTop: "10px" }}>
            <TextField
              fullWidth
              defaultValue={gelenData.remitter_sort_code}
              name="remitter_sort_code"
              id="remitter_sort_code"
              label="Sort Code"
              variant="outlined"
              inputRef={register}
              InputLabelProps={{ shrink: true }}
              error={errors.remitter_sort_code ? true : false}
              helperText={errors.remitter_sort_code ?? ""}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderPaymentStyleSelection = () => {
    return (
      <>
        <form>
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <Typography variant="body">
              <strong style={{ color: "steelblue", fontWeight: "bold" }}>
                Choose payment style
              </strong>
            </Typography>
            <div>
              <input
                id="fromConnectedAccounts"
                name="paymentSelectionOption"
                value="fromConnectedAccounts"
                checked={selectedOption === "fromConnectedAccounts"}
                type="radio"
                onChange={(e) => handleRadioChange(e)}
              />
              <label for="makePayment">From Connected Bank Accounts </label>
            </div>
            <div>
              <input
                id="byManuallyTyping"
                name="paymentSelectionOption"
                value="byManuallyTyping"
                checked={selectedOption === "byManuallyTyping"}
                type="radio"
                onChange={(e) => handleRadioChange(e)}
              />
              <label for="receivePayment">By entering account number</label>
            </div>
          </div>
        </form>
      </>
    );
  };

  const RemitterDetails = () => {
    return (
      <>
        {renderPaymentStyleSelection()}

        {selectedOption === "fromConnectedAccounts" && userConnectedAccounts()}
        {selectedOption === "byManuallyTyping" && userBankAccountForm()}
      </>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BeneficiaryDetails />;
      case 1:
        return <RemitterDetails />;
      case 2:
        return <ReviewDetails />;
      default:
        return <h1>ERROR</h1>;
    }
  };

  const renderSteps = () => {
    return (
      <>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          <>
            {getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={() => handleBack()} className={classes.button}>
                  Back
                </Button>
              )}
              {activeStep === steps.length && paymentLoading && (
                <Typography>
                  You will be redirected to payment provider...
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit((data) => handleNext(data))}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </>
        </>
      </>
    );
  };
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title="Transfer Money"
      description=""
      onSubmit={handleSubmit((data) => onSend(data))}
      withActions={false}
    >
      {renderSteps()}
      <LoadingDialog
        open={openLoadingDialog}
        setOpen={setOpenLoadingDialog}
        loadingText="You will be redirected to payment provider!"
        backgroundColor={"lightBlue"}
      />

      <PaymentConfirmationDialog
        open={openPaymentConfirmDialog}
        setOpen={setOpenPaymentConfirmDialog}
        onSubmit={onSend}
      />
    </DialogComponent>
  );
};

export default TransferMoneyDialog;
