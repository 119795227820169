import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import BasePage from "../../../../Components/BasePage";
import TableComponent from "../../../../Components/Table/TableComponent";
import { exchanceCodeForTrueLayerToken, getBankAuthUrl, getSelfBankAccounts, setReceivePaymentAccount } from "../../../../store/actions/bankingActions";
import { getBusinessDetails, getBusinessStat } from "../../../../store/actions/businessActions";
import { useUiContext } from "./../../../../Contexts/UiContext";
import AddBankAccountDialog from "./AddBankAccountDialog";
import BankAccountsToolbar from "./BankAccountsToolbar";

const headCells = [
  { id: "provider_name", label: "Provider" },
  { id: "account_type", label: "Account Type" },
  { id: "display_name", label: "Account Name" },
  { id: "account_number", label: "Account Number" },
  { id: "currency", label: "Currency" },
  { id: "balance", label: "Balance" },
  { id: "overdraft", label: "Overdraft" },
];

const BankAccounts = ({ history }) => {
  const { errors } = useUiContext();
  let [addBankAccountDialogOpen, setAddBankAccountDialogOpen] = useState(false);
  let [transactionDateDialogOpen, setTransactionDateDialogOpen] = useState(false);

  let [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (!afterRender) {
      setAfterRender(true);
      dispatch(getBusinessDetails());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (afterRender) {
      dispatch(getSelfBankAccounts());
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const bankAccounts = useSelector((state) => state.banking.bankAccounts);
  const isLoading = useSelector((state) => state.banking.isLoading);
  const authUrl = useSelector((state) => state.banking.authUrl);
  const authUrlLoading = useSelector((state) => state.banking.authUrlLoading);
  const business = useSelector((state) => state.business);

  let bankingLoading = useSelector((state) => state.banking.isLoading);
  const user = useSelector((state) => state.user);

  const onSubmit = () => {
    let businessBankingInfo = business.company_details.banking[0];
    let selectedBankingInfo = selected[0];

    if (businessBankingInfo.account_number === selectedBankingInfo.account_number) {
      if (businessBankingInfo.sort_code === selectedBankingInfo.sort_code.replace("-", "")) {
        alert("here");
        dispatch(setReceivePaymentAccount(selected[0]));
      }
    } else {
      alert("The provided informations are not matching in our system!");
    }
  };

  const renderToolbar = () => {
    return (
      <BankAccountsToolbar
        setAddBankAccountDialogOpen={setAddBankAccountDialogOpen}
        addBankAccountDialogOpen={addBankAccountDialogOpen}
        setTransactionDateDialogOpen={setTransactionDateDialogOpen}
        transactionDateDialogOpen={transactionDateDialogOpen}
        history={history}
        onSubmit={onSubmit}
        selected={selected}
        business={business}
      />
    );
  };

  const handleGetAuthUrl = () => {
    dispatch(getBankAuthUrl());
  };

  useEffect(() => {
    dispatch(getBusinessStat());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authUrl) {
      setTimeout(function() {
        window.location.href = authUrl.authUrl;
      }, 2000);
    }
  }, [authUrl]);

  useEffect(() => {
    if (afterRender) {
      var code = new URLSearchParams(location.search).get("code");
      var error = new URLSearchParams(location.search).get("error");

      if (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Could not connect your bank!",
        }).then(async (val) => {
          if (val.isConfirmed) {
            history.push("/dashboard/bank-accounts");
          }
          if (val.isDismissed) {
            history.push("/dashboard/bank-accounts");
          }
        });
      }
      (async () => {
        if (code) {
          dispatch(exchanceCodeForTrueLayerToken(code));
        }
      })();
    }

    // eslint-disable-next-line
  }, [location, afterRender]);

  return (
    <BasePage navigation="Dashboard/BankAccounts" business={business} canView={user?.permissions?.bankAccount?.view}>
      <Grid container style={{ marginBottom: "5px" }}>
        <Grid item xs={8}>
          {business?.company_details?.banking[0] && !business?.receive_payment_details && (
            <Alert severity="info" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
              Receive Payment Account Details (Not verified yet):
              <br />
              <strong>Account number:</strong> {business && business.company_details.banking[0].account_number} <strong>Sort code:</strong>{" "}
              {business && business.company_details.banking[0].sort_code}
            </Alert>
          )}
          {business?.receive_payment_details && business?.receive_payment_details?.receive_payment_account_id && (
            <Alert severity="success" style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
              <AlertTitle>Success</AlertTitle>
              Account ownership verified to receive payment for invoices.
              <br />
              <strong>Provider: </strong>
              {business?.receive_payment_details.trueLayerDetails?.provider.display_name}
              <strong> Account Name: </strong>
              {business?.receive_payment_details.trueLayerDetails?.display_name}
              <strong> Account Number:</strong> {business?.receive_payment_details.trueLayerDetails?.account_number.number}
              <strong> Sort Code :</strong> {business?.receive_payment_details.trueLayerDetails?.account_number.sort_code}
            </Alert>
          )}
        </Grid>
        <Grid item xs={4}>
          <Alert style={{ fontSize: "12px", backgroundColor: "#FAFAFA" }}>
            You can connect maximum {business?.payeda_package?.bank_token?.Credit} banks. <br />
            <strong>(You have {business?.payeda_package?.bank_token?.Balance || 0} left)</strong>
          </Alert>
        </Grid>
      </Grid>
      <div style={{ fontSize: "5px" }}></div>

      {bankingLoading && "loading"}
      {bankAccounts && (
        <TableComponent
          toolbar={renderToolbar()}
          data={bankAccounts}
          selected={selected}
          setSelected={setSelected}
          headCells={headCells}
          isLoading={isLoading}
          getAllData={getSelfBankAccounts}
          shouldSearch={false}
          shouldGetData={false}
          shouldPaginate={false}
          shouldView={true}
        />
      )}
      <AddBankAccountDialog
        open={addBankAccountDialogOpen}
        errors={errors}
        setOpen={setAddBankAccountDialogOpen}
        handleGetAuthUrl={handleGetAuthUrl}
        authUrl={authUrl}
        authUrlLoading={authUrlLoading}
      />
    </BasePage>
  );
};

export default BankAccounts;
