import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "./../util/constants/general";

export const useBasePageStyles = makeStyles((theme) => ({
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey[600],
    paddingBottom: 15,
    display: "block",
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
  },
  paper: {
    padding: 30,
  },
  clear: {
    clear: "both",
  },
}));
export const useLoadingIconStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 0,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    paddingLeft: drawerWidth + 40,
    textAlign: "center",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const useRightListItemStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  head: {
    marginRight: "0",
    marginLeft: "auto",
  },
}));

export const useListItemStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "space-between",
  },
  itembar: {
    padding: "10px 20px 10px",
    "&:$selected": {
      color: "blue",
    },
  },
}));

export const useNavbarStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "rgb(0, 71, 103)",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  paper: {
    backgroundColor: "white",
  },
  link: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "bolder",
    size: "18px",
    marginLeft: "10px",

    "&:hover": {
      opacity: "0.5",
    },
  },
  toolbarcontainer: {
    display: "flex",
  },
  mtest: {
    PaddingLeft: "50%",
    PaddingRight: "auto",
  },
  Rtoolbar: {
    display: "flex",
    marginRight: "0",
    marginLeft: "auto",
    justifyContent: "space-between",
  },
  Ltoolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "0",
    marginLeft: "0",
    marginRight: "auto",
  },
  closeicon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0, 10px",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: "#F50451",
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#001F3D",
    color: "#fff",
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },

  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolBarColor: {
    backgroundColor: "#fff",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
    color: "black",
  },
  icon: {
    root: {
      "& > svg": {
        margin: theme.spacing(2),
      },
    },
  },
  companyLogo: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
  },
}));

export const usePhoneInputStyles = makeStyles((theme) => ({
  phone: {
    display: "flex",
    position: "relative",
    width: "-webkit-fill-available!important",
  },
}));

export const useSideBarStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#F50451",
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#ffff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  root: {
    "&:hover": {
      backgroundColor: "#77D2BB",
      borderRadius: "10px",
      color: "black",
      fontWeight: "800!important",
    },
  },
  button: {
    border: "none",
    width: "100%",
    backgroundColor: "transparent",
    fontWeight: "800!important",
    fontSize: " 1.5em",
    marginBottom: "5px",
    color: "black",
    height: "75px",
    "&:hover": {
      opacity: 0.5,
      transitionDuration: "0.4s",
      borderRadius: "20px",
    },
  },
  hr: {
    width: "100%",
    border: "1px solid black",
    borderRadius: "5px",
  },
  iconStyle: {
    color: "#F29EC8",
    "&:hover": {
      color: "#F50451",
    },
  },
  nested: {
    paddingLeft: theme.spacing(10),
    "&:hover": {
      backgroundColor: "#A9E2D5",
      borderRadius: "10px",
      fontWeight: "bold",
      color: "black",
    },
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useCountryRegionStyles = makeStyles((theme) => ({
  dropdown: {
    height: "30px",
    width: "-webkit-fill-available!important",
    marginTop: "5px",
    borderRadius: "5px",
  },
}));

export const useUploadComponentStyle = makeStyles((theme) => ({
  box: {
    padding: 10,
    margin: 10,
    display: "inline-block",
    borderStyle: "dashed",
    width: "min-content",
  },
}));

export const useHeaderComponentStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
  },
  pageHeader: {
    padding: theme.spacing(4),
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(3),
    "& .MuiTypography-subtitle2": {},
  },
  content: {
    flexGrow: 0,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    paddingLeft: drawerWidth + 40,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const useDialogComponentStyles = makeStyles((theme) => ({
  dialog: {
    margin: "auto",
    display: "flex !important",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
}));

export const useConfirmDeleteDialogStyles = makeStyles((theme) => ({
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export const useSearchComponentStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: "lightgrey",
  },
  searchDrawer: {
    overflow: "hidden",
    width: 280,
  },
  searchGrid: {
    width: 250,
  },
  searchField: {
    margin: 10,
  },
  button: {
    width: "20px",
    marginLeft: "7px",
  },
}));
