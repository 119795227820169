import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import NumberFormatCustom from "../../Components/NumberFormatCustom";
import MultipleSelect from "../../Components/DropDown/MultipleSelectDropDown";
import { BUSINESS_TYPES, BUSINESS_MODELS } from "../../util/constants/companyInformation";
import { useEffect } from "react";
import { getFinanceBasketData } from "../../store/actions/financeActions";

const FinanceSearchFilter = ({ register, errors, getInv, dispatch }) => {
  const [total_amount_gt, setTotal_amount_gt] = React.useState(0);
  const [total_amount_lt, setTotal_amount_lt] = React.useState(1000000);

  const [turnover_amount_gt, setTurnover_amount_gt] = React.useState(0);
  const [turnover_amount_lt, setTurnover_amount_lt] = React.useState(1000000);

  const [min_number_of_employee, setMinNumberOfEmployee] = React.useState(0);
  const [max_number_of_employee, setMaxNumberOfEmployee] = React.useState(100000);

  const [selectedIssuDate, setSelectedIssueDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());

  const [selectedSectorTypes, setSelectedSectorTypes] = useState([]);
  const [selectedSectorModels, setSelectedSectorModels] = useState([]);

  const [excludedSectorTypes, setExcludedSectorTypes] = useState([]);
  const [excludedSectorModels, setExcludedSectorModels] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  function convertDate(date, start) {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    let timeString = start ? "00:00:00" : "23:59:00";

    return yyyy + "-" + mm + "-" + dd + " " + timeString;
  }
  const handleRetrieveRequests = async (data) => {
    let queryStrings = {
      min_invoice_amount: total_amount_gt,
      max_invoice_amount: total_amount_lt,
      min_turnover: turnover_amount_gt,
      max_turnover: turnover_amount_lt,
      max_employee: max_number_of_employee,
      min_employee: min_number_of_employee,
      bid_opens_at: await convertDate(selectedIssuDate, true),
      bid_closes_at: await convertDate(selectedDueDate, false),
      sectorModelIn: selectedSectorModels,
      sectorModelNotIn: excludedSectorModels,
      sectorTypeIn: selectedSectorTypes,
      sectorTypeNotIn: excludedSectorTypes,
    };
    dispatch(getInv({ ...queryStrings }));
  };

  useEffect(() => {
    dispatch(getInv({}));
  }, []);

  const renderFilters = () => {
    return (
      <Grid container spacing={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Min Invoice Amount"
              value={total_amount_gt}
              onChange={(e) => setTotal_amount_gt(parseInt(e.target.value))}
              variant="outlined"
              name="total_amount_gt"
              id="total_amount_gt"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Max Invoice Amount"
              value={total_amount_lt}
              onChange={(e) => setTotal_amount_lt(parseInt(e.target.value))}
              variant="outlined"
              name="total_amount_lt"
              id="total_amount_lt"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Min Turnover Amount"
              value={turnover_amount_gt}
              onChange={(e) => setTurnover_amount_gt(parseInt(e.target.value))}
              variant="outlined"
              name="turnover_amount_gt"
              id="turnover_amount_gt"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Max Turnover Amount"
              value={turnover_amount_lt}
              onChange={(e) => setTurnover_amount_lt(parseInt(e.target.value))}
              variant="outlined"
              name="turnover_amount_lt"
              id="turnover_amount_lt"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Min number of employee"
              value={min_number_of_employee}
              onChange={(e) => setMinNumberOfEmployee(parseInt(e.target.value))}
              variant="outlined"
              name="min_number_of_employee"
              id="min_number_of_employee"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Max number of employee"
              value={max_number_of_employee}
              onChange={(e) => setMaxNumberOfEmployee(parseInt(e.target.value))}
              variant="outlined"
              name="max_number_of_employee"
              id="max_number_of_employee"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <MultipleSelect
              selected={selectedSectorTypes}
              setSelected={setSelectedSectorTypes}
              menuData={BUSINESS_TYPES.map((types) => types.label)}
              label="Included Sector Types"
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <MultipleSelect
              selected={excludedSectorTypes}
              setSelected={setExcludedSectorTypes}
              menuData={BUSINESS_TYPES.map((types) => types.label)}
              label="Excluded Sector Types"
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <MultipleSelect
              selected={selectedSectorModels}
              setSelected={setSelectedSectorModels}
              menuData={BUSINESS_MODELS.map((types) => types.label)}
              label="Included Sector MOdels"
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <MultipleSelect
              selected={excludedSectorModels}
              setSelected={setExcludedSectorModels}
              menuData={BUSINESS_MODELS.map((types) => types.label)}
              label="Excluded Sector MOdels"
            />
          </Grid>

          <Grid item xs={12} sm={5} md={5} lg={5}>
            <KeyboardDatePicker
              inputVariant="outlined"
              margin="dense"
              id="issued_date"
              name="issued_date"
              label="From"
              value={selectedIssuDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              inputRef={register}
              onChange={(date) => setSelectedIssueDate(date)}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />{" "}
          </Grid>

          <Grid item xs={12} sm={5} md={5} lg={5}>
            <KeyboardDatePicker
              margin="dense"
              inputVariant="outlined"
              id="due_date"
              name="due_date"
              label="To"
              value={selectedDueDate}
              openTo="year"
              format="yyyy-MM-dd"
              size="small"
              views={["year", "month", "date"]}
              inputRef={register}
              onChange={(date) => setSelectedDueDate(date)}
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button variant="outlined" color="primary" style={{ marginTop: "10px" }} onClick={() => handleRetrieveRequests()}>
              Search
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };
  return (
    <>
      <Button color="secondary" variant="outlined" style={{ marginBottom: "25px" }} onClick={() => setShowFilters(!showFilters)}>
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
      {showFilters && renderFilters()}
    </>
  );
};

export default FinanceSearchFilter;
