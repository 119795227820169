import { IconButton, Tooltip, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import React from "react";
import { useHistory } from "react-router-dom";
import TableToolbarComponent from "./../../Components/Table/TableToolbarComponent";
import ButtonBase from "./../../Components/shared/ButtonBase";
import { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "../Invoice/InvoicePDF";

const FinanceBasketTableToolbar = ({ numSelected, selected, setErrors, setOpen, header, handleRemoveFromBasket }) => {
  let history = useHistory();
  const [downloadPDF, setDownloadPDF] = useState(false);

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <Grid container>
        <>
          {header === "Basket Details" && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <ButtonBase tooltip="Make an offer" text="Make an Offer" backColor="#008BD3" textColor="white" onClick={() => setOpen(true)} fontSize="300" customWidth={"250px"} />
              </Grid>
              {selected.length === 1 && (
                <>
                  {" "}
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    {selected[0] && downloadPDF ? (
                      <>
                        <PDFDownloadLink
                          document={<InvoicePDF invoiceData={selected[0]} />}
                          fileName={selected[0].invoice_id}
                          style={{
                            textDecoration: "none",
                            padding: "16px",
                            color: "#4a4a4a",
                            backgroundColor: "#f2f2f2",
                            border: "1px solid #4a4a4a",
                            borderRadius: "20px",
                            width: "250px",
                            height: "50px",
                          }}
                        >
                          {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download")}
                        </PDFDownloadLink>
                      </>
                    ) : (
                      <>
                        <ButtonBase
                          tooltip="Prepare Invoice PDF"
                          text="Prepare Invoice PDF"
                          backColor="#00AFA1"
                          textColor="white"
                          onClick={() => setDownloadPDF(true)}
                          fontSize="400"
                          customWidth="250px"
                        />
                      </>
                    )}
                  </Grid>{" "}
                </>
              )}

              {selected.length > 0 && (
                <>
                  {" "}
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <ButtonBase
                      tooltip="Remove from the basket"
                      text="Remove from the basket"
                      backColor="red"
                      textColor="white"
                      onClick={handleRemoveFromBasket}
                      fontSize="400"
                      customWidth="250px"
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          {selected.length === 1 && header === "Finance Baskets" && <Button onClick={() => history.push(`/dashboard/finance-basket/${selected[0].id}`)}>Details</Button>}

          {selected.length === 1 && header === "Finance Basket" && (
            <Button
              onClick={() => {
                window.open(selected[0].offer_document);
              }}
              variant="contained"
              style={{ float: "right", marginRight: "5px", backgroundColor: "green", color: "white" }}
            >
              Download Offer PDF{" "}
            </Button>
          )}
        </>
      </Grid>
    </TableToolbarComponent>
  );
};

export default FinanceBasketTableToolbar;
