import { Button, Divider, Paper, TextField, Typography, Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkoutPaymentRequest } from "../../../store/actions/bankingActions";
import { useUiContext } from "./../../../Contexts/UiContext";
import { UI_API_BASE, API_BASE } from "./../../../store/env";
import { useCheckoutStyles } from "../InvoiceStyles";
import ReceiptPDF from "./ReceiptPDF";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useHistory } from "react-router-dom";

const ViewInvoice = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState({});
  const [paymentLink, setPaymentLink] = useState("");

  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { errors, setErrors } = useUiContext();
  const classes = useCheckoutStyles();
  const location = useLocation();

  const getInvoiceDetails = async (payment_id) => {
    let request;
    try {
      request = await axios.get(`${API_BASE}/outgoing/receipt`, {
        params: {
          payment_id: payment_id,
        },
      });
    } catch (error) {
      setLoading(false);
      setErrors({ general: "Wrong link submitted" });
      return null;
    }

    if (request.status === 200) {
      let invoice = request.data;
      setInvoiceDetails({ ...invoice, id: payment_id });
      setLoading(false);
      return invoice;
    } else {
      setLoading(false);
      setErrors({ general: "Wrong link submitted" });
      return null;
    }
  };
  useEffect(() => {
    setLoading(true);

    var id = new URLSearchParams(location.search).get("payment_id");
    (async () => {
      if (id) {
        console.log("is here");
        var invoice = await getInvoiceDetails(id);
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width >= 1280) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, [isMobile]);

  return (
    <>
      {loading && <h4>Loading...</h4>}

      {!invoiceDetails.amount && !loading && <h2>No receipt found</h2>}

      <Grid container>
        <Grid item xs={12} lg={12} md={12}>
          {invoiceDetails && invoiceDetails?.amount && (
            <>
              {!isMobile && (
                <PDFViewer width="100%" height="1000">
                  <ReceiptPDF invoiceData={invoiceDetails} receiptType="outgoing" />
                </PDFViewer>
              )}
              <div className="downloadButton">
                {isMobile && (
                  <PDFDownloadLink
                    document={<ReceiptPDF invoiceData={invoiceDetails} receiptType="outgoing" />}
                    fileName={invoiceDetails.invoice_number}
                    style={{
                      textDecoration: "none",
                      padding: "16px",
                      color: "#4a4a4a",
                      backgroundColor: "#f2f2f2",
                      border: "1px solid #4a4a4a",
                      width: "120px",
                    }}
                  >
                    {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download PDF")}
                  </PDFDownloadLink>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewInvoice;
