import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";

import DialogComponent from "./DialogComponent";
import { MdOutlineSecurityUpdateWarning } from "react-icons/md";

const PaymentConfirmationDialog = ({
  open,
  setOpen,
  title,
  description,
  onSubmit,
}) => {
  const { handleSubmit } = useForm();

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={true}
      backgroundColor={"lightBlue"}
    >
      <div style={{ textAlign: "center" }}>
        <Typography>
          <span
            style={{ fontWeight: "bold", fontSize: "35px", color: "black" }}
          >
            Important
          </span>
        </Typography>
        <MdOutlineSecurityUpdateWarning
          style={{ fontSize: "50px", color: "red" }}
        />
      </div>
      <Grid
        container
        spacing={3}
        style={{ marginTop: "50px", textAlign: "center" }}
      >
        {/* <Typography>Payment Total: <span>£50</span></Typography>
                <Grid item xs={12}>
                    <Typography>Payee Details</Typography>
                    <ul>
                        <li>Payee name: {supplier && supplier?.name}</li>
                        <li>Payment Ref: {supplier && supplier?.representaive.name}</li>
                    </ul>
                </Grid> */}

        <Typography>
          <span style={{ fontWeight: "bold", fontSize: "22px" }}>
            Please wait Payeda confirmation message, after your bank authorised
            the payment. Thank you
          </span>
        </Typography>
      </Grid>
    </DialogComponent>
  );
};

export default PaymentConfirmationDialog;
