import React from "react";
import { Typography } from "@material-ui/core";

import DialogComponent from "./DialogComponent";

const LoadingDialog = ({ open, setOpen, loadingText, backgroundColor }) => {
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={""}
      withActions={false}
      backgroundColor={backgroundColor}
    >
      <Typography
        variant="h6"
        style={{ textAlign: "center", fontSize: "18px" }}
      >
        Loading...
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ textAlign: "center", fontSize: "18px" }}
      >
        {loadingText}
      </Typography>
    </DialogComponent>
  );
};

export default LoadingDialog;
