import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTenantsFromCloudApp } from "../../store/actions/invoiceActions";
import ImportFromCloud from "./ImportFromCloud";

const ImportInvoiceComponent = ({
  setInvoices,
  title,
  errors,
  business,
  history,
}) => {
  const [selectedTenant, setSelectedTenant] = useState(null);
  // eslint-disable-next-line
  const [prepereadInvoices, setPreparedInvoices] = useState([]);
  const dispatch = useDispatch();

  let cloudInvoices = useSelector((state) => state.invoice.cloudInvoices);

  useEffect(() => {
    dispatch(getTenantsFromCloudApp("xero"));
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    var cloudApplication = "xero";
    if (cloudInvoices && cloudInvoices.length > 0) {
      await cloudInvoices.forEach((invoice) => {
        //invoice
        if (cloudApplication === "quickBooks") {
          prepereadInvoices.push({
            account_id: selectedTenant,
            invoice_number: invoice.DocNumber,
            issued_date: invoice.TxnDate,
            due_date: invoice.DueDate,
            total_vat_amount: invoice.TxnTaxDetail.TotalTax,
            subtotal_amount: invoice.TotalAmt - invoice.TxnTaxDetail.TotalTax,
            total_paid_amount: invoice.TotalAmt - invoice.Balance,
            amount_to_be_paid: invoice.Balance,
            total_discount_amount: 0,
            currency: invoice.CurrencyRef.value,
            customer_name:
              title === "Outgoing Invoice"
                ? invoice.CustomerRef.name
                : business.company_details.name,

            supplier_name:
              title === "Incoming Invoice"
                ? invoice.CustomerRef.name
                : business.company_details.name,
            erp_no: invoice.CustomerRef.value,
            invoice_lines: [],
            accounting_software: "xero",
          });
          let lineDetails = {};
          for (let invoiceLine of invoice.Line) {
            lineDetails = {
              product: invoiceLine.Description,
              product_quantity: invoiceLine.SalesItemLineDetail?.Qty,
              unit_price: invoiceLine.SalesItemLineDetail?.UnitPrice,
              line_total_amount: invoiceLine.Amount,
              subtotal_amount: invoiceLine.Amount,
              line_vat_amount: 0,
            };

            if (invoiceLine.DetailType === "SubTotalLineDetail") {
              break;
            } else {
              prepereadInvoices[
                prepereadInvoices.length - 1
              ].invoice_lines.push(lineDetails);
            }
          }
        } else if (cloudApplication === "xero") {
          prepereadInvoices.push({
            account_id: selectedTenant,
            invoice_id: invoice.InvoiceID,
            invoice_number: invoice.InvoiceNumber,
            issued_date: invoice.DateString.slice(0, 10),
            due_date: invoice.DueDateString.slice(0, 10),
            total_vat_amount: invoice.TotalTax,
            subtotal_amount: invoice.SubTotal, //amount without tax
            total_paid_amount: invoice.AmountPaid,
            amount_to_be_paid: invoice.AmountDue,
            total_discount_amount: "0",
            currency: invoice.CurrencyCode,
            customer_name:
              title === "Outgoing Invoice"
                ? invoice.Contact.Name
                : business.company_details.name,

            supplier_name:
              title === "Incoming Invoice"
                ? invoice.Contact.Name
                : business.company_details.name,
            phone:
              invoice?.Contact?.Phones[0]?.PhoneCountryCode +
              " " +
              invoice?.Contact?.Phones[0]?.PhoneNumber,
            email: invoice?.Contact?.EmailAddress,
            erp_no: invoice.Contact.ContactID,
            invoice_lines: [],
            accounting_software: "xero",
          });
          let lineDetails = {};
          if (invoice.LineItems.length > 0) {
            for (let invoiceLine of invoice.LineItems) {
              lineDetails = {
                product: invoiceLine.Description,
                product_quantity: invoiceLine.Quantity,
                unit_price: invoiceLine.UnitAmount,
                line_vat_amount: invoiceLine.TaxAmount,
                line_total_amount: invoiceLine.LineAmount,
                subtotal_amount: invoiceLine.LineAmount,
              };
              prepereadInvoices[
                prepereadInvoices.length - 1
              ].invoice_lines.push(lineDetails);
            }
          }
        }
        setInvoices(prepereadInvoices);
      });
    }

    // eslint-disable-next-line
  }, [cloudInvoices]);

  const handlePageChange = (pageType) => {
    // if (pageType === "excell") {
    //   setRenderExcell(true);
    //   setRenderCloud(false);
    // } else if (pageType === "cloud") {
    //   setRenderCloud(true);
    //   setRenderExcell(false);
    // }
  };

  return (
    <>
      <Button
        onClick={() => {
          handlePageChange("cloud");
        }}
      >
        Import From Cloud
      </Button>

      {/* {renderExcell && (
        <ImportFromExcell handleFileSelect={handleFileSelect} errors={errors} />
      )} */}
      <ImportFromCloud
        history={history}
        errors={errors}
        business={business}
        title={title}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
      />
    </>
  );
};

export default ImportInvoiceComponent;
