import React from 'react';

import TableComponent from '../../../Components/Table/TableComponent';

const headCells = [
    { id: "requesterFullName", label: "Requester Full Name" },
    { id: "requesterPhone", label: "Phone" },
    { id: "requesterEmail", label: "Email" },
    { id: "invoiceAmount", label: "Invoice Amount" },
    { id: "invoiceStatus", label: "Status" },
    { id: "agreedAmount", label: "Agreed Amount"}
  ];
  
const PendingAgreementApprovalRequestsTable = ({ pendingBankApprovalRequests, toolbar, footer, selected, setSelected}) => {

    return (
        <TableComponent
            toolbar={toolbar}
            footer={footer}
            data={pendingBankApprovalRequests}
            selected={selected}
            setSelected={setSelected}
            headCells={headCells}
        />
    )
}

export default PendingAgreementApprovalRequestsTable
