import React from "react";

import TableComponent from "../../../Components/Table/TableComponent";

const headCells = [
    { id: "bankName", label: "Bank Name" },
    { id: "repFullName", label: "Representative's Name" },
    { id: "phone", label: "Phone Number",},
    { id: "email", label: "Email" },
];

const FinancesTable = ({
  finances,
  selected,
  setSelected,
  toolbar,
}) => {

return (
    <TableComponent
      toolbar={toolbar}
      data={finances}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
    />
  );
};

export default FinancesTable;
