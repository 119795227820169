const SECTION_HEADERS_FIN = {
  agreement: "Agreement",
  creditRequest: "Credit Request",
  factoringRequest: "Factoring Request",
  log: "Log",
  member: "Member",
};

const CHECKBOX_ID_INCREMENTS_FIN = {
  agreement: "Agreement",
  creditRequest: "CreditRequest",
  factoringRequest: "FactoringRequest",
  log: "Log",
  member: "Member",
};

const CHECKBOX_LABELS_FIN = {
  creditRequest: {
    view: "View Credit Request",
  },
  factoringRequest: {
    view: "View Factoring Request",
  },
  agreement: {
    approve: "Approve Agreement",
    deny: "Deny Agreement",
  },
  log: {
    view: "View Log",
  },
};

module.exports = {
  CHECKBOX_LABELS_FIN,
  CHECKBOX_ID_INCREMENTS_FIN,
  SECTION_HEADERS_FIN,
};
