import { makeStyles } from "@material-ui/core/styles";

export const useSupplierStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content2: {
    display: "flex",
    flex: "1 1 auto",
    overlflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentIn: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  box: {
    padding: 10,
    margin: 10,
    display: "inline-block",
    borderStyle: "dashed",
    width: "min-content",
  },
  grid: {
    flexGrow: 1,
    margin: "10px",
    width: "80%",
  },
  paper: {
    width: "100%",
    color: theme.palette.text.secondary,
  },
}));

export const useInviteDialogStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vh",
  },
  phone: {
    width: "-webkit-fill-available!important",
  },
  paper: {
    padding: 10,
    margin: 5,
    textAlign: "-webkit-center",
  },
}));
