import React from 'react';

import TableComponent from '../../Components/Table/TableComponent';

const headCells = [
    { id: "createdAt", label: "Date" },
    { id: "doneBy", label: "Done By" },
    { id: "detail", label: "Details" },
    { id: "reason", label: "Reason" },
    { id: "action", label: "Action" }
];

const ActivityLogsTable = ({ logs, selected, setSelected}) => {


    return (
        <TableComponent
            data={logs}
            selected={selected}
            setSelected={setSelected}
            headCells={headCells}
        />
    )
}
export default ActivityLogsTable
