import { Button, Grid, Typography } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasePage from "../../Components/BasePage";
import ButtonBase from "../../Components/shared/ButtonBase";
import TableComponent from "../../Components/Table/TableComponent";
import { useUiContext } from "../../Contexts/UiContext";
import { getMoneyTransactions, getSelfBankAccounts, getUserRemainingBalance } from "../../store/actions/bankingActions";
import ReceiptPDF from "./ReceiptPDF";
import TransferMoneyDialog from "./TransferMoneyDialog";

const headCells = [
  { id: "sent_at", label: "Date" },

  { id: "amount_sent", label: "Amount" },
  { id: "beneficiary_name", label: "Beneficiary Name" },
  { id: "beneficiary_account_number", label: "Beneficiary ACCT" },
  { id: "beneficiary_sort_code", label: "Beneficiary Sort Code" },

  { id: "remitter_account_number", label: "From - Account Number" },
  { id: "remitter_sort_code", label: "From - Sort code" },
  { id: "receipt_id", label: "Ref" },
];

const TransferMoney = ({ business, history }) => {
  const dispatch = useDispatch();
  const { setErrors } = useUiContext();

  const [userHasTransferMoneyBalance, setUserHasBalance] = useState(false);
  const [openTransferMoneyDialog, setOpenTransferMoneyDialog] = useState(false);

  const [afterRender, setAfterRender] = useState(false); // internal state
  const [selected, setSelected] = useState([]);
  const [canUpgradeToTransfer, setCanUpgradeToTransfer] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);
  let dailyRemainingBalance = useSelector((state) => state?.banking?.daily_remaining_balance);

  let transactionsLoading = useSelector((state) => state.banking.loading);
  let transactions = useSelector((state) => state.banking.transactions);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let transferToken = business?.payeda_package;
    let canTransferMoney = business.canTransferMoney;
    let isAwaitingApproval = business.awaitingTransferMoneyApproval || business.awaitingReceivePaymentApproval;

    if (business && business.company_details) {
      if (!canTransferMoney && !isAwaitingApproval) {
        setCanUpgradeToTransfer(true);
      }

      if (transferToken && Object.keys(transferToken).length > 1) {
        setUserHasBalance(true);
      } else {
        setUserHasBalance(false);
      }
    }
    setAfterRender(true);
  }, [business]);

  useEffect(() => {
    if (!transactionsLoading) {
      dispatch(getMoneyTransactions());
      dispatch(getSelfBankAccounts());
      dispatch(getUserRemainingBalance());
    }
    // eslint-disable-next-line
  }, [afterRender]);

  const renderNotApproved = () => {
    return (
      <>
        {!business?.canTransferMoney && <Typography>You can't transfer money yet!</Typography>}
        {(business?.awaitingTransferMoneyApproval || business?.awaitingReceivePaymentApproval) && <Typography>Your account is awaiting approval for transfering money</Typography>}
      </>
    );
  };

  const renderPackageNotFound = () => {
    return (
      <>
        {business && (
          <>
            <Typography>You don't have transfer money membership</Typography>
            <Button color="primary" variant="outlined" onClick={() => history.push("/dashboard/buy-plans")}>
              Buy transfer membership
            </Button>
          </>
        )}
      </>
    );
  };

  const renderIfDidNotConnectAnyBankAccount = () => {
    return (
      <>
        {(!business.bank_accounts || business?.bank_accounts?.length === 0) && (
          <>
            {/* <Typography>To start transferring funds, you need to verify your account holder information by linking a bank account.</Typography>
            <Button color="primary" variant="outlined" onClick={() => history.push("/dashboard/bank-accounts")}>
              Link a bank account
            </Button> */}
          </>
        )}
      </>
    );
  };

  const renderUpgradeToTransfer = () => {
    return (
      <>
        <Button color="primary" variant="outlined" onClick={() => history.push("/dashboard/update-business?type=transfer_money")}>
          Update your account to transfer money
        </Button>
      </>
    );
  };
  //9nSGpFO1afSFleWn5CbW
  const renderTransactionsTable = () => {
    return (
      <>
        <TableComponent
          data={transactions}
          selected={selected}
          setSelected={setSelected}
          headCells={headCells}
          isLoading={transactionsLoading}
          shouldGetData={false}
          shouldPaginate={false}
          shouldSearch={false}
          shouldView={true}
        />
      </>
    );
  };

  const renderTransferMoney = () => {
    return (
      <Grid container spacing={3} style={{ marginBottom: "10px", marginLeft: "10px" }}>
        <Grid item>
          {" "}
          <Button variant="outlined" color="primary" onClick={() => setOpenTransferMoneyDialog(true)}>
            Transfer Money
          </Button>
        </Grid>
        <Grid item>
          {selected[0] && downloadPDF ? (
            <>
              <PDFDownloadLink
                document={<ReceiptPDF invoiceData={selected[0]} />}
                fileName={selected[0].invoice_number}
                style={{
                  textDecoration: "none",
                  padding: "16px",
                  color: "#4a4a4a",
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #4a4a4a",
                  borderRadius: "20px",
                  width: "120px",
                  height: "50px",
                }}
              >
                {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download")}
              </PDFDownloadLink>
            </>
          ) : (
            <>{selected.length > 0 && <ButtonBase tooltip="Prepare PDF" text="PDF" backColor="#00AFA1" textColor="white" onClick={() => setDownloadPDF(true)} fontSize="400" />}</>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <BasePage navigation="Dashboard/Transfer Money" business={business} canView={user?.permissions?.transferMoney?.create}>
      {renderNotApproved()}
      {business && renderIfDidNotConnectAnyBankAccount()}
      {!(business.awaitingTransferMoneyApproval || business.awaitingReceivePaymentApproval) && !userHasTransferMoneyBalance && !canUpgradeToTransfer && renderPackageNotFound()}

      {canUpgradeToTransfer && renderUpgradeToTransfer()}
      {userHasTransferMoneyBalance && dailyRemainingBalance && user?.permissions?.transferMoney?.create && renderTransferMoney()}
      {/* {renderSuccessDialog()} */}

      {transactions && transactions.length > 0 && renderTransactionsTable()}
      {openTransferMoneyDialog && <TransferMoneyDialog open={openTransferMoneyDialog} setOpen={setOpenTransferMoneyDialog} setErrors={setErrors} business={business} />}
    </BasePage>
  );
};

export default TransferMoney;
