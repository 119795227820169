import { Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BasePage from "../../../Components/BasePage";
import { createIncomingInvoices } from "../../../store/actions/invoiceActions";
import DialogComponent from "./../../../Components/Dialogs/DialogComponent";
import { useUiContext } from "./../../../Contexts/UiContext";
import ImportInvoiceComponent from "./../ImportInvoiceComponent";
import InvoiceComponent from "./../InvoiceComponent";
import { useImportInvoiceStyles } from "./../InvoiceStyles";

const ImportIncomingInvoice = ({
  history,
  business,
  tenants,
  cloudInvoices,
}) => {
  let dispatch = useDispatch();

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);

  const classes = useImportInvoiceStyles();
  const { loading, errors } = useUiContext();

  const handleShowInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setInvoiceDialogOpen(true);
  };

  const handleSubmitInvoices = () => {
    let data = [];
    invoices.forEach((invoice) => {
      let serialised = JSON.stringify(invoice.invoice_lines);
      invoice["invoice_lines"] = serialised;
      data.push(invoice);
    });
    dispatch(createIncomingInvoices({ data }, history));
  };

  const renderInvoices = () => {
    return (
      <Paper
        className={classes.invoicePaper}
        style={{ padding: "10px" }}
        elevation={4}
      >
        {invoices &&
          invoices.map((invoice) => (
            <Grid container spacing={3} key={invoice.invoice_number}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Invoice No: {invoice.invoice_number}
                </Typography>
                <Typography variant="body2">
                  Issued Date: {invoice.issued_date}
                </Typography>
                <Typography variant="body2">
                  Due Date: {invoice.due_date}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Customer Name: {invoice.customer_name}
                </Typography>
                <Typography variant="body2">
                  Erp No: {invoice.erp_no}
                </Typography>
                <Button
                  onClick={() => handleShowInvoice(invoice)}
                  variant="outlined"
                >
                  Show
                </Button>
              </Grid>
            </Grid>
          ))}
      </Paper>
    );
  };

  return (
    <BasePage navigation="Dashboard/ImportIncomingInvoices" business={business}>
      <ImportInvoiceComponent
        invoices={invoices}
        setInvoices={setInvoices}
        tenants={tenants}
        cloudInvoices={cloudInvoices}
        title="Incoming Invoice"
        loading={loading}
        errors={errors}
        business={business}
        history={history}
      />
      {renderInvoices()}
      <DialogComponent
        open={invoiceDialogOpen}
        setOpen={setInvoiceDialogOpen}
        title={`Invoice No: ${selectedInvoice.invoice_number}`}
        withActions={false}
      >
        <InvoiceComponent invoice={selectedInvoice} />
      </DialogComponent>
      <Button
        onClick={handleSubmitInvoices}
        color="primary"
        variant="contained"
      >
        Submit
      </Button>{" "}
    </BasePage>
  );
};

export default ImportIncomingInvoice;
