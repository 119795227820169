let liveUrl;
let localUrl;
let uiAPIBase;

let re_env = "live" //live, dev

console.log("RE env = ", re_env);
if (re_env === "sandbox") {
  liveUrl = "https://europe-west3-payeda-sandbox.cloudfunctions.net";
  localUrl = "http://localhost:5000/payeda-sandbox/europe-west3";
  uiAPIBase = "https://payeda-sandbox.web.app";
} else if (re_env === "dev") {
  // liveUrl = "https://europe-west1-payeda-dev-8fa74.cloudfunctions.net";
  liveUrl = "https://dev.payeda.net/api";
  localUrl = "http://localhost:5000";
  uiAPIBase = "https://dev.payeda.net";
} else if (re_env === "live") {
  liveUrl = "https://console.payeda.net/api";
  // eslint-disable-next-line
  // 5001 port enable for live
  localUrl = "http://localhost:5001";
  uiAPIBase = "https://console.payeda.net";
}

export const API_BASE = liveUrl;
export const UI_API_BASE = uiAPIBase;
export const REACT_ENV = re_env;
