import React from "react";

import TableComponent from "../../../Components/Table/TableComponent";

const headCells = [
  { id: "name", label: "Name" },
  { id: "country_city", label: "Country" },
  { id: "account_type", label: "Account Type" },

  { id: "business_model", label: "Business Model" },
  { id: "user_email", label: "User Email" },
];

const VerifiedBusinessesTable = ({ businessesToDeny, selected, setSelected, toolbar, getAllData }) => {
  return (
    <TableComponent
      toolbar={toolbar}
      data={businessesToDeny}
      selected={selected}
      setSelected={setSelected}
      headCells={headCells}
      getAllData={getAllData}
      shouldGetData={false}
      shouldView={true}
    />
  );
};

export default VerifiedBusinessesTable;
