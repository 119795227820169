import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import countryList from "react-select-country-list";
import DialogComponent from "../../Components/Dialogs/DialogComponent";
import DropdownComponent from "../../Components/DropdownComponent";
import PhoneInputComponent from "./../../Components/PhoneInputComponent";

const customStyles = {
  menu: () => ({
    backgroundColor: "white",
  }),
};

const CustomerDialog = ({ open, setOpen, title, description, errors, onSubmit, defaultValues, business, isEdit }) => {
  const { register, control, handleSubmit, reset } = useForm();

  let { address: businessAddress, currency } = business.company_details;

  const [countryValue, setCountryValue] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [countryLabel, setCountryLabel] = useState("");
  const [platformUsage, setPlatformUsage] = useState("");

  useEffect(() => {
    if (business && business.account_settings) {
      let platformUsage = business?.account_settings?.invoice_settings?.platform_usage;
      if (platformUsage) {
        setPlatformUsage(platformUsage.isErp ? "erp" : "manual");
      }
    }
  }, [business]);
  const getCountries = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountryCode(value.value);
    setCountryLabel(value.label);
    setCountryValue(value);
  };

  useEffect(() => {
    setCountryValue(defaultValues?.country ? defaultValues?.country : "");

    setCountryCode(defaultValues?.country_code ? defaultValues?.country_code : business?.country_code ? business.country_code : "");
    setCountryLabel(defaultValues?.country ? defaultValues?.country : businessAddress?.country ? businessAddress?.country : "");

    reset(defaultValues);
  }, [defaultValues, reset, business, businessAddress?.country]);

  const renderAddress = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            id="address_line_1"
            name="address_line_1"
            label="Address"
            error={errors.address_line_1 ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="address_line_2"
            name="address_line_2"
            label="Address"
            error={errors.address_line_2 ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <TextField
            variant="outlined"
            id="post_code"
            name="post_code"
            label="Postcode"
            error={errors.post_code ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            readOnly
            variant="outlined"
            id="country"
            name="country"
            label="Country "
            value={countryLabel}
            error={errors.Country ? true : false}
            helperText={errors.Country ?? ""}
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="city"
            name="city"
            label="City"
            error={errors.city ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="region_province"
            name="region_province"
            label="Region/Province/County "
            error={errors.region_province ? true : false}
            helperText="Optional"
            type="text"
            fullWidth={true}
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
      withActions={platformUsage === "manual" ? true : false}
    >
      <TextField
        margin="dense"
        variant="outlined"
        required
        autoFocus
        id="name"
        name="name"
        label="Company Name or Full Name"
        error={errors.name ? true : false}
        helperText={errors.name ?? ""}
        fullWidth
        inputRef={register}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <br />
      <br />

      <Select
        required
        styles={customStyles}
        id="country"
        name="country"
        options={getCountries}
        value={countryValue}
        onChange={changeHandler}
        placeholder={countryValue || "Select a country"}
        inputRef={register}
      />
      <br />
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <TextField
            required
            readOnly
            variant="outlined"
            key="country_code"
            id="country_code"
            name="country_code"
            label="Country Code"
            error={errors.country_code ? true : false}
            helperText={errors.country_code ?? ""}
            value={countryCode}
            type="text"
            fullWidth={true}
            inputRef={register}
            style={{ backgroundColor: "#D9EDF7" }}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            id="tax_id"
            name="tax_id"
            label="Tax ID or ID"
            error={errors.tax_id ? true : false}
            helperText={errors.tax_id ?? ""}
            fullWidth
            variant="outlined"
            inputRef={register}
            inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
          />
        </Grid>
      </Grid>
      <TextField
        required
        margin="dense"
        id="erp_no"
        name="erp_no"
        label="Customer No (ERP)"
        error={errors.erp_no ? true : false}
        helperText={errors.id ?? "The ID assigned to the customer in your system. If you don't have one you can use Tax ID or National ID"}
        fullWidth
        variant="outlined"
        inputRef={register}
        inputProps={{ readOnly: isEdit }}
      />
      <TextField
        margin="dense"
        id="tax_admin"
        name="tax_admin"
        label="Tax Administration"
        fullWidth
        inputRef={register}
        variant="outlined"
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <div>
        <PhoneInputComponent errors={errors} register={register} control={control} disabled={platformUsage === "erp" ? true : false} />
        <Typography color={errors.phone ? "error" : "default"} variant="caption" component="p">
          {errors.phone ? errors.phone : "Required for invitation request."}
        </Typography>
      </div>

      <Divider />

      <TextField
        margin="dense"
        id="full_name"
        name="full_name"
        label="Representative Name"
        helperText="Optional"
        inputRef={register}
        variant="outlined"
        fullWidth
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <TextField
        margin="dense"
        id="email"
        name="email"
        label="Email"
        helperText="Optional"
        fullWidth
        variant="outlined"
        inputRef={register}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <TextField
        margin="dense"
        id="fax_number"
        name="fax_number"
        label="Fax No"
        helperText="Optional"
        fullWidth
        variant="outlined"
        inputRef={register}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />

      <DropdownComponent
        required
        id="currency"
        name="currency"
        label="Currency"
        defaultValue={currency}
        items={[{ value: "GBP", label: "GBP" }]}
        error={errors.currency ? true : false}
        helperText="Invoice Currency"
        control={control}
        inputProps={{ readOnly: platformUsage === "erp" ? true : false }}
      />
      <h2>Address</h2>
      {renderAddress()}

      <br />
    </DialogComponent>
  );
};

export default CustomerDialog;
