import React from "react";
import { Link } from "react-router-dom";

import { Button, IconButton, Tooltip } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import AddButton from "./../../Components/shared/AddButton";
import TableToolbarComponent from "./../../Components/Table/TableToolbarComponent";
import { usePermissionsContext } from "./../../Contexts/PermissionsContext";
import canPerformAction from "./../../util/permissions/canPerformAction";

const SuppliersTableToolbar = ({
  numSelected,
  header,
  setCreateDialogOpen,
  setEditDialogOpen,
  setInviteDialogOpen,
  setDeleteDialogOpen,
  setBankAccountDialogOpen,
  history,
}) => {
  const { permissions } = usePermissionsContext();

  return (
    <TableToolbarComponent header={header} numSelected={numSelected}>
      <>
        {numSelected > 0 ? (
          <>
            {numSelected > 0 && (
              <>
                <Tooltip title="Invite">
                  <IconButton
                    onClick={() => setInviteDialogOpen(true)}
                    style={{ color: "#14980B" }}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Tooltip>

                {canPerformAction("suppliers", permissions, "edit") && (
                  <>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => setEditDialogOpen(true)}
                        style={{ color: "#1F036D" }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Tooltip title="Add Bank Account">
                  <IconButton
                    onClick={() => setBankAccountDialogOpen(true)}
                    style={{ color: "#ecb613" }}
                  >
                    <AccountBalanceIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {canPerformAction("suppliers", permissions, "remove") && (
              <Tooltip title="Delete">
                <IconButton onClick={() => setDeleteDialogOpen(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <div style={{ minWidth: "1000px" }}>
              <div style={{ float: "left" }}>
                {" "}
                <Button
                  onClick={() => history.push("/dashboard/merged-accounts")}
                >
                  Get Inviter Suppliers
                </Button>
              </div>
              <div style={{ float: "right" }}>
                {" "}
                {canPerformAction("suppliers", permissions, "create") && (
                  <>
                    <Tooltip title="Import">
                      <IconButton
                        component={Link}
                        to="/dashboard/import-suppliers"
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {canPerformAction("suppliers", permissions, "create") && (
                  <>
                    <AddButton onClick={() => setCreateDialogOpen(true)} />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </TableToolbarComponent>
  );
};

export default SuppliersTableToolbar;
