import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import DialogComponent from "./DialogComponent";

const BuyPackageDialog = ({ open, setOpen, onSubmit }) => {
  const history = useHistory();
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={"Information"}
      onSubmit={onSubmit}
      withActions={false}
    >
      <Typography>
        We couldn't found any package associated with your acccount
      </Typography>
      <br />
      <Typography>
        You can start using our platform by choosing a package suitable for your
        company.
        <br />
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <Button
          color="primary"
          onClick={() => history.push("/dashboard/buy-plans")}
        >
          Go to packages page
        </Button>
      </div>
    </DialogComponent>
  );
};

export default BuyPackageDialog;
