import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { UI_API_BASE } from "../../store/env";
import { exchanceCodeForTrueLayerToken } from "../../store/actions/bankingActions";
import { useLocation } from "react-router-dom";

const ConnectBankAccountResult = ({ paymentID, paymentHasError }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [afterRender, setAfterRender] = useState(false); // internal state
    const [exchangeCode, setExchangeCode] = useState("");
    const [linkContainsError, setLinkContainsError] = useState(false);
    const [userId, setUserId] = useState("");

    let isLoading = useSelector((state) => state.banking.connectBankAccountLoading);
    let isSuccess = useSelector((state) => state.banking.connectBankAccountSuccess);
    let isFailed = useSelector((state) => state.banking.connectBankAccountFailed);

    console.log("isLoading", isLoading, "isSUcess", isSuccess, "isFa", isFailed);
    const submitExchangeCode = () => {
        dispatch(exchanceCodeForTrueLayerToken(exchangeCode, userId))
    };

    useEffect(() => {
        setAfterRender(true); // (1) will be called after DOM rendered
    }, []);

    useEffect(() => {
        let code;
        let error;
        let state; // contains userId

        code = new URLSearchParams(location.search).get("code");
        error = new URLSearchParams(location.search).get("error");
        state = new URLSearchParams(location.search).get("state");

        setExchangeCode(code);
        setUserId(state);

        if (error) {
            setLinkContainsError(true)
        }
    }, [afterRender])

    useEffect(() => {
        if (afterRender) {
            if (linkContainsError) { errorAlert() }
            else if (exchangeCode) { submitExchangeCode(exchangeCode) }
        }
    }, [afterRender, linkContainsError, exchangeCode]);

    useEffect(() => {
        if (isLoading) {
            loadingAlert();
        }
        // eslint-disable-next-line
    }, [isLoading]);

    useEffect(() => {
        console.log("isSccess", isSuccess, isFailed)
        if (afterRender && isSuccess === true) {
            successAlert();

        } else if (afterRender && (isFailed === true)) {
            errorAlert();
        }
        // eslint-disable-next-line
    }, [isSuccess, isFailed, afterRender]);

    useEffect(() => {
        if (isFailed === true) {
            errorAlert();
        }
        // eslint-disable-next-line
    }, [isFailed]);



    const successAlert = () => {
        Swal.fire({
            title: "Successfully Linked Bank Account",
            icon: "success",
            confirmButtonText: "Go to Console",
        }).then(async (val) => {
            if (val.isConfirmed) {
                window.location.reload();
                window.location.href = `${UI_API_BASE}/dashboard/bank-accounts`;
            }
        });
    };

    const errorAlert = async () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            confirmButtonText: "RETRY",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Go to dashboard",
        }).then(async (val) => {
            if (val.isConfirmed) {
                await submitExchangeCode();
            }
            if (val.isDismissed) {
                window.location.href = `${UI_API_BASE}/dashboard`;
            }
        });
    };

    const loadingAlert = () => {
        Swal.fire({
            title: "",
            text: "Loading...",
            showSpinner: true,
            footer: "Do not close this window, this may take a few minutes.",
            icon: "https://www.boasnotas.com/img/loading2.gif",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
        });
    };


    return <></>;
};

export default ConnectBankAccountResult;
