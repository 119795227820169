import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import DialogComponent from "./DialogComponent";

const UpgradeBusinessDialog = ({ open, setOpen, onSubmit, upgradeLink }) => {
  const history = useHistory();
  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={"Information"}
      onSubmit={onSubmit}
      withActions={false}
    >
      <Typography>
        To use this feature, you need to upgrade your account. In order to
        upgrade, you need to fill in some information, if you want, click the
        button.{" "}
      </Typography>
      <br />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={() => history.push(upgradeLink)}
        >
          Upgrade Your Account
        </Button>
      </div>
    </DialogComponent>
  );
};

export default UpgradeBusinessDialog;
