const SECTION_HEADERS_BUS = {
  customer: "Customer",
  supplier: "Supplier",
  product: "Product",
  incomingInvoice: "Incoming Invoice",
  activityLog: "Activity Log",
  bankingLog: "Banking Log",
  tokenSpending: "Token Spending",
  plan: "Plan",
  connectCloudErp: "Connect Cloud Erp",
  outgoingInvoice: "Outgoing Invoice",
  member: "Member",
  bankAccount: "Bank Account",
  document: "Document",
  transferMoney: "Transfer Money",
};
//   checkBoxIdIncrements
const CHECKBOX_ID_INCREMENTS_BUS = {
  customer: "Customer",
  supplier: "Supplier",
  product: "Product",
  incomingInvoice: "IncomingInvoice",
  activityLog: "ActivityLog",
  bankingLog: "BankingLog",
  tokenSpending: "TokenSpending",
  plan: "Plan",
  connectCloudErp: "ConnectCloudErp",
  outgoingInvoice: "OutgoingInvoice",
  member: "Member",
  bankAccount: "BankAccount",
  document: "Document",
  transferMoney: "transferMoney",
};
// checkBoxLabels
const CHECKBOX_LABELS_BUS = {
  customer: {
    create: "Create Customer",
    view: "View Customer",
    edit: "Edit Customer",
    remove: "Remove Customer",
  },
  supplier: {
    create: "Create Supplier",
    view: "View Supplier",
    edit: "Edit Supplier",
    remove: "Remove Supplier",
  },
  product: {
    create: "Create Product",
    view: "View Product",
    edit: "Edit Product",
    remove: "Remove Product",
  },
  incomingInvoice: {
    approve: "Approve Incoming Invoice",
    askExtension: "Extension Request Incoming Invoice",
    create: "Create Incoming Invoice",
    deny: "Deny Incoming Invoice",
    view: "View Incoming Invoice",
    remove: "Remove Incoming Invoice",
    askCredit: "Ask Credit",
    makePayment: "Make Payment ",
    viewReceipt: "View Receipt",
  },
  // log: {
  //   view: "View Logs",
  // },
  activityLog: {
    view: "View Activity Log",
  },
  bankingLog: {
    view: "View Banking Log",
  },
  tokenSpending: {
    view: "View Token Spending",
  },
  plan: {
    create: "Buy Plan",
    view: "View Plan",
  },
  connectCloudErp: {
    create: "Connect Cloud Erp",
  },
  outgoingInvoice: {
    earlyPayment: "Make Early Payment",
    extend: "Approve Outgoing Invoice",
    create: "Create Outgoing Invoice",
    send: "Send Outgoing Invoice",
    view: "View Outgoing Invoice",
    remove: "Remove Outgoing Invoice",
    askFactoring: "Ask Factoring",
    viewReceipt: "View Receipt",
  },

  member: {
    create: "Create Member",
    view: "View Member",
    edit: "Edit Member",
    remove: "Remove Member",
  },
  bankAccount: {
    create: "Add Bank Account",
    view: "View Bank Account",
    remove: "Delete Bank Account",
  },
  transferMoney: {
    create: "Transfer Money",
  },
  document: {
    create: "Create Document",
    view: "View Document",
  },
};

module.exports = {
  SECTION_HEADERS_BUS,
  CHECKBOX_ID_INCREMENTS_BUS,
  CHECKBOX_LABELS_BUS,
};
