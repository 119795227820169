import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from './../../util/constants/general';

export const useAdminStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    content: {
        flexGrow: 0,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        paddingLeft: drawerWidth + 40,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }
}));