import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from "../../Components/Table/TableComponent";
import { getAllOffers } from "../../store/actions/financeActions";
import FinanceBasketTableToolbar from "./FinanceBasketTableToolbar";

const headCells = [
  { id: "supplier_name", label: "Supplier Name" },

  {
    id: "advance_payment_percentage",
    label: "Advance Payment % ",
  },
  { id: "advance_payment_amount", label: "Advance Payment Amount" },
  { id: "advance_payment_maturity", label: "Advance Payment Maturity" },

  { id: "expense_amount", label: "Expense Amount" },

  { id: "remaining_payment_amount", label: "Remaining Payment Amount" },
  { id: "remaining_payment_maturity", label: "Remaining Payment Maturity" },

  { id: "requestedTotalAmount", label: "Requested Amount" },
  { id: "offeredTotalAmount", label: "Offered Amount" },

  { id: "status", label: "Status" },
  { id: "createdAt", label: "Offered at" },
];

const FinanceOffersTable = () => {
  let isLoading = useSelector((state) => state.finance.isLoading);
  let financeOffers = useSelector((state) => state.finance.financeOffers);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getAllOffers());
  }, []);
  const financeBasketTableToolbar = () => {
    return <FinanceBasketTableToolbar header="Finance Basket" selected={selected} setSelected={setSelected} />;
  };

  return (
    <>
      <TableComponent
        toolbar={financeBasketTableToolbar()}
        data={financeOffers}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isLoading={isLoading}
        getAllData={getAllOffers}
        searchBarText="Search customers"
        shouldGetData={true}
        shouldPaginate={false}
        shouldView={true}
      />
    </>
  );
};

export default FinanceOffersTable;
