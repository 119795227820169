import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInviterInformation, mergeAccounts } from "../../../../store/actions/businessActions";
import BasePage from "./../../../../Components/BasePage";
import LoadingIcon from "./../../../../Components/LoadingIcon";
import InvitationCodeStep from "./InvitationCodeStep";
import { useLinkAccountStyle } from "./MergeAccountsStyle";
import ReviewYourCodeStep from "./ReviewYourCodeStep";

const MergeAccountsPage = ({ business, history }) => {
  const classes = useLinkAccountStyle();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [invitationCode, setInvitationCode] = useState({});
  const [progress, setProgress] = React.useState(0);
  const user = useSelector((state) => state.user);
  let inviterInformation = useSelector((state) => state.business.inviterInformation);

  const steps = ["Invitiation Code", "Review your code"];

  // React.useEffect(() => {
  //   setInviterInfo(inviterInformation);
  // }, [inviterInformation]);
  useEffect(() => {
    if (inviterInformation) {
      setTimeout(() => {
        setProgress(10);
        setStep(step + 1);
      }, 600);
    }
    // eslint-disable-next-line
  }, [inviterInformation]);

  const nextStep = async (data) => {
    await setInvitationCode(data);
    await dispatch(getInviterInformation({ inviteCode: data.invitationCode }));
  };

  const hanleMergeAccounts = async (data) => {
    await dispatch(mergeAccounts(data, history));
  };

  const previousStep = () => {
    dispatch({ type: "INVITER_DATA_FETCH_SUCCESS", payload: null });
    setInvitationCode({});
    setStep(step - 1);
  };

  const renderStepCounter = (step) => {
    return (
      <Stepper activeStep={step} className={classes.stepper} orientation="vertical">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent> {getStepContent(step)}</StepContent>
          </Step>
        ))}
      </Stepper>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return renderStepOne();
      case 1:
        if (progress === 0) {
          return <LoadingIcon />;
        } else if (Object.keys(inviterInformation).length > 1 || inviterInformation) {
          return renderStepTwo();
        }
        break;
      default:
        throw new Error("Unknown step");
    }
  };

  const renderStepOne = () => {
    return <InvitationCodeStep onSubmit={nextStep} defaultValues={invitationCode} />;
  };

  const renderStepTwo = () => {
    return <ReviewYourCodeStep previousStep={previousStep} defaultValues={invitationCode} info={inviterInformation} onSubmit={hanleMergeAccounts} />;
  };

  return (
    <BasePage navigation="Dashboard/Customers" business={business} canView={user?.permissions?.supplier?.create}>
      {renderStepCounter(step)}
    </BasePage>
  );
};

export default MergeAccountsPage;
