import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Controller } from "react-hook-form";
import { usePhoneInputStyles } from "./ComponentStyles";

const PhoneInputComponent = ({ errors, register, control, name, disabled }) => {
  const classes = usePhoneInputStyles();
  return (
    <div>
      <Controller
        as={
          <PhoneInput
            id={name ? name : "phone"}
            name={name ? name : "phone"}
            inputClass={classes.phone}
            containerClass={classes.phone}
            searchClass={classes.phone}
            inputStyle={{ height: "55px" }}
            isValid={!errors.phone}
            defaultErrorMessage={errors.phone ?? false}
            country={"gb"}
            inputRef={register}
            disabled={disabled}
          />
        }
        name={name ? name : "phone"}
        control={control}
      />
    </div>
  );
};

export default PhoneInputComponent;
